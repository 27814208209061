<template>
  <div class="row admin-page officePage">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-md-12 row">
      <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
        <div class="filter form-group row">
          <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
            <button
              class="col-md-12 btn btn-secondary text-left"
              style="padding-left: 8px"
              @click="handleSelection({ Id: -1, Name: '' })"
            >
              <i class="fe-settings"></i>
              Company Wide Settings
            </button>
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="Filter Offices"
              v-model="officesFilter"
              v-if="checkAuth(1454)"
            />
          </div>
          <div class="list col-md-12" v-show="isListReady">
            <div
              class="office item row"
              v-for="(item, index) in filteredOffice"
              :key="item.Id"
              :class="{ active: activeItem === item.Id }"
              :style="[
                index % 2 === 0
                  ? { 'background-color': '#F1EFEF' }
                  : { 'background-color': '#FFFFFF' },
              ]"
              @click="() => handleSelection(item)"
            >
              <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
                <i class="fe-settings"></i>
                <span style="margin-left: 8px">{{ item.Name }}</span>
              </span>
            </div>
          </div>
          <div
            v-if="isMobileDevice()"
            style="
              border-top: 1px solid rgb(0, 0, 0, 0);
              margin: 20px 0 0 9px;
              padding-bottom: 55px;
            "
            class="col-md-12 scrollToSelectedItem"
          ></div>
        </div>
      </div>
      <div
        class="col-xl-9 col-lg-6 col-md-12 col-sm-12 panelWrapper noMargin"
        v-if="isEditing"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-md-12">
              <input
                autocomplete="off"
                type="checkbox"
                id="AcceptAutoCall"
                class="checkbox-input form-control"
                v-model="selectedRow.IsAutoAcceptCall"
              />

              <label class="generalLabel">Auto Accept Calls</label>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="inboundCallTimeoutInput" class="generalLabel"
                >Inbound Call Timeout *</label
              >

              <input
                autocomplete="off"
                type="text"
                id="inboundCallTimeoutInput"
                maxlength="4"
                v-model="selectedRow.DefaultInboundCallTimeout"
                style="width: 80px !important"
                class="generalTextBox ml-2"
                @keypress="validateTimeout($event)"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3">
              <button
                type="button"
                style="width: 100%"
                class="btn btn-success btn-md waves-effect waves-light mt-2 mr-2"
                @click="saveCallSettings()"
                :disabled="isLoading"
              >
                Save Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import _orderby from "lodash";
import deepCopy from "@/helpers/deepCopy";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";

const init = {
  Id: 0,
  OfficeId: 0,
  IsAutoAcceptCall: false,
  DefaultInboundCallTimeout: 0,
};

export default {
  name: "InboundCallSettingsManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: {},
  data() {
    return {
      isListReady:false,
      isLoading: false,
      selectedRow: Object.assign({}, init),
      allowedKeyCodes: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57], //0-9
      outboundCallMethodId: 0,
      oldCallMethodId: 0,
      userOfficeId: 0,
      officesFilter: "",
      oldValue: "",
      activeOffice: null,
      isEditing: false,
      valueToRevert: deepCopy(init),
      activeItem: null,
      isCancelledBeforeSave: false,
      hasAccessOtherOffices: false,
      allSettings: [],
    };
  },
  computed: mapState({
    offices: (state) => state.globals.offices,

    filteredOffice() {
      return _orderby.orderBy(
        this.offices.filter((x) =>
          this.hasAccessOtherOffices
            ? x &&
              x.Name.toLowerCase().indexOf(this.officesFilter.toLowerCase()) >
                -1
            : x.Id == this.userOfficeId
        )
      );
    },
  }),
  async mounted() {
    this.$store.dispatch(globalTypes.GET_OFFICE_NAMES);

    let err, result;
    [err, result] = await this.$store.dispatch(
      types.GET_INBOUND_CALL_SETTINGS_ICSM
    );
    if (result && result.Data) {
      this.allSettings = deepCopy(result.Data);
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    if (!this.hasAccessOtherOffices) {
      let indx = this.offices.findIndex((x) => x.Id == this.userOfficeId);
      if (indx > -1) {
        this.handleOfficeSelection(this.offices[indx]);
      }
    }
    this.isListReady=true;
  },
  methods: {
    updateAllSettingsList() {
      let indx = this.allSettings.findIndex((x) => x.Id == this.selectedRow.Id);
      if (indx > -1) {
        this.allSettings[indx] = deepCopy(this.selectedRow);
      }
    },
    pushAllSettingsList() {
      let indx = this.allSettings.findIndex((x) => x.Id == this.selectedRow.Id);
      if (indx == -1) {
        this.allSettings.push(this.selectedRow);
      }
    },

    async handleSelection(row) {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = false;
            } else {
              this.isCancelledBeforeSave = true;
              return;
            }
          });
      }
      this.isEditing = true;
      if (!this.isCancelledBeforeSave) {
        let indx = this.allSettings.findIndex((x) => x.OfficeId == row.Id);
        if (indx > -1) {
          this.selectedRow = deepCopy(this.allSettings[indx]);
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = row.Id;
        } else {
          this.selectedRow = deepCopy(init);
          this.selectedRow.OfficeId = row.Id;
          this.oldValue = JSON.stringify(this.selectedRow);
          this.activeItem = row.Id;

          if (row.Id == -1) {
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.GET_COMPANY_CALL_SETTINGS
            );

            if (result && result.Data) {
              this.selectedRow.DefaultInboundCallTimeout =
                result.Data.DefaultInboundCallTimeout;
              this.selectedRow.IsAutoAcceptCall = result.Data.IsAutoAcceptCall;
              this.oldValue = JSON.stringify(this.selectedRow);
            }
          }
        }

        // this.callSettings = deepCopy(this.valueToRevert);
        // let indx = this.officeList.findIndex(
        //   (x) => x.Id == this.valueToRevert.Id
        // );
        // this.officeList[indx] = this.valueToRevert;
        // this.officeList.push();
        // this.valueToRevert = deepCopy(row);
        // this.oldValue = JSON.stringify(row);
        // this.activeItem = row.Id;
        // this.callSettings = row;
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    validateTimeout(event) {
      if (!this.allowedKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    async saveCallSettings() {
      this.isLoading = true;
      if (JSON.stringify(this.selectedRow) != this.oldValue) {
        if (this.selectedRow.OfficeId == -1) {
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.UPDATE_COMPANY_CALL_SETTINGS,
            {
              IsAutoAcceptCall: this.selectedRow.IsAutoAcceptCall,
              DefaultInboundCallTimeout:
                this.selectedRow.DefaultInboundCallTimeout,
            }
          );
          if (result) {
            this.$swal.fire("Success!", "Settings updated.", "success");
            this.oldValue = JSON.stringify(this.selectedRow);
          }
        } else {
          let type =
            this.selectedRow.Id > 0
              ? types.UPDATE_INBOUND_CALL_SETTINGS_ICSM
              : types.INSERT_INBOUND_CALL_SETTINGS_ICSM;
          let err, result;
          [err, result] = await this.$store.dispatch(type, this.selectedRow);
          if (result) {
            this.selectedRow = deepCopy(result.Data);
            this.oldValue = JSON.stringify(this.selectedRow);
            if (type == types.UPDATE_INBOUND_CALL_SETTINGS_ICSM) {
              this.updateAllSettingsList();
            } else {
              this.pushAllSettingsList();
            }
            this.$swal.fire("Success!", "Settings updated.", "success");
          }
        }
      } else {
        this.$swal.fire("Warning!", "No changes detected.", "warning");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
