<template>
  <div v-show="(tabId != null && tabId == Customer.Id) || tabId == null">
    <div
      v-if="isLoading"
      class="policyLoader noPadding"
      style="left: -12px; width: 105%; z-index: 400"
    >
      <div class="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div>
      <div class="row">
        <div
          class="col-md-12 policy-header"
          style="margin: 0; position: relative; padding: 0"
          :style="
            attorneyOfficeName != ''
              ? 'background: linear-gradient(180deg, rgba(244,249,255,1) 0%, rgba(255 219 219) 100%)'
              : 'background-color: #f4f9ff'
          "
        >
          <div class="row mt-2" style="position: relative">
            <div class="col-xl-8 col-lg-12 col-md-12">
              <div style="display: flex; align-items: center">
                <div class="flex-fill pl-2" style="flex-basis: fit-content">
                  <span
                    class="generalLabel"
                    style="font-size: 18px; color: #2a404c"
                    >{{ Customer.CustomerProfile.FirstName }}
                    {{ Customer.CustomerProfile.LastName }}
                  </span>

                  <span
                    v-if="stillInTermSettlement"
                    class="generalLabel ml-2"
                    style="font-size: 18px; color: #f1556c"
                    >TERM SETTLEMENT
                  </span>

                  <span
                    v-if="attorneyOfficeName != ''"
                    style="font-size: 18px; color: #f1556c"
                    class="generalLabel ml-2"
                  >
                    {{ this.attorneyOfficeName }}
                  </span>
                </div>
                <div style="flex-basis: fit-content">
                  <div style="min-width: 250px !important; margin-right: 4px">
                    <button
                      type="button"
                      @click="handleSave"
                      :id="'CustomerSaveButton' + customerId.toString()"
                      :disabled="invalid || saving"
                      class="btn generalButton float-right ml-1 mr-1"
                      style="background-color: #3bafda; color: #ffffff"
                    >
                      <i
                        v-if="saving"
                        class="fas fa-spinner"
                        style="font-size: 18px"
                      ></i>
                      <i
                        v-if="!saving"
                        class="fas fa-save"
                        style="font-size: 18px; margin-top: 5px"
                      ></i>
                    </button>

                    <button
                      type="button"
                      @click="handleFavorite"
                      v-if="Customer && Customer.Id > 0"
                      :disabled="saving || Customer.Id == 0"
                      class="btn generalButton float-right ml-1 mr-1"
                      style="color: #3bafda"
                      title="Favorite"
                    >
                      <i
                        style="font-size: 18px; margin-top: 5px"
                        v-if="!isFavorited"
                        class="far fa-star"
                      ></i>
                      <i
                        style="font-size: 18px; margin-top: 5px"
                        v-if="isFavorited"
                        class="fas fa-star"
                      ></i>
                    </button>
                    <button
                      type="button"
                      @click="handleSms"
                      :disabled="saving"
                      v-if="
                        Customer.CustomerProfile.CustomerPhones &&
                        Customer.CustomerProfile.CustomerPhones.length > 0 &&
                        isAuthorizedToSee(6231)
                      "
                      class="btn generalButton float-right ml-1 mr-1"
                      style="background-color: #3bafda; color: #ffffff"
                      title="Send SMS"
                    >
                      <i
                        class="fas fa-sms"
                        style="font-size: 18px; margin-top: 5px"
                      ></i>
                    </button>
                    <button
                      v-if="hasAuth(3912)"
                      type="button"
                      class="btn generalButton float-right ml-1 mr-1"
                      style="background-color: #3bafda; color: #fff"
                      @click="openQuickEmails()"
                    >
                      <i
                        class="fas fa-envelope"
                        style="font-size: 18px; margin-top: 5px"
                      ></i>
                    </button>

                    <!-- <button
                    v-if="false"
                    type="button"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #1abc9c; color: #fff"
                  >
                    Next Client
                  </button> -->
                    <!-- <button
                    v-if="false"
                    type="button"
                    class="btn generalButton float-right ml-1 mr-1"
                    style="background-color: #f1556c; color: #fff"
                  >
                    Previous Client
                  </button> -->
                  </div>
                </div>
              </div>
              <div class="col-12 mt-1">
                <div
                  class="balanceSummary"
                  v-if="
                    checkAuth(54124) &&
                    (next3MonthsCashFlowList.length > 0 ||
                      balanceSummaryText != '')
                  "
                >
                  <label
                    v-if="balanceSummaryText != ''"
                    style="font-size: 12px"
                    class="generalLabel mr-1"
                    >Balance:</label
                  >
                  <label class="mr-1" style="font-size: 12px">{{
                    balanceSummaryText
                  }}</label>

                  <label
                    v-if="next3MonthsCashFlowList.length > 0"
                    style="font-size: 12px"
                    class="generalLabel mr-1"
                    >Cash Flow:</label
                  >
                  <label style="font-size: 12px" class="">
                    <span
                      :key="item.MonthName"
                      v-for="item in next3MonthsCashFlowList"
                      style="margin-right: 8px"
                    >
                      {{ item.MonthName }}
                      <span v-bind:class="{ editCtextRed: item.Cash < 0 }">{{
                        formatMoney(item.Cash)
                      }}</span>
                    </span>
                  </label>
                  <label style="font-size: 12px" class="generalLabel mr-1"
                    >Last Cleared Draft Dt:</label
                  >
                  <label class="mr-1" style="font-size: 12px">
                    {{
                      lastClearedDraftDate == "" ? "N/A" : lastClearedDraftDate
                    }}</label
                  >
                  <label style="font-size: 12px" class="generalLabel mr-1"
                    >Next Draft Dt:</label
                  >
                  <label class="mr-1" style="font-size: 12px">{{
                    nextDraftDate == "" ? "N/A" : nextDraftDate
                  }}</label>
                  <label
                    style="font-size: 12px; color: #f1556c"
                    class="mr-1"
                    v-if="
                      clientInfo &&
                      clientInfo.Global &&
                      clientInfo.Global.DraftsAuthorized == 'N'
                    "
                    >Not Auth</label
                  >
                  <label
                    style="font-size: 12px; color: #f1556c"
                    v-if="
                      clientInfo &&
                      clientInfo.Global &&
                      clientInfo.Global.DraftsWillProcess == 'N'
                    "
                    >Not Process</label
                  >
                </div>
              </div>
              <div class="row" style="padding: 0 0 0 3px">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin"></div>
                </div>
              </div>
              <div class="row" style="padding: 0 0 0 3px; margin-top: 4px">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin">
                    <label
                      style="font-size: 14px"
                      class="generalLabel"
                      for="assignedAgent"
                      >Assigned Agent</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      id="assignedAgent"
                      class="headerSelectBox"
                      v-model="Customer.SalesUserId"
                      v-enableIfAuthorized="'1071'"
                    >
                      <option :value="null">Unassigned</option>
                      <option
                        v-for="item in finalSalesAgentList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin">
                    <label
                      style="font-size: 14px"
                      class="generalLabel"
                      for="office"
                      >Office</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      :disabled="!hasAuth(1454) && !hasAuth(55011)"
                      id="office"
                      class="headerSelectBox"
                      v-model="Customer.OfficeId"
                      @change="officeChanged"
                    >
                      <option :disabled="Customer.Id > 0" :value="null">
                        Unassigned
                      </option>
                      <option
                        v-for="item in filteredOffices"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin">
                    <label
                      style="font-size: 14px"
                      class="generalLabel"
                      for="assignedAgent"
                      >Status</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="Customer.StatusId"
                      id="customerStatus"
                      class="headerSelectBox"
                      v-enableIfAuthorized="'1040'"
                    >
                      <option :value="null">Select Status</option>
                      <option
                        v-for="item in customerStatuses"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 noMargin">
                  <div class="col-md-12 noMargin">
                    <label
                      style="font-size: 14px"
                      class="generalLabel"
                      for="langeuageDropDown"
                      >Language</label
                    >
                  </div>

                  <div class="col-md-12 noMargin">
                    <select
                      v-model="Customer.LanguageId"
                      id="langeuageDropDown"
                      class="headerSelectBox"
                    >
                      <option :disabled="Customer.LanguageId > 0" :value="null">
                        Select
                      </option>
                      <option :value="1">
                        {{ mapLanguageIdToLanguageString(1) }}
                      </option>
                      <option :value="2">
                        {{ mapLanguageIdToLanguageString(2) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row mt-1" style="padding: 0 0 0 3px; margin-top: 0">
                <div class="col-xl-12 row">
                  <span
                    v-if="Customer.Id != null && Customer.Id > 0"
                    class="badge mt-2 ml-2"
                    style="
                      font-size: 12px;
                      background-color: #d6d7d7;
                      color: #2a404c;
                      padding: 8px;
                    "
                    >Customer Id: {{ addPrefixToCustomerId(Customer.Id) }}</span
                  >
                  <o-dropdown
                    v-if="customerId > 0"
                    aria-role="list"
                    animation="fade"
                    style="cursor: pointer; z-index: 300"
                  >
                    <template v-slot:trigger>
                      <span
                        class="badge badge-info mt-2 ml-2"
                        style="padding: 8px"
                        @click="openCreditPullOptions()"
                      >
                        <i class="fas fa-file-contract mr-1"></i>
                        Pull Credit Options

                        <i class="fas fa-angle-down ml-1"></i>
                      </span>
                    </template>
                    <o-dropdown-item
                      v-if="
                        isAuthorizedToSee(782381) ||
                        isAuthorizedToSee(11880) ||
                        isAuthorizedToSee(11898) ||
                        isAuthorizedToSee(782382) ||
                        isAuthorizedToSee(782390)
                      "
                      aria-role="listitem"
                      @click="openPullCreditHistoryPopup()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull History
                    </o-dropdown-item>
                    <o-dropdown-item
                      aria-role="listitem"
                      @click="openPullCreditRequestPopup(1)"
                      v-if="isAuthorizedToSee(11880)"
                    >
                      <i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull (Array)
                    </o-dropdown-item>

                    <o-dropdown-item
                      v-if="isAuthorizedToSee(782381)"
                      aria-role="listitem"
                      @click="openPullCreditRequestPopup(2)"
                    >
                      <i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull (MeridianLink)
                    </o-dropdown-item>

                    <o-dropdown-item
                      v-if="isAuthorizedToSee(782383)"
                      aria-role="listitem"
                      @click="openPullCreditRequestPopup(5)"
                    >
                      <i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull (Spin Wheel)
                    </o-dropdown-item>
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(782383)"
                      aria-role="listitem"
                      @click="openPullCreditRequestPopup(6)"
                    >
                      <i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull (Spin Wheel - Manual)
                    </o-dropdown-item>

                    <o-dropdown-item
                      aria-role="listitem"
                      @click="openPullCreditRequestPopup(3)"
                      v-if="isAuthorizedToSee(11898)"
                    >
                      <i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull (Trusted Credit)
                    </o-dropdown-item>

                    <o-dropdown-item
                      aria-role="listitem"
                      @click="openPullCreditRequestPopup(4)"
                      v-if="isAuthorizedToSee(782382)"
                    >
                      <i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull (XActus)
                    </o-dropdown-item>

                    <o-dropdown-item
                      v-if="isAuthorizedToSee(81450)"
                      aria-role="listitem"
                      @click="openMonevoPopup()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Monevo
                    </o-dropdown-item>
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(81450)"
                      aria-role="listitem"
                      @click="openMonevoCoBrandedPopup()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Monevo CoBranded
                    </o-dropdown-item>

                    <o-dropdown-item
                      v-if="isAuthorizedToSee(782390)"
                      aria-role="listitem"
                      @click="openEquifaxPopup()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Credit Pull (Equifax)
                    </o-dropdown-item>
                  </o-dropdown>
                  <o-dropdown
                    aria-role="list"
                    animation="fade"
                    style="cursor: pointer"
                  >
                    <template v-slot:trigger>
                      <span
                        @click="docusignOpened()"
                        class="badge badge-info mt-2 ml-2"
                        style="padding: 8px"
                        v-if="
                          Customer.CreditorTotalBalance > 0 && checkAuth(1772)
                        "
                      >
                        <i class="fas fa-file-upload mr-1"></i>
                        DocuSign Options
                        <i class="fas fa-angle-down ml-1"></i>
                      </span>
                    </template>

                    <o-dropdown-item
                      aria-role="listitem"
                      @click="openEnvelopeHistoryPopUp()"
                    >
                      <i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Envelope History</o-dropdown-item
                    >
                    <o-dropdown-item
                      aria-role="listitem"
                      @click="openEnvelopeSendPopUp()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Send Envelope</o-dropdown-item
                    >
                  </o-dropdown>

                  <o-dropdown
                    v-if="hasAccessToOtherOptions"
                    aria-role="list"
                    animation="fade"
                    style="cursor: pointer"
                  >
                    <template v-slot:trigger>
                      <span
                        class="badge badge-info mt-2 ml-2"
                        style="padding: 8px"
                      >
                        <i class="fas fa-ellipsis-v mr-1"></i>
                        Other Options
                        <i class="fas fa-angle-down ml-1"></i>
                      </span>
                    </template>

                    <o-dropdown-item
                      v-if="isAuthorizedToSee(70002)"
                      aria-role="listitem"
                      @click="createOrUpdateLoan()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >New Line: Create/Update Loan</o-dropdown-item
                    >
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(70002)"
                      aria-role="listitem"
                      @click="checkLoanStatus()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >New Line: Check Loan Status</o-dropdown-item
                    >

                    <o-dropdown-item
                      v-if="isAuthorizedToSee(81401)"
                      aria-role="listitem"
                      @click="openLeadTracPopup()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Push to LeadTrac</o-dropdown-item
                    >
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(81552)"
                      aria-role="listitem"
                      @click="pushToTCI()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >SBF - Loan Application</o-dropdown-item
                    >
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(70001)"
                      aria-role="listitem"
                      @click="openSuperMoneyPopup()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Super Money
                    </o-dropdown-item>
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(100078)"
                      aria-role="listitem"
                      @click="openGlobalMeridians()"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Global Meridian Loan
                    </o-dropdown-item>
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(70003)"
                      aria-role="listitem"
                      @click="openLoanApplicationPopup(LOAN_APPLICATIONS?.Evvo)"
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >EVVO - Loan Application</o-dropdown-item
                    >
                    <o-dropdown-item
                      v-if="isAuthorizedToSee(70004)"
                      aria-role="listitem"
                      @click="
                        openLoanApplicationPopup(LOAN_APPLICATIONS?.Premlo)
                      "
                      ><i
                        class="fas fa-angle-right pr-2"
                        style="color: #37cde6"
                      ></i
                      >Premlo - Loan Application</o-dropdown-item
                    >
                  </o-dropdown>

                  <span
                    v-if="
                      !isRefreshing &&
                      Customer.Bank &&
                      Customer.CustomerProfile.Address &&
                      (!Customer.CustomerGlobalInformation ||
                        !Customer.CustomerGlobalInformation.AccountNumber) &&
                      Customer.Id > 0 &&
                      !isNaN(parseInt(Customer.CreditorTotalBalance)) &&
                      parseInt(Customer.CreditorTotalBalance) > 0 &&
                      isAuthorizedToSee(4391)
                    "
                    @click="handleEnroll()"
                    class="badge badge-success mt-2 ml-2"
                    style="padding: 8px; cursor: pointer"
                  >
                    <i class="fas fa-arrow-alt-circle-right"></i>
                    Enroll
                  </span>

                  <span
                    v-if="customerId > 0"
                    class="badge badge-info mt-2 ml-2"
                    style="padding: 8px; cursor: pointer"
                    @click="refreshCustomerFromDBOnClick(false)"
                  >
                    <i class="fe-refresh-ccw mr-1"></i>
                    Refresh
                  </span>
                  <div v-if="!checkAuth(50002) && optionalProducts && optionalProducts.length> 0">
                    <span
                      v-for="item in getCustomerOptionalProducts()" :key="item"
                    class="badge mt-2 ml-2"
                    style="
                      font-size: 12px;
                      background-color: #d6d7d7;
                      color: #2a404c;
                      padding: 8px;
                    "
                    >{{ item }}
                  </span>

                  </div>
                  
                </div>
              </div>
            </div>
            <div
              class="blueBoxPanel"
              :style="'height:auto'"
              v-if="Customer.CustomerGlobalInformation && !isLoading"
            >
              <div class="blueBoxRow">
                <label
                  class="generalLabel blueBoxHeader"
                  :style="
                    isBlueBoxExpanded
                      ? 'border-bottom:1px solid #999'
                      : 'border:none;'
                  "
                  >Program Calculator
                  <a
                    v-if="
                      Customer.CustomerGlobalInformation.RemovedFeeGroup ==
                      'Evergreen'
                    "
                    class="generalLabel"
                    style="color: red"
                    >(NO EG)</a
                  >
                  <span
                    style="display: flex"
                    class="blueBoxServiceFeeLabel"
                    v-if="isReadyToCalcDraft()"
                    >Service Fee: {{ getServiceFeePercentage() }}%
                    <i
                      v-if="
                        checkAuth(100051) ||
                        checkAuth(81405) ||
                        checkAuth(100053) ||
                        checkAuth(100061)
                      "
                      :class="
                        checkAuth(100051) ||
                        checkAuth(81405) ||
                        checkAuth(100053)
                          ? 'pl-2  fas fa-pencil-alt'
                          : ' pl-2 fas fa-info-circle'
                      "
                      style="
                        cursor: pointer;
                        color: #504f4f;
                        font-size: 14px;
                        margin-top: 5px;
                      "
                      @click="openSettlementFeePercentagePopup()"
                    ></i>
                  </span>
                </label>

                <label
                  style="font-size: 12px !important"
                  class="text-danger no Margin"
                  v-if="!isReadyToCalcDraft() && isBlueBoxExpanded"
                  >Missing information to calculate Draft Amount!</label
                >
              </div>
              <div class="noMargin">
                <div
                  class="col-md-12 noPadding blueBoxRow"
                  v-if="isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <div style="width: 36%; margin: 0 2px 0px 0; float: left">
                      <div class="blueBoxLabelAutoSize">Months:</div>

                      <input
                        type="text"
                        class="blueBoxControl"
                        style="
                          width: 35px;
                          padding: 5px !important;
                          padding-left: 8px !important;
                          margin-left: 5px;
                        "
                        @change="updateBlueBoxValues"
                        placeholder=""
                        v-myMask="{
                          alias: 'numeric',

                          rightAlign: false,
                        }"
                        v-model="
                          Customer.CustomerGlobalInformation.EnrollmentMonths
                        "
                      />
                      <div
                        v-if="blueBoxMonthsMax"
                        class="blueMaxMonthLabelPanel"
                      >
                        (Max
                        {{
                          blueBoxMonthsMax +
                          Customer.CustomerProfile.ExtraTermMonths
                        }})
                      </div>
                    </div>
                    <div style="width: 60%; float: left">
                      <div class="blueBoxLabelAutoSize">Recurring Date:</div>
                      <DatePicker
                        mode="date"
                        :available-dates="
                          isBlueBoxReadOnly
                            ? datepickerNoConfig
                            : datepickerConfig
                        "
                        :locale="'en-US'"
                        v-model="Customer.CustomerGlobalInformation.StartDate"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            id="DateOfBirthOfEditClient"
                            style="width: 100px"
                            class="blueBoxControl ml-2 px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :disabled="isBlueBoxReadOnly"
                            :class="isBlueBoxReadOnly ? 'readonlyInput' : ''"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="
                    isBlueBoxExpanded &&
                    (checkAuth(100052) ||
                      (!checkAuth(100052) &&
                        Customer.CustomerGlobalInformation.FirstPaymentDate))
                  "
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <input
                      autocomplete="none"
                      :disabled="!checkAuth(100052)"
                      v-model="firstPaymentDateSet"
                      @change="firstPaymentDateChanged"
                      type="checkbox"
                      class="checkbox-input generalCheckBox mr-1"
                      style="
                        float: left;
                        border-radius: 4px !important;
                        border: solid 1px #d1d1d1 !important;
                      "
                    />
                    <div class="blueBoxLabelAutoSize">
                      Change First Payment Date
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="firstPaymentDateSet && isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                    v-if="isBlueBoxExpanded"
                  >
                    <div class="blueBoxLabel">Initial Payment Date:</div>
                    <DatePicker
                      mode="date"
                      :available-dates="datepickerNoConfig"
                      :locale="'en-US'"
                      v-model="
                        Customer.CustomerGlobalInformation.FirstPaymentDate
                      "
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          style="width: 100px"
                          class="blueBoxControl px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :disabled="isBlueBoxReadOnly || !checkAuth(100052)"
                          :class="isBlueBoxReadOnly ? 'readonlyInput' : ''"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
                <div
                  class="col-md-12 noPadding blueBoxRow"
                  v-if="hasAuth(50001) && isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <div class="blueBoxLabelAutoSize">
                      Adjust Program Terms:
                    </div>

                    <CustomNumericInput
                      class="blueBoxDaysInput generalTextBox"
                      style="
                        width: 40px;
                        padding: 5px 2px 5px 0px !important;
                        text-align: center !important;
                      "
                      :value="Customer.CustomerProfile.ExtraTermMonths"
                      @updateValue="updateExtraTermMonth"
                      :minNumber="0"
                      :maxNumber="maxExtraTermCount"
                      placeholder=""
                    />
                    <div class="blueBoxLabelAutoSize blueBoxRedLabel">
                      {{ "(Max " + maxExtraTermCount + " Months)" }}
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center; margin: 5px 0"
                    class="blueBoxControlWrapper"
                    v-if="Customer.CustomerGlobalInformation.StartDate"
                  >
                    <div class="blueBoxLabel">Payment Option:</div>
                    <select
                      :disabled="!this.checkAuth(4392)"
                      option
                      @change="PaymentOptionTypeIdChanged()"
                      v-model="
                        Customer.CustomerGlobalInformation.PaymentOptionTypeId
                      "
                      class="blueBoxControl"
                      style="width: 120px; padding: 5px 1% !important"
                    >
                      <option
                        :disabled="!this.checkAuth(4392)"
                        v-for="item in paymentOptions"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                    <div
                      v-if="
                        Customer.CustomerGlobalInformation.StartDate &&
                        Customer.CustomerGlobalInformation
                          .PaymentOptionTypeId == 2
                      "
                    >
                      <div
                        class="blueBoxLabel blueBoxLabelAutoSize"
                        style="margin: 5px 0 3px 10px"
                      >
                        Day:
                      </div>
                      <CustomNumericInput
                        class="blueBoxDaysInput generalTextBox ml-1"
                        style="padding: 5px"
                        :value="Customer.CustomerGlobalInformation.DayOfMonth"
                        @updateValue="updateSplitPaymentNew"
                        :minNumber="1"
                        :maxNumber="
                          getMaxDayofMonth(
                            Customer.CustomerGlobalInformation.StartDate
                          )
                        "
                        placeholder="Day"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <div class="blueBoxLabel">Total Debt:</div>

                    <input
                      type="text"
                      class="blueBoxControl readonlyInput"
                      placeholder=""
                      :value="formatMoney(totalDebtAmount)"
                      disabled="disabled"
                    />
                  </div>
                </div>

                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <div class="blueBoxLabel">Draft Amount:</div>

                    <input
                      type="text"
                      class="blueBoxControl readonlyInput"
                      placeholder=""
                      :value="
                        formatMoney(
                          Customer.CustomerGlobalInformation.DraftAmount
                        )
                      "
                      disabled="disabled"
                    />

                    <a
                      v-if="
                        Customer.CustomerGlobalInformation
                          .PaymentOptionTypeId == 4
                      "
                      style="
                        font-size: 13px;
                        color: red;
                        font-weight: 600;
                        padding-left: 5px;
                      "
                    >
                      ({{
                        formatMoney(
                          getWeeklyDraftAmount(
                            Customer.CustomerGlobalInformation
                              .PaymentOptionTypeId
                          )
                        )
                      }})
                    </a>
                    <a
                      v-if="
                        Customer.CustomerGlobalInformation
                          .PaymentOptionTypeId == 3
                      "
                      style="
                        font-size: 13px;
                        color: red;
                        font-weight: 600;
                        padding-left: 5px;
                      "
                      >({{
                        formatMoney(
                          getWeeklyDraftAmount(
                            Customer.CustomerGlobalInformation
                              .PaymentOptionTypeId
                          )
                        )
                      }})</a
                    >
                    <a
                      v-if="
                        Customer.CustomerGlobalInformation
                          .PaymentOptionTypeId == 2
                      "
                      style="
                        font-size: 13px;
                        color: red;
                        font-weight: 600;
                        padding-left: 5px;
                      "
                      >({{
                        formatMoney(
                          getWeeklyDraftAmount(
                            Customer.CustomerGlobalInformation
                              .PaymentOptionTypeId
                          )
                        )
                      }})</a
                    >
                  </div>
                </div>
                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <div class="blueBoxLabel">Program Cost:</div>
                    <span class="blueBoxLabel">
                      {{ formatMoney(totalPayback) }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="isBlueBoxExpanded"
                >
                  <div
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <div class="blueBoxLabel">Savings:</div>
                    <span class="blueBoxLabel">
                      {{ formatMoney(totalDebtAmount - totalPayback) }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-md-12 noPadding noMargin"
                  v-if="
                    this.Customer.CustomerGlobalInformation && isBlueBoxExpanded
                  "
                >
                  <div
                    v-if="
                      !(
                        Customer.CustomerGlobalInformation &&
                        Customer.CustomerGlobalInformation.IsDpg == true
                      )
                    "
                    style="display: flex; align-items: center"
                    class="blueBoxControlWrapper"
                  >
                    <div class="blueBoxLabel">Policy Group:</div>

                    <select
                      :disabled="blockChanges() || !hasAuth(4113)"
                      v-model="Customer.CustomerGlobalInformation.PolicyGroupId"
                      class="blueBoxControl"
                      style="width: 220px; padding: 5px 1% !important"
                      @change="
                        policyGroupChanged(
                          Customer.CustomerGlobalInformation.PolicyGroupId
                        )
                      "
                    >
                      <option
                        v-for="item in policyGroupList"
                        :key="item.PolicyGroupId"
                        :value="item.PolicyGroupId"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12 noPadding noMargin">
                  <div
                    style="display: flex; align-items: center; margin: 0"
                    class="blueBoxControlWrapper"
                  >
                    <div
                      v-on:click.prevent="toggleBlueBox"
                      class="blueBoxIconWrapper text-center"
                      :style="
                        isBlueBoxExpanded
                          ? 'margin:0 auto;'
                          : 'margin:-5px auto;'
                      "
                    >
                      <i
                        class="blueBoxIcon"
                        :class="
                          isBlueBoxExpanded
                            ? 'ri-arrow-up-s-line'
                            : 'ri-arrow-down-s-line'
                        "
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 policyWrapper" style="padding-top: 4px">
          <div
            v-if="
              Customer.OpenTasks &&
              checkAuth(10028) &&
              Customer.OpenTasks.length > 0
            "
            style="
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
              border: 1px rgba(255, 178, 178, 0.363) solid;
              padding: 5px 4px 0px 4px;
              margin-top: 5px;
              margin-bottom: 5px;
              background-color: #f0556c;
              color: white;
              border-radius: 0.25rem;
            "
          >
            <div style="display: flex">
              <div>
                <label style="color: white" class="generalLabel mr-1"
                  >Open Tasks:</label
                >
                <label class="generalLabel" style="color: white">
                  {{ limitedTasks.join(",") }}</label
                >
              </div>
              <div v-if="Customer.OpenTasks.length > 3" class="dropdown">
                <span style="cursor: pointer; padding: 8px 10px; color: white">
                  <i class="fas fa-info-circle"></i>
                </span>

                <div style="color: black" class="dropdown-content">
                  <a
                    class="generalLabel"
                    v-for="item in remainingTasks"
                    :key="item"
                    >{{ item }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 policy-form noPadding mt-1">
            <ul class="nav">
              <li
                v-show="getTabIsVisible(tab)"
                v-for="(tab, index) in tabs"
                :key="tab.id"
                style="margin: 0 15px 8px 0"
              >
                <a
                  data-toggle="tab"
                  :aria-expanded="tab.active"
                  v-on:click.prevent="() => handleTabChange(index)"
                  class="policyTabItem"
                  :class="{ policyTabActive: tab.active }"
                >
                  <span class="d-inline-block">
                    <i style="font-size: 16px" :class="tab.iconName"></i>
                  </span>
                  <span class="d-none d-sm-inline-block tab-item-label">
                    {{
                      tab.id == 7
                        ? Customer.CustomerGlobalInformation &&
                          Customer.CustomerGlobalInformation.IsDpg == true
                          ? "DPG"
                          : getGlobalTabName(
                              tab.label,
                              Customer.CustomerGlobalInformation
                                .PaymentProcessorID
                            )
                        : tab.label
                    }}
                  </span>

                  <span
                    v-if="
                      Customer.OpenTasks &&
                      Customer.OpenTasks.length > 0 &&
                      checkAuth(10028) &&
                      tab.id == 4
                    "
                    class="badge noti-icon-badge badge-danger ml-1"
                    style="padding: 4px 7px"
                    >{{ Customer.OpenTasks.length }}</span
                  >
                </a>
              </li>
            </ul>
            <div class="col-md-12 noPadding">
              <div v-show="tabs[0].active">
                <ClientProfile
                  :Customer="Customer"
                  ref="profileTab"
                  @update:Customer="Customer = $event"
                  @updatePaymentCalculator="updatePaymentCalculator"
                  @saveCustomerFromTags="saveCustomerFromTags"
                  @updatePASNumber="updatePASNumber"
                ></ClientProfile>
              </div>
              <div v-show="tabs[1].active">
                <CoClientsProfile
                  :Customer="Customer"
                  @update:Customer="Customer = $event"
                ></CoClientsProfile>
              </div>

              <div
                v-show="tabs[2].active && customerId > 0"
                v-if="Customer && Customer.Id > 0"
              >
                <ClientCreditors
                  :blockMakingChange="blockChanges()"
                  :customerId="customerId"
                  :officeId="Customer.OfficeId"
                  :isDpg="
                    Customer.CustomerGlobalInformation
                      ? Customer.CustomerGlobalInformation.IsDpg
                      : false
                  "
                  :paymentProcessorId="
                    Customer.CustomerGlobalInformation
                      ? Customer.CustomerGlobalInformation.PaymentProcessorID
                      : 0
                  "
                  :customerStatusId="Customer.StatusId"
                  :customerFirstName="Customer.CustomerProfile.FirstName"
                  :customerLastName="Customer.CustomerProfile.LastName"
                  :customerAddressLine1="Customer.CustomerProfile.Address.Line1"
                  :customerAddressLine2="Customer.CustomerProfile.Address.Line2"
                  :customerCity="Customer.CustomerProfile.Address.City"
                  :customerStateId="Customer.CustomerProfile.Address.StateId"
                  :customerZip="Customer.CustomerProfile.Address.Zip"
                  :feePercentage="getServiceFeePercentage()"
                  :tabClicked="tabs[2].active"
                  :customerDob="Customer.CustomerProfile.DateOfBirth"
                  :customerSsn="Customer.CustomerProfile.SsnEncrypted"
                  :customerName="
                    Customer.CustomerProfile.FirstName +
                    ' ' +
                    Customer.CustomerProfile.LastName
                  "
                  :enrolledDate="
                    Customer.CustomerGlobalInformation.EnrolledDate
                  "
                  @updateTotalCreditorAmount="updateTotalCreditorAmount"
                  @updateTotalCreditorCount="updateTotalCreditorCount"
                  @updateTotalCreditorAfterSync="updateTotalCreditorAfterSync"
                  @updateBlueBoxforNameChange="updateBlueBoxforNameChange"
                  ref="clientCreditorTab"
                ></ClientCreditors>
              </div>
              <div v-show="tabs[3].active && customerId > 0">
                <ClientTasks
                  :customerId="customerId"
                  :tabClicked="tabs[3].active"
                  :phoneNumber="primaryPhoneNumber"
                ></ClientTasks>
              </div>
              <div v-show="tabs[4].active && customerId > 0">
                <ClientNotes
                  :customerId="customerId"
                  :tabClicked="tabs[4].active"
                  :csrTaskId="0"
                  :portalLink="portalAutoLoginUrl"
                  :languageId="Customer.LanguageId"
                ></ClientNotes>
              </div>
              <div v-show="tabs[5].active && customerId > 0">
                <ClientDocuments
                  :customerId="customerId"
                  :customerOfficeId="Customer.OfficeId"
                  :tabClicked="tabs[5].active"
                  :isNegotiatorTaskPopup="false"
                  :isBLP="isBLP"
                  @set-loading="isLoading = $event"
                ></ClientDocuments>
              </div>
              <div
                v-show="
                  tabs[6].active &&
                  Customer.Bank &&
                  Customer.CustomerProfile.Address &&
                  Customer.CustomerGlobalInformation &&
                  Customer.CustomerGlobalInformation.PolicyGroupId &&
                  Customer.CustomerGlobalInformation.AccountNumber &&
                  hasAuth(4815)
                "
              >
                <GCSTabs
                  v-if="hasAuth(4815) && !isLoading"
                  :Customer="Customer"
                  :tabClicked="tabs[6].active"
                ></GCSTabs>
              </div>
              <div v-show="tabs[7].active && customerId > 0">
                <DraftChanges
                  v-if="Customer.OfficeId > 0"
                  :customerId="customerId"
                  :officeId="Customer.OfficeId"
                  :policyGroupId="
                    Customer.CustomerGlobalInformation
                      ? Customer.CustomerGlobalInformation.PolicyGroupId
                      : 0
                  "
                  :paymentOptionId="
                    Customer.CustomerGlobalInformation
                      ? Customer.CustomerGlobalInformation.PaymentOptionTypeId
                      : 1
                  "
                  :dayOfMonth="
                    Customer.CustomerGlobalInformation
                      ? Customer.CustomerGlobalInformation.DayOfMonth
                      : 0
                  "
                  :draftDate="this.Customer.CustomerGlobalInformation.StartDate"
                  :tabClicked="tabs[7].active"
                  :isDpgCustomer="
                    Customer.CustomerGlobalInformation
                      ? Customer.CustomerGlobalInformation.IsDpg
                      : null
                  "
                  :paymentProcessorId="
                    Customer.CustomerGlobalInformation
                      ? Customer.CustomerGlobalInformation.PaymentProcessorID
                      : null
                  "
                ></DraftChanges>
              </div>
              <div v-show="tabs[8].active && customerId > 0">
                <PortalAuthorizations
                  :customerId="customerId"
                  :customerEmail="customerPrimaryEmail"
                  :attorneyName="attorneyOfficeName"
                  :tabClicked="tabs[8].active"
                ></PortalAuthorizations>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isSettlementFeePercentagePopup"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            :class="
              checkAuth(100061)
                ? 'col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12'
                : 'col-xl-3 col-lg-6 col-md-12 col-sm-12 col-xs-12'
            "
          >
            <SettlementFeePercentagePopup
              :policyGroupId="
                Customer.CustomerGlobalInformation
                  ? Customer.CustomerGlobalInformation.PolicyGroupId
                  : 0
              "
              :officeId="Customer.OfficeId ? Customer.OfficeId : 0"
              :stateId="
                Customer.CustomerProfile.Address
                  ? Customer.CustomerProfile.Address.StateId
                    ? Customer.CustomerProfile.Address.StateId
                    : 0
                  : 0
              "
              :attorneyName="attorneyOfficeName"
              :customerId="Customer.Id"
              :percantageValue="paymentCalculatorServiceFeePercent"
              :removedFeeGroup="
                Customer.CustomerGlobalInformation.RemovedFeeGroup
              "
              :maxServiceFeePercentvalue="paymentCalculatorMaxServiceFeePercent"
              :serviceFeeAdjustment="paymentCalculatorServiceFeeAdjustment"
              :serviceFeeAdjustmentAttorney="
                paymentCalculatorServiceFeeAdjustmentAttorney
              "
              :minDebtAmountValue="paymentCalculatorMinDebtAmount"
              :minDebtAmountDefaultValue="paymentCalculatorMinDebtAmountDefault"
              :minDraftAmountValue="paymentCalculatorMinDraftAmount"
              :customerTotalDebtAmount="totalDebtAmount"
              :enrollmentMonths="
                this.Customer.CustomerGlobalInformation
                  ? this.Customer.CustomerGlobalInformation.EnrollmentMonths
                  : 0
              "
              @closeSettlementFeePercentagePopup="
                closeSettlementFeePercentagePopup
              "
              @saveServiceFeePercantage="saveServiceFeePercantage"
              :createdDate="
                Customer.CreatedDate
                  ? Customer.CreatedDate
                  : new Date().toISOString().split('T')[0]
              "
            ></SettlementFeePercentagePopup>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isQuickEmailPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <QuickEmails
              :customerId="Customer.Id"
              :languageId="Customer.LanguageId"
              @closeQuickEmailPopup="closeQuickEmailPopup"
              @quickEmailSelected="quickEmailSelected"
            ></QuickEmails>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isEnvelopeHistoryPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-4 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <DocusignEnvelopeHistory
              :customerId="Customer.Id"
              :docusignHistory="docusignHistory"
              @closeEnvelopeHistoryPopUp="closeEnvelopeHistoryPopUp"
            ></DocusignEnvelopeHistory>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isSendEnvelopePopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <DocusignEnvelopeSend
              :customerId="Customer.Id"
              :officeId="Customer.OfficeId"
              :isDpg="Customer.CustomerGlobalInformation.IsDpg"
              :customerAvailableTemplates="customerAvailableTemplates"
              @closeEnvelopeSendPopUp="closeEnvelopeSendPopUp"
            ></DocusignEnvelopeSend>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isStatusChangePopupOpen"
          style="-webkit-box-align: center; align-items: center"
        >
          <div
            style="position: relative; margin: 3% auto"
            class="offset-xl-4 offset-lg-3 col-xl-5 col-lg-7 col-md-12 col-sm-12 col-xs-12"
          >
            <ClientStatusChangePopup
              :customerId="Customer.Id"
              :officeId="Customer.OfficeId"
              :fromStatusId="initialStatusId"
              :toStatusId="Customer.StatusId"
              @closeStatusChangePopup="closeStatusChangePopup"
              @statusChangePopupSubmit="statusChangePopupSubmit"
            ></ClientStatusChangePopup>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isPullCreditRequestPopupOpen"
          style="
            -webkit-box-align: center;
            align-items: center;
            display: flex;
            z-index: 399 !important;
          "
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <PullCreditRequestPopup
              :spinWheelCustomerArr="spinWheelCustomerArr"
              :xActusCustomerArr="xActusCustomerArr"
              :trustedCreditCustomerArr="trustedCreditCustomerArr"
              :meridianCustomerArr="meridianCustomerArr"
              :sourceId="pullCreditSourceId"
              :pullCreditCustomerArr="pullCreditCustomerArr"
              @closePullCreditRequestPopup="closePullCreditRequestPopup"
              @openArrayAuthPopup="openArrayAuthPopup"
              @openSpinWheelAuthPopup="openSpinWheelAuthPopup"
            ></PullCreditRequestPopup>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="backgroundLock"
          v-if="
            IsArrayAdditionalAuthorizationPopupOpen && arrayAuthQuestionsData
          "
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-3 col-xl-6 offset-lg-2 col-lg-5 col-md-12 col-sm-12 col-xs-12"
          >
            <ArrayAdditionalAuthorization
              :arrayCredentials="arrayAuthQuestionsData"
              @closeArrayAdditionalAuthorization="
                closeArrayAdditionalAuthorization
              "
              @openArrayAuthPopup="openArrayAuthPopup"
            ></ArrayAdditionalAuthorization>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="
            isSpinWheelAdditionalAuthorizationPopupOpen && spinWheelAuthData
          "
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 5% auto"
            class="offset-xl-2 col-xl-4 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <SpinWheelAdditionalAuthorization
              :spinWheelCredentials="spinWheelAuthData"
              @closeSpinWheelAdditionalAuthorization="
                closeSpinWheelAdditionalAuthorization
              "
            ></SpinWheelAdditionalAuthorization>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isPullCreditHistoryPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-4 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <PullCreditHistoryPopup
              :customerId="Customer.Id"
              @closePullCreditHistoryPopup="closePullCreditHistoryPopup"
              @showCreditReport="showCreditReport"
              @openArrayAuthPopup="openArrayAuthPopup"
            ></PullCreditHistoryPopup>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="backgroundLock"
          v-if="isEquifaxPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 10% auto"
            class="offset-xl-4 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <EquifaxPopup
              :data="equifaxCustomerData"
              :coCustomerData="equifaxCoCustomerData"
              @closeEquifaxPopup="closeEquifaxPopup"
            ></EquifaxPopup>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isSuperMoneyPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 2% auto"
            class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <SuperMoneyPopup
              :dataToPassSuperMoney="dataToPassSuperMoney"
              @closeSuperMoneyPopup="closeSuperMoneyPopup"
            ></SuperMoneyPopup>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="backgroundLock"
          v-if="isEvvoPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 2% auto"
            class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <EvvoPopup
              :data="loanApplicationData"
              @close="closeEvvoPopup"
            ></EvvoPopup>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="backgroundLock"
          v-if="isPremloPopupOpen"
          style="-webkit-box-align: center; align-items: center; display: flex"
        >
          <div
            style="position: relative; margin: 2% auto"
            class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <PremloPopup
              :data="loanApplicationData"
              @close="closePremloPopup"
            ></PremloPopup>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="backgroundLock"
          v-if="isMonevoPopupOpen"
          style="-webkit-box-align: center; align-items: center"
        >
          <div
            style="position: relative; margin: 1% auto"
            class="offset-xl-3 col-xl-7 offset-lg-3 col-lg-8 col-md-12 col-sm-12 col-xs-12"
          >
            <MonevoPopup
              :data="dataToPassMonevo"
              @closeMonevoPopup="closeMonevoPopup"
            ></MonevoPopup>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="backgroundLock"
          v-if="isMonevoCoBrandedPopupOpen"
          style="-webkit-box-align: center; align-items: center"
        >
          <div
            style="position: relative; margin: 1% auto"
            class="offset-xl-3 col-xl-7 offset-lg-3 col-lg-8 col-md-12 col-sm-12 col-xs-12"
          >
            <MonevoCoBrandedPopup
              :data="dataToPassMonevo"
              @closeMonevoCoBrandedPopup="closeMonevoCoBrandedPopup"
            ></MonevoCoBrandedPopup>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isGlobalMeridianOpen"
          style="-webkit-box-align: center; align-items: center"
        >
          <div
            style="position: relative; margin: 1% auto"
            class="offset-xl-3 col-xl-7 offset-lg-3 col-lg-8 col-md-12 col-sm-12 col-xs-12"
          >
            <GlobalMeridians
              :dataToGlobalMeridians="dataToGlobalMeridians"
              @closeGlobalMeridiansPopup="closeGlobalMeridiansPopup"
            ></GlobalMeridians>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isDocusignSendingOptionsOpen"
          style="-webkit-box-align: center; align-items: center"
        >
          <div
            style="position: relative; margin: 15% auto"
            class="offset-xl-3 offset-lg-3 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12"
          >
            <DocusignSendingOptionsPopup
              @closeSendingOptionsPopup="closeSendingOptionsPopup"
            ></DocusignSendingOptionsPopup>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="backgroundLock"
          v-if="isApplicationPopup"
          style="-webkit-box-align: center; align-items: center"
        >
          <div
            style="position: relative; margin: 1% auto"
            class="offset-xl-3 offset-lg-3 col-xl-9 col-lg-4 col-md-12 col-sm-12 col-xs-12"
          >
            <ApplicationPopUp
              @closeApplicationPopUp="closeApplicationPopUp"
              :loanResult="newLineLoanResult"
            ></ApplicationPopUp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomNumericInput from "@/components/CustomNumericInput";
import QuickEmails from "@/components/QuickEmails.vue";
import deepCopy from "@/helpers/deepCopy";
import {
  getLoanApplicationPopupWarning,
  setLoanApplicationPopupValues,
} from "@/helpers/popupValidations.js";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import ArrayAdditionalAuthorization from "@/views/agent/EditClient/ArrayAdditionalAuthorization";
import ClientNotes from "@/views/agent/EditClient/ClientNotes/ClientNotes.vue";
import DocusignEnvelopeHistory from "@/views/agent/EditClient/DocusignEnvelopeHistory";
import DocusignEnvelopeSend from "@/views/agent/EditClient/DocusignEnvelopeSend";
import DraftChanges from "@/views/agent/EditClient/DraftChanges/DraftChanges";
import draftChangeTypes from "@/views/agent/EditClient/DraftChanges/types";
import EquifaxPopup from "@/views/agent/EditClient/EquifaxPopup.vue";
import GCSTabs from "@/views/agent/EditClient/GCS/GCSTabs.vue";
import gcsTypes from "@/views/agent/EditClient/GCS/types";
import GlobalMeridians from "@/views/agent/EditClient/GlobalMeridians";
import MonevoCoBrandedPopup from "@/views/agent/EditClient/MonevoCoBrandedPopup";
import MonevoPopup from "@/views/agent/EditClient/MonevoPopup";
import EvvoPopup from "@/views/agent/EditClient/Popups/EvvoPopup.vue";
import PremloPopup from "@/views/agent/EditClient/Popups/PremloPopup.vue";
import PortalAuthorizations from "@/views/agent/EditClient/PortalAuthorizations/PortalAuthorizations";
import PullCreditHistoryPopup from "@/views/agent/EditClient/PullCreditHistoryPopup";
import PullCreditRequestPopup from "@/views/agent/EditClient/PullCreditRequestPopup";
import SettlementFeePercentagePopup from "@/views/agent/EditClient/SettlementFeePercentagePopup";
import SpinWheelAdditionalAuthorization from "@/views/agent/EditClient/SpinWheelAdditionalAuthorization";
import SuperMoneyPopup from "@/views/agent/EditClient/SuperMoneyPopup";
import { camelCase, groupBy, orderBy } from "lodash";
import { DatePicker } from "v-calendar";
import { mapState } from "vuex";
import ApplicationPopUp from "./ApplicationPopUp";
import ClientCreditors from "./ClientCreditors/ClientCreditors";
import creditorTypes from "./ClientCreditors/types";
import ClientDocuments from "./ClientDocuments";
import ClientProfile from "./ClientProfile";
import ClientTasks from "./ClientTasks/ClientTasks";
import CoClientsProfile from "./CoClientsProfile";
import { BLP_TAG_TYPE_ID } from "./constants";
import { BLP_TAG_TYPE_ID_GRT } from "./constants";
import DocusignSendingOptionsPopup from "./DocusignSendingOptionsPopup";
import ClientStatusChangePopup from "./DraftChanges/ClientStatusChangePopup";
import types from "./types";

export default {
  name: "EditClient",
  components: {
    SpinWheelAdditionalAuthorization,
    ApplicationPopUp,
    GlobalMeridians,
    DocusignSendingOptionsPopup,
    SettlementFeePercentagePopup,
    SuperMoneyPopup,
    EvvoPopup,
    PremloPopup,
    PullCreditHistoryPopup,
    PullCreditRequestPopup,
    DocusignEnvelopeHistory,
    DocusignEnvelopeSend,
    ClientDocuments,
    ClientProfile,
    ClientTasks,
    ClientCreditors,
    CoClientsProfile,
    QuickEmails,
    ClientNotes,
    GCSTabs,
    DatePicker,
    CustomNumericInput,
    DraftChanges,
    PortalAuthorizations,
    ClientStatusChangePopup,
    MonevoPopup,
    ArrayAdditionalAuthorization,
    MonevoCoBrandedPopup,
    EquifaxPopup,
  },
  mixins: [utilitiesMixin, formatMixin],

  props: {
    customerId: {
      type: Number,
      default: () => 0,
    },
    activeTabId: {
      type: Number,
      default: () => 0,
    },
  },
  emits: ["updateCustomerId", "updateTabName"],
  data() {
    return {
      optionalProducts: [],
      feeTypeList: [],
      removedFeeGroupingKeys: [],
      portalAutoLoginUrl: "",
      totalPayback: 0,
      isApplicationPopup: false,
      applicationList: [],
      newLineLoanResult: null,
      maxExtraTermCount: 0,
      leadTracUrl:
        "https://secure.leadtrac.net/2.20/LeadManager/editlead.aspx?lead=",
      isDraftAmountDifferentInDB: false,
      OtherTasks: [
        "Summons Documents",
        "Portal Inquiry Review",
        "Global NSF",
        "Client Contact",
      ],
      isGlobalMeridianOpen: false,
      isDocusignSendingOptionsOpen: false,
      clientInfo: {},
      firstPaymentDateSet: false,
      arrayAuthQuestionsData: null,
      spinWheelAuthData: null,
      IsArrayAdditionalAuthorizationPopupOpen: false,
      isSpinWheelAdditionalAuthorizationPopupOpen: false,
      pullCreditSourceId: 0,
      xActusCustomerArr: [],
      meridianCustomerArr: [],
      finalSalesAgentList: [],
      isSettlementFeePercentagePopup: false,
      customerPrefix: null,
      userInfo: null,
      customerInit: Object.assign({}, this.customerInitFnc()),
      paymentCalculatorServiceFeePercent: 0,
      paymentCalculatorMaxServiceFeePercent: 0,
      paymentCalculatorServiceFeeAdjustment: 0,
      paymentCalculatorServiceFeeAdjustmentAttorney: 0,
      paymentCalculatorMinDebtAmount: 0,
      paymentCalculatorMinDebtAmountDefault: 0,
      paymentCalculatorMinDraftAmount: 0,
      next3MonthsCashFlowList: [],
      attorneyOfficeName: "",
      balanceSummaryText: "",
      lastClearedDraftDate: null,
      nextDraftDate: null,
      customerDocusignContractValidationError: "",
      coCustomerDocusignContractValidationError: "",

      creditors: [],
      isSuperMoneyPopupOpen: false,
      isMonevoPopupOpen: false,
      isMonevoCoBrandedPopupOpen: false,
      isEvvoPopupOpen: false,
      isPremloPopupOpen: false,

      dataToPassSuperMoney: {
        FirstName: "",
        LastName: "",
        CustomerEmail: "",
        PhoneNumber: "",
        DateOfBirth: "",
        TotalAmount: 0,
        Income: 0,
        CheckingAccount: "",
        AddressLine1: "",
        City: "",
        ShortState: "",
        Zip: "",
        CustomerId: 0,
      },
      dataToPassMonevo: {
        CustomerId: 0,
        AgentName: "",
        FirstName: "",
        LastName: "",
        MiddleName: "",
        Email: "",
        Phone: "",
        Line1: "",
        Line2: "",
        City: "",
        ShortState: "",
        Zip: "",
        Ssn: "",
        MonthlyIncome: 0,
        EmployerName: null,
        PayFrequency: null,
        MonthsAtAddress: null,
        MonthsAtEmployer: null,
        IncomeSource: null,
        MonthlyRent: null,
        ResidenceOwnership: null,
        OwnCar: null,
        CoCustomers: [],
      },
      dataToGlobalMeridians: {
        CustomerId: 0,
        Email: "",
        FirstName: "",
        LastName: "",
        DateOfBirth: "",
        Ssn: "",
        Income: 0,
        PhoneNumber: "",
        City: "",
        ShortState: "",
        Zip: "",
        TotalAmount: 0,
        AddressLine1: "",
        EmployerName: null,
        MonthsAtEmployer: null,
        IncomeSource: null,
        EmploymentPayFrequency: null,
        MonthsAtAddress: null,
        MonthlyRent: null,
        ResidenceOwnershipId: null,
        EducationLevel: null,
        EmploymentStatus: null,
        SelfReportedCreditScore: null,
        GraduateDegree: null,
      },
      loanApplicationData: {},
      isRefreshing: false,
      Customer: Object.assign({}, this.customerInitFnc()),
      assignedAgent: "",
      assignedAgentList: [],
      customerToSave: {},
      oldValue: "",
      isLoading: false,
      arrPermission: [],
      tabs: [
        {
          id: 1,
          name: "tab1",
          label: "Profile",
          iconName: "fe-user",
          active: false,
          AuthorizationId: 1050,
        },
        {
          id: 2,
          name: "tab2",
          label: "Co-Customers",
          iconName: "fe-users",
          active: false,
          AuthorizationId: 1050,
        },
        {
          id: 3,
          name: "tab3",
          label: "Creditors",
          iconName: "fe-dollar-sign",
          active: false,
          AuthorizationId: 35675,
        },
        {
          id: 4,
          name: "tab4",
          label: "Tasks",
          iconName: " fas fa-tasks",
          active: false,
          AuthorizationId: 10028,
        },
        {
          id: 5,
          name: "tab5",
          label: "Notes",
          iconName: "fas fa-file-alt",
          active: false,
          AuthorizationId: 1672,
        },
        {
          id: 6,
          name: "tab6",
          label: "Documents",
          iconName: "fas fa-book",
          active: false,
          AuthorizationId: 1825,
        },
        {
          id: 7,
          name: "tab7",
          label: "Global",
          iconName: " fas fa-share-alt",
          active: false,
          AuthorizationId: 4815,
        },
        {
          id: 8,
          name: "tab8",
          label: "Draft Changes",
          iconName: "far fa-edit",
          active: false,
          AuthorizationId: 6631,
        },
        {
          id: 9,
          name: "tab8",
          label: "Authorization",
          iconName: "fas fa-file-alt",
          active: false,
          AuthorizationId: 11800,
        },
      ],
      docusignHistory: [],
      customerAvailableTemplates: [],
      saving: false,
      invalid: false,

      isFavorited: false,
      isQuickEmailPopupOpen: false,
      selectedQuickEmailFromPopup: null,
      isEnvelopeHistoryPopupOpen: false,
      isSendEnvelopePopupOpen: false,
      customerPrimaryEmail: "",
      isBlueBoxReadOnly: false,
      blueBoxUpdating: false,
      //blueBoxMonthsMin: 1,
      blueBoxMonthsMax: 0,
      blueBoxPanelHeight: 60,
      isBlueBoxExpanded: false,
      // totalAmountValue: 0.0,

      datepickerConfig: {
        end: this.addYearsToDate(new Date(), 4),
      },
      datepickerNoConfig: {
        /* this parameter is required to reset config*/
      },
      isPullCreditRequestPopupOpen: false,
      pullCreditCustomerArr: [],
      isPullCreditHistoryPopupOpen: false,
      isEquifaxPopupOpen: false,
      equifaxCustomerData: null,
      equifaxCoCustomerData: null,
      isStatusChangePopupOpen: false,
      isInitiallySoldStatus: false,
      initialStatusId: 0,
      skipStatusControl: false,
      changeDetailType: {
        ChangeTypeId: 0,
        OldValue: "",
        NewValue: "",
      },
      InitialInfo: {
        FirstName: "",
        MI: "",
        LastName: "",
        BankAccount: "",
        Routing: "",
        BankAccountTypeId: 0,
        Address: "",
        AddressLine2: "",
        City: "",
        StateId: 0,
        Zip: "",
        PolicyGroupId: 0,
        DoB: null,
        Ssn: "",
      },
      policyGroupList: [],
      tabId: null,
      userOfficeId: 0,
      primaryPhoneNumber: "",
      paymentCalculatorIssues: "",
      userSubOffices: [],
      policyGroupId: null,
      policyGroupCheckKeywords: ["csg", "with csg"],
      pasPhoneNumbers: [],
      LOAN_APPLICATIONS: { Evvo: "EVVO", Premlo: "Premlo" },
    };
  },

  computed: {
    isBLP() {
      return this.Customer?.CustomerTags?.some(
        (tag) =>
          tag.TagTypeId === BLP_TAG_TYPE_ID ||
          tag.TagTypeId === BLP_TAG_TYPE_ID_GRT
      );
    },
    hasAccessToOtherOptions() {
      return (
        (this.checkAuth(81401) || // LeadTrac
          this.checkAuth(81552) || // SBF Loan Application
          this.checkAuth(70002) || // New Line Report
          this.checkAuth(100078) || // Global Meridian Loan
          this.checkAuth(70001) || // SuperMoney
          this.checkAuth(70003) || // EVVO
          this.checkAuth(70004)) && // Premlo
        this.Customer.Id > 0
      );
    },
    ...mapState({
      customerTagTypes: (state) => state.globals.customerTagTypes,
      expenseTypeList: (state) => state.globals.expenseTypeList,
      companySettingsStatesList: (state) =>
        state.globals.companySettingsStatesList,
      incomingCustomerInfo: (state) => state.globals.incomingCustomerInfo,
      campaignList: (state) => state.globals.campaignList,
      customerStatuses: (state) => state.globals.customerStatuses,
      offices: (state) => state.globals.offices,
      salesAgents: (state) => state.globals.salesAgents,
      negotiators: (state) => state.globals.negotiators,
      accountTypeList: (state) => state.globals.accountTypeList,
      states: (state) => state.globals.states,
      assignedLeadData: (state) => state.globals.assignedLeadData,
      scripts: (state) => state.globals.scriptList,
      docusignValidationSettings: (state) =>
        state.clientManagement.docusignValidationSettings,
      filteredSalesAgents() {
        let rtn = [];
        if (this.checkAuth(1454)) {
          rtn = this.salesAgents;
        } else if (this.checkAuth(55011)) {
          rtn =
            this.userSubOffices.length > 0
              ? this.salesAgents.filter(
                  (x) =>
                    x.OfficeId == this.userOfficeId ||
                    this.userSubOffices.includes(x.OfficeId)
                )
              : this.salesAgents.filter((x) => x.OfficeId == this.userOfficeId);
        } else {
          rtn = this.salesAgents.filter((x) => x.OfficeId == this.userOfficeId);
        }

        return orderBy(rtn, "Name", "asc");
      },
      filteredOffices() {
        let rtn = [];
        if (this.checkAuth(1454)) {
          rtn = this.offices;
        } else if (this.checkAuth(55011)) {
          rtn =
            this.userSubOffices.length > 0
              ? this.offices.filter(
                  (x) =>
                    x.Id == null ||
                    x.Id == this.userOfficeId ||
                    this.userSubOffices.includes(x.Id)
                )
              : this.offices.filter(
                  (x) => x.Id == null || x.Id == this.userOfficeId
                );
        } else {
          rtn = this.offices.filter((x) => x.Id == this.userOfficeId);
        }

        return orderBy(rtn, "Name", "asc");
      },

      paymentOptions: (state) => state.globals.paymentOptions,

      creditPullScripts() {
        return this.Customer && this.userInfo
          ? this.scripts
              .filter((x) => x.IsActive)
              .filter((x) =>
                this.compareArray(
                  x.Roles.map((y) => y.Id),
                  this.userInfo.roles
                )
              )
              .filter((x) =>
                x.Statuses.map((y) => y.Id).includes(this.Customer.StatusId)
              )
              .filter((x) => x.DisplayOnPullCreditOptions == true)
          : [];
      },
      docusignScripts() {
        return this.Customer && this.userInfo
          ? this.scripts
              .filter((x) => x.IsActive)
              .filter((x) =>
                this.compareArray(
                  x.Roles.map((y) => y.Id),
                  this.userInfo.roles
                )
              )
              .filter((x) =>
                x.Statuses.map((y) => y.Id).includes(this.Customer.StatusId)
              )
              .filter((x) => x.DisplayOnDocusignOptions == true)
          : [];
      },
      fullName() {
        return (
          this.Customer.CustomerProfile.FirstName +
          " " +
          this.Customer.CustomerProfile.LastName
        );
      },
      totalIncome() {
        let totalCoCustomerIncome = 0;
        if (this.Customer.CoCustomerProfiles.length) {
          let includedCoCustomers = this.Customer.CoCustomerProfiles.filter(
            (x) => !x.IsExcluded
          );

          for (let item of includedCoCustomers) {
            totalCoCustomerIncome += parseFloat(item.Income);
          }
        }

        let additionalIncome = 0;
        if (
          this.Customer &&
          this.Customer.CustomerTags.length > 0 &&
          this.customerTagTypes &&
          this.customerTagTypes.length > 0
        ) {
          let incomeTagIds = this.customerTagTypes
            .filter((x) => x.TagDataType == "Income")
            .map((x) => x.Id);
          let customerIncomes = this.Customer.CustomerTags.filter((x) =>
            incomeTagIds.includes(x.TagTypeId)
          );
          let totalTagIncome = 0;
          for (let item of customerIncomes) {
            totalTagIncome += item.TagValue ? parseFloat(item.TagValue) : 0;
          }
          additionalIncome = totalTagIncome;
        }

        return (
          parseFloat(this.Customer.CustomerProfile.Income) +
          parseFloat(totalCoCustomerIncome) +
          parseFloat(additionalIncome)
        );
      },
      totalDebtAmount() {
        return this.Customer.CreditorTotalBalance > 0
          ? this.Customer.CreditorTotalBalance
          : this.Customer.CustomerProfile.DebtAmount;
      },

      limitedTasks() {
        return this.Customer.OpenTasks.slice(0, 3);
      },
      remainingTasks() {
        return this.Customer.OpenTasks.slice(3);
      },
      stillInTermSettlement() {
        if (this.Customer.TermSettlementEndDate == null) {
          return false;
        }
        return new Date() <= new Date(this.Customer.TermSettlementEndDate);
      },
      selectedTab() {
        return this.tabs.find((tab) => tab.active) || this.tabs[0];
      },
    }),
  },
  watch: {
    activeTabId(val) {
      if (val) {
        this.tabId = val;
        this.$store.commit(
          globalTypes.SET_CURRENT_KO_PATH,
          this.$koPaths.customers[camelCase(this.selectedTab.label)]
        );
      }
    },
    fullName(val) {
      this.$emit("updateTabName", val);
    },
    policyGroupId(newVal, oldVal) {
      if (this.checkAuth(778851)) {
        let oldGroupName = "";
        let newGroupName = "";
        if (oldVal > 0) {
          let indx = this.policyGroupList.findIndex(
            (x) => x.PolicyGroupId == oldVal
          );
          oldGroupName =
            indx > -1 ? this.policyGroupList[indx].Name.toLowerCase() : "";
        }
        if (newVal > 0) {
          let indx = this.policyGroupList.findIndex(
            (x) => x.PolicyGroupId == newVal
          );
          newGroupName =
            indx > -1 ? this.policyGroupList[indx].Name.toLowerCase() : "";
        }
        if (
          (oldGroupName == "" ||
            this.policyGroupCheckKeywords.some((x) =>
              oldGroupName.startsWith(x)
            )) &&
          !this.policyGroupCheckKeywords.some((x) => newGroupName.startsWith(x))
        ) {
          this.resetPasNumberByState();
        }

        if (
          (oldGroupName == "" ||
            !this.policyGroupCheckKeywords.some((x) =>
              oldGroupName.startsWith(x)
            )) &&
          this.policyGroupCheckKeywords.some((x) => newGroupName.startsWith(x))
        ) {
          this.setPasNumberByState();
        }
      }
    },
  },
  async mounted() {
    this.$store.commit(
      globalTypes.SET_CURRENT_KO_PATH,
      this.$koPaths.customers.profile
    );
    // new branch
    this.isDraftAmountDifferentInDB = false;
    this.isLoading = true;

    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.userInfo) {
      this.userOfficeId = this.userInfo.officeId;
      this.userSubOffices = this.userInfo.subOffices;
    }

    if (this.checkAuth(3994)) {
      this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
        CustomerId: this.customerId,
        UserActivityTypeId: 1,
        AdditionalNotes: "Opened customer record",
      });
    }

    await Promise.all([
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_TIMEZONES),
      this.$store.dispatch(globalTypes.GET_STATES),
      this.$store.dispatch(globalTypes.GET_ACCOUNT_TYPES_LIST),
      this.$store.dispatch(globalTypes.GET_EXPENSE_TYPES_LIST),
      this.$store.dispatch(globalTypes.GET_GENDERS),
      this.$store.dispatch(globalTypes.GET_PHONETYPES),
      this.$store.dispatch(globalTypes.GET_PRIORITYTYPES),
      this.$store.dispatch(globalTypes.GET_FAV),
      this.$store.dispatch(globalTypes.GET_SALES_AGENTS),
      this.$store.dispatch(globalTypes.GET_NEGOTIATORS),
      this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE),
      this.$store.dispatch(globalTypes.GET_SCRIPTS),
      this.$store.dispatch(globalTypes.GET_ALL_PAYMENT_OPTIONS),
      this.$store.dispatch(globalTypes.GET_COMPANY_SETTINGS_STATES),
    ]);

    this.finalSalesAgentList = deepCopy(this.filteredSalesAgents);

    if (this.userInfo != null) {
      this.userOfficeId = this.userInfo.officeId;
      if (
        this.userInfo.customerIdPrefix != null &&
        this.userInfo.customerIdPrefix.length > 0
      ) {
        this.customerPrefix = this.userInfo.customerIdPrefix;
      }
      if (this.userInfo.roles != null && this.userInfo.roles.length > 0) {
        await this.$store.dispatch(globalTypes.GET_CAMPAIGNLIST, {
          Roles: this.userInfo.roles,
        });
      }
    }

    this.arrPermission = this.userInfo.moduleFunctions
      .split(",")
      .map(function (item) {
        return parseInt(item, 10);
      });

    this.$Emitter.on("updateStatus", (data) => {
      if (this.Customer.Id == data.customerId) {
        this.Customer.StatusId = data.statusId;
      }
    });

    if (this.checkAuth(778851)) {
      this.fillPASPhoneNumbers();
    }

    if (this.customerId > 0) {
      //Bind Customer Data

      let err, result;

      [err, result] = await this.$store.dispatch(
        types.GET_CLIENT,
        this.customerId
      );

      if (result) {
        if (result.Data) {
          this.Customer = Object.assign({}, result.Data);

          if (
            result.Data.CustomerProfile.CustomerPhones &&
            result.Data.CustomerProfile.CustomerPhones.length > 0
          ) {
            let phones = orderBy(
              result.Data.CustomerProfile.CustomerPhones,
              "Priority",
              "asc"
            );

            if (phones && phones.length > 0) {
              this.primaryPhoneNumber = phones[0].PhoneNumber;
            }
          }

          if (result.Data.CustomerGlobalInformation) {
            this.getCustomerInformation();
            this.firstPaymentDateCheck(
              result.Data.CustomerGlobalInformation.FirstPaymentDate
            );
          }
          this.officeChanged();
          let indx = this.finalSalesAgentList.findIndex(
            (x) => x.Id == this.Customer.SalesUserId
          );
          if (indx == -1) {
            // if current assigned agent is not a sales agent anymore

            let salesAgentItem = {
              Id: this.Customer.SalesUserId,
              Name: this.Customer.SalesUserName,
              OfficeId: 1,
            };
            this.finalSalesAgentList.push(salesAgentItem);
          }
          this.setNullObjectsInModel();
          this.tabs[0].active = true;

          this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.setInitialInfo(); // set initial info to compare for info changes.
          if (this.Customer.Id > 0) {
            this.bindPolicyGroup();
          }

          await this.checkBlueBox();
          await this.showGlobalBalanceSumamry();

          if (!this.Customer.CustomerGlobalInformation.PaymentOptionTypeId) {
            this.Customer.CustomerGlobalInformation.PaymentOptionTypeId = 1;
          }
          if (this.Customer.CustomerGlobalInformation) {
            if (
              typeof this.Customer.CustomerGlobalInformation.DayOfMonth ==
              "undefined"
            )
              this.Customer.CustomerGlobalInformation.DayOfMonth = null;
          }
          let leadData = deepCopy(this.assignedLeadData);
          if (leadData && leadData.Id == this.Customer.Id) {
            let statusName = "";
            let statusIndx = this.customerStatuses.findIndex(
              (x) => x.Id == this.Customer.StatusId
            );
            if (statusIndx > -1) {
              statusName = this.customerStatuses[statusIndx].Name;
            }
            this.$Emitter.emit("refreshPhonePanelAfterCustomerSave", {
              Id: this.Customer.Id,
              AssignedUserId: this.Customer.SalesUserId,
              PhoneList: this.Customer.CustomerProfile.CustomerPhones,
            });
          }

          let PopupTags = this.Customer.CustomerTags.filter((x) => x.IsPopUp);
          let PopUpText = "";
          for (let item of PopupTags) {
            let tagName = this.getTagTypeName(item.TagTypeId);
            let tagType = this.getTagDataType(item.TagTypeId);
            PopUpText +=
              tagName +
              ":  " +
              this.formatTagValue(tagType, item.TagValue) +
              "<br>";
          }
          if (PopUpText.length > 0) {
            this.$swal("Customer Tag Warning!", PopUpText, "warning");
          }

          if (this.checkAuth(12800) && this.isSoldStatus()) {
            await this.createPortalLink();
          }
        }

        if (!this.checkAuth(50002)) {
          await this.getAllOptionalProducts();
        }
      } else {
        this.$swal(
          "Warning",
          "You are not authorized to see this customer",
          "warning"
        );
        this.$emit("forceTabClose", this.customerId);
        this.isLoading = false;
        return;
      }
    } else {
      this.tabs[0].active = true;

      this.Customer = deepCopy(this.customerInit);
      if (this.incomingCustomerInfo) {
        this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
          Message:
            "Campaign Lead: " +
            JSON.stringify(this.incomingCustomerInfo) +
            "##User: " +
            this.userInfo.userId,
        });

        if (this.incomingCustomerInfo.CampaignId > 0) {
          if (this.Customer.CustomerCampaignDetail) {
            this.Customer.CustomerCampaignDetail.CampaignId =
              this.incomingCustomerInfo.CampaignId;
          } else {
            this.Customer.CustomerCampaignDetail = {
              CampaignId: this.incomingCustomerInfo.CampaignId,
              VendorLeadId: null,
              Medium: null,
              Source: null,
              Term: null,
            };
          }
        }

        if (
          this.incomingCustomerInfo.PhoneNumber &&
          this.incomingCustomerInfo.PhoneNumber.length > 8
        ) {
          this.Customer.CustomerProfile.CustomerPhones.push({
            Id: 0,
            PhoneNumber: this.incomingCustomerInfo.PhoneNumber,
            PhoneTypeId: 1,
            Priority: 1,
            Edit: false,
          });
        }

        if (this.incomingCustomerInfo.LanguageId > 0) {
          this.Customer.LanguageId = this.incomingCustomerInfo.LanguageId;
        }
      }
      await this.$store.dispatch(
        globalTypes.UPDATE_INCOMING_CUSTOMER_INFO,
        null
      );
    }

    if (!this.Customer.Id) {
      if (this.customerStatuses.length > 0) {
        let priorityList = this.customerStatuses.map((x) => x.Priority);

        let highestPriority = Math.min.apply(Math, priorityList);

        let beginningStatusId = this.customerStatuses.find(
          (x) => x.Priority == highestPriority
        ).Id;

        if (beginningStatusId) this.Customer.StatusId = beginningStatusId;
      }

      if (this.Customer.SalesUserId == 0 || this.Customer.SalesUserId == null) {
        // SET ASSIGNEDUSER for a new Client
        if (this.userInfo && this.userInfo.userId) {
          this.Customer.SalesUserId = this.userInfo.userId;
        }
      }
    }

    let favorites = this.$store.state.globals.favorites;
    if (favorites.length > 0) {
      let indx = favorites.findIndex((x) => x.CustomerId == this.Customer.Id);
      if (indx > -1) {
        this.isFavorited = true;
      } else {
        this.isFavorited = false;
      }
    }

    this.isBlueBoxExpanded = true;
    this.blueBoxPanelHeight = 325;
    this.isLoading = false;
    //scroll to top on page open

    window.scrollTo(0, 0);

    this.initialSalesUserId = this.Customer.SalesUserId;
  },
  methods: {
    async getAllOptionalProducts() {
      let err, result;
      if (
        this.Customer &&
        this.Customer.CustomerProfile &&
        this.Customer.CustomerGlobalInformation &&
        this.Customer.CustomerGlobalInformation.PolicyGroupId > 0 &&
        this.Customer.CustomerProfile.Address &&
        this.Customer.CustomerProfile.Address.StateId > 0
      ) {
        let payload = {
          PolicyGroupId: this.Customer.CustomerGlobalInformation.PolicyGroupId,
          OfficeId: this.Customer.OfficeId,
          StateId: this.Customer.CustomerProfile.Address.StateId,
          CreatedDate: this.Customer.CreatedDate,
        };
        [err, result] = await this.$store.dispatch(
          types.GET_ALL_OPTIONAL_PRODUCT_TYPES,
          payload
        );

        if (result && result.Data) {
          this.optionalProducts = deepCopy(result.Data);
        }
      }
    },
    getCustomerOptionalProducts() {

      let rtn=[];
      if (
        this.Customer &&
        this.Customer.CustomerGlobalInformation &&
        this.optionalProducts &&
        this.optionalProducts.length > 0
      ) {
        let removedProducts =
          this.Customer.CustomerGlobalInformation.RemovedFeeGroup;
        if (this.optionalProducts && this.optionalProducts.length > 0) {
          let customerProducts = [];
          if(removedProducts && removedProducts.length >0)
        {
          customerProducts = this.optionalProducts.filter(x=> 
            x = !removedProducts.includes(x.Name)
          );
        }
        else{
          customerProducts = this.optionalProducts;
        }
        
        // customerProducts = customerProducts.length > 0 ? customerProducts.map(x=> x.Name) : [];
       // rtn = customerProducts.length > 0 ? customerProducts.map(x => x.replace("OPT - ", "")) : [];
       customerProducts = customerProducts.length > 0 ? customerProducts.map(x => x.Name) : [];
          rtn = customerProducts.length > 0
                  ? customerProducts.map(x =>
                          x.includes("BLP") ? "BLP - Full Service" : x.replace("OPT - ", "")
                  )
                  : [];
        
        }
      }

      return rtn;
    },

    closeEquifaxPopup() {
      this.isEquifaxPopupOpen = false;
    },
    getGlobalTabName(tabName, paymentProcessorId) {
      let rtn = tabName;
      if (paymentProcessorId == 1) {
        rtn = "Global";
      } else if (paymentProcessorId == 2) {
        rtn = "DPG";
      } else if (paymentProcessorId == 3) {
        rtn = "CFT";
      }
      return rtn;
    },
    async getFeeTypes() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        draftChangeTypes.GET_FEES_TYPES,
        {
          CustomerId: this.Customer.Id,
          OfficeId: this.Customer.OfficeId,
        }
      );
      if (result && result.Data) {
        this.feeTypeList = deepCopy(result.Data);
      }
    },
    getFeeTypeKey(value) {
      let rtn = "";
      if (this.feeTypeList.length > 0 && value != "") {
        let index = this.feeTypeList.findIndex((x) => x.Description == value);
        if (index > -1) {
          rtn = this.feeTypeList[index].FeeType;
        }
      }
      return rtn;
    },
    async deActivateRemovedFees() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        draftChangeTypes.GET_FEE_LIST,
        {
          CustomerId: this.Customer.Id,
          OfficeId: this.Customer.OfficeId,
        }
      );

      let feeList = [];
      if (result && result.Data) {
        feeList =
          result.Data.length > 0
            ? result.Data.filter((x) => x.Active == "Y").map((x) => {
                return {
                  EffectiveDate: x.EffectiveDate,
                  DebitId: x.DebitId,
                  Amount: x.Amount,
                  Type: x.Type,
                  Memo: x.Memo,
                  NewDate: x.EffectiveDate,
                  NewAmount: x.Amount,
                  GlobalFeeType: this.getFeeTypeKey(x.Type),
                  CreditorId: x.CreditorId,
                  CreditorName: x.CreditorName,
                };
              })
            : [];

        let err1, result1;
        [err1, result1] = await this.$store.dispatch(
          types.GET_GLOBAL_FEE_TYPES_BY_GROUPING_KEYS,
          {
            OfficeId: this.Customer.OfficeId,
            StateId: this.Customer.CustomerProfile.Address.StateId,
            PolicyGroupId:
              this.Customer.CustomerGlobalInformation.PolicyGroupId,
            CreatedDate: this.Customer.CreatedDate,
            GroupingKeys: this.removedFeeGroupingKeys
              .filter((x) => x.ProductType == "Fee")
              .map((x) => x.Id),
          }
        );
        if (result1 && result1.Data) {
          let globalFeeTypeList = result1.Data;

          if (globalFeeTypeList && globalFeeTypeList.length > 0) {
            let matchedFeeList = feeList.filter((x) =>
              globalFeeTypeList.includes(x.GlobalFeeType)
            );

            if (matchedFeeList && matchedFeeList.length > 0) {
              let changeDetails = [];
              for (let item of matchedFeeList) {
                changeDetails.push({
                  OriginalDate: this.formatShortDate(item.EffectiveDate),
                  FeeDate: this.formatShortDate(item.NewDate),
                  GlobalDebitId: item.DebitId > 0 ? item.DebitId : 0,
                  FeeAmount: 0,
                  GlobalFeeType: item.GlobalFeeType,
                  CreditorId: item.CreditorId,
                });
              }

              if (changeDetails.length > 0) {
                let err2, result2;
                [err2, result2] = await this.$store.dispatch(
                  draftChangeTypes.CREATE_FEE_CHANGE,
                  {
                    CustomerId: this.Customer.Id,
                    ApplicableFees: [],
                    Notes: "Optional fees deactivated.",
                    IsEmergency: false,
                    ChangeDetails: changeDetails,
                  }
                );
              }
            }
          }
        }
      }
    },
    async deActivateRemovedCharges() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        draftChangeTypes.GET_CHARGE_LIST,
        {
          CustomerId: this.Customer.Id,
          OfficeId: this.Customer.OfficeId,
        }
      );

      let chargeList = [];
      if (result && result.Data) {
        chargeList =
          result.Data.length > 0
            ? result.Data.filter((x) => x.Active == "Y").map((x) => {
                return {
                  EffectiveDate: x.EffectiveDate,
                  ChargeId: x.ChargeId,
                  Amount: x.Amount,
                  TransactionCombined: x.TransactionCombined,
                  TransactionType: x.TransactionType,
                };
              })
            : [];

        let err1, result1;
        [err1, result1] = await this.$store.dispatch(
          types.GET_GLOBAL_FEE_TYPES_BY_GROUPING_KEYS,
          {
            OfficeId: this.Customer.OfficeId,
            StateId: this.Customer.CustomerProfile.Address.StateId,
            PolicyGroupId:
              this.Customer.CustomerGlobalInformation.PolicyGroupId,
            CreatedDate: this.Customer.CreatedDate,
            GroupingKeys: this.removedFeeGroupingKeys
              .filter((x) => x.ProductType == "Charge")
              .map((x) => x.Id),
          }
        );

        if (result1 && result1.Data) {
          let globalFeeTypeList = result1.Data;

          if (globalFeeTypeList && globalFeeTypeList.length > 0) {
            let matchedFeeList = chargeList.filter((x) =>
              globalFeeTypeList.includes(x.TransactionType)
            );

            if (matchedFeeList && matchedFeeList.length > 0) {
              let changeDetails = [];
              for (let item of matchedFeeList) {
                changeDetails.push({
                  ChargeId: item.ChargeId,
                  OriginalDate: new Date(item.EffectiveDate).toString(
                    "M/d/yyyy"
                  ),
                  ChargeDate: new Date(item.EffectiveDate).toString("M/d/yyyy"),
                  ChargeAmount: 0,
                });
              }

              if (changeDetails.length > 0) {
                let err2, result2;
                [err2, result2] = await this.$store.dispatch(
                  draftChangeTypes.CREATE_CHARGE_CHANGES,
                  {
                    CustomerId: this.Customer.Id,
                    ApplicableFees: [],
                    Notes: "Optional charges deactivated.",
                    IsEmergency: false,
                    ChangeDetails: changeDetails,
                  }
                );
              }
            }
          }
        }
      }
    },
    async createPortalLink() {
      let err, result;
      [err, result] = await this.$store.dispatch(
        globalTypes.GET_PORTAL_AUTO_LOGIN_LINK,
        { CustomerId: this.customerId }
      );
      if (result) {
        if (result.Data) {
          this.portalAutoLoginUrl = result.Data + "&rp=1";
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    getExpensesAmount() {
      let total = 0;

      this.Customer.CustomerExpenses.forEach((x) => {
        const amount = parseFloat(x.Amount);
        if (!isNaN(amount)) {
          total += amount;
        }
      });

      let expenseTags = this.Customer.CustomerTags.filter(
        (x) => this.getTagDataType(x.TagTypeId) == "Expense"
      );
      if (expenseTags) {
        expenseTags.forEach((x) => {
          const tagValue = parseFloat(x.TagValue);
          if (!isNaN(tagValue)) {
            total += tagValue;
          }
        });
      }

      return total;
    },
    updatePASNumber() {
      if (this.policyGroupId > 0 && this.checkAuth(778851)) {
        let indx = this.policyGroupList.findIndex(
          (x) => x.PolicyGroupId == this.policyGroupId
        );
        if (indx > -1) {
          let pgName = this.policyGroupList[indx].Name;

          if (pgName && pgName != "") {
            if (
              this.policyGroupCheckKeywords.some((x) =>
                pgName.toLowerCase().startsWith(x)
              )
            ) {
              this.setPasNumberByState();
            }
          }
        }
      }
    },
    openCreditPullOptions() {
      if (this.isDataChanged()) {
        this.$swal(
          "You have unsaved changes!",
          "Please save your changes first!",
          "warning"
        );
        return;
      }
    },
    setPasNumberByState() {
      if (this.Customer) {
        if (
          this.Customer.CustomerProfile &&
          this.Customer.CustomerProfile.Address &&
          this.Customer.CustomerProfile.Address.StateId > 0
        ) {
          let customerTags = this.Customer.CustomerTags;
          if (
            customerTags &&
            customerTags.length > 0 &&
            this.customerTagTypes.length > 0
          ) {
            let pasTag = this.customerTagTypes.find(
              (x) => this.cleanTagName(x.TagName) == "pasnumber"
            );
            if (typeof pasTag != "undefined" && pasTag) {
              let indx = customerTags.findIndex(
                (x) => x.TagTypeId == pasTag.Id
              );
              if (indx > -1) {
                let pasPhone = this.getPASPhoneNumber(
                  this.Customer.CustomerProfile.Address.StateId
                );
                if (pasPhone != "") {
                  this.Customer.CustomerTags[indx].TagValue = pasPhone;
                } else {
                  this.Customer.CustomerTags[indx].TagValue = null;
                }
              }
            }
          }
        }
      }
    },
    resetPasNumberByState() {
      if (this.Customer) {
        let customerTags = this.Customer.CustomerTags;
        if (
          customerTags &&
          customerTags.length > 0 &&
          this.customerTagTypes.length > 0
        ) {
          let pasTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "pasnumber"
          );
          if (typeof pasTag != "undefined" && pasTag) {
            let indx = customerTags.findIndex((x) => x.TagTypeId == pasTag.Id);
            if (indx > -1) {
              this.Customer.CustomerTags[indx].TagValue = null;
            }
          }
        }
      }
    },
    policyGroupChanged(pid) {
      if (pid) {
        this.policyGroupId = pid;
      }
      this.updateBlueBoxValues();
    },
    fillPASPhoneNumbers() {
      this.pasPhoneNumbers.push({ StateId: 24, Phone: "218-302-0060" }); //Minnesota
      this.pasPhoneNumbers.push({ StateId: 44, Phone: "281-805-0981" }); //Texas
      this.pasPhoneNumbers.push({ StateId: 9, Phone: "302-206-5455" }); //Delaware
      this.pasPhoneNumbers.push({ StateId: 17, Phone: "785-205-0772" }); //Kansas
      this.pasPhoneNumbers.push({ StateId: 18, Phone: "502-805-4077" }); //Kentuky
      this.pasPhoneNumbers.push({ StateId: 20, Phone: "207-719-2994" }); //Maine
      this.pasPhoneNumbers.push({ StateId: 41, Phone: "854-214-2086" }); //South Carolina
      this.pasPhoneNumbers.push({ StateId: 42, Phone: "605-307-7020" }); //South Dakota
      this.pasPhoneNumbers.push({ StateId: 10, Phone: "813-308-4940" }); //Florida
      this.pasPhoneNumbers.push({ StateId: 1, Phone: "659-208-7466" }); //Alabama
      this.pasPhoneNumbers.push({ StateId: 2, Phone: "231-403-0528" }); //Alaska
      this.pasPhoneNumbers.push({ StateId: 3, Phone: "520-542-2661" }); //Arizona
      this.pasPhoneNumbers.push({ StateId: 4, Phone: "870-300-3454" }); //Arkansas
      this.pasPhoneNumbers.push({ StateId: 5, Phone: "530-512-6201" }); //California
      this.pasPhoneNumbers.push({ StateId: 19, Phone: "225-254-3704" }); //Louisianna
      this.pasPhoneNumbers.push({ StateId: 22, Phone: "413-284-9680" }); //Massachusetts
      this.pasPhoneNumbers.push({ StateId: 23, Phone: "231-403-0528" }); //Michigan
      this.pasPhoneNumbers.push({ StateId: 25, Phone: "601-299-9184" }); //Mississippi
      this.pasPhoneNumbers.push({ StateId: 26, Phone: "660-694-4071" }); //Missouri
      this.pasPhoneNumbers.push({ StateId: 28, Phone: "308-223-4720" }); //Nebraska
      this.pasPhoneNumbers.push({ StateId: 36, Phone: "704-780-0533" }); //Ohio
      this.pasPhoneNumbers.push({ StateId: 37, Phone: "580-527-7911" }); //Oklahoma
      this.pasPhoneNumbers.push({ StateId: 48, Phone: "240-301-7712" }); //Washington D.C.
      this.pasPhoneNumbers.push({ StateId: 33, Phone: "914-809-8039" }); //New York
      this.pasPhoneNumbers.push({ StateId: 34, Phone: "910-310-4351" }); //North Carolina
    },
    getPASPhoneNumber(stateId) {
      let rtn = "";
      if (this.pasPhoneNumbers.length > 0) {
        let indx = this.pasPhoneNumbers.findIndex((x) => x.StateId == stateId);

        rtn = indx > -1 ? this.pasPhoneNumbers[indx].Phone : "";
      }

      return rtn;
    },
    firstPaymentDateChanged() {
      if (this.firstPaymentDateSet == false) {
        if (this.Customer && this.Customer.CustomerGlobalInformation) {
          this.Customer.CustomerGlobalInformation.FirstPaymentDate = null;
        }
      }
    },
    async createOrUpdateLoan() {
      if (this.isDataChanged()) {
        this.$swal(
          "You have unsaved changes!",
          "Please save your changes first!",
          "warning"
        );
        return;
      }
      let err, result;
      [err, result] = await this.$store.dispatch(types.SUBMIT_APPLICATION, {
        CustomerId: this.customerId,
      });
      if (result) {
        if (result.Data && result.Data.IsSuccessful) {
          this.$swal(result.Message, result.Data.Display, "success");
          this.refreshCustomerFromDB(false);
          this.$Emitter.emit("refreshNewLineReport", {
            CustomerId: this.customerId,
          });
        } else if (result.Data && !result.Data.IsSuccessful) {
          let errorMessage = result.Errors.join(",");
          this.$swal(result.Message, errorMessage, "error");
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    async checkLoanStatus() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_APPLICATION, {
        CustomerId: this.customerId,
      });
      if (result) {
        if (result.Data && result.Data.IsSuccessful) {
          if (result.Data.ApiResponse && result.Data.ApiResponse.data) {
            this.newLineLoanResult = deepCopy(result.Data.ApiResponse.data);
            this.isApplicationPopup = true;
          } else {
            this.$swal("Warning!", result.Data.Message, "warning");
          }
        } else {
          this.$swal("Error!", "An error occured!", "error");
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },

    async pushToTCI() {
      if (this.isDataChanged()) {
        this.$swal(
          "You have unsaved changes!",
          "Please save your changes first!",
          "warning"
        );
        return;
      }

      let err, result;
      [err, result] = await this.$store.dispatch(types.PUSH_TO_TCI, {
        CustomerId: this.customerId,
      });
      if (result) {
        this.$swal("Success!", "Successfully pushed to TCI.", "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    async openLeadTracPopup() {
      try {
        if (this.isDataChanged()) {
          this.$swal(
            "You have unsaved changes!",
            "Please save your changes first!",
            "warning"
          );
          return;
        }

        if (
          !(
            this.Customer.CustomerGlobalInformation &&
            this.Customer.CustomerGlobalInformation.PolicyGroupId > 0
          )
        ) {
          this.$swal("Warning", "Policy Group Id required!", "warning");
          return;
        }

        let leadTracTagIndex = -1;
        let leadTracTag = this.customerTagTypes.find(
          (tagType) => tagType.TagName == "_LeadTracId"
        );

        if (typeof leadTracTag == "undefined" || !leadTracTag) {
          this.$swal(
            "Error!",
            "Unable to push to LeadTrac. Required configuration: text type customer tag with name _LeadTracId.",
            "error"
          );
          return;
        }

        let err, result;
        [err, result] = await this.$store.dispatch(types.POST_LEAD_TRAC, {
          CustomerId: this.customerId,
          PolicyGroupId: this.Customer.CustomerGlobalInformation.PolicyGroupId,
        });

        if (result) {
          let leadTrackCustomerStatusId = 0;
          if (result.Data) {
            leadTrackCustomerStatusId = parseInt(result.Data.StatusId);
          }
          if (result.Data && result.Data.LeadTracId) {
            if (
              leadTracTag &&
              this.Customer.CustomerTags &&
              this.Customer.CustomerTags.length > 0
            ) {
              leadTracTagIndex = this.Customer.CustomerTags.findIndex(
                (tag) => tag.TagTypeId == leadTracTag.Id
              );
            }
            if (leadTracTagIndex == -1) {
              let tag = {
                CustomerId: this.Customer.Id,
                TagTypeId: leadTracTag.Id,
                TagValue: result.Data.LeadTracId,
                CreatedUserId: this.userInfo.userId,
                TagCreatedDate: new Date(),
              };
              this.Customer.CustomerTags.push(tag);
              if (leadTrackCustomerStatusId > 0) {
                this.Customer.StatusId = leadTrackCustomerStatusId;
              }
              this.handleSave();
              await this.sleep(3000);
              if (this.checkAuth(82406)) {
                this.linkUrl(this.leadTracUrl + result.Data.LeadTracId);
              }
            } else {
              if (
                this.Customer.CustomerTags[leadTracTagIndex].TagValue !=
                result.Data.LeadTracId
              ) {
                this.Customer.CustomerTags[leadTracTagIndex].TagValue =
                  result.Data.LeadTracId;
                if (leadTrackCustomerStatusId > 0) {
                  this.Customer.StatusId = leadTrackCustomerStatusId;
                }
                this.handleSave();
                await this.sleep(3000);
                if (this.checkAuth(82406)) {
                  this.linkUrl(this.leadTracUrl + result.Data.LeadTracId);
                }
              }
            }
          }

          this.$swal("Success!", "Record added successfully.", "success");
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      } catch {
        this.$swal("Error!", "An error occured!", "error");
      }
    },

    saveCustomerFromTags() {
      this.handleSave();
    },
    async IsDraftDifferentInDB() {
      let rtn = false;
      if (
        this.Customer.CustomerGlobalInformation.DraftAmount &&
        this.Customer.CustomerGlobalInformation.DraftAmount != null &&
        !isNaN(parseInt(this.Customer.CustomerGlobalInformation.DraftAmount)) &&
        parseInt(this.Customer.CustomerGlobalInformation.DraftAmount) > 0
      ) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          globalTypes.GET_DRAFT_AMOUNT,
          {
            CustomerId: this.customerId,
          }
        );
        if (result && result.Data) {
          if (
            parseInt(this.Customer.CustomerGlobalInformation.DraftAmount) !=
            parseInt(result.Data.DraftAmount)
          ) {
            rtn = true;
          }
        }
      }
      return rtn;
    },

    openDocusignOptionsPopup() {
      this.isDocusignSendingOptionsOpen = true;
    },
    closeSendingOptionsPopup() {
      this.isDocusignSendingOptionsOpen = false;
    },
    closeApplicationPopUp() {
      this.isApplicationPopup = false;
    },
    async getCustomerInformation() {
      if (
        this.Customer.CustomerGlobalInformation != null &&
        this.Customer.CustomerGlobalInformation.AccountNumber != null
      ) {
        let err, result;

        [err, result] = await this.$store.dispatch(types.GET_EDIT_CLIENT_INFO, {
          CustomerId: this.customerId,
          OfficeId: this.userOfficeId,
        });
        if (result && result.Data) {
          this.clientInfo = deepCopy(result.Data);
        }
      }
    },
    async updatePaymentCalculator() {
      await this.updateBlueBoxValues();
    },
    checkSalesUserId() {
      if (this.finalSalesAgentList && this.finalSalesAgentList.length > 0) {
        let indx = this.finalSalesAgentList.findIndex(
          (x) => x.Id == this.Customer.SalesUserId
        );
        if (indx == -1) {
          this.Customer.SalesUserId = null;
        }
      } else {
        this.Customer.SalesUserId = null;
      }
    },
    async officeChanged() {
      if (this.Customer && !this.checkAuth(1454) && this.checkAuth(55011)) {
        let officeId = this.Customer.OfficeId;
        if (officeId > 0) {
          this.finalSalesAgentList = this.filteredSalesAgents.filter(
            (x) => x.OfficeId == officeId
          );
        }

        //this.checkSalesUserId();
      }

      if (this.Customer.Id > 0) {
        this.bindPolicyGroup();
      }
      await this.updateBlueBoxValues();
    },
    getWeeklyDraftAmount(typeId) {
      if (typeId == 2) {
        //twice a month
        let yearlyTotalAmount =
          parseFloat(this.Customer.CustomerGlobalInformation.DraftAmount) * 12;
        return parseFloat(yearlyTotalAmount / 24);
      } else if (typeId == 3) {
        //Bi-weekly
        let yearlyTotalAmount =
          parseFloat(this.Customer.CustomerGlobalInformation.DraftAmount) * 12;
        return parseFloat(yearlyTotalAmount / 26);
      } else if (typeId == 4) {
        //weekly
        let yearlyTotalAmount =
          parseFloat(this.Customer.CustomerGlobalInformation.DraftAmount) * 12;
        return parseFloat(yearlyTotalAmount / 52);
      } else {
        return "Invalid option";
      }
    },
    toggleBlueBox() {
      this.isBlueBoxExpanded = !this.isBlueBoxExpanded;

      if (this.isBlueBoxExpanded) {
        this.blueBoxPanelHeight = 325;
      } else {
        this.blueBoxPanelHeight = 60;
      }
    },
    // getTotalPayback() {
    //   let rtn = 0;
    //   if (
    //     this.Customer &&
    //     this.Customer.CustomerGlobalInformation &&
    //     this.Customer.CustomerGlobalInformation.DraftAmount &&
    //     this.Customer.CustomerGlobalInformation.EnrollmentMonths
    //   ) {
    //     let draftAmount = parseFloat(
    //       this.Customer.CustomerGlobalInformation.DraftAmount
    //     );
    //     let months = parseInt(
    //       this.Customer.CustomerGlobalInformation.EnrollmentMonths
    //     );

    //     rtn = parseFloat(draftAmount * months);
    //   }
    //   return rtn;
    // },
    async showMeridianLinkPopup() {
      this.meridianCustomerArr = [];
      let creditPullCustomerArr = [];
      // create customer - co Customer array
      // get address values for both
      let addr = {
        street: "",
        city: "",
        state: null,
        zipCode: "",
      };
      if (this.Customer.CustomerProfile.Address) {
        if (this.Customer.CustomerProfile.Address.Line1) {
          addr.street = this.Customer.CustomerProfile.Address.Line1;
        }

        if (this.Customer.CustomerProfile.Address.Line2) {
          addr.street =
            addr.street + " " + this.Customer.CustomerProfile.Address.Line2;
        }

        if (this.Customer.CustomerProfile.Address.City) {
          addr.city = this.Customer.CustomerProfile.Address.City;
        }
        if (this.Customer.CustomerProfile.Address.StateId) {
          addr.state = this.Customer.CustomerProfile.Address.StateId;
        }
        if (this.Customer.CustomerProfile.Address.Zip) {
          addr.zipCode = this.Customer.CustomerProfile.Address.Zip;
        }
      }
      //push Customer Profile 1st

      let mainCustomer = await this.generateCustomerData4CreditPull(
        0,
        this.Customer.Id,
        this.Customer.CustomerProfile.FirstName,
        this.Customer.CustomerProfile.LastName,
        this.Customer.CustomerProfile.DateOfBirth,
        this.Customer.CustomerProfile.SsnEncrypted,
        addr
      );

      creditPullCustomerArr.push(mainCustomer);
      // for loop for co clients to fill for Credit Pull
      if (
        this.Customer.CoCustomerProfiles &&
        this.Customer.CoCustomerProfiles.length > 0
      ) {
        for (let item of this.Customer.CoCustomerProfiles) {
          let coCustomer = await this.generateCustomerData4CreditPull(
            item.Id,
            this.Customer.Id,
            item.FirstName,
            item.LastName,
            item.DateOfBirth,
            item.SsnEncrypted,
            addr
          );
          creditPullCustomerArr.push(coCustomer);
        }
      }
      this.pullCreditSourceId = 2;
      this.meridianCustomerArr = creditPullCustomerArr;
      this.isPullCreditRequestPopupOpen = true;
    },

    getServiceFeePercentage() {
      let rtn = this.paymentCalculatorServiceFeePercent * 100;
      if (
        this.Customer &&
        this.Customer.CustomerGlobalInformation &&
        this.Customer.CustomerGlobalInformation.FeePercentage != null &&
        this.Customer.CustomerGlobalInformation.FeePercentage > 0
      ) {
        rtn = this.Customer.CustomerGlobalInformation.FeePercentage * 100;
      }
      return parseFloat(rtn.toFixed(2));
    },

    closeSettlementFeePercentagePopup() {
      this.isSettlementFeePercentagePopup = false;
    },

    saveServiceFeePercantage(value) {
      this.isSettlementFeePercentagePopup = false;
      if (value.FeePercentage == null) {
        this.Customer.CustomerGlobalInformation.FeePercentage = null;
      } else {
        this.Customer.CustomerGlobalInformation.FeePercentage = parseFloat(
          value.FeePercentage / 100
        );
      }

      let removedFees = null;
      if (value.RemovedFeeGroup != null) {
        this.removedFeeGroupingKeys = value.RemovedFeeGroup;
        if (value.RemovedFeeGroup.length > 1) {
          removedFees = value.RemovedFeeGroup.map((x) => x.Id).join(",");
        } else if (value.RemovedFeeGroup.length == 1) {
          removedFees = value.RemovedFeeGroup[0].Id;
        }
      }

      this.Customer.CustomerGlobalInformation.RemovedFeeGroup = removedFees;

      if (value.MinDebtAmount > 0) {
        this.Customer.CustomerGlobalInformation.MinDebtAmount =
          value.MinDebtAmount;
      }
      if (value.MinDraftAmount > 0) {
        this.Customer.CustomerGlobalInformation.MinDraftAmount =
          value.MinDraftAmount;
      }

      this.handleSave();
    },

    async openSettlementFeePercentagePopup() {
      if (this.checkAuth(100053)) {
        await this.$store.dispatch(types.GET_DOCUSIGN_CONTRACT_VALIDATION, {
          CompanyId: this.Customer.CompanyId,
        });
      }

      this.isSettlementFeePercentagePopup = true;
    },
    PaymentOptionTypeIdChanged() {
      if (this.Customer.CustomerGlobalInformation.PaymentOptionTypeId != 2) {
        this.Customer.CustomerGlobalInformation.DayOfMonth = null;
      }
    },
    addPrefixToCustomerId(customerId) {
      let rtn = customerId.toString();
      if (this.customerPrefix != null) {
        rtn = this.customerPrefix + rtn;
      }
      return rtn;
    },

    compareArray(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },
    getTagTypeName(id) {
      let rtn = "";
      if (this.customerTagTypes.length > 0 && id > 0) {
        let index = this.customerTagTypes.findIndex((x) => x.Id == id);
        rtn = index > -1 ? this.customerTagTypes[index].TagName : "";
      }

      return rtn;
    },
    getTagDataType(id) {
      let rtn = "";
      if (this.customerTagTypes.length > 0 && id > 0) {
        let index = this.customerTagTypes.findIndex((x) => x.Id == id);
        rtn = index > -1 ? this.customerTagTypes[index].TagDataType : "";
      }

      return rtn;
    },
    formatTagValue(tagTypeName, tagValue) {
      let rtn = tagValue;
      if (tagTypeName == "Money") {
        rtn = this.formatMoney(tagValue);
      } else if (tagTypeName == "Date") {
        rtn = this.formatShortDate(tagValue);
      } else if (tagTypeName == "Date and Time") {
        rtn = this.formatGridDate(tagValue);
      } else if (tagTypeName == "Percantage") {
        rtn = this.formatPercentage(tagValue);
      } else if (tagTypeName == "CheckItem") {
        rtn = tagValue == true ? "Yes" : "No";
      }
      return rtn;
    },
    getNextCashFlowData(cashFlowData, months) {
      let balance = 0;
      let currentDate = new Date();
      this.addMonthsFix(currentDate, months);

      let currentMonth = currentDate.getMonth();
      let nextDate = this.addMonthsFix(currentDate, months + 1);
      while (nextDate.getMonth() != currentMonth) {
        nextDate = this.addDays(nextDate, -1);
      }

      let arr = cashFlowData.filter(
        (x) =>
          new Date(x.Date) <=
          new Date(
            nextDate.getFullYear(),
            nextDate.getMonth(),
            nextDate.getDate(),
            23,
            59,
            59
          )
      );
      if (arr.length > 0) {
        balance = arr[arr.length - 1].Balance;
      }

      return balance;
    },
    async showGlobalBalanceSumamry() {
      if (!this.checkAuth(54124)) return;

      if (
        this.Customer &&
        this.Customer.Id > 0 &&
        this.checkAuth(4815) &&
        this.Customer.OfficeId &&
        this.Customer.Bank &&
        this.Customer.CustomerProfile.Address &&
        this.Customer.CustomerGlobalInformation &&
        this.Customer.CustomerGlobalInformation.PolicyGroupId &&
        this.Customer.CustomerGlobalInformation.AccountNumber
      ) {
        let err, result;
        [err, result] = await this.$store.dispatch(gcsTypes.GET_CASHFLOW_DATA, {
          CustomerId: this.Customer.Id,
          OfficeId: this.Customer.OfficeId,
        });

        if (result && result.Data) {
          let cashFlowData = result.Data.CashFlows;

          let currentBalance = 0;

          let postedTransactions = cashFlowData.filter((x) =>
            this.Customer.CustomerGlobalInformation.IsDpg == true
              ? x.Status == "Cleared"
              : x.Status == "Posted"
          );
          if (postedTransactions.length > 0) {
            currentBalance =
              postedTransactions[postedTransactions.length - 1].Balance;
          }
          this.balanceSummaryText = this.formatMoney(currentBalance);

          let balance1 = this.getNextCashFlowData(cashFlowData, 1);

          let balance2 = this.getNextCashFlowData(cashFlowData, 2);

          let balance3 = this.getNextCashFlowData(cashFlowData, 3);

          this.next3MonthsCashFlowList = [];
          this.next3MonthsCashFlowList.push({
            MonthName: this.getMonthName(this.addMonthsFix(new Date(), 1)),
            Cash: balance1,
          });
          this.next3MonthsCashFlowList.push({
            MonthName: this.getMonthName(this.addMonthsFix(new Date(), 2)),
            Cash: balance2,
          });
          this.next3MonthsCashFlowList.push({
            MonthName: this.getMonthName(this.addMonthsFix(new Date(), 3)),
            Cash: balance3,
          });
        }

        let err2, result2;
        [err2, result2] = await this.$store.dispatch(
          types.GET_BALANCE_SUMMARY,
          { CustomerId: this.Customer.Id }
        );
        if (result2 && result2.Data) {
          this.lastClearedDraftDate = this.formatShortDate(
            result2.Data.LastClearedDraftDate
          );
          this.nextDraftDate = this.formatShortDate(
            result2.Data.NextClearDraftDate
          );
        }
      }
    },
    async updateBlueBoxforNameChange() {
      await this.updateBlueBoxValues();
    },
    isAuthorizedToSee(authId) {
      let rtn = false;

      if (this.userInfo != null && this.userInfo.moduleFunctions != null) {
        let arrPermission = this.userInfo.moduleFunctions
          .split(",")
          .map(function (item) {
            return parseInt(item, 10);
          });

        if (arrPermission.includes(parseInt(authId))) {
          rtn = true;
        }
      }
      return rtn;
    },
    customerInitFnc() {
      return {
        CompanyId: null,
        OfficeId: null,
        SalesUserId: null,
        StatusId: null,
        LanguageId: null,
        CreatedDate: null,
        CreatedUserId: null,
        HardshipId: null,
        HardshipStatementNotes: null,
        CreditorTotalBalance: 0.0,

        IsTermSettlement: false,
        TermSettlementStartDate: null,
        TermSettlementEndDate: null,
        Bank: {
          Name: null,
          RoutingNumber: null,
          AccountTypeId: null,
          AccountNumberEncrypted: null,
          ValidationStatus: null,
        },
        CustomerCampaignDetail: {
          CampaignId: null,
          Name: null,
          VendorLeadId: null,
          Medium: null,
          Source: null,
          Term: null,
        },
        CustomerProfile: {
          FirstName: null,
          LastName: null,
          SsnEncrypted: null,
          MiddleInitial: null,
          GenderId: null,
          MothersMaidenName: null,
          OccupationId: null,
          DebtAmount: 0.0,
          Income: 0,
          DateOfBirth: null,
          ResidenceOwnershipId: null,
          ResidenceYears: null,
          DeclaredBankruptcy: null,
          ChapterId: null,
          YearFiled: null,
          YearDischarged: null,
          CreditRatingId: null,
          Address: {
            Line1: null,
            Line2: null,
            Zip: null,
            City: null,
            StateId: null,
            TimeZoneId: null,
            ValidationStatus: null,
          },
          CustomerEmails: [],
          CustomerPhones: [],
          ExtraTermMonths: 0,
        },
        CoCustomerProfiles: [],
        CustomerExpenses: [],
        CustomerGlobalInformation: {
          Id: 0,
          AccountNumber: null,
          DraftAmount: null,
          EnrolledDate: null,
          EnrollmentMonths: null,
          GlobalBalanceOffline: null,
          StartDate: null,
          FirstPaymentDate: null,
          PolicyGroupId: null,
          PaymentOptionTypeId: 1,
          DayOfMonth: null,
          FeePercentage: null,
          RemovedFeeGroup: null,
          IsDpg: null,
          MinDebtAmount: null,
          MinDraftAmount: null,
        },
        CustomerTags: [],
      };
    },
    emptyTheObjectsOnUnmount() {
      this.customerDocusignContractValidationError = null;
      this.coCustomerDocusignContractValidationError = null;

      this.isSuperMoneyPopupOpen = null;
      this.dataToPassSuperMoney = null;
      this.isRefreshing = null;
      this.Customer = null;
      this.assignedAgent = null;
      this.assignedAgentList = null;
      this.customerToSave = null;
      this.oldValue = null;
      this.isLoading = null;
      this.arrPermission = null;
      this.tabs = null;
      (this.docusignHistory = null), (this.customerAvailableTemplates = null);
      this.saving = null;
      this.invalid = null;
      this.isFavorited = null;
      this.isQuickEmailPopupOpen = null;
      this.selectedQuickEmailFromPopup = null;
      this.isEnvelopeHistoryPopupOpen = null;
      this.isSendEnvelopePopupOpen = null;
      this.customerPrimaryEmail = null;
      this.isBlueBoxReadOnly = null;
      this.blueBoxMonthsMax = null;
      // this.totalAmountValue = null;

      this.datepickerConfig = null;
      this.datepickerNoConfig = null;
      this.isPullCreditRequestPopupOpen = null;
      this.pullCreditCustomerArr = null;
      this.isPullCreditHistoryPopupOpen = null;
      this.meridianCustomerArr = null;
      this.xActusCustomerArr = null;
      this.isStatusChangePopupOpen = null;
      this.isInitiallySoldStatus = null;
      this.initialStatusId = null;
      this.initialSalesUserId = null;
      this.skipStatusControl = null;
      this.changeDetailType = null;
      this.InitialInfo = null;
      this.policyGroupList = null;
      this.tabId = null;
    },
    blockChanges() {
      let rtn = false;

      if (!this.hasAuth(96483)) {
        rtn = this.customerStatuses.some(
          (x) => x.Id == this.Customer.StatusId && x.IsBlockMakingChange
        );
      }
      return rtn;
    },

    async showSpinWheelPopup(isManual) {
      this.$store.commit(
        globalTypes.SET_CURRENT_KO_PATH,
        this.$koPaths.customers.cprSpinwheel
      );
      this.spinWheelCustomerArr = [];
      let creditPullCustomerArr = [];
      let addr = {
        street: "",
        city: "",
        state: null,
        zipCode: "",
      };
      let mainCustomer = await this.generateCustomerData4CreditPull(
        0,
        this.Customer.Id,
        this.Customer.CustomerProfile.FirstName,
        this.Customer.CustomerProfile.LastName,
        this.Customer.CustomerProfile.DateOfBirth,
        this.Customer.CustomerProfile.SsnEncrypted,
        addr,
        this.primaryPhoneNumber
      );

      creditPullCustomerArr.push(mainCustomer);

      if (
        this.Customer.CoCustomerProfiles &&
        this.Customer.CoCustomerProfiles.length > 0
      ) {
        for (let item of this.Customer.CoCustomerProfiles) {
          let phone = "";
          if (item.PhoneList && item.PhoneList.length > 0) {
            let sorted = orderBy(item.PhoneList, "Priority", "asc");
            phone = sorted[0].PhoneNumber;
          }

          let coCustomer = await this.generateCustomerData4CreditPull(
            item.Id,
            this.Customer.Id,
            item.FirstName,
            item.LastName,
            item.DateOfBirth,
            item.SsnEncrypted,
            addr,
            phone
          );
          creditPullCustomerArr.push(coCustomer);
        }
      }
      this.pullCreditSourceId = isManual ? 6 : 5;
      this.spinWheelCustomerArr = creditPullCustomerArr;
      this.isPullCreditRequestPopupOpen = true;
    },

    async showTrustedCreditPopup() {
      this.$store.commit(
        globalTypes.SET_CURRENT_KO_PATH,
        this.$koPaths.customers.cprTrustedCredit
      );
      this.trustedCreditCustomerArr = [];
      let creditPullCustomerArr = [];
      // create customer - co Customer array
      // get address values for both
      let addr = {
        street: "",
        city: "",
        state: null,
        zipCode: "",
      };
      if (this.Customer.CustomerProfile.Address) {
        if (this.Customer.CustomerProfile.Address.Line1) {
          addr.street = this.Customer.CustomerProfile.Address.Line1;
        }

        if (this.Customer.CustomerProfile.Address.Line2) {
          addr.street =
            addr.street + " " + this.Customer.CustomerProfile.Address.Line2;
        }

        if (this.Customer.CustomerProfile.Address.City) {
          addr.city = this.Customer.CustomerProfile.Address.City;
        }
        if (this.Customer.CustomerProfile.Address.StateId) {
          addr.state = this.Customer.CustomerProfile.Address.StateId;
        }
        if (this.Customer.CustomerProfile.Address.Zip) {
          addr.zipCode = this.Customer.CustomerProfile.Address.Zip;
        }
      }
      //push Customer Profile 1st

      let mainCustomer = await this.generateCustomerData4CreditPull(
        0,
        this.Customer.Id,
        this.Customer.CustomerProfile.FirstName,
        this.Customer.CustomerProfile.LastName,
        this.Customer.CustomerProfile.DateOfBirth,
        this.Customer.CustomerProfile.SsnEncrypted,
        addr
      );

      creditPullCustomerArr.push(mainCustomer);
      // for loop for co clients to fill for Credit Pull
      if (
        this.Customer.CoCustomerProfiles &&
        this.Customer.CoCustomerProfiles.length > 0
      ) {
        for (let item of this.Customer.CoCustomerProfiles) {
          let coCustomer = await this.generateCustomerData4CreditPull(
            item.Id,
            this.Customer.Id,
            item.FirstName,
            item.LastName,
            item.DateOfBirth,
            item.SsnEncrypted,
            addr
          );
          creditPullCustomerArr.push(coCustomer);
        }
      }
      this.pullCreditSourceId = 3;
      this.trustedCreditCustomerArr = creditPullCustomerArr;
      this.isPullCreditRequestPopupOpen = true;
    },

    async showxActusPopup() {
      this.$store.commit(
        globalTypes.SET_CURRENT_KO_PATH,
        this.$koPaths.customers.cprXactus
      );
      this.xActusCustomerArr = [];
      let creditPullCustomerArr = [];
      // create customer - co Customer array
      // get address values for both
      let addr = {
        street: "",
        city: "",
        state: null,
        zipCode: "",
      };
      if (this.Customer.CustomerProfile.Address) {
        if (this.Customer.CustomerProfile.Address.Line1) {
          addr.street = this.Customer.CustomerProfile.Address.Line1;
        }

        if (this.Customer.CustomerProfile.Address.Line2) {
          addr.street =
            addr.street + " " + this.Customer.CustomerProfile.Address.Line2;
        }

        if (this.Customer.CustomerProfile.Address.City) {
          addr.city = this.Customer.CustomerProfile.Address.City;
        }
        if (this.Customer.CustomerProfile.Address.StateId) {
          addr.state = this.Customer.CustomerProfile.Address.StateId;
        }
        if (this.Customer.CustomerProfile.Address.Zip) {
          addr.zipCode = this.Customer.CustomerProfile.Address.Zip;
        }
      }
      //push Customer Profile 1st

      let mainCustomer = await this.generateCustomerData4CreditPull(
        0,
        this.Customer.Id,
        this.Customer.CustomerProfile.FirstName,
        this.Customer.CustomerProfile.LastName,
        this.Customer.CustomerProfile.DateOfBirth,
        this.Customer.CustomerProfile.SsnEncrypted,
        addr
      );

      creditPullCustomerArr.push(mainCustomer);
      // for loop for co clients to fill for Credit Pull

      let activeCoCustomers = [];
      if (
        this.Customer.CoCustomerProfiles &&
        this.Customer.CoCustomerProfiles.length > 0
      ) {
        activeCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => !x.IsExcluded
        );
      }

      if (activeCoCustomers.length > 0) {
        for (let item of activeCoCustomers) {
          let coCustomer = await this.generateCustomerData4CreditPull(
            item.Id,
            this.Customer.Id,
            item.FirstName,
            item.LastName,
            item.DateOfBirth,
            item.SsnEncrypted,
            addr
          );
          creditPullCustomerArr.push(coCustomer);
        }
      }
      this.pullCreditSourceId = 4;
      this.xActusCustomerArr = creditPullCustomerArr;
      this.isPullCreditRequestPopupOpen = true;
    },
    async showPullCreditRequestPopup() {
      this.$store.commit(
        globalTypes.SET_CURRENT_KO_PATH,
        this.$koPaths.customers.cprArray
      );
      this.pullCreditCustomerArr = [];
      let creditPullCustomerArr = [];
      // create customer - co Customer array
      // get address values for both
      let addr = {
        street: "",
        city: "",
        state: null,
        zipCode: "",
      };
      if (this.Customer.CustomerProfile.Address) {
        if (this.Customer.CustomerProfile.Address.Line1) {
          addr.street = this.Customer.CustomerProfile.Address.Line1;
        }

        if (this.Customer.CustomerProfile.Address.Line2) {
          addr.street =
            addr.street + " " + this.Customer.CustomerProfile.Address.Line2;
        }

        if (this.Customer.CustomerProfile.Address.City) {
          addr.city = this.Customer.CustomerProfile.Address.City;
        }
        if (this.Customer.CustomerProfile.Address.StateId) {
          addr.state = this.Customer.CustomerProfile.Address.StateId;
        }
        if (this.Customer.CustomerProfile.Address.Zip) {
          addr.zipCode = this.Customer.CustomerProfile.Address.Zip;
        }
      }
      //push Customer Profile 1st

      let mainCustomer = await this.generateCustomerData4CreditPull(
        0,
        this.Customer.Id,
        this.Customer.CustomerProfile.FirstName,
        this.Customer.CustomerProfile.LastName,
        this.Customer.CustomerProfile.DateOfBirth,
        this.Customer.CustomerProfile.SsnEncrypted,
        addr
      );

      creditPullCustomerArr.push(mainCustomer);
      // for loop for co clients to fill for Credit Pull
      if (
        this.Customer.CoCustomerProfiles &&
        this.Customer.CoCustomerProfiles.length > 0
      ) {
        for (let item of this.Customer.CoCustomerProfiles) {
          let coCustomer = await this.generateCustomerData4CreditPull(
            item.Id,
            this.Customer.Id,
            item.FirstName,
            item.LastName,
            item.DateOfBirth,
            item.SsnEncrypted,
            addr
          );
          creditPullCustomerArr.push(coCustomer);
        }
      }
      this.pullCreditCustomerArr = creditPullCustomerArr;
      this.pullCreditSourceId = 1;
      this.isPullCreditRequestPopupOpen = true;
    },

    async openPullCreditRequestPopup(sourceId) {
      if (this.creditPullScripts && this.creditPullScripts.length > 0) {
        let message = "";
        let orderedList = orderBy(
          this.creditPullScripts,
          "DisplayOrder",
          "asc"
        );
        for (let item of orderedList) {
          message = message + "<b>" + item.Title + "</b><br>";
          message = message + item.Script + "<br>";
        }
        message += "Did you read the scripts?";
        this.$swal
          .fire({
            title: "PLEASE READ:",
            html: message,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowOutsideClick: false,
          })
          .then(async (dialog) => {
            if (dialog.value) {
              if (sourceId == 1) {
                this.showPullCreditRequestPopup();
              } else if (sourceId == 2) {
                this.showMeridianLinkPopup();
              } else if (sourceId == 3) {
                this.showTrustedCreditPopup();
              } else if (sourceId == 4) {
                this.showxActusPopup();
              } else if (sourceId == 5) {
                this.showSpinWheelPopup();
              } else if (sourceId == 6) {
                this.showSpinWheelPopup(true);
              }
            }
          });
      } else {
        if (sourceId == 1) {
          this.showPullCreditRequestPopup();
        } else if (sourceId == 2) {
          this.showMeridianLinkPopup();
        } else if (sourceId == 3) {
          this.showTrustedCreditPopup();
        } else if (sourceId == 4) {
          this.showxActusPopup();
        } else if (sourceId == 5) {
          this.showSpinWheelPopup();
        } else if (sourceId == 6) {
          this.showSpinWheelPopup(true);
        }
      }
    },
    async generateCustomerData4CreditPull(
      coCustId,
      customerId,
      firstName,
      lastName,
      dateofBirth,
      ssnEnc,
      addr,
      phone = ""
    ) {
      let rtn = {
        coCustId: 0,
        customerId: 0,
        firstName: "",
        lastName: "",
        dateofBirth: "",
        ssn: "",
        street: "",
        city: "",
        state: null,
        zipCode: "",
        phone: "",
      };
      rtn.coCustId = coCustId;
      rtn.customerId = customerId;
      rtn.firstName = firstName;
      rtn.lastName = lastName;
      rtn.dateofBirth = dateofBirth;
      rtn.ssn = "";
      if (ssnEnc && ssnEnc.length > 0) {
        rtn.ssn = await this.AES256_GCM_decrypt(ssnEnc);
      }

      rtn.street = addr.street;
      rtn.city = addr.city;
      rtn.state = addr.state;
      rtn.zipCode = addr.zipCode;
      rtn.phone = phone;
      return rtn;
    },

    closePullCreditRequestPopup(message) {
      this.isPullCreditRequestPopupOpen = false;
      if (message != null) {
        this.$swal(message.Type, message.Text, message.Type.toLowerCase());
      }
    },
    openArrayAuthPopup(data) {
      if (data) {
        this.isPullCreditRequestPopupOpen = false;
        this.IsArrayAdditionalAuthorizationPopupOpen = true;
        this.arrayAuthQuestionsData = data;
      }
    },
    openSpinWheelAuthPopup(data) {
      if (data) {
        this.isPullCreditRequestPopupOpen = false;
        this.isSpinWheelAdditionalAuthorizationPopupOpen = true;
        this.spinWheelAuthData = data;
      }
    },
    async closeArrayAdditionalAuthorization(result) {
      this.IsArrayAdditionalAuthorizationPopupOpen = false;
      if (result) {
        if (!result.Data && result.Message) {
          this.$swal(
            result.Message.Type,
            result.Message.Text,
            result.Message.Type.toLowerCase()
          );
        } else if (result.Data) {
          await this.sleep(1000);
          let coCustomerAuthData = deepCopy(result.Data);
          coCustomerAuthData.isJointRequest = false;
          coCustomerAuthData.clientKey = result.Data.clientKey2;
          this.arrayAuthQuestionsData = coCustomerAuthData;
          this.IsArrayAdditionalAuthorizationPopupOpen = true;
        }
      }
    },
    closeSpinWheelAdditionalAuthorization(message) {
      this.isSpinWheelAdditionalAuthorizationPopupOpen = false;
      if (message != null) {
        this.$swal(message.Type, message.Text, message.Type.toLowerCase());
      }
    },
    openPullCreditHistoryPopup() {
      this.isPullCreditHistoryPopupOpen = true;
    },
    closePullCreditHistoryPopup() {
      this.isPullCreditHistoryPopupOpen = false;
    },
    async openEquifaxPopup() {
      if (this.Customer && this.Customer.CustomerProfile) {
        let ssnEnc = this.Customer.CustomerProfile.SsnEncrypted;
        let coCustomerSsnEnc = "";
        if (
          this.Customer.CoCustomerProfiles &&
          this.Customer.CoCustomerProfiles.length > 0
        ) {
          coCustomerSsnEnc = this.Customer.CoCustomerProfiles[0].SsnEncrypted;
        }

        let ssnDecyrpted = "";
        let coCustomerSsnDecyrpted = "";

        if (ssnEnc && ssnEnc.length > 0) {
          ssnDecyrpted = await this.AES256_GCM_decrypt(ssnEnc);
        }
        if (coCustomerSsnEnc && coCustomerSsnEnc.length > 0) {
          coCustomerSsnDecyrpted = await this.AES256_GCM_decrypt(
            coCustomerSsnEnc
          );
        }

        let addressLine1 = "",
          addressLine2 = "",
          city = "",
          zip = "",
          stateId = 0;
        if (this.Customer.CustomerProfile.Address) {
          let addressInfo = this.Customer.CustomerProfile.Address;
          city = addressInfo.City;
          addressLine1 = addressInfo.Line1;
          addressLine2 = addressInfo.Line2;
          zip = addressInfo.Zip;
          stateId = addressInfo.StateId;
        }

        this.equifaxCustomerData = {
          CustomerId: this.Customer.Id,
          FirstName: this.Customer.CustomerProfile.FirstName,
          MiddleName: this.Customer.CustomerProfile.MiddleInitial,
          LastName: this.Customer.CustomerProfile.LastName,
          DateOfBirth: new Date(this.Customer.CustomerProfile.DateOfBirth),
          Ssn: ssnDecyrpted != "" ? ssnDecyrpted : "",
          City: city,
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          Zip: zip,
          StateId: stateId ? stateId : 1,
          PhoneNumber: this.primaryPhoneNumber,
          Email: this.customerPrimaryEmail,
        };

        if (
          this.Customer.CoCustomerProfiles &&
          this.Customer.CoCustomerProfiles.length > 0
        ) {
          let firstProfile = this.Customer.CoCustomerProfiles[0];
          this.equifaxCoCustomerData = {
            CustomerId: firstProfile.Id,
            FirstName: firstProfile.FirstName,
            MiddleName: firstProfile.MiddleInitial,
            LastName: firstProfile.LastName,
            DateOfBirth: new Date(firstProfile.DateOfBirth),
            Ssn: coCustomerSsnDecyrpted != "" ? coCustomerSsnDecyrpted : "",
            City: city,
            AddressLine1: addressLine1,
            AddressLine2: addressLine2,
            Zip: zip,
            StateId: stateId ? stateId : 1,
            PhoneNumber: firstProfile.CoCustomerPhones[0]?.PhoneNumber || "",
            Email: firstProfile.CoCustomerEmails[0]?.Email || "",
          };
        }
      }

      if (this.equifaxCustomerData) {
        this.isEquifaxPopupOpen = true;
      } else {
        this.$swal("Warning", "Customer information required", "warning");
      }
    },
    showCreditReport(data) {
      if (data != null) {
        this.$Emitter.emit("openCreditPullReportPopup", {
          CustomerId: this.customerId,
          BlockMakingChange: this.blockChanges(),
          CreditReportData: data,
          ServiceId: data.ServiceId,
        });

        this.isPullCreditHistoryPopupOpen = false;
      } else {
        this.$swal("Error!", "An error occured!", "error");
      }
    },
    async openGlobalMeridians() {
      let warningMsg = "";
      let annualIncome = 0;
      let totalAmount = 0;
      if (
        this.Customer.CustomerProfile.DebtAmount &&
        parseInt(this.Customer.CustomerProfile.DebtAmount) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CustomerProfile.DebtAmount);
      } else if (
        this.Customer.CreditorTotalBalance &&
        parseInt(this.Customer.CreditorTotalBalance) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CreditorTotalBalance);
      }
      let shortState = "";
      if (
        this.Customer.CustomerProfile.Address.StateId &&
        parseInt(this.Customer.CustomerProfile.Address.StateId) > 0
      ) {
        shortState = this.getStateShort(
          this.Customer.CustomerProfile.Address.StateId
        );
      }

      if (totalAmount == 0) {
        warningMsg = this.buildMoneyWarningMessage(
          warningMsg,
          "Lead Debt Amount"
        );
      }

      if (this.Customer.CustomerProfile.CustomerEmails.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Email Address");
      }

      if (this.Customer.CustomerProfile.FirstName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "First Name");
      }
      if (this.Customer.CustomerProfile.LastName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Last Name");
      }

      if (
        this.Customer.CustomerProfile.SsnEncrypted &&
        this.Customer.CustomerProfile.SsnEncrypted.length > 0
      ) {
        let ssn = await this.AES256_GCM_decrypt(
          this.Customer.CustomerProfile.SsnEncrypted
        );
        if (typeof ssn != "undefined" && ssn != null && ssn.length > 0) {
          this.dataToGlobalMeridians.Ssn = ssn;
        } else {
          warningMsg = this.buildMoneyWarningMessage(warningMsg, "SSN");
        }
      }

      if (
        !this.Customer.CustomerProfile.DateOfBirth ||
        (this.Customer.CustomerProfile.DateOfBirth &&
          this.Customer.CustomerProfile.DateOfBirth.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Date Of Birth");
      }

      if (this.Customer.CustomerProfile.CustomerPhones.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Phone Number");
      }

      if (
        !this.Customer.CustomerProfile.Address.Line1 ||
        (this.Customer.CustomerProfile.Address.Line1 &&
          this.Customer.CustomerProfile.Address.Line1.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Address");
      }
      if (
        !this.Customer.CustomerProfile.Address.City ||
        (this.Customer.CustomerProfile.Address.City &&
          this.Customer.CustomerProfile.Address.City.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "City");
      }
      if (shortState.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "State");
      }
      if (
        !this.Customer.CustomerProfile.Address.Zip ||
        (this.Customer.CustomerProfile.Address.Zip &&
          this.Customer.CustomerProfile.Address.Zip.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Zip Code");
      }

      let totalCoCustomerIncome = 0;
      if (this.Customer.CoCustomerProfiles.length) {
        let includedCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => !x.IsExcluded
        );

        for (let item of includedCoCustomers) {
          totalCoCustomerIncome += parseFloat(item.Income);
        }
      }

      annualIncome = totalCoCustomerIncome;

      if (
        this.Customer.CustomerProfile.Income &&
        parseFloat(this.Customer.CustomerProfile.Income) > 0
      ) {
        annualIncome += parseFloat(this.Customer.CustomerProfile.Income);
      }

      annualIncome = annualIncome * 12;

      if (annualIncome == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Income");
      }

      if (!this.Customer.CustomerGlobalInformation) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Draft Amount");
      } else {
        if (!(this.Customer.CustomerGlobalInformation.DraftAmount > 0)) {
          warningMsg = this.buildMoneyWarningMessage(
            warningMsg,
            "Draft Amount"
          );
        }

        if (!(this.totalDebtAmount > 0)) {
          warningMsg = this.buildMoneyWarningMessage(warningMsg, "Debt Amount");
        }

        if (!(this.Customer.CustomerGlobalInformation.EnrollmentMonths > 0)) {
          warningMsg = this.buildMoneyWarningMessage(
            warningMsg,
            "Enrollment Months"
          );
        }
        if (!(this.totalPayback > 0)) {
          warningMsg = this.buildMoneyWarningMessage(
            warningMsg,
            "Payback Amount"
          );
        }
      }

      if (warningMsg.length > 0) {
        warningMsg = warningMsg + " required for Global Meridian Loan";
        this.$swal("Warning!", warningMsg, "warning");
      } else {
        this.dataToGlobalMeridians.CustomerId = this.Customer.Id;

        if (typeof this.userInfo != "undefined" && this.userInfo) {
          this.dataToGlobalMeridians.FirstName =
            this.userInfo.firstName + " " + this.userInfo.lastName;
        }

        this.dataToGlobalMeridians.TotalAmount = totalAmount;
        this.dataToGlobalMeridians.FirstName =
          this.Customer.CustomerProfile.FirstName;

        this.dataToGlobalMeridians.MiddleName =
          this.Customer.CustomerProfile.MiddleInitial;

        this.dataToGlobalMeridians.LastName =
          this.Customer.CustomerProfile.LastName;

        let emails = orderBy(
          this.Customer.CustomerProfile.CustomerEmails,
          "Priority",
          "asc"
        );
        if (emails.length > 0)
          this.dataToGlobalMeridians.Email = emails[0].Email;

        let phones = orderBy(
          this.Customer.CustomerProfile.CustomerPhones,
          "Priority",
          "asc"
        );
        if (phones.length > 0)
          this.dataToGlobalMeridians.PhoneNumber = phones[0].PhoneNumber;

        this.dataToGlobalMeridians.DateOfBirth =
          this.Customer.CustomerProfile.DateOfBirth;

        this.dataToGlobalMeridians.AddressLine1 =
          this.Customer.CustomerProfile.Address.Line1;

        this.dataToGlobalMeridians.City =
          this.Customer.CustomerProfile.Address.City;

        this.dataToGlobalMeridians.ShortState = shortState;

        this.dataToGlobalMeridians.Zip =
          this.Customer.CustomerProfile.Address.Zip;

        this.dataToGlobalMeridians.AnnualIncome = annualIncome;

        if (this.Customer.CustomerGlobalInformation) {
          this.dataToGlobalMeridians.TotalDebt = this.totalDebtAmount;
          this.dataToGlobalMeridians.Cost = this.totalPayback;
          this.dataToGlobalMeridians.Savings = parseFloat(
            this.totalDebtAmount - this.totalPayback
          );
          this.dataToGlobalMeridians.Term =
            this.Customer.CustomerGlobalInformation.EnrollmentMonths;
          this.dataToGlobalMeridians.DraftAmount =
            this.Customer.CustomerGlobalInformation.DraftAmount;
        }
        let customerTags = this.Customer.CustomerTags;
        if (
          customerTags &&
          customerTags.length > 0 &&
          this.customerTagTypes.length > 0
        ) {
          let employerNameTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "employername"
          );
          if (typeof employerNameTag != "undefined" && employerNameTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == employerNameTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.EmployerName =
                customerTags[indx].TagValue;
            }
          }

          let educationLevelTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "educationlevel"
          );
          if (typeof educationLevelTag != "undefined" && educationLevelTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == educationLevelTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.EducationLevel =
                customerTags[indx].TagValue;
            }
          }

          let employmentStatusTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "employmentstatus"
          );
          if (
            typeof employmentStatusTag != "undefined" &&
            employmentStatusTag
          ) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == employmentStatusTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.EmploymentStatus =
                customerTags[indx].TagValue;
            }
          }

          let selfReportedCreditScoreTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "selfreportedcreditscore"
          );
          if (
            typeof selfReportedCreditScoreTag != "undefined" &&
            selfReportedCreditScoreTag
          ) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == selfReportedCreditScoreTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.SelfReportedCreditScore =
                customerTags[indx].TagValue;
            }
          }

          let employmentPayFrequencyTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "employmentpayfrequency"
          );
          if (
            typeof employmentPayFrequencyTag != "undefined" &&
            employmentPayFrequencyTag
          ) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == employmentPayFrequencyTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.EmploymentPayFrequency =
                customerTags[indx].TagValue;
            }
          }

          let monthsAtAddressTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "monthsataddress"
          );
          if (typeof monthsAtAddressTag != "undefined" && monthsAtAddressTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == monthsAtAddressTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.MonthsAtAddress =
                customerTags[indx].TagValue;
            }
          }

          let monthsAtEmployerTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "monthsatemployer"
          );
          if (
            typeof monthsAtEmployerTag != "undefined" &&
            monthsAtEmployerTag
          ) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == monthsAtEmployerTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.MonthsAtEmployer =
                customerTags[indx].TagValue;
            }
          }

          let graduateDegreeTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "graduatedegree"
          );
          if (typeof graduateDegreeTag != "undefined" && graduateDegreeTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == graduateDegreeTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.GraduateDegree =
                customerTags[indx].TagValue;
            }
          }

          let incomeSourceTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "incomesource"
          );
          if (typeof incomeSourceTag != "undefined" && incomeSourceTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == incomeSourceTag.Id
            );
            if (indx > -1) {
              this.dataToGlobalMeridians.IncomeSource =
                customerTags[indx].TagValue;
            }
          }
        }

        let customerExpenses = this.Customer.CustomerExpenses;

        if (
          customerExpenses &&
          customerExpenses.length > 0 &&
          this.expenseTypeList.length > 0
        ) {
          let mortgageRentExpense = this.expenseTypeList.find(
            (x) => this.cleanTagName(x.Name) == "mortgagerent"
          );

          if (
            typeof mortgageRentExpense != "undefined" &&
            mortgageRentExpense
          ) {
            let indx = customerExpenses.findIndex(
              (x) => x.TypeId == mortgageRentExpense.Id
            );

            if (indx > -1) {
              this.dataToGlobalMeridians.MonthlyRent =
                customerExpenses[indx].Amount;
            }
          }
        }

        if (
          this.Customer &&
          this.Customer.CustomerProfile &&
          this.Customer.CustomerProfile.ResidenceOwnershipId
        ) {
          this.dataToGlobalMeridians.ResidenceOwnershipId =
            this.Customer.CustomerProfile.ResidenceOwnershipId;
        }

        this.isGlobalMeridianOpen = true;
      }
    },

    closeGlobalMeridiansPopup() {
      this.isGlobalMeridianOpen = false;
    },

    cleanTagName(item) {
      let rtn = "";
      if (item) {
        rtn = item.toLowerCase();
        rtn = rtn.replaceAll(" ", "");
      }

      return rtn;
    },

    async openMonevoPopup() {
      let warningMsg = "";
      let totalAmount = 0;
      if (
        this.Customer.CustomerProfile.DebtAmount &&
        parseInt(this.Customer.CustomerProfile.DebtAmount) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CustomerProfile.DebtAmount);
      } else if (
        this.Customer.CreditorTotalBalance &&
        parseInt(this.Customer.CreditorTotalBalance) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CreditorTotalBalance);
      }

      let shortState = "";
      if (
        this.Customer.CustomerProfile.Address.StateId &&
        parseInt(this.Customer.CustomerProfile.Address.StateId) > 0
      ) {
        shortState = this.getStateShort(
          this.Customer.CustomerProfile.Address.StateId
        );
      }

      if (totalAmount == 0) {
        warningMsg = this.buildMoneyWarningMessage(
          warningMsg,
          "Lead Debt Amount"
        );
      }

      if (this.Customer.CustomerProfile.FirstName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "First Name");
      }
      if (this.Customer.CustomerProfile.LastName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Last Name");
      }
      if (this.Customer.CustomerProfile.CustomerEmails.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Email Address");
      }

      if (this.Customer.CustomerProfile.CustomerPhones.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Phone Number");
      }

      if (
        !this.Customer.CustomerProfile.DateOfBirth ||
        (this.Customer.CustomerProfile.DateOfBirth &&
          this.Customer.CustomerProfile.DateOfBirth.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Date Of Birth");
      }

      if (
        !this.Customer.CustomerProfile.Address.Line1 ||
        (this.Customer.CustomerProfile.Address.Line1 &&
          this.Customer.CustomerProfile.Address.Line1.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Address");
      }
      if (
        !this.Customer.CustomerProfile.Address.City ||
        (this.Customer.CustomerProfile.Address.City &&
          this.Customer.CustomerProfile.Address.City.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "City");
      }
      if (shortState.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "State");
      }
      if (
        !this.Customer.CustomerProfile.Address.Zip ||
        (this.Customer.CustomerProfile.Address.Zip &&
          this.Customer.CustomerProfile.Address.Zip.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Zip Code");
      }

      if (warningMsg.length > 0) {
        warningMsg = warningMsg + " required for Monevo";
        this.$swal("Warning!", warningMsg, "warning");
      } else {
        this.dataToPassMonevo.CustomerId = this.Customer.Id;

        if (typeof this.userInfo != "undefined" && this.userInfo) {
          this.dataToPassMonevo.AgentName =
            this.userInfo.firstName + " " + this.userInfo.lastName;
        }

        //this.dataToPassMonevo.LoanAmount = totalAmount;
        this.dataToPassMonevo.FirstName =
          this.Customer.CustomerProfile.FirstName;

        this.dataToPassMonevo.MiddleName =
          this.Customer.CustomerProfile.MiddleInitial;

        this.dataToPassMonevo.LastName = this.Customer.CustomerProfile.LastName;

        let emails = orderBy(
          this.Customer.CustomerProfile.CustomerEmails,
          "Priority",
          "asc"
        );
        if (emails.length > 0) this.dataToPassMonevo.Email = emails[0].Email;

        let phones = orderBy(
          this.Customer.CustomerProfile.CustomerPhones,
          "Priority",
          "asc"
        );
        if (phones.length > 0)
          this.dataToPassMonevo.Phone = phones[0].PhoneNumber;

        this.dataToPassMonevo.DateOfBirth =
          this.Customer.CustomerProfile.DateOfBirth;

        this.dataToPassMonevo.MonthlyIncome =
          this.Customer.CustomerProfile.Income;

        this.dataToPassMonevo.Line1 =
          this.Customer.CustomerProfile.Address.Line1;

        this.dataToPassMonevo.Line2 =
          this.Customer.CustomerProfile.Address.Line2;

        this.dataToPassMonevo.City = this.Customer.CustomerProfile.Address.City;

        this.dataToPassMonevo.StateShort = shortState;

        this.dataToPassMonevo.Zip = this.Customer.CustomerProfile.Address.Zip;

        let customerTags = this.Customer.CustomerTags;
        if (
          customerTags &&
          customerTags.length > 0 &&
          this.customerTagTypes.length > 0
        ) {
          let employerNameTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "employername"
          );
          if (typeof employerNameTag != "undefined" && employerNameTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == employerNameTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.EmployerName = customerTags[indx].TagValue;
            }
          }

          let payFrequencyTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "payfrequency"
          );
          if (typeof payFrequencyTag != "undefined" && payFrequencyTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == payFrequencyTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.PayFrequency = customerTags[indx].TagValue;
            }
          }

          let monthsAtAddressTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "monthsataddress"
          );
          if (typeof monthsAtAddressTag != "undefined" && monthsAtAddressTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == monthsAtAddressTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.MonthsAtAddress =
                customerTags[indx].TagValue;
            }
          }

          let monthsAtEmployerTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "monthsatemployer"
          );
          if (
            typeof monthsAtEmployerTag != "undefined" &&
            monthsAtEmployerTag
          ) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == monthsAtEmployerTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.MonthsAtEmployer =
                customerTags[indx].TagValue;
            }
          }

          let incomeSourceTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "incomesource"
          );
          if (typeof incomeSourceTag != "undefined" && incomeSourceTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == incomeSourceTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.IncomeSource = customerTags[indx].TagValue;
            }
          }
        }

        let customerExpenses = this.Customer.CustomerExpenses;

        if (
          customerExpenses &&
          customerExpenses.length > 0 &&
          this.expenseTypeList.length > 0
        ) {
          let mortgageRentExpense = this.expenseTypeList.find(
            (x) => this.cleanTagName(x.Name) == "mortgagerent"
          );

          if (
            typeof mortgageRentExpense != "undefined" &&
            mortgageRentExpense
          ) {
            let indx = customerExpenses.findIndex(
              (x) => x.TypeId == mortgageRentExpense.Id
            );

            if (indx > -1) {
              this.dataToPassMonevo.MonthlyRent = customerExpenses[indx].Amount;
            }
          }
        }

        if (
          this.Customer &&
          this.Customer.CustomerProfile &&
          this.Customer.CustomerProfile.ResidenceOwnershipId
        ) {
          this.dataToPassMonevo.ResidenceOwnership =
            this.Customer.CustomerProfile.ResidenceOwnershipId;
        }

        if (
          this.Customer.CustomerProfile.SsnEncrypted &&
          this.Customer.CustomerProfile.SsnEncrypted.length > 0
        ) {
          let ssn = await this.AES256_GCM_decrypt(
            this.Customer.CustomerProfile.SsnEncrypted
          );
          if (typeof ssn != "undefined" && ssn != null && ssn.length > 0) {
            this.dataToPassMonevo.Ssn = ssn;
          }
        }

        this.isMonevoPopupOpen = true;
      }
    },
    async openMonevoCoBrandedPopup() {
      let warningMsg = "";
      let totalAmount = 0;
      if (
        this.Customer.CustomerProfile.DebtAmount &&
        parseInt(this.Customer.CustomerProfile.DebtAmount) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CustomerProfile.DebtAmount);
      } else if (
        this.Customer.CreditorTotalBalance &&
        parseInt(this.Customer.CreditorTotalBalance) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CreditorTotalBalance);
      }

      let shortState = "";
      if (
        this.Customer.CustomerProfile.Address.StateId &&
        parseInt(this.Customer.CustomerProfile.Address.StateId) > 0
      ) {
        shortState = this.getStateShort(
          this.Customer.CustomerProfile.Address.StateId
        );
      }

      if (totalAmount == 0) {
        warningMsg = this.buildMoneyWarningMessage(
          warningMsg,
          "Lead Debt Amount"
        );
      }

      if (this.Customer.CustomerProfile.FirstName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "First Name");
      }
      if (this.Customer.CustomerProfile.LastName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Last Name");
      }
      if (this.Customer.CustomerProfile.CustomerEmails.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Email Address");
      }

      if (this.Customer.CustomerProfile.CustomerPhones.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Phone Number");
      }

      if (
        !this.Customer.CustomerProfile.DateOfBirth ||
        (this.Customer.CustomerProfile.DateOfBirth &&
          this.Customer.CustomerProfile.DateOfBirth.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Date Of Birth");
      }

      if (
        !this.Customer.CustomerProfile.Address.Line1 ||
        (this.Customer.CustomerProfile.Address.Line1 &&
          this.Customer.CustomerProfile.Address.Line1.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Address");
      }
      if (
        !this.Customer.CustomerProfile.Address.City ||
        (this.Customer.CustomerProfile.Address.City &&
          this.Customer.CustomerProfile.Address.City.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "City");
      }
      if (shortState.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "State");
      }
      if (
        !this.Customer.CustomerProfile.Address.Zip ||
        (this.Customer.CustomerProfile.Address.Zip &&
          this.Customer.CustomerProfile.Address.Zip.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Zip Code");
      }

      if (warningMsg.length > 0) {
        warningMsg = warningMsg + " required for Monevo";
        this.$swal("Warning!", warningMsg, "warning");
      } else {
        this.dataToPassMonevo.CustomerId = this.Customer.Id;

        if (typeof this.userInfo != "undefined" && this.userInfo) {
          this.dataToPassMonevo.AgentName =
            this.userInfo.firstName + " " + this.userInfo.lastName;
        }

        //this.dataToPassMonevo.LoanAmount = totalAmount;
        this.dataToPassMonevo.FirstName =
          this.Customer.CustomerProfile.FirstName;

        this.dataToPassMonevo.MiddleName =
          this.Customer.CustomerProfile.MiddleInitial;

        this.dataToPassMonevo.LastName = this.Customer.CustomerProfile.LastName;

        let emails = orderBy(
          this.Customer.CustomerProfile.CustomerEmails,
          "Priority",
          "asc"
        );
        if (emails.length > 0) this.dataToPassMonevo.Email = emails[0].Email;

        let phones = orderBy(
          this.Customer.CustomerProfile.CustomerPhones,
          "Priority",
          "asc"
        );
        if (phones.length > 0)
          this.dataToPassMonevo.Phone = phones[0].PhoneNumber;

        this.dataToPassMonevo.DateOfBirth =
          this.Customer.CustomerProfile.DateOfBirth;

        this.dataToPassMonevo.MonthlyIncome =
          this.Customer.CustomerProfile.Income;

        this.dataToPassMonevo.Line1 =
          this.Customer.CustomerProfile.Address.Line1;

        this.dataToPassMonevo.Line2 =
          this.Customer.CustomerProfile.Address.Line2;

        this.dataToPassMonevo.City = this.Customer.CustomerProfile.Address.City;

        this.dataToPassMonevo.StateShort = shortState;

        this.dataToPassMonevo.Zip = this.Customer.CustomerProfile.Address.Zip;

        let customerTags = this.Customer.CustomerTags;
        if (
          customerTags &&
          customerTags.length > 0 &&
          this.customerTagTypes.length > 0
        ) {
          let employerNameTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "employername"
          );
          if (typeof employerNameTag != "undefined" && employerNameTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == employerNameTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.EmployerName = customerTags[indx].TagValue;
            }
          }

          let payFrequencyTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "payfrequency"
          );
          if (typeof payFrequencyTag != "undefined" && payFrequencyTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == payFrequencyTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.PayFrequency = customerTags[indx].TagValue;
            }
          }

          let monthsAtAddressTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "monthsataddress"
          );
          if (typeof monthsAtAddressTag != "undefined" && monthsAtAddressTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == monthsAtAddressTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.MonthsAtAddress =
                customerTags[indx].TagValue;
            }
          }

          let monthsAtEmployerTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "monthsatemployer"
          );
          if (
            typeof monthsAtEmployerTag != "undefined" &&
            monthsAtEmployerTag
          ) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == monthsAtEmployerTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.MonthsAtEmployer =
                customerTags[indx].TagValue;
            }
          }

          let incomeSourceTag = this.customerTagTypes.find(
            (x) => this.cleanTagName(x.TagName) == "incomesource"
          );
          if (typeof incomeSourceTag != "undefined" && incomeSourceTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == incomeSourceTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.IncomeSource = customerTags[indx].TagValue;
            }
          }

          let ownVehicleTag = this.customerTagTypes.find((x) =>
            this.cleanTagName(x.TagName).includes("paidoffvehicle")
          );

          if (typeof ownVehicleTag != "undefined" && ownVehicleTag) {
            let indx = customerTags.findIndex(
              (x) => x.TagTypeId == ownVehicleTag.Id
            );
            if (indx > -1) {
              this.dataToPassMonevo.OwnCar = customerTags[indx].TagValue;
            }
          }
        }

        let customerExpenses = this.Customer.CustomerExpenses;

        if (
          customerExpenses &&
          customerExpenses.length > 0 &&
          this.expenseTypeList.length > 0
        ) {
          let mortgageRentExpense = this.expenseTypeList.find(
            (x) => this.cleanTagName(x.Name) == "mortgagerent"
          );

          if (
            typeof mortgageRentExpense != "undefined" &&
            mortgageRentExpense
          ) {
            let indx = customerExpenses.findIndex(
              (x) => x.TypeId == mortgageRentExpense.Id
            );

            if (indx > -1) {
              this.dataToPassMonevo.MonthlyRent = customerExpenses[indx].Amount;
            }
          }
        }

        if (
          this.Customer &&
          this.Customer.CustomerProfile &&
          this.Customer.CustomerProfile.ResidenceOwnershipId
        ) {
          this.dataToPassMonevo.ResidenceOwnership =
            this.Customer.CustomerProfile.ResidenceOwnershipId;
        }

        if (
          this.Customer.CustomerProfile.SsnEncrypted &&
          this.Customer.CustomerProfile.SsnEncrypted.length > 0
        ) {
          let ssn = await this.AES256_GCM_decrypt(
            this.Customer.CustomerProfile.SsnEncrypted
          );
          if (typeof ssn != "undefined" && ssn != null && ssn.length > 0) {
            this.dataToPassMonevo.Ssn = ssn;
          }
        }

        this.dataToPassMonevo.CoCustomers = [];
        if (
          this.Customer.CoCustomerProfiles &&
          this.Customer.CoCustomerProfiles.length > 0
        ) {
          let notExcluded = this.Customer.CoCustomerProfiles.filter(
            (x) => x.IsExcluded == false
          );
          if (notExcluded.length > 0) {
            this.dataToPassMonevo.CoCustomers = notExcluded;
          }
        }
      }

      this.isMonevoCoBrandedPopupOpen = true;
    },
    async openSuperMoneyPopup() {
      let warningMsg = "";
      let totalAmount = 0;
      let annualIncome = 0;
      if (
        this.Customer.CustomerProfile.DebtAmount &&
        parseInt(this.Customer.CustomerProfile.DebtAmount) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CustomerProfile.DebtAmount);
      } else if (
        this.Customer.CreditorTotalBalance &&
        parseInt(this.Customer.CreditorTotalBalance) > 0
      ) {
        totalAmount = parseFloat(this.Customer.CreditorTotalBalance);
      }
      let isAcctTypeChecking = "NO";
      if (this.Customer.Bank && this.Customer.Bank.AccountTypeId) {
        isAcctTypeChecking =
          this.Customer.Bank.AccountTypeId == 1 ? "YES" : "NO";
      }

      let shortState = "";
      if (
        this.Customer.CustomerProfile.Address.StateId &&
        parseInt(this.Customer.CustomerProfile.Address.StateId) > 0
      ) {
        shortState = this.getStateShort(
          this.Customer.CustomerProfile.Address.StateId
        );
      }

      if (this.Customer.CustomerProfile.FirstName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "First Name");
      }
      if (this.Customer.CustomerProfile.LastName.length < 2) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Last Name");
      }
      if (this.Customer.CustomerProfile.CustomerEmails.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Email Address");
      }

      if (this.Customer.CustomerProfile.CustomerPhones.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Phone Number");
      }

      if (
        !this.Customer.CustomerProfile.DateOfBirth ||
        (this.Customer.CustomerProfile.DateOfBirth &&
          this.Customer.CustomerProfile.DateOfBirth.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Date Of Birth");
      }
      if (totalAmount < 500 || totalAmount > 100000) {
        warningMsg = this.buildMoneyWarningMessage(
          warningMsg,
          "Debt Amount (Needs to be btw $500, $100,000)"
        );
      }

      let totalCoCustomerIncome = 0;
      if (this.Customer.CoCustomerProfiles.length) {
        let includedCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => !x.IsExcluded
        );

        for (let item of includedCoCustomers) {
          totalCoCustomerIncome += parseFloat(item.Income);
        }
      }

      annualIncome = totalCoCustomerIncome;

      if (
        this.Customer.CustomerProfile.Income &&
        parseFloat(this.Customer.CustomerProfile.Income) > 0
      ) {
        annualIncome += parseFloat(this.Customer.CustomerProfile.Income);
      }

      annualIncome = annualIncome * 12;

      if (annualIncome == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Annual Income");
      }

      if (
        !this.Customer.CustomerProfile.Address.Line1 ||
        (this.Customer.CustomerProfile.Address.Line1 &&
          this.Customer.CustomerProfile.Address.Line1.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Address");
      }
      if (
        !this.Customer.CustomerProfile.Address.City ||
        (this.Customer.CustomerProfile.Address.City &&
          this.Customer.CustomerProfile.Address.City.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "City");
      }
      if (shortState.length == 0) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "State");
      }
      if (
        !this.Customer.CustomerProfile.Address.Zip ||
        (this.Customer.CustomerProfile.Address.Zip &&
          this.Customer.CustomerProfile.Address.Zip.length == 0)
      ) {
        warningMsg = this.buildMoneyWarningMessage(warningMsg, "Zip Code");
      }

      if (warningMsg.length > 0) {
        warningMsg = warningMsg + " required for SuperMoney";
        this.$swal("Warning!", warningMsg, "warning");
      } else {
        this.dataToPassSuperMoney.CustomerId = this.Customer.Id;

        this.dataToPassSuperMoney.FirstName =
          this.Customer.CustomerProfile.FirstName;

        this.dataToPassSuperMoney.LastName =
          this.Customer.CustomerProfile.LastName;

        let emails = orderBy(
          this.Customer.CustomerProfile.CustomerEmails,
          "Priority",
          "asc"
        );
        if (emails.length > 0)
          this.dataToPassSuperMoney.CustomerEmail = emails[0].Email;

        let phones = orderBy(
          this.Customer.CustomerProfile.CustomerPhones,
          "Priority",
          "asc"
        );
        if (phones.length > 0)
          this.dataToPassSuperMoney.PhoneNumber = phones[0].PhoneNumber;

        this.dataToPassSuperMoney.DateOfBirth =
          this.Customer.CustomerProfile.DateOfBirth;

        this.dataToPassSuperMoney.TotalAmount = totalAmount;

        this.dataToPassSuperMoney.Income = annualIncome;

        this.dataToPassSuperMoney.CheckingAccount = isAcctTypeChecking;

        this.dataToPassSuperMoney.AddressLine1 =
          this.Customer.CustomerProfile.Address.Line1;

        this.dataToPassSuperMoney.City =
          this.Customer.CustomerProfile.Address.City;

        this.dataToPassSuperMoney.ShortState = shortState;

        this.dataToPassSuperMoney.Zip =
          this.Customer.CustomerProfile.Address.Zip;

        this.isSuperMoneyPopupOpen = true;
      }
    },
    async openLoanApplicationPopup(provider) {
      let [err, result] = await this.$store.dispatch(
        types.GET_CLIENT,
        this.Customer.Id
      );

      if (result?.Data) {
        const customer = result.Data;
        let warningMsg = await getLoanApplicationPopupWarning({
          customer,
          provider,
        });

        if (warningMsg.length > 0) {
          warningMsg = `${warningMsg} required for ${provider}`;
          this.$swal("Warning!", warningMsg, "warning");
        } else {
          this.loanApplicationData = await setLoanApplicationPopupValues({
            customer,
            provider,
          });
          if (provider === this.LOAN_APPLICATIONS?.Evvo) {
            this.isEvvoPopupOpen = true;
          } else {
            this.isPremloPopupOpen = true;
          }
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
    },
    buildMoneyWarningMessage(oldMsg, newMsg) {
      let rtn = oldMsg;
      if (rtn.length > 0) {
        rtn = rtn + ", ";
      }
      rtn = rtn + newMsg;
      return rtn;
    },
    closeSuperMoneyPopup() {
      this.isSuperMoneyPopupOpen = false;
    },
    closeMonevoPopup() {
      this.isMonevoPopupOpen = false;
    },
    closeMonevoCoBrandedPopup() {
      this.isMonevoCoBrandedPopupOpen = false;
    },
    closeEvvoPopup() {
      this.isEvvoPopupOpen = false;
    },
    closePremloPopup() {
      this.isPremloPopupOpen = false;
    },

    setValueFromPath(object, path, value) {
      path
        .split(".")
        .reduce(
          (o, p, i) =>
            (o[p] = path.split(".").length === ++i ? value : o[p] || {}),
          object
        );
    },
    getObjectValueFromPath(object, path) {
      path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      path = path.replace(/^\./, ""); // strip a leading dot
      let a = path.split(".");
      for (let i = 0; i < a.length; i++) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
    findPath(ob, key) {
      let path = [];
      let keyExists = function (obj) {
        if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
          return false;
        } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
          return true;
        } else if (Array.isArray(obj)) {
          let parentKey = path.length ? path.pop() : "";

          for (let i = 0; i < obj.length; i++) {
            path.push(`${parentKey}[${i}]`);
            const result = keyExists(obj[i], key);
            if (result) {
              return result;
            }
            path.pop();
          }
        } else {
          for (let k in obj) {
            path.push(k);
            let result = keyExists(obj[k], key);
            if (result) {
              return result;
            }
            path.pop();
          }
        }
        return false;
      };

      keyExists(ob);

      return path.join(".") + "." + key;
    },

    recursiveChangeComparison(obj1, obj2, changeArray) {
      let r = changeArray;
      Object.keys(obj1).forEach((key) => {
        let value1 = obj1[key];
        let value2 = obj2[key];

        if (
          typeof value1 !== "object" &&
          typeof value2 !== "object" &&
          value1 != value2
        ) {
          r.push(key);
        } else if (
          Array.isArray(value1) &&
          Array.isArray(value2) &&
          JSON.stringify(value1) != JSON.stringify(value2)
        ) {
          r.push(key);
        } else if (
          typeof value1 === "object" &&
          value1 != null &&
          typeof value2 === "object" &&
          value2 != null
        ) {
          this.recursiveChangeComparison(value1, value2, changeArray);
        }
      });
      return r;
    },
    dataSyncProcess(customerId, userId, userName, objectType) {
      if (objectType == "Customer") {
        this.handleSyncCustomer(customerId, userName);
      } else if (objectType == "Creditor") {
        if (this.tabs[2].active) {
          this.handleSyncCreditor(customerId, userName, true);
        } else {
          this.handleSyncCreditor(customerId, userName, false);
        }
      }
    },
    getTabIsVisible(tab) {
      if (tab.id == 1 || tab.id == 2) {
        return this.hasAuth(tab.AuthorizationId);
      } else if (tab.id == 7) {
        return (
          this.Customer.CustomerGlobalInformation &&
          this.Customer.CustomerGlobalInformation.AccountNumber &&
          this.hasAuth(tab.AuthorizationId)
        );
      } else if (tab.id == 8) {
        return (
          this.Customer.CustomerGlobalInformation &&
          this.Customer.CustomerGlobalInformation.AccountNumber &&
          this.hasAuth(tab.AuthorizationId)
        );
      } else {
        return this.hasAuth(tab.AuthorizationId) && this.customerId > 0;
      }
    },
    hasAuth(mfid) {
      return this.arrPermission.includes(mfid);
    },
    isSoldStatus() {
      let rtn = false;
      let indx = this.customerStatuses.findIndex(
        (x) => x.Id == this.Customer.StatusId
      );
      if (this.customerStatuses[indx]) {
        rtn = this.customerStatuses[indx].IsSold;
      }

      return rtn;
    },
    async bindPolicyGroup() {
      if (this.Customer.OfficeId == null) {
        return;
      }
      if (this.Customer.CustomerProfile.Address == null) {
        return;
      }
      if (this.Customer.CustomerProfile.Address.StateId == null) {
        return;
      }

      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_POLICY_GROUPS_BY_CUSTOMER,
        {
          CustomerId: this.Customer.Id,
          OfficeId: this.Customer.OfficeId,
        }
      );

      if (result) {
        this.policyGroupList = result.Data;

        let selectedPolicyGroupIds = orderBy(
          this.policyGroupList.filter((x) => x.Selected),
          "Id",
          "asc"
        );
        if (
          selectedPolicyGroupIds == null ||
          selectedPolicyGroupIds.length == 0
        ) {
          selectedPolicyGroupIds = orderBy(
            this.policyGroupList.filter((x) => x.IsActive),
            "Id",
            "asc"
          );
        }

        if (
          this.Customer.CustomerGlobalInformation &&
          selectedPolicyGroupIds.length > 0
        ) {
          this.Customer.CustomerGlobalInformation.PolicyGroupId =
            selectedPolicyGroupIds[0].PolicyGroupId;

          if (this.checkAuth(778851)) {
            this.policyGroupId = selectedPolicyGroupIds[0].PolicyGroupId;
          }
        }
      }
    },
    async setInfoChanges() {
      let changeArr = [];
      if (
        this.Customer.CustomerGlobalInformation &&
        this.Customer.CustomerGlobalInformation.AccountNumber &&
        this.Customer.CustomerGlobalInformation.AccountNumber.length > 2 &&
        this.isSoldStatus()
      ) {
        if (
          this.InitialInfo.FirstName != this.Customer.CustomerProfile.FirstName
        ) {
          this.changeDetailType.ChangeTypeId = 1;
          this.changeDetailType.OldValue = this.InitialInfo.FirstName;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.FirstName;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.MI != this.Customer.CustomerProfile.MiddleInitial
        ) {
          this.changeDetailType.ChangeTypeId = 2;
          this.changeDetailType.OldValue = this.InitialInfo.MI;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.MiddleInitial;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.LastName != this.Customer.CustomerProfile.LastName
        ) {
          this.changeDetailType.ChangeTypeId = 3;
          this.changeDetailType.OldValue = this.InitialInfo.LastName;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.LastName;

          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.BankAccount !=
          this.Customer.Bank.AccountNumberEncrypted
        ) {
          this.changeDetailType.ChangeTypeId = 4;
          this.changeDetailType.OldValue = await this.AES256_GCM_decrypt(
            this.InitialInfo.BankAccount
          );
          this.changeDetailType.NewValue = await this.AES256_GCM_decrypt(
            this.Customer.Bank.AccountNumberEncrypted
          );
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (this.InitialInfo.Routing != this.Customer.Bank.RoutingNumber) {
          this.changeDetailType.ChangeTypeId = 5;
          this.changeDetailType.OldValue = this.InitialInfo.Routing;
          this.changeDetailType.NewValue = this.Customer.Bank.RoutingNumber;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.BankAccountTypeId != this.Customer.Bank.AccountTypeId
        ) {
          this.changeDetailType.ChangeTypeId = 6;
          this.changeDetailType.OldValue = this.InitialInfo.BankAccountTypeId;
          this.changeDetailType.NewValue = this.Customer.Bank.AccountTypeId;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.Address !=
          this.Customer.CustomerProfile.Address.Line1
        ) {
          this.changeDetailType.ChangeTypeId = 7;
          this.changeDetailType.OldValue = this.InitialInfo.Address;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.Address.Line1;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.AddressLine2 !=
          this.Customer.CustomerProfile.Address.Line2
        ) {
          this.changeDetailType.ChangeTypeId = 8;
          this.changeDetailType.OldValue = this.InitialInfo.AddressLine2;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.Address.Line2;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.City != this.Customer.CustomerProfile.Address.City
        ) {
          this.changeDetailType.ChangeTypeId = 9;
          this.changeDetailType.OldValue = this.InitialInfo.City;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.Address.City;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.StateId !=
          this.Customer.CustomerProfile.Address.StateId
        ) {
          this.changeDetailType.ChangeTypeId = 10;
          this.changeDetailType.OldValue = this.InitialInfo.StateId;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.Address.StateId;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (this.InitialInfo.Zip != this.Customer.CustomerProfile.Address.Zip) {
          this.changeDetailType.ChangeTypeId = 11;
          this.changeDetailType.OldValue = this.InitialInfo.Zip;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.Address.Zip;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.Customer.CustomerGlobalInformation &&
          this.InitialInfo.PolicyGroupId !=
            this.Customer.CustomerGlobalInformation.PolicyGroupId &&
          !(
            this.Customer.CustomerGlobalInformation &&
            this.Customer.CustomerGlobalInformation.IsDpg == true
          )
        ) {
          this.changeDetailType.ChangeTypeId = 12;
          this.changeDetailType.OldValue = this.InitialInfo.PolicyGroupId;
          this.changeDetailType.NewValue =
            this.Customer.CustomerGlobalInformation.PolicyGroupId;
          changeArr.push(deepCopy(this.changeDetailType));
        }

        if (this.InitialInfo.DoB != this.Customer.CustomerProfile.DateOfBirth) {
          this.changeDetailType.ChangeTypeId = 13;
          this.changeDetailType.OldValue = this.InitialInfo.DoB;
          this.changeDetailType.NewValue =
            this.Customer.CustomerProfile.DateOfBirth;
          changeArr.push(deepCopy(this.changeDetailType));
        }
        if (
          this.InitialInfo.Ssn != this.Customer.CustomerProfile.SsnEncrypted
        ) {
          this.changeDetailType.ChangeTypeId = 14;
          this.changeDetailType.OldValue = await this.AES256_GCM_decrypt(
            this.InitialInfo.Ssn
          );
          this.changeDetailType.NewValue = await this.AES256_GCM_decrypt(
            this.Customer.CustomerProfile.SsnEncrypted
          );
          changeArr.push(deepCopy(this.changeDetailType));
        }

        if (changeArr.length > 0) {
          let err, result;
          [err, result] = await this.$store.dispatch(
            types.CREATE_CLIENT_INFO_CHANGES,
            {
              CustomerId: this.Customer.Id,
              ChangeDetails: changeArr,
            }
          );
          if (result) {
            this.setInitialInfo();
          } else {
            this.setInitialInfo();
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      }
    },
    setInitialInfo() {
      this.InitialInfo.FirstName = this.Customer.CustomerProfile.FirstName;
      this.InitialInfo.MI = this.Customer.CustomerProfile.MiddleInitial;
      this.InitialInfo.LastName = this.Customer.CustomerProfile.LastName;
      this.InitialInfo.BankAccount = this.Customer.Bank.AccountNumberEncrypted;
      this.InitialInfo.Routing = this.Customer.Bank.RoutingNumber;
      this.InitialInfo.BankAccountTypeId = this.Customer.Bank.AccountTypeId;
      this.InitialInfo.Address = this.Customer.CustomerProfile.Address.Line1;
      this.InitialInfo.AddressLine2 =
        this.Customer.CustomerProfile.Address.Line2;
      this.InitialInfo.City = this.Customer.CustomerProfile.Address.City;
      this.InitialInfo.StateId = this.Customer.CustomerProfile.Address.StateId;
      this.InitialInfo.Zip = this.Customer.CustomerProfile.Address.Zip;
      this.InitialInfo.PolicyGroupId = this.Customer.CustomerGlobalInformation
        ? this.Customer.CustomerGlobalInformation.PolicyGroupId
        : null;
      this.InitialInfo.DoB = this.Customer.CustomerProfile.DateOfBirth;
      this.InitialInfo.Ssn = this.Customer.CustomerProfile.SsnEncrypted;
    },

    closeStatusChangePopup(data) {
      this.isStatusChangePopupOpen = false;
      if (data > 0) {
        this.Customer.StatusId = data;
      }
    },

    statusChangePopupSubmit() {
      this.skipStatusControl = true;
      this.handleSave(true);
    },

    getMaxDay(date) {
      let rtn = val;
      let month = new Date(date).getMonths();
      let day = parseInt(val);
      if (!isNaN(day)) {
        rtn = new Date(2022, month + 1, 0).getDate();
      }

      return rtn;
    },
    updateSplitPaymentNew(value) {
      this.Customer.CustomerGlobalInformation.DayOfMonth = value;
    },

    openEnvelopeHistoryPopUp() {
      this.isEnvelopeHistoryPopupOpen = true;
      this.isSendEnvelopePopupOpen = false;
    },
    closeEnvelopeHistoryPopUp() {
      this.isEnvelopeHistoryPopupOpen = false;
    },
    async getCustomerCreditors() {
      let err, result;
      [err, result] = await this.$store.dispatch(creditorTypes.GET_CREDITORS, {
        CustomerId: this.Customer.Id,
      });

      if (result && result.Data) {
        this.creditors = deepCopy(result.Data);
      }
    },
    async validateFieldsForDocusign(
      fieldName,
      message,
      objectType,
      minCharLimit,
      matchKey = "",
      matchValue = ""
    ) {
      let arr = fieldName.split(".");

      let obj = this.Customer;

      if (objectType == "Customer") {
        for (let item of arr) {
          obj = obj[item];
        }

        if (message == "Income") {
          let budget =
            parseFloat(this.totalIncome) -
            this.getExpensesAmount() -
            parseFloat(this.Customer.CustomerGlobalInformation.DraftAmount);
          if (budget.toFixed(2) < minCharLimit) {
            this.customerDocusignContractValidationError +=
              "Available income is less than " +
              this.formatMoney(minCharLimit) +
              ",";
          }
        } else if (message == "SSN") {
          let ssn = await this.getDecryptedValue(obj);
          if (ssn == null || ssn == "") {
            this.customerDocusignContractValidationError += message + ", ";
          }
        } else if (message == "Bank Account Number") {
          let acctNumber = await this.getDecryptedValue(obj);
          if (acctNumber == null || acctNumber == "") {
            this.customerDocusignContractValidationError += message + ", ";
          }
        } else if (message == "Draft Amount") {
          let minDraftAmountCheckValue = parseFloat(minCharLimit);

          if (
            this.Customer &&
            this.Customer.CustomerGlobalInformation &&
            this.Customer.CustomerGlobalInformation.MinDraftAmount > 0
          ) {
            minDraftAmountCheckValue = parseFloat(
              this.Customer.CustomerGlobalInformation.MinDraftAmount
            );
          }

          if (minDraftAmountCheckValue > obj) {
            this.customerDocusignContractValidationError +=
              message +
              " must be greater than " +
              this.formatMoney(minDraftAmountCheckValue);
          }
        } else if (obj == null || obj == "") {
          this.customerDocusignContractValidationError += message + ", ";
        } else if (
          minCharLimit > 0 &&
          obj != null &&
          obj.length < minCharLimit
        ) {
          this.customerDocusignContractValidationError +=
            message + ": minimum " + minCharLimit + " characters required, ";
        }
      }
      if (objectType == "Creditor") {
        if (this.Customer.CreditorCount == 0) {
          this.customerDocusignContractValidationError += message + ", ";
        } else {
          await this.getCustomerCreditors();
          let disabledCreditorStatusList = this.getDisabledCreditorStatus();
          let activeCreditors = this.creditors.filter(
            (x) => !disabledCreditorStatusList.includes(x.CreditorStatusId)
          );
          if (matchKey != "" && matchValue != "") {
            let matches = activeCreditors.filter(
              (x) =>
                this.cleanSpecialChars(x[matchKey]) ==
                this.cleanSpecialChars(matchValue)
            );
            let valid = true;
            for (let item of matches) {
              valid *= item[fieldName].length == minCharLimit;
            }

            if (!valid) {
              this.customerDocusignContractValidationError += message + ", ";
            }
          } else {
            if (fieldName == "OriginalBalance") {
              let valid = true;
              for (let item of activeCreditors) {
                valid *=
                  parseFloat(item[fieldName]) >= parseFloat(minCharLimit);
              }

              if (!valid) {
                this.customerDocusignContractValidationError +=
                  "<br/>Minimum debt amount is " +
                  this.formatMoney(minCharLimit) +
                  " for all creditors.<br/>";
              }
            }
          }
        }
      } else if (
        objectType == "CoCustomer" &&
        this.Customer.CoCustomerProfiles.length > 0
      ) {
        let validForAllCoCustomers = true;
        for (let item of this.Customer.CoCustomerProfiles.filter(
          (x) => !x.IsExcluded
        )) {
          obj = item[fieldName];
          if (message == "SSN") {
            let ssn = await this.getDecryptedValue(obj);
            if (ssn == null || ssn == "") {
              this.coCustomerDocusignContractValidationError += message + ", ";
            }
          } else if (obj == null || obj == "") {
            validForAllCoCustomers *= false;
          }
        }
        if (validForAllCoCustomers == false) {
          this.coCustomerDocusignContractValidationError += message + ", ";
        }
      }
      // } else if (objectType == "CreditorOriginalBalance") {
      //   if (message == "Minimum Debt Amount") {
      //     await this.getCustomerCreditors();
      //     let failedCreditors = this.creditors.filter(
      //         (x) => x.OriginalBalance < parseFloat(minCharLimit)
      //       );
      //       if (failedCreditors.length > 0) {
      //         this.customerDocusignContractValidationError +=
      //           "<br/>Minimum debt amount is " +
      //           this.formatMoney(minCharLimit) +
      //           " for all creditors.";
      //       }

      //   }
      // }
    },

    async openEnvelopeSendPopUp() {
      this.isDraftAmountDifferentInDB = await this.IsDraftDifferentInDB();

      if (this.isDataChanged() || this.isDraftAmountDifferentInDB) {
        this.$swal(
          "You have unsaved changes!",
          "Please save your changes before sending a document!",
          "warning"
        );
        return;
      }

      let enrolled =
        this.Customer.CustomerGlobalInformation &&
        this.Customer.CustomerGlobalInformation.AccountNumber &&
        this.Customer.CustomerGlobalInformation.AccountNumber.length > 0;

      if (!enrolled) {
        if (this.paymentCalculatorIssues.length > 0) {
          this.$swal("Warning!", this.paymentCalculatorIssues, "warning");
          return;
        }
      }

      this.customerDocusignContractValidationError = "";
      this.coCustomerDocusignContractValidationError = "";
      if (this.docusignValidationSettings.length > 0) {
        for (let item of this.docusignValidationSettings) {
          await this.validateFieldsForDocusign(
            item.Id,
            item.DisplayName,
            item.ObjectType,
            item.MinCharLimit,
            item.MatchKey,
            item.MatchValue
          );
        }

        let validationError = "";

        if (this.customerDocusignContractValidationError != "") {
          validationError +=
            "<b>Customer Required Fields:</b> <br>" +
            this.customerDocusignContractValidationError.trimEnd().slice(0, -1);
        }

        if (this.coCustomerDocusignContractValidationError != "") {
          validationError +=
            "<br/><b>Co Customers Required Fields:</b> <br/> " +
            this.coCustomerDocusignContractValidationError
              .trimEnd()
              .slice(0, -1);
        }

        if (validationError != "" && !enrolled) {
          if (this.checkAuth(1775)) {
            this.$swal(
              "Validation Error!",
              validationError,
              " required to send envelope!",
              "warning"
            );
          } else {
            this.$swal(
              "Validation Error!",
              validationError,
              " required to send envelope!",
              "warning"
            );
            return;
          }
        }
      }

      if (this.docusignScripts && this.docusignScripts.length > 0) {
        let message = "";

        let orderedList = orderBy(this.docusignScripts, "DisplayOrder", "asc");
        for (let item of orderedList) {
          message = message + "<b>" + item.Title + "</b><br>";
          message = message + item.Script + "<br><hr>";
        }
        message += "Did you read the scripts?";
        this.$swal
          .fire({
            title: "PLEASE READ:",
            html: message,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowOutsideClick: false,
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isSendEnvelopePopupOpen = true;
              this.isEnvelopeHistoryPopupOpen = false;
            }
          });
      } else {
        this.isSendEnvelopePopupOpen = true;
        this.isEnvelopeHistoryPopupOpen = false;
      }
    },
    closeEnvelopeSendPopUp() {
      this.isSendEnvelopePopupOpen = false;
    },
    async docusignOpened() {
      if (this.Customer.Bank.AccountTypeId <= 0) {
        this.$swal(
          "Warning",
          "Bank Account Type is mandatory for DocuSign options.",
          "warning"
        );
        return;
      }

      await this.$store.dispatch(types.GET_DOCUSIGN_CONTRACT_VALIDATION, {
        CompanyId: this.Customer.CompanyId,
      });

      let result;
      let TemplateData = { CustomerId: this.Customer.Id };

      result = await this.$store.dispatch(
        types.GET_AVAILABLE_TEMPLATE_BY_CUSTOMER,
        TemplateData
      );

      if (result) {
        this.customerAvailableTemplates = result.Data;
      }
      result = await this.$store.dispatch(
        types.GET_DOCUSIGN_HISTORY,
        TemplateData
      );

      if (result) {
        this.docusignHistory = result.Data;
      }
    },
    setNullObjectsInModel() {
      if (this.Customer.CustomerGlobalInformation == null) {
        this.isBlueBoxReadOnly = false;
        this.Customer.CustomerGlobalInformation = {
          Id: 0,
          AccountNumber: null,
          DraftAmount: null,
          EnrolledDate: null,
          EnrollmentMonths: 12,
          GlobalBalanceOffline: null,
          StartDate: null,
          FirstPaymentDate: null,
          PolicyGroupId: null,
          PaymentOptionTypeId: null,
          DayOfMonth: null,
          IsDpg: null,
        };
      } else {
        if (this.Customer.CustomerGlobalInformation.AccountNumber)
          this.isBlueBoxReadOnly = true;
      }

      if (this.Customer.Bank == null) {
        this.Customer.Bank = {
          Name: null,
          RoutingNumber: null,
          AccountTypeId: null,
          AccountNumberEncrypted: null,
        };
      }

      if (this.Customer.CustomerProfile.Address == null) {
        this.Customer.CustomerProfile.Address = {
          Line1: null,
          Line2: null,
          City: null,
          StateId: null,
          TimeZoneId: null,
          Zip: null,
        };
      }

      if (this.Customer.CustomerCampaignDetail == null) {
        this.Customer.CustomerCampaignDetail = {
          CampaignId: null,
          VendorLeadId: null,
          Medium: null,
          Source: null,
          Term: null,
        };
      }

      if (
        this.Customer.CustomerProfile.CustomerEmails &&
        this.Customer.CustomerProfile.CustomerEmails &&
        this.Customer.CustomerProfile.CustomerEmails.length > 0
      ) {
        let emails = orderBy(
          this.Customer.CustomerProfile.CustomerEmails,
          "Priority",
          "asc"
        );
        if (emails.length > 0) this.customerPrimaryEmail = emails[0].Email;
      }
    },

    async checkBlueBox() {
      if (this.isReadyToCalcDraft()) {
        await this.updateBlueBoxValues();
      }

      this.getAttorneyInfo();
    },
    async updateBlueBoxValues() {
      if (this.isReadyToCalcDraft()) {
        let switchReadOnly = false;
        if (!this.isBlueBoxReadOnly) {
          this.isBlueBoxReadOnly = true;
          switchReadOnly = true;
        }

        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_PAYMENT_CALC_VALUE,
          {
            OfficeId: this.Customer.OfficeId,
            StateId: this.Customer.CustomerProfile.Address.StateId,
            DebtAmount: this.totalDebtAmount,
            CreditorCount: this.Customer.CreditorCount,
            Months: this.Customer.CustomerGlobalInformation.EnrollmentMonths,
            CreatedDate: this.Customer.CreatedDate,
            CustomerId: this.Customer.Id,
            ExtraTermMonths: this.Customer.CustomerProfile.ExtraTermMonths,
            PolicyGroupId:
              this.Customer.CustomerGlobalInformation.PolicyGroupId,
            RemovedFeeGroup:
              this.Customer.CustomerGlobalInformation.RemovedFeeGroup,
          }
        );

        if (switchReadOnly) this.isBlueBoxReadOnly = false;

        if (result) {
          if (result.Data) {
            this.maxExtraTermCount = result.Data.AdjustmentMonths;
            this.blueBoxMonthsMax = result.Data.Limits.MaxMonths;
            this.Customer.CustomerGlobalInformation.DraftAmount =
              result.Data.MonthlyDraft;

            if (result.Data.TotalProgramCost) {
              this.totalPayback = result.Data.TotalProgramCost;
            }

            if (result.Data.ServiceFeePercent) {
              this.paymentCalculatorServiceFeePercent =
                result.Data.ServiceFeePercent;
              this.paymentCalculatorMaxServiceFeePercent =
                result.Data.MaxServiceFeePercent;
            }

            if (result.Data.CustomerMinDebtAmount) {
              this.paymentCalculatorMinDebtAmount =
                result.Data.CustomerMinDebtAmount;
            }

            if (result.Data.MinDebtAmountDefault) {
              this.paymentCalculatorMinDebtAmountDefault =
                result.Data.MinDebtAmountDefault;
            }

            if (result.Data.CustomerMinDraftAmount) {
              this.paymentCalculatorMinDraftAmount =
                result.Data.CustomerMinDraftAmount;
            }

            if (result.Data.Limits) {
              this.paymentCalculatorServiceFeeAdjustment =
                result.Data.Limits.SvcFeeAdjustment;
              this.paymentCalculatorServiceFeeAdjustmentAttorney =
                result.Data.Limits.SvcFeeAdjustmentAtty;
            }

            if (
              this.Customer.CustomerGlobalInformation.EnrollmentMonths <
              result.Data.Limits.MinMonths
            ) {
              this.Customer.CustomerGlobalInformation.EnrollmentMonths =
                result.Data.Limits.MinMonths;

              this.updateBlueBoxValues();
            } else if (
              this.Customer.CustomerGlobalInformation.EnrollmentMonths >
              result.Data.Limits.MaxMonths +
                this.Customer.CustomerProfile.ExtraTermMonths
            ) {
              this.Customer.CustomerGlobalInformation.EnrollmentMonths =
                result.Data.Limits.MaxMonths +
                this.Customer.CustomerProfile.ExtraTermMonths;

              this.updateBlueBoxValues();
            }

            if (
              result.Data.PossibleIssues &&
              result.Data.PossibleIssues.length > 0
            ) {
              this.paymentCalculatorIssues =
                result.Data.PossibleIssues.join(",");

              this.$swal("Warning!", this.paymentCalculatorIssues, "warning");
            } else {
              this.paymentCalculatorIssues = "";
            }
          }
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Payment Calculator Error!", errMsg, "error");
        }
      }
    },

    async updateExtraTermMonth(value) {
      this.Customer.CustomerProfile.ExtraTermMonths =
        value > this.maxExtraTermCount ? this.maxExtraTermCount : value;
      this.updateBlueBoxValues();
    },

    async getAttorneyInfo() {
      let err, result;
      [err, result] = await this.$store.dispatch(types.GET_ATTORNEY_INFO, {
        StateId: this.Customer.CustomerProfile.Address.StateId,
        PolicyGroupId: this.Customer.CustomerGlobalInformation.PolicyGroupId,
        CreatedDate: this.Customer.CreatedDate,
      });

      if (result && result.Data) {
        this.attorneyOfficeName =
          result.Data.OfficeName +
          " (" +
          result.Data.FirstName +
          " " +
          result.Data.LastName +
          ")";
      } else {
        this.attorneyOfficeName = "";
      }
    },

    async handleSyncCreditor(customerId, userName, isActive) {
      if (isActive) {
        if (
          this.$refs &&
          this.$refs.clientCreditorTab &&
          typeof this.$refs.clientCreditorTab !== "undefined"
        ) {
          this.$refs.clientCreditorTab.proceedSync(customerId);
        }
      } else {
        let err, result;
        [err, result] = await this.$store.dispatch(
          creditorTypes.GET_CREDITORS,
          { CustomerId: customerId }
        );
        if (result) {
          this.creditors = deepCopy(result.Data);
          let total = 0;
          for (let item of this.creditors) {
            total += parseFloat(item.OriginalBalance);

            this.updateTotalCreditorAfterSync(total, this.creditors.length);
          }
        }
      }
    },
    firstPaymentDateCheck(date) {
      this.firstPaymentDateSet = date == null ? false : true;
    },
    async handleSyncCustomer(customerId, userName) {
      let err, result;
      let updateOldCust = true;
      [err, result] = await this.$store.dispatch(types.GET_CLIENT, customerId);

      if (result) {
        if (result.Data) {
          let showMessage = false;
          let localChangesArr = [];
          let remoteChangesArr = [];

          if (result.Data.CustomerGlobalInformation) {
            this.firstPaymentDateCheck(
              result.Data.CustomerGlobalInformation.FirstPaymentDate
            );
          }

          // Change Detection Process
          //get local change Array
          if (JSON.stringify(this.Customer) != this.oldValue) {
            // there are local changes. fill up local change array
            this.recursiveChangeComparison(
              this.Customer,
              JSON.parse(this.oldValue),
              localChangesArr
            );
            showMessage = true;
            updateOldCust = false;
          }

          let scrollX = window.pageXOffset;
          let scrollY = window.pageYOffset;
          this.isRefreshing = true;

          let tmpOldVal = this.oldValue;
          if (!showMessage) {
            let tmp = Object.assign({}, result.Data);
            if (tmp.CustomerCampaignDetail == null) {
              tmp.CustomerCampaignDetail = {
                CampaignId: null,
                VendorLeadId: null,
                Medium: null,
                Source: null,
                Term: null,
              };
            }
            this.Customer = Object.assign({}, tmp);
          } else {
            showMessage = false;
            //get remote change array
            this.recursiveChangeComparison(
              result.Data,
              JSON.parse(tmpOldVal),
              remoteChangesArr
            );
            // compare local and remote changes arrays. if there is any similarity show message.
            for (let item of localChangesArr) {
              if (remoteChangesArr.includes(item)) {
                showMessage = true;
                break;
              }
            }
            //update changed values only...
            if (remoteChangesArr.length > 0) {
              for (let item of remoteChangesArr) {
                let changedPath = this.findPath(this.Customer, item);
                if (changedPath.startsWith(".")) {
                  changedPath = changedPath.slice(1, changedPath.length);
                }
                let resultObj = deepCopy(result.Data);
                let newValue = this.getObjectValueFromPath(
                  resultObj,
                  changedPath
                );
                this.setValueFromPath(this.Customer, changedPath, newValue);
              }
            }
          }

          this.setNullObjectsInModel();

          await this.checkBlueBox();
          /*if(this.Customer.Id > 0){
            this.bindPolicyGroup()
          }*/
          if (updateOldCust) this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.initialSalesUserId = this.Customer.SalesUserId;
          this.setInitialInfo(); // set initial info to compare for info changes.
          this.isRefreshing = false;

          await this.handleScroll(scrollX, scrollY);

          if (showMessage && userName != "System") {
            this.$swal(
              "Warning",
              userName +
                " just made some changes on some fields you edited for customer id: " +
                this.Customer.Id +
                ".",
              "warning"
            );
          }
        }
      }

      this.$forceUpdate();
    },

    async refreshCustomerFromDB(refreshGrid) {
      let err, result;
      this.isRefreshing = true;
      this.isLoading = true;

      [err, result] = await this.$store.dispatch(
        types.GET_CLIENT,
        this.Customer.Id
      );

      if (result) {
        if (result.Data) {
          this.Customer = Object.assign({}, result.Data);
          if (result.Data.CustomerGlobalInformation) {
            this.firstPaymentDateCheck(
              result.Data.CustomerGlobalInformation.FirstPaymentDate
            );
          }
          this.setNullObjectsInModel();
          if (
            typeof this.$refs.profileTab != "undefined" &&
            this.$refs.profileTab
          ) {
            this.$refs.profileTab.setCustomerExpenseUiSettings();
            this.$refs.profileTab.setCustomerTagsUiSettings();
          }

          //this.tabs[0].active = true;

          await this.checkBlueBox();
          if (this.Customer.Id > 0) {
            this.bindPolicyGroup();
          }
          this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.setInitialInfo(); // set initial info to compare for info changes.
          //this.handleTabChange(0);
        }
      }
      if (refreshGrid) {
        this.$emit("handleSearch");
      }
      this.$forceUpdate();
      this.isRefreshing = false;
      this.isLoading = false;
    },
    async refreshCustomerFromDBOnClick(refreshGrid) {
      let err, result;
      this.isRefreshing = true;
      this.isLoading = true;

      [err, result] = await this.$store.dispatch(
        types.GET_CLIENT,
        this.Customer.Id
      );

      if (result) {
        if (result.Data) {
          this.Customer = Object.assign({}, result.Data);
          if (result.Data.CustomerGlobalInformation) {
            this.firstPaymentDateCheck(
              result.Data.CustomerGlobalInformation.FirstPaymentDate
            );
          }
          this.setNullObjectsInModel();
          if (
            typeof this.$refs.profileTab != "undefined" &&
            this.$refs.profileTab
          ) {
            this.$refs.profileTab.setCustomerExpenseUiSettings();
            this.$refs.profileTab.setCustomerTagsUiSettings();
            if (this.tabs && this.tabs.length > 0) {
              this.tabs.map((t) => {
                t.active = false;
              });
              this.tabs[0].active = true;
            }
          }

          //this.tabs[0].active = true;

          await this.checkBlueBox();
          if (this.Customer.Id > 0) {
            this.bindPolicyGroup();
          }
          this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.setInitialInfo(); // set initial info to compare for info changes.
          //this.handleTabChange(0);
        }
      }
      if (refreshGrid) {
        this.$emit("handleSearch");
      }
      this.$forceUpdate();
      this.isRefreshing = false;
      this.isLoading = false;
    },
    async refreshCustomerAfterCreditor(refreshGrid) {
      let err, result;
      this.isRefreshing = true;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(
        types.GET_CLIENT,
        this.Customer.Id
      );

      if (result) {
        if (result.Data) {
          this.Customer = Object.assign({}, result.Data);
          if (result.Data.CustomerGlobalInformation) {
            this.firstPaymentDateCheck(
              result.Data.CustomerGlobalInformation.FirstPaymentDate
            );
          }
          this.setNullObjectsInModel();
          if (
            typeof this.$refs.profileTab != "undefined" &&
            this.$refs.profileTab
          ) {
            this.$refs.profileTab.setCustomerExpenseUiSettings();
            this.$refs.profileTab.setCustomerTagsUiSettings();
          }

          //this.tabs[0].active = true;

          await this.checkBlueBox();
          if (this.Customer.Id > 0) {
            this.bindPolicyGroup();
          }
          this.oldValue = JSON.stringify(this.Customer);
          this.isInitiallySoldStatus = this.isSoldStatus();
          this.initialStatusId = this.Customer.StatusId;
          this.setInitialInfo(); // set initial info to compare for info changes.
        }
      }
      if (refreshGrid) {
        this.$emit("handleSearch");
      }
      this.$forceUpdate();
      this.isRefreshing = false;
      this.isLoading = false;
    },
    async handleEnroll() {
      let err, result;
      let iscancelled = false;
      if (this.isDataChanged()) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you close the tab",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            iscancelled = false;
          } else {
            iscancelled = true;
          }
        });
        if (iscancelled) return;
        else {
          this.refreshCustomerFromDB(false);
        }
      }
      if (
        !this.validateDraftDate(
          this.Customer.CustomerGlobalInformation.StartDate
        )
      ) {
        await this.$swal(
          "Warning",
          "Initial Draft Date must be greater than today!",
          "warning"
        );
        return;
      }
      this.saving = true;
      this.isLoading = true;
      [err, result] = await this.$store.dispatch(
        types.INSERT_GLOBALNEWACCOUNTAndPROGRAMDRAFTS,
        {
          CustomerId: this.Customer.Id,
          OfficeId: this.Customer.OfficeId,
        }
      );
      if (result) {
        this.$swal("Success!", result.Message, "success");
        //refresh client and grid
        this.refreshCustomerFromDB(true);
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.saving = false;
      this.isLoading = false;
    },
    async revertNullObjects() {
      if (
        JSON.stringify(this.customerToSave.Bank) ==
        JSON.stringify(this.customerInit.Bank)
      ) {
        this.customerToSave.Bank = null;
      }
      if (
        JSON.stringify(this.customerToSave.CustomerProfile.Address) ==
        JSON.stringify(this.customerInit.CustomerProfile.Address)
      ) {
        this.customerToSave.CustomerProfile.Address = null;
      }
      if (
        JSON.stringify(this.customerToSave.CustomerGlobalInformation) ==
        JSON.stringify(this.customerInit.CustomerGlobalInformation)
      ) {
        this.customerToSave.CustomerGlobalInformation = null;
      }

      if (
        JSON.stringify(this.customerToSave.CustomerCampaignDetail) ==
        JSON.stringify(this.customerInit.CustomerCampaignDetail)
      ) {
        this.customerToSave.CustomerCampaignDetail = null;
      }
    },
    isReadyToCalcDraft() {
      let rtn = false;

      if (
        this.Customer &&
        this.Customer.OfficeId &&
        this.Customer.CustomerProfile.Address &&
        this.Customer.CustomerProfile.Address.StateId &&
        this.Customer.CustomerGlobalInformation &&
        this.Customer.CustomerGlobalInformation.EnrollmentMonths &&
        this.Customer.CreatedDate &&
        this.totalDebtAmount > 0
      ) {
        rtn = true;
      }

      return rtn;
    },
    handleFavorite() {
      this.isFavorited = !this.isFavorited;
      if (this.isFavorited) {
        this.$store.dispatch(globalTypes.POSTFAV, {
          CustomerId: this.Customer.Id,
          FirstName: this.Customer.CustomerProfile.FirstName,
          LastName: this.Customer.CustomerProfile.LastName,
        });
      } else {
        this.$store.dispatch(globalTypes.DELETEFAV, {
          CustomerId: this.Customer.Id,
        });
      }
    },
    isDataChanged() {
      if (this.oldValue != null && this.oldValue != "") {
        let parseOldValue = JSON.parse(this.oldValue);

        parseOldValue.CustomerExpenses = parseOldValue.CustomerExpenses.filter(
          (x) => x.Amount > 0
        );
        parseOldValue.CustomerExpenses.forEach((x) => delete x.UiOrder);

        parseOldValue.CustomerTags = parseOldValue.CustomerTags.filter(
          (x) => x.TagValue != null
        );
        parseOldValue.CustomerTags.forEach((x) => delete x.UiOrder);

        this.oldValue = JSON.stringify(parseOldValue);
      }

      this.Customer.CustomerExpenses = this.Customer.CustomerExpenses.filter(
        (x) => x.Amount > 0
      );
      this.Customer.CustomerExpenses.forEach((x) => delete x.UiOrder);

      this.Customer.CustomerTags = this.Customer.CustomerTags.filter(
        (x) => x.TagValue != null
      );
      this.Customer.CustomerTags.forEach((x) => delete x.UiOrder);

      let rtn = false;
      let current = JSON.stringify(this.Customer);

      if (
        typeof this.Customer.Id == "undefined" &&
        this.Customer.CustomerProfile.FirstName != null &&
        this.Customer.CustomerProfile.FirstName.length > 0
      ) {
        rtn = true;
      } else if (this.oldValue != current && this.Customer.Id > 0) {
        rtn = true;
      }
      return rtn;
    },
    checkNullOrEmpty(value) {
      return value == null || value == "";
    },
    validateCoCustomers() {
      let rtn = true;

      if (this.Customer && this.Customer.CoCustomerProfiles) {
        if (this.Customer.CoCustomerProfiles.length > 0) {
          for (let i in this.Customer.CoCustomerProfiles) {
            rtn *= !this.checkNullOrEmpty(
              this.Customer.CoCustomerProfiles[i].FirstName
            );
            rtn *= !this.checkNullOrEmpty(
              this.Customer.CoCustomerProfiles[i].LastName
            );
          }
        }
      }

      return rtn;
    },
    // async saveForPolicyGroups() {
    //   if (this.blockChanges()) {
    //     return;
    //   }

    //   this.saving = true;
    //   if (!this.isDataChanged()) {
    //     this.saving = false;
    //     this.customerToSave = {};
    //     return;
    //   } else {
    //     try {
    //       let err, result;
    //       this.customerToSave = deepCopy(this.Customer);

    //       //set PhoneIds
    //       if (
    //         this.customerToSave.CustomerProfile.CustomerPhones &&
    //         this.customerToSave.CustomerProfile.CustomerPhones.length > 0
    //       ) {
    //         this.customerToSave.CustomerProfile.CustomerPhones =
    //           this.customerToSave.CustomerProfile.CustomerPhones.map((x) => {
    //             if (x.Id < 0) x.Id = 0;
    //             return x;
    //           });
    //       }
    //       //set EmailIds
    //       if (this.customerToSave.CustomerProfile.CustomerEmails.length > 0) {
    //         this.customerToSave.CustomerProfile.CustomerEmails =
    //           this.customerToSave.CustomerProfile.CustomerEmails.map((x) => {
    //             if (x.Id < 0) x.Id = 0;
    //             return x;
    //           });
    //       }

    //       //set encrypted values
    //       // if (this.Customer.Bank) {
    //       //   let accountNumber = await this.AES256_GCM_ENCRYPT(
    //       //     this.Customer.Bank.AccountNumberEncrypted
    //       //   );
    //       //   this.customerToSave.Bank.AccountNumberEncrypted = accountNumber;
    //       // }

    //       //set v-masks formatted fields

    //       this.customerToSave.CustomerProfile.Income = parseFloat(
    //         this.Customer.CustomerProfile.Income
    //       );
    //       this.customerToSave.CustomerProfile.YearFiled = parseFloat(
    //         this.Customer.CustomerProfile.YearFiled
    //       );
    //       this.customerToSave.CustomerProfile.YearDischarged = parseFloat(
    //         this.Customer.CustomerProfile.YearDischarged
    //       );

    //       if (this.Customer.CustomerGlobalInformation) {
    //         this.customerToSave.CustomerGlobalInformation.EnrollmentMonths =
    //           parseInt(
    //             this.Customer.CustomerGlobalInformation.EnrollmentMonths
    //           );
    //       }

    //       // set null childs
    //       this.revertNullObjects();

    //       let type = "";
    //       if (this.Customer.Id) {
    //         type = types.UPDATE_CLIENT;
    //       }

    //       [err, result] = await this.$store.dispatch(type, this.customerToSave);

    //       if (result) {
    //         this.Customer = deepCopy(result.Data);

    //         this.setNullObjectsInModel();

    //         this.oldValue = JSON.stringify(this.Customer);
    //         this.saving = false;
    //         this.customerToSave = {};
    //       }
    //     } catch (err) {
    //       this.$swal("Error!", JSON.stringify(err), "error");
    //     }

    //     this.saving = false;
    //   }
    // },
    isCustomerTagsValid() {
      let isValid = true;
      if (this.Customer.CustomerTags.length > 0) {
        let tags = deepCopy(
          this.Customer.CustomerTags.filter((x) => x.TagValue != null)
        );

        for (let item of tags) {
          if (
            item.TagValue == null ||
            (typeof item.TagValue == "string" && item.TagValue.length == 0)
          ) {
            isValid = false;
            break;
          }
        }
      }

      return isValid;
    },

    getStateShort(stateId) {
      let rtn = "";
      let tmpStates = this.states.filter((x) => x.Id == stateId);
      if (tmpStates.length > 0) {
        rtn = tmpStates[0].ShortCode;
      }

      return rtn;
    },

    validateCustomerAge(date) {
      let rtn = true;

      if (date) {
        let customerDob = new Date(date);
        let checkDate = new Date(
          customerDob.getFullYear() + 18,
          customerDob.getMonth(),
          customerDob.getDate()
        );
        rtn = new Date() >= checkDate;
      }

      return rtn;
    },
    validateDraftDate(date) {
      let rtn = true;

      if (date) {
        let draftDate = new Date(date);
        let checkDate = new Date(
          draftDate.getFullYear(),
          draftDate.getMonth(),
          draftDate.getDate()
        );
        rtn = checkDate >= new Date();
      }

      return rtn;
    },
    async handleSave(saveWithoutTags = false) {
      if (
        this.checkNullOrEmpty(this.Customer.CustomerProfile.FirstName) ||
        this.checkNullOrEmpty(this.Customer.CustomerProfile.LastName) ||
        !this.Customer.CustomerProfile.CustomerPhones ||
        this.Customer.CustomerProfile.CustomerPhones.length == 0
      ) {
        this.$swal(
          "Warning!",
          "First name, last name and phone required!",
          "warning"
        );
        return;
      }

      if (!this.validateCoCustomers()) {
        this.$swal(
          "Warning!",
          "Co Customers name fields are required!",
          "warning"
        );
        return;
      }

      if (this.blockChanges() && this.Customer.Id > 0) {
        this.$swal(
          "Warning!",
          "You are not authorized to make changes!",
          "warning"
        );

        return;
      }

      try {
        if (
          this.Customer.CustomerTags &&
          this.Customer.CustomerTags.length > 0
        ) {
          let tags = groupBy(this.Customer.CustomerTags, "TagTypeId");

          let keys = Object.keys(tags);
          for (let key of keys) {
            let tagItem = tags[key];

            if (tagItem.length > 1) {
              this.$store.dispatch(globalTypes.SEND_ERROR_LOG, {
                Message: "Tag Duplication on Save: " + JSON.stringify(tagItem),
              });
            }
          }
        }
      } catch {
        //error
      }

      if (
        this.validateCustomerAge(this.Customer.CustomerProfile.DateOfBirth) ==
        false
      ) {
        this.$swal(
          "Warning!",
          "Customer/Co-Customers can not be under 18 years of age.",
          "warning"
        );
        return;
      }

      if (this.Customer.CoCustomerProfiles.length > 0) {
        let coCustomersAgeValidation = true;
        this.Customer.CoCustomerProfiles.filter((x) => !x.IsExcluded).forEach(
          (item) => {
            coCustomersAgeValidation *= this.validateCustomerAge(
              item.DateOfBirth
            );
          }
        );
        if (coCustomersAgeValidation == false) {
          this.$swal(
            "Warning!",
            "Co-Customers can not be under 18 years of age.",
            "warning"
          );
          return;
        }
      }

      let parseOldValue = null;

      if (this.oldValue != "") {
        parseOldValue = JSON.parse(this.oldValue);
      }

      if (this.Customer.CustomerGlobalInformation) {
        if (this.Customer.CustomerGlobalInformation.FirstPaymentDate) {
          let firstdate = new Date(
            this.Customer.CustomerGlobalInformation.FirstPaymentDate
          );

          let dateNow = new Date();
          let currentDate = new Date(
            dateNow.getFullYear(),
            dateNow.getMonth(),
            dateNow.getDate(),
            0,
            0,
            0
          );
          if (
            parseOldValue &&
            parseOldValue.CustomerGlobalInformation.FirstPaymentDate
          ) {
            let oldPaymentDate = new Date(
              parseOldValue.CustomerGlobalInformation.FirstPaymentDate
            );

            if (
              new Date(oldPaymentDate).toLocaleDateString() !=
                new Date(firstdate).toLocaleDateString() &&
              firstdate < currentDate
            ) {
              this.$swal(
                "Warning!",
                "First payment date can not be less than current date.",
                "warning"
              );
              this.Customer.CustomerGlobalInformation.FirstPaymentDate =
                parseOldValue.CustomerGlobalInformation.FirstPaymentDate;

              return;
            }
          } else {
            if (firstdate < currentDate) {
              this.$swal(
                "Warning!",
                "First payment date can not be less than current date.",
                "warning"
              );
              return;
            }
          }

          let recurDate = new Date(
            this.Customer.CustomerGlobalInformation.StartDate
          );
          if (firstdate > recurDate) {
            this.$swal(
              "Warning!",
              "First payment date can not be greater than recurring date.",
              "warning"
            );
            return;
          }
        }
      }

      let changedFields = [];
      if (parseOldValue) {
        if (parseOldValue.CustomerProfile) {
          if (
            parseOldValue.CustomerProfile.FirstName !=
              this.Customer.CustomerProfile.FirstName ||
            parseOldValue.CustomerProfile.LastName !=
              this.Customer.CustomerProfile.LastName ||
            parseOldValue.CustomerProfile.MiddleInitial !=
              this.Customer.CustomerProfile.MiddleInitial ||
            parseOldValue.CustomerProfile.DateOfBirth !=
              this.Customer.CustomerProfile.DateOfBirth ||
            parseOldValue.CustomerProfile.MothersMaidenName !=
              this.Customer.CustomerProfile.MothersMaidenName ||
            parseOldValue.CustomerProfile.GenderId !=
              this.Customer.CustomerProfile.GenderId ||
            parseOldValue.CustomerProfile.SsnEncrypted !=
              this.Customer.CustomerProfile.SsnEncrypted ||
            parseOldValue.CustomerProfile.DebtAmount !=
              this.Customer.CustomerProfile.DebtAmount
          ) {
            changedFields.push("Customer Information Section");
          }
          if (
            JSON.stringify(parseOldValue.CustomerProfile.CustomerPhones) !=
            JSON.stringify(this.Customer.CustomerProfile.CustomerPhones)
          ) {
            changedFields.push("Phones Section");
          }
          if (
            JSON.stringify(parseOldValue.CustomerProfile.CustomerEmails) !=
            JSON.stringify(this.Customer.CustomerProfile.CustomerEmails)
          ) {
            changedFields.push("Emails Section");
          }
          if (
            JSON.stringify(parseOldValue.CustomerProfile.Address) !=
            JSON.stringify(this.Customer.CustomerProfile.Address)
          ) {
            changedFields.push("Mailing Address Section");
          }
          if (
            JSON.stringify(parseOldValue.Bank) !=
            JSON.stringify(this.Customer.Bank)
          ) {
            changedFields.push("Bank Information Section");
          }
          if (
            parseOldValue.CustomerProfile.Income !=
              this.Customer.CustomerProfile.Income ||
            JSON.stringify(parseOldValue.CustomerExpenses) !=
              JSON.stringify(this.Customer.CustomerExpenses)
          ) {
            changedFields.push("Financial Information Section");
          }
          if (
            parseOldValue.CustomerCampaignDetail.CampaignId !=
              this.Customer.CustomerCampaignDetail.CampaignId ||
            parseOldValue.HardshipId != this.Customer.HardshipId ||
            parseOldValue.HardshipStatementNotes !=
              this.Customer.HardshipStatementNotes ||
            parseOldValue.CustomerProfile.OccupationId !=
              this.Customer.CustomerProfile.OccupationId ||
            parseOldValue.CustomerProfile.ResidenceOwnershipId !=
              this.Customer.CustomerProfile.ResidenceOwnershipId ||
            parseOldValue.CustomerProfile.ResidenceYears !=
              this.Customer.CustomerProfile.ResidenceYears ||
            parseOldValue.CustomerProfile.DeclaredBankruptcy !=
              this.Customer.CustomerProfile.DeclaredBankruptcy ||
            parseOldValue.CustomerProfile.ChapterId !=
              this.Customer.CustomerProfile.ChapterId ||
            parseOldValue.CustomerProfile.YearFiled !=
              this.Customer.CustomerProfile.YearFiled ||
            parseOldValue.CustomerProfile.YearDischarged !=
              this.Customer.CustomerProfile.YearDischarged
          ) {
            changedFields.push("Other Information Section");
          }
        }
      }
      //this.checkSalesUserId();

      this.saving = true;

      if (!this.isDataChanged() && !this.isDraftAmountDifferentInDB) {
        this.saving = false;
        this.customerToSave = {};
        this.$swal("Warning!", "No Changes Detected!", "warning");
        this.$Emitter.emit("updateCustomerProfileTags", {});

        return;
      } else {
        try {
          this.isDraftAmountDifferentInDB = false;
          if (
            this.isInitiallySoldStatus &&
            !this.isSoldStatus() &&
            !this.skipStatusControl
          ) {
            // show pop up...
            this.isStatusChangePopupOpen = true;
            this.saving = false;
            return;
          }

          this.skipStatusControl = false;

          let err, result;

          // check status change without authorization
          if (
            !this.checkAuth(1040) &&
            this.initialStatusId != this.Customer.StatusId
          ) {
            this.Customer.StatusId = this.initialStatusId;
          }

          // check sales user change without authorization
          if (
            !this.checkAuth(1071) &&
            this.initialSalesUserId != this.Customer.SalesUserId
          ) {
            this.Customer.SalesUserId = this.initialSalesUserId;
          }

          this.customerToSave = deepCopy(this.Customer);

          //set PhoneIds
          if (
            this.customerToSave.CustomerProfile.CustomerPhones &&
            this.customerToSave.CustomerProfile.CustomerPhones.length > 0
          ) {
            this.customerToSave.CustomerProfile.CustomerPhones =
              this.customerToSave.CustomerProfile.CustomerPhones.map((x) => {
                if (x.Id < 0) x.Id = 0;
                return x;
              });
          }
          //set EmailIds
          if (this.customerToSave.CustomerProfile.CustomerEmails.length > 0) {
            this.customerToSave.CustomerProfile.CustomerEmails =
              this.customerToSave.CustomerProfile.CustomerEmails.map((x) => {
                if (x.Id < 0) x.Id = 0;
                return x;
              });
          }

          //set v-masks formatted fields

          this.customerToSave.CustomerProfile.Income = parseFloat(
            this.Customer.CustomerProfile.Income
          );
          this.customerToSave.CustomerProfile.YearFiled = parseFloat(
            this.Customer.CustomerProfile.YearFiled
          );
          this.customerToSave.CustomerProfile.YearDischarged = parseFloat(
            this.Customer.CustomerProfile.YearDischarged
          );

          if (this.Customer.CustomerGlobalInformation) {
            this.customerToSave.CustomerGlobalInformation.EnrollmentMonths =
              parseInt(
                this.Customer.CustomerGlobalInformation.EnrollmentMonths
              );

            if (this.firstPaymentDateSet == false) {
              this.customerToSave.CustomerGlobalInformation.FirstPaymentDate =
                null;
            }
          }

          if (this.customerToSave.CustomerProfile.DateOfBirth != null) {
            this.customerToSave.CustomerProfile.DateOfBirth =
              this.formatShortDate(this.Customer.CustomerProfile.DateOfBirth);
          }

          if (this.customerToSave.CoCustomerProfiles.length > 0) {
            for (let index in this.customerToSave.CoCustomerProfiles) {
              if (
                this.customerToSave.CoCustomerProfiles[index].DateOfBirth !=
                null
              ) {
                this.customerToSave.CoCustomerProfiles[index].DateOfBirth =
                  this.formatShortDate(
                    this.Customer.CoCustomerProfiles[index].DateOfBirth
                  );
              }
            }
          }

          if (
            this.Customer &&
            this.Customer.CustomerProfile &&
            this.Customer.CustomerProfile.Address &&
            this.Customer.CustomerProfile.Address.StateId
          ) {
            let validStates = this.companySettingsStatesList;
            if (
              !validStates.includes(
                this.Customer.CustomerProfile.Address.StateId
              )
            ) {
              this.$swal(
                "Warning!",
                "Customer is in a non-active state.",
                "warning"
              );
              this.saving = false;
              return;
            }
          }

          // set null childs
          this.revertNullObjects();

          if (this.Customer.CompanyId == null) {
            this.customerToSave.CompanyId = this.userInfo.companyId;
          }

          if (
            this.Customer &&
            this.Customer.CustomerProfile &&
            this.Customer.CustomerProfile.Address &&
            this.Customer.CustomerProfile.Address.StateId
          ) {
            let validationPayload = {
              OfficeId: this.Customer.OfficeId
                ? this.Customer.OfficeId
                : this.userInfo.officeId,
              StateId: this.Customer.CustomerProfile.Address.StateId,
              CreatedDate: this.Customer.CreatedDate
                ? this.Customer.CreatedDate
                : new Date(),
              CompanyId: this.Customer.CompanyId
                ? this.Customer.CompanyId
                : this.userInfo.companyId,
            };
            let aerr, aresult;
            [aerr, aresult] = await this.$store.dispatch(
              types.GET_PAYMENT_CALCULATOR_STATE_VALIDATION,
              validationPayload
            );

            if (aresult && aresult.Data && !aresult.Data.IsValid) {
              this.$swal(
                "Warning!",
                "Customer is in a non-active state.",
                "warning"
              );
              this.saving = false;
              return;
            }
          }

          let type = "";
          if (this.Customer.Id) {
            type =
              saveWithoutTags == true
                ? types.UPDATE_CLIENT_WITHOUT_TAGS
                : types.UPDATE_CLIENT;
          } else {
            type = types.INSERT_NEW_CLIENT;
          }

          if (
            this.customerToSave.CustomerGlobalInformation &&
            this.customerToSave.CustomerGlobalInformation.StartDate
          ) {
            let globalStartDate = new Date(
              this.customerToSave.CustomerGlobalInformation.StartDate
            );
            this.customerToSave.CustomerGlobalInformation.StartDate = new Date(
              globalStartDate.getFullYear(),
              globalStartDate.getMonth(),
              globalStartDate.getDate(),
              0,
              0,
              0
            ).toString("MM-dd-yyyy");
          }
          if (
            this.customerToSave.CustomerGlobalInformation &&
            this.customerToSave.CustomerGlobalInformation.FirstPaymentDate
          ) {
            let globalFirstPaymentDate = new Date(
              this.customerToSave.CustomerGlobalInformation.FirstPaymentDate
            );
            this.customerToSave.CustomerGlobalInformation.FirstPaymentDate =
              new Date(
                globalFirstPaymentDate.getFullYear(),
                globalFirstPaymentDate.getMonth(),
                globalFirstPaymentDate.getDate(),
                0,
                0,
                0
              ).toString("MM-dd-yyyy");
          }

          [err, result] = await this.$store.dispatch(type, this.customerToSave);

          if (result) {
            this.$swal("Success!", result.Message, "success");

            this.Customer = deepCopy(result.Data);

            let phones = orderBy(
              result.Data.CustomerProfile.CustomerPhones,
              "Priority",
              "asc"
            );

            if (phones && phones.length > 0) {
              this.primaryPhoneNumber = phones[0].PhoneNumber;
            }

            if (
              this.Customer.CustomerGlobalInformation &&
              this.Customer.CustomerGlobalInformation.AccountNumber &&
              !(
                this.Customer.CustomerGlobalInformation &&
                this.Customer.CustomerGlobalInformation.IsDpg == true
              ) &&
              this.removedFeeGroupingKeys &&
              this.removedFeeGroupingKeys.length > 0
            ) {
              let feeGroups = this.removedFeeGroupingKeys.filter(
                (x) => x.ProductType == "Fee"
              );
              let chargeGroups = this.removedFeeGroupingKeys.filter(
                (x) => x.ProductType == "Charge"
              );
              if (feeGroups.length > 0) {
                await this.getFeeTypes();
                await this.deActivateRemovedFees();
              }
              if (chargeGroups.length > 0) {
                await this.deActivateRemovedCharges();
              }
            }
            if (result.Data.CustomerGlobalInformation) {
              this.firstPaymentDateCheck(
                result.Data.CustomerGlobalInformation.FirstPaymentDate
              );
            }

            this.setNullObjectsInModel();

            this.oldValue = JSON.stringify(this.Customer);
            this.$emit("updateCustomerId", this.Customer.Id);

            let statusName = "";
            let indx = this.customerStatuses.findIndex(
              (x) => x.Id == this.Customer.StatusId
            );
            if (indx > -1) {
              statusName = this.customerStatuses[indx].Name;
            }

            this.setInfoChanges(); // create info changes if conditionas match...
            this.$Emitter.emit("refreshPhonePanelAfterCustomerSave", {
              Id: this.Customer.Id,
              AssignedUserId: this.Customer.SalesUserId,
              PhoneList: this.Customer.CustomerProfile.CustomerPhones,
            });

            this.refreshCustomerFromDB(true);
            this.saving = false;
            this.customerToSave = {};

            let changedFieldsText =
              changedFields.length > 0 ? changedFields.join(", ") : "";
            if (type == types.UPDATE_CLIENT) {
              if (this.checkAuth(3994)) {
                this.$store.dispatch(globalTypes.INSERT_USER_ACTIVITY, {
                  CustomerId: this.Customer.Id,
                  UserActivityTypeId: 2,
                  AdditionalNotes:
                    changedFields.length > 0
                      ? "Updated Customer Info (" + changedFieldsText + ")"
                      : "Updated Customer Info",
                });
              }
            }
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        } catch (err) {
          this.$swal("Error!", JSON.stringify(err), "error");
        }

        this.saving = false;
      }
    },
    async handleSaveSilent() {
      if (
        this.checkNullOrEmpty(this.Customer.CustomerProfile.FirstName) ||
        this.checkNullOrEmpty(this.Customer.CustomerProfile.LastName) ||
        !this.Customer.CustomerProfile.CustomerPhones ||
        this.Customer.CustomerProfile.CustomerPhones.length == 0
      ) {
        return false;
      }

      if (!this.validateCoCustomers()) {
        return false;
      }

      if (this.blockChanges() && this.Customer.Id > 0) {
        return false;
      }

      if (!this.isCustomerTagsValid()) {
        return false;
      }

      if (
        this.validateCustomerAge(this.Customer.CustomerProfile.DateOfBirth) ==
        false
      ) {
        return false;
      }

      if (this.Customer.CoCustomerProfiles.length > 0) {
        let coCustomersAgeValidation = true;
        this.Customer.CoCustomerProfiles.filter((x) => !x.IsExcluded).forEach(
          (item) => {
            coCustomersAgeValidation *= this.validateCustomerAge(
              item.DateOfBirth
            );
          }
        );
        if (coCustomersAgeValidation == false) {
          return false;
        }
      }

      if (this.Customer.CustomerGlobalInformation) {
        if (this.Customer.CustomerGlobalInformation.FirstPaymentDate) {
          let firstdate = new Date(
            this.Customer.CustomerGlobalInformation.FirstPaymentDate
          );

          let dateNow = new Date();
          let currentDate = new Date(
            dateNow.getFullYear(),
            dateNow.getMonth(),
            dateNow.getDate(),
            0,
            0,
            0
          );

          let parseOldValue = null;

          if (this.oldValue != "") {
            parseOldValue = JSON.parse(this.oldValue);
          }

          if (
            parseOldValue &&
            parseOldValue.CustomerGlobalInformation.FirstPaymentDate
          ) {
            let oldPaymentDate = new Date(
              parseOldValue.CustomerGlobalInformation.FirstPaymentDate
            );

            if (
              oldPaymentDate < currentDate &&
              new Date(oldPaymentDate).toLocaleDateString() !=
                new Date(firstdate).toLocaleDateString() &&
              firstdate < currentDate
            ) {
              this.Customer.CustomerGlobalInformation.FirstPaymentDate =
                parseOldValue.CustomerGlobalInformation.FirstPaymentDate;

              return false;
            }
          } else {
            if (firstdate < currentDate) {
              return false;
            }
          }

          let recurDate = new Date(
            this.Customer.CustomerGlobalInformation.StartDate
          );
          if (firstdate > recurDate) {
            return false;
          }
        }
      }

      if (
        this.isInitiallySoldStatus &&
        !this.isSoldStatus() &&
        !this.skipStatusControl
      ) {
        // show pop up...
        this.isStatusChangePopupOpen = true;
        this.saving = false;
        return false;
      }

      this.skipStatusControl = false;

      let err, result;
      this.customerToSave = deepCopy(this.Customer);

      //set PhoneIds
      if (
        this.customerToSave.CustomerProfile.CustomerPhones &&
        this.customerToSave.CustomerProfile.CustomerPhones.length > 0
      ) {
        this.customerToSave.CustomerProfile.CustomerPhones =
          this.customerToSave.CustomerProfile.CustomerPhones.map((x) => {
            if (x.Id < 0) x.Id = 0;
            return x;
          });
      }
      //set EmailIds
      if (this.customerToSave.CustomerProfile.CustomerEmails.length > 0) {
        this.customerToSave.CustomerProfile.CustomerEmails =
          this.customerToSave.CustomerProfile.CustomerEmails.map((x) => {
            if (x.Id < 0) x.Id = 0;
            return x;
          });
      }

      //set v-masks formatted fields

      this.customerToSave.CustomerProfile.Income = parseFloat(
        this.Customer.CustomerProfile.Income
      );
      this.customerToSave.CustomerProfile.YearFiled = parseFloat(
        this.Customer.CustomerProfile.YearFiled
      );
      this.customerToSave.CustomerProfile.YearDischarged = parseFloat(
        this.Customer.CustomerProfile.YearDischarged
      );

      if (this.Customer.CustomerGlobalInformation) {
        this.customerToSave.CustomerGlobalInformation.EnrollmentMonths =
          parseInt(this.Customer.CustomerGlobalInformation.EnrollmentMonths);

        if (this.firstPaymentDateSet == false) {
          this.customerToSave.CustomerGlobalInformation.FirstPaymentDate = null;
        }
      }

      if (this.customerToSave.CustomerProfile.DateOfBirth != null) {
        this.customerToSave.CustomerProfile.DateOfBirth = this.formatShortDate(
          this.Customer.CustomerProfile.DateOfBirth
        );
      }

      if (this.customerToSave.CoCustomerProfiles.length > 0) {
        for (let index in this.customerToSave.CoCustomerProfiles) {
          if (
            this.customerToSave.CoCustomerProfiles[index].DateOfBirth != null
          ) {
            this.customerToSave.CoCustomerProfiles[index].DateOfBirth =
              this.formatShortDate(
                this.Customer.CoCustomerProfiles[index].DateOfBirth
              );
          }
        }
      }

      if (
        this.Customer &&
        this.Customer.CustomerProfile &&
        this.Customer.CustomerProfile.Address &&
        this.Customer.CustomerProfile.Address.StateId
      ) {
        let validStates = this.companySettingsStatesList;
        if (
          !validStates.includes(this.Customer.CustomerProfile.Address.StateId)
        ) {
          this.saving = false;
          return false;
        }
      }

      // set null childs
      this.revertNullObjects();

      let type = "";
      if (this.Customer.Id) {
        type = types.UPDATE_CLIENT;
      } else {
        type = types.INSERT_NEW_CLIENT;
      }

      [err, result] = await this.$store.dispatch(type, this.customerToSave);

      if (result) {
        this.Customer = deepCopy(result.Data);
        if (result.Data.CustomerGlobalInformation) {
          this.firstPaymentDateCheck(
            result.Data.CustomerGlobalInformation.FirstPaymentDate
          );
        }

        this.setNullObjectsInModel();

        this.oldValue = JSON.stringify(this.Customer);
        this.$emit("updateCustomerId", this.Customer.Id);

        let statusName = "";
        let indx = this.customerStatuses.findIndex(
          (x) => x.Id == this.Customer.StatusId
        );
        if (indx > -1) {
          statusName = this.customerStatuses[indx].Name;
        }

        this.setInfoChanges(); // create info changes if conditionas match...
        /*this.$Emitter.emit("refreshPhonePanelAfterCustomerSave", {
              Id: this.Customer.Id,
              AssignedUserId: this.Customer.SalesUserId,
              PhoneList: this.Customer.CustomerProfile.CustomerPhones,
            });*/

        this.refreshCustomerFromDB(true);

        this.customerToSave = {};
        return true;
      } else {
        let errMsg = this.getApiErrorMessage(err);
      }
      return true;
    },
    handleReminders() {},
    async handleScroll(x, y) {
      await this.sleep(10);
      window.scrollTo(x, y);
    },
    handleSms() {
      let phoneData = [];
      let phones = this.Customer.CustomerProfile.CustomerPhones;

      if (phones.length > 0) {
        phones = orderBy(phones, "Priority", "asc");
        for (let p of phones) {
          phoneData.push({
            Id: p.Id,
            PhoneNumber: p.PhoneNumber,
            Priority: p.Priority,
            IsCoCustomer: false,
          });
        }
      }

      if (this.Customer.CoCustomerProfiles) {
        let activeCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => x.IsExcluded == false
        );

        let coPhones = [];
        if (activeCoCustomers.length > 0) {
          for (let item of activeCoCustomers) {
            if (item.CoCustomerPhones && item.CoCustomerPhones.length > 0) {
              for (let p of item.CoCustomerPhones) {
                coPhones.push(p);
              }
            }
          }
        }

        if (coPhones.length > 0) {
          coPhones = orderBy(coPhones, "Priority", "asc");

          for (let p of coPhones) {
            phoneData.push({
              Id: p.Id,
              PhoneNumber: p.PhoneNumber,
              Priority: p.Priority,
              IsCoCustomer: true,
            });
          }
        }
      }

      if (phoneData.length > 0) {
        this.$Emitter.emit("openSms", {
          Phones: "",
          PhoneList: phoneData,
          CustomerId: this.Customer.Id,
          LanguageId: this.Customer.LanguageId,
        });
      } else {
        this.$swal("Warning", "There is no phone number", "warning");
      }
    },

    handleTabChange(index) {
      if (
        document.getElementById(
          "CustomerSaveButton" + this.customerId.toString()
        ) != null
      ) {
        if (
          document.getElementById(
            "CustomerSaveButton" + this.customerId.toString()
          ).disabled
        ) {
          //this.$refs.observer.validate(); temp
          return;
        }
      }

      this.tabs.map((t) => {
        t.active = false;
      });
      this.$store.commit(
        globalTypes.SET_CURRENT_KO_PATH,
        this.$koPaths.customers[camelCase(this.tabs[index].label)]
      );
      this.tabs[index].active = true;
    },
    openQuickEmails() {
      if (
        this.Customer &&
        this.Customer.CustomerProfile.CustomerEmails &&
        this.Customer.CustomerProfile.CustomerEmails.length > 0
      ) {
        this.isQuickEmailPopupOpen = true;
      } else {
        this.$swal("Warning", "This customer has no email address", "warning");
      }
    },
    closeQuickEmailPopup() {
      this.isQuickEmailPopupOpen = false;
    },

    async quickEmailSelected(item) {
      this.selectedQuickEmailFromPopup = item;
      this.isQuickEmailPopupOpen = false;

      let toEmail = this.customerPrimaryEmail;
      if (item.TypeId == 2) {
        let activeCoCustomers = this.Customer.CoCustomerProfiles.filter(
          (x) => x.IsExcluded == false
        );
        if (activeCoCustomers.length > 0) {
          let emails = [];
          for (let item of activeCoCustomers) {
            if (item.CoCustomerEmails && item.CoCustomerEmails.length > 0) {
              let orderedEmails = orderBy(
                item.CoCustomerEmails,
                "Priority",
                "asc"
              );
              let emailToAdd = orderedEmails[0].Email;
              if (this.customerPrimaryEmail != emailToAdd) {
                if (emails.filter((x) => x == emailToAdd).length == 0) {
                  emails.push(emailToAdd);
                }
              }
            }
          }

          if (emails.length > 0) {
            toEmail = toEmail + ";" + emails.join(";");
          }
        }
      } else if (item.TypeId == 3 && item.ToAddress != "") {
        toEmail = item.ToAddress;
      }

      this.$Emitter.emit("openPortalUserInterface", {
        Customer: this.Customer,
        CustomerId: this.Customer.Id,
        CustomerEmail: toEmail,
        QuickEmail: item,
        IsPortalMessage: this.hasAuth(3958),
        IsPortalAuthMessage: false,
        AuthList: [],
      });
    },

    async updateTotalCreditorAfterSync(totalAmount, totalCount) {
      let isDataChanged = this.isDataChanged();
      this.Customer.CreditorTotalBalance = totalAmount;
      this.Customer.CustomerProfile.DebtAmount = totalAmount;
      this.Customer.CreditorCount = totalCount;
      if (!isDataChanged) {
        await this.sleep(500);
        this.oldValue = JSON.stringify(this.Customer);
      }
    },
    async updateTotalCreditorAmount(totalAmount) {
      let isDataChanged = this.isDataChanged();
      this.Customer.CreditorTotalBalance = totalAmount;
      this.Customer.CustomerProfile.DebtAmount = totalAmount;
      // this.handleSaveSilent();
      if (!isDataChanged) {
        this.refreshCustomerAfterCreditor(true);
        this.oldValue = JSON.stringify(this.Customer);
      } //else {
      await this.checkBlueBox();
      // }
      //let customerSaved = await this.handleSaveSilent();

      if (
        this.Customer.CustomerGlobalInformation &&
        this.Customer.CustomerGlobalInformation.Id > 0 &&
        this.Customer.CustomerGlobalInformation.DraftAmount
      ) {
        this.$store.dispatch(types.UPDATE_DRAFT_AMOUNT, {
          CustomerId: this.Customer.Id,
          DraftAmount: this.Customer.CustomerGlobalInformation.DraftAmount,
        });
      }
    },
    updateTotalCreditorCount(countVal) {
      let isDataChanged = this.isDataChanged();
      this.Customer.CreditorCount = countVal;
      if (!isDataChanged) {
        this.oldValue = JSON.stringify(this.Customer);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.headerSelectBox {
  width: 100%;
  border-radius: 6px;
  padding: 7px 12px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
  width: 100%;
}
.negotiatorSelectBox {
  width: 60%;
  border-radius: 6px;
  padding: 7px 12px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}
.assignNegButton {
  width: 35%;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 10px 0 rgb(107 85 202 / 20%);
  box-shadow: 0 2px 10px 0 rgb(107 85 202 / 20%);
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
}

.blueBoxPanel {
  width: 420px;
  position: absolute;
  top: -106px;
  right: 12px;
  z-index: 99;
  margin: 0;
  padding: 5px 6px 5px 14px;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
  background-color: #bddbf5;
}
.blueBoxRow {
  margin: 5px 0;
}

@media screen and (max-width: 1023px) {
  .blueBoxPanel {
    padding: 10px 12px 10px 40px;
    border: none;
  }
}
.blueBoxControlWrapper {
  width: 100%;
  margin: 4px 0;
}
.blueBoxLabel {
  width: 36%;
  float: left;
  margin: 5px 1%;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
  display: block;
}
.blueBoxRedLabel {
  font-size: 13px;
  color: red;
  font-weight: 600;
  padding-left: 5px;
}
.blueBoxIconWrapper {
  width: 50px;
  padding: 5px;
  float: left;
}
.blueBoxIcon {
  cursor: pointer;
  color: black;
  font-size: 20px;
  font-weight: bold;
}
.blueBoxLabelAutoSize {
  width: auto;
  float: left;
  margin: 5px 1%;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
  display: block;
}
.blueBoxDaysInput {
  width: 45px;
  float: left;
  padding: 2px 5px;
  border-radius: 4px !important;
  border: solid 1px #d1d1d1 !important;
}
.blueBoxControl {
  width: 150px;
  float: left;
  border-radius: 6px;
  padding: 5px 2%;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}
.blueMaxMonthLabelPanel {
  width: 80px;
  position: absolute;
  top: 6px;
  left: 102px;
  font-size: 8px;
  font-weight: bold;
  color: red;
  line-height: 16px;
}
.blueBoxServiceFeeLabel {
  float: right;
  font-size: 12px;
  color: red;
}
.mouthsBlueBoxControl {
  width: 50px;
  border-radius: 6px;
  padding: 5px;
  border: solid 1px #d1d1d1;
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #2a404c;
}

.blueBoxHeader {
  width: 93%;
  font-size: 18px;
  padding: 3px 0;
  line-height: 24px;
  border-bottom: 1px solid #999;
}

.o-drop__item--active {
  background-color: transparent;
  color: #000000;
}

.o-drop__item--active:hover {
  background-color: #d1d1d171;
  color: #000000;
}

.o-drop__item {
  min-width: 17rem !important;
  font-size: revert !important;
}

.editCtextRed {
  color: #ff2525;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 200px;
  border-radius: 10px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f8f8f8;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.balanceSummary {
  background: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #d1d1d1;
  padding: 5px 4px 0px;
  margin-top: 5px;
  margin-bottom: 5px;

  border-radius: 0.25rem;
}
</style>
