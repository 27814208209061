export default {
  GET_PAYMENT_CALCULATOR_PROGRAM_SUMMARY: "GET_PAYMENT_CALCULATOR_PROGRAM_SUMMARY",
  GET_EQUIFAX_DIT_LOGS: "GET_EQUIFAX_DIT_LOGS",
  GET_EQUIFAX_MFA_LOGS: "GET_EQUIFAX_MFA_LOGS",
  SEND_EQUIFAX_DIT_REQUEST: "SEND_EQUIFAX_DIT_REQUEST",
  SEND_EQUIFAX_KBA_ANSWERS: "SEND_EQUIFAX_KBA_ANSWERS",
  SEND_EQUIFAX_MFA_REQUEST: "SEND_EQUIFAX_MFA_REQUEST",
  SEND_EQUIFAX_MFA_VALIDATION_REQUEST: "SEND_EQUIFAX_MFA_VALIDATION_REQUEST",
  GET_EQUIFAX_API_SETTINGS: "GET_EQUIFAX_API_SETTINGS",
  REQUEST_EQUIFAX_CREDIT_REPORT: "REQUEST_EQUIFAX_CREDIT_REPORT",

  GET_MAIL_CONTENT_BY_ID: "GET_MAIL_CONTENT_BY_ID",
  GET_GLOBAL_FEE_TYPES_BY_GROUPING_KEYS:
    "GET_GLOBAL_FEE_TYPES_BY_GROUPING_KEYS",
  GET_PAYMENT_CALCULATOR_STATE_VALIDATION:
    "GET_PAYMENT_CALCULATOR_STATE_VALIDATION",
  GET_ALL_OPTIONAL_PRODUCT_TYPES: "GET_ALL_OPTIONAL_PRODUCT_TYPES",
  GET_BALANCE_SUMMARY: "GET_BALANCE_SUMMARY",
  GET_CUSTOMER_OPTIN_STATUS: "GET_CUSTOMER_OPTIN_STATUS",
  SUBMIT_APPLICATION: "SUBMIT_APPLICATION",
  GET_APPLICATION: "GET_APPLICATION",
  SUBMIT_EARLY_LOAN: "SUBMIT_EARLY_LOAN",
  PUSH_TO_TCI: "PUSH_TO_TCI",
  POST_LEAD_TRAC: "POST_LEAD_TRAC",
  POST_GLOBAL_MERIDIANS: "POST_GLOBAL_MERIDIANS",
  UPDATE_DRAFT_AMOUNT: "UPDATE_DRAFT_AMOUNT",
  SET_AUTH_QUESTION_PULLED: "SET_AUTH_QUESTION_PULLED",
  SEND_ARRAY_AUTH_ANSWERS: "SEND_ARRAY_AUTH_ANSWERS",
  GET_CREDIT_PULL_HISTORY: "GET_CREDIT_PULL_HISTORY",
  SET_CREDIT_PULL_HISTORY: "SET_CREDIT_PULL_HISTORY",
  DOWNLOAD_CREDIT_PULL_HISTORY: "DOWNLOAD_CREDIT_PULL_HISTORY",
  REQUEST_CREDIT_PULL_REPORT: "REQUEST_CREDIT_PULL_REPORT",
  GET_CUSTOMERDISPOSITIONS: "GET_CUSTOMERDISPOSITIONS",
  MODIFY_CUSTOMERDISPOSITIONS: "MODIFY_CUSTOMERDISPOSITIONS",
  SET_CUSTOMERDISPOSITIONS: "SET_CUSTOMERDISPOSITIONS",
  MODIFY_DISPOSITIONLOGS: "MODIFY_DISPOSITIONLOGS",
  GET_CLIENT: "GET_CLIENT",
  SET_CLIENT: "SET_CLIENT",
  DELETEEMAIL: "DELETEEMAIL",
  DELETEPHONE: "DELETEPHONE",
  GETASSIGNEDAGENTS: "GETASSIGNEDAGENTS",
  INSERT_NEW_CLIENT: "INSERT_NEW_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_WITHOUT_TAGS: "UPDATE_CLIENT_WITHOUT_TAGS",
  GET_CALL_LOGS: "GET_CALL_LOGS",
  GET_CAMPAIGNS: "GET_CAMPAIGNS",
  RESET_STATES: "RESET_STATES",
  SET_RESET_STATES: "SET_RESET_STATES",
  GET_ACTIVE_PROVIDERS: "GET_ACTIVE_PROVIDERS",
  GET_CUSTOMERDOCUMENTS: "GET_CUSTOMERDOCUMENTS",
  SET_CUSTOMERDOCUMENTS: "SET_CUSTOMERDOCUMENTS",
  GENERATE_CUSTOMER_TEMPLATE: "GENERATE_CUSTOMER_TEMPLATE",
  GET_CUSTOMER_DOCUMENT_TYPES: "GET_CUSTOMER_DOCUMENT_TYPES",
  GET_CUSTOMERDOCUMENTLIST: "GET_CUSTOMERDOCUMENTLIST",
  REFRESH_CUSTOMERDOCUMENTLIST: "REFRESH_CUSTOMERDOCUMENTLIST",
  SET_CUSTOMERDOCUMENTLIST: "SET_CUSTOMERDOCUMENTLIST",
  DOWNLOAD_CUSTOMER_DOCUMENT: "DOWNLOAD_CUSTOMER_DOCUMENT",
  DELETE_CUSTOMER_DOCUMENT: "DELETE_CUSTOMER_DOCUMENT",
  RENAME_CUSTOMER_DOCUMENT: "RENAME_CUSTOMER_DOCUMENT",
  UPLOAD_EXTRA_DOCUMENT: "UPLOAD_EXTRA_DOCUMENT",
  GET_GLOBALDOCUMENTTYPES: "GET_GLOBALDOCUMENTTYPES",
  SET_GLOBALDOCUMENTTYPES: "SET_GLOBALDOCUMENTTYPES",
  UPLOAD_DOCUMENTTOGLOBAL: "UPLOAD_DOCUMENTTOGLOBAL",
  GET_PAYMENT_CALC_VALUE: "GET_PAYMENT_CALC_VALUE",
  GET_ATTORNEY_INFO: "GET_ATTORNEY_INFO",
  VALIDATE_ACCOUNT_NUMBER: "VALIDATE_ACCOUNT_NUMBER",
  INSERT_GLOBALNEWACCOUNT: "INSERT_GLOBALNEWACCOUNT",
  INSERT_PROGRAMDRAFTS: "INSERT_PROGRAMDRAFTS",
  INSERT_GLOBALNEWACCOUNTAndPROGRAMDRAFTS:
    "INSERT_GLOBALNEWACCOUNTAndPROGRAMDRAFTS",
  GET_DOCUSIGN_PREVALIDATE: "GET_DOCUSIGN_PREVALIDATE",
  GET_DOCUSIGN_HISTORY: "GET_DOCUSIGN_HISTORY",
  GET_DOCUSIGN_ENVELOPE_STATUS: "GET_DOCUSIGN_ENVELOPE_STATUS",
  SEND_DOCUSIGN_REMINDER: "SEND_DOCUSIGN_REMINDER",
  VOID_DOCUSIGN_ENVELOPE: "VOID_DOCUSIGN_ENVELOPE",
  SEND_DOCUSIGN_ENVELOPE: "SEND_DOCUSIGN_ENVELOPE",
  GET_AVAILABLE_TEMPLATE_BY_CUSTOMER: "GET_AVAILABLE_TEMPLATE_BY_CUSTOMER",
  GET_DOCUSIGN_CONTRACT_VALIDATION: "GET_DOCUSIGN_CONTRACT_VALIDATION",
  SET_DOCUSIGN_CONTRACT_VALIDATION: "SET_DOCUSIGN_CONTRACT_VALIDATION",

  CREATE_CLIENT_INFO_CHANGES: "CREATE_CLIENT_INFO_CHANGES",
  CREATE_DOCUMENT_REVIEW_CHANGES: "CREATE_DOCUMENT_REVIEW_CHANGES",
  GET_POLICY_GROUPS_BY_CUSTOMER: "GET_POLICY_GROUPS_BY_CUSTOMER",
  GET_EDIT_CLIENT_INFO: "GET_EDIT_CLIENT_INFO",
};
