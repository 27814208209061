/**
 * @file EnvelopeAutovoidManagement/types.js
 * @description This file contains the types of the store for Envelope Autovoid Management.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 */

export default {
  GET_ENVELOPE_AUTOVOID: "GET_ENVELOPE_AUTOVOID",
  SET_ENVELOPE_AUTOVOID: "SET_ENVELOPE_AUTOVOID",
  ADD_ENVELOPE_AUTOVOID: "ADD_ENVELOPE_AUTOVOID",
  UPDATE_ENVELOPE_AUTOVOID: "UPDATE_ENVELOPE_AUTOVOID",
  DELETE_ENVELOPE_AUTOVOID: "DELETE_ENVELOPE_AUTOVOID",
};
