<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <Placeholders
      :typeId="placeholderTypeId"
      @setPlaceholderVisibility="isPlaceholdersOpen = $event"
      @setPlaceholderContent="setPlaceholder($event)"
      v-if="isPlaceholdersOpen"
    ></Placeholders>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 pt-2">
      <button
        v-if="checkAuth(4939)"
        class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
        @click="handleAddTemplate"
      >
        Add New Template
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="emailFilter"
            placeholder="Filter quick emails"
          />
        </div>
        <div class="list col-md-12" v-show="isListReady">
          <div
            class="office item row"
            v-for="(item, index) in filteredEmails"
            :key="item.Id"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving || deleting"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-mail-settings-line"></i>
              <span style="margin-left: 8px">{{ item.Title }}</span>
            </span>
          </div>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="panelContent" style="padding-top: 0">
        <div class="row">
          <div class="col-md-12">
            <label for="Title" class="form-label">Title *:</label>
          </div>
          <div class="col-md-12">
            <input
              autocomplete="off"
              class="form-control"
              v-model="selectedRow.Title"
              :class="v$.selectedRow.Title.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Title.$touch"
              id="quickEmailMTitle"
              type="text"
              placeholder="Title"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12" style="position: relative">
            <label for="Subject" class="form-label">Subject: *</label>
            <button
              type="button"
              title="subjectButton"
              id="pholder1btn-qem"
              v-on:click.prevent="() => openPlaceholders('Subject', 1)"
              class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
              style="
                position: absolute;
                right: 12px;
                top: 0;
                z-index: 100;
                background-color: #3498db;
              "
            ></button>
          </div>

          <div class="col-md-12" style="position: relative">
            <input
              autocomplete="off"
              class="form-control"
              id="quickEmailMSubject"
              type="text"
              placeholder="Subject"
              v-model="selectedRow.Subject"
              :class="v$.selectedRow.Subject.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.Subject.$touch"
            />
          </div>
          <div></div>
        </div>

        <div class="row mt-2" style="position: relative">
          <button
            v-if="selectedRow.TypeId == 3"
            type="button"
            v-on:click.prevent="() => openPlaceholders('ToAddress', 2)"
            class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
            style="
              position: absolute;
              right: 12px;
              top: 0;
              z-index: 100;
              background-color: #3498db;
            "
          ></button>
          <label for="email" class="col-md-12 is-5">Email To:</label>

          <div class="col-xl-5 col-lg-6 col-md-12">
            <select
              class="form-control"
              v-model="selectedRow.TypeId"
              @change="handleToEmailChange()"
            >
              <i class="fas fa-angle-down" aria-hidden="true"></i>
              <option
                v-for="item in quickEmailTypes"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div class="col-xl-7 col-lg-6 col-md-12">
            <input
              autocomplete="off"
              v-if="selectedRow.TypeId == 3"
              class="form-control pl-3"
              v-model="selectedRow.ToAddress"
              :class="v$.selectedRow.ToAddress.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.ToAddress.$touch"
              id="quickEmailMToAddress"
              type="text"
              placeholder="To Address"
            />
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label for="bcc" class="form-label">Bcc:</label>
            <input
              autocomplete="off"
              class="form-control"
              type="text"
              placeholder="Bcc"
              v-model="selectedRow.BccAddress"
            />
            <button
              type="button"
              v-on:click.prevent="() => openPlaceholders('BCC', 2)"
              class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
              style="
                position: absolute;
                right: 12px;
                top: 0;
                z-index: 100;
                background-color: #3498db;
              "
            ></button>
          </div>
          <div class="col-md-7">
            <label for="roles" class="form-label">Roles:</label>
            <VueMultiselect
              v-model="selectedRow.QuickEmailRoles"
              :options="sortedRoles"
              :multiple="true"
              :close-on-select="false"
              placeholder="Please Select"
              label="Name"
              track-by="Id"
              :class="v$.selectedRow.QuickEmailRoles.$error ? 'has-error' : ''"
              @blur="v$.selectedRow.QuickEmailRoles.$touch"
            />
          </div>
        </div>

        <div class="col-md-12 tabPosition mt-3">
          <ul class="nav" style="margin: 0 !important">
            <li
              v-for="tab in tabs.filter((x) => x.show)"
              :key="tab.id"
              class="nav-item tab-item"
            >
              <a
                data-toggle="tab"
                :aria-expanded="tab.active"
                v-on:click.prevent="() => handleTabChange(tab.name)"
                class="tab-link"
                :class="{ active: tab.active }"
              >
                <span class="d-sm-inline-block tab-item-label">
                  {{ tab.label }}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div v-if="tabs[0].active" class="bodyBorder noMargin row mt-2">
          <div class="col-md-12" style="position: relative">
            <button
              type="button"
              title="manage"
              id="pholder2btn"
              v-on:click.prevent="() => openPlaceholders('Body', 1)"
              class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
              style="
                position: absolute;
                right: 12px;
                top: 0;
                z-index: 100;
                background-color: #3498db;
              "
            ></button>
          </div>
          <div class="col-md-12 pl-2 form-check form-switch">
            <div class="form-check form-check-inline">
              <input
                @change="handleExternalHtmlChange(false)"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
                :checked="!selectedRow.IsExternalHtml"
              />
              <label class="form-check-label" for="inlineRadio1"
                >Text Editor</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                @change="handleExternalHtmlChange(true)"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
                :checked="selectedRow.IsExternalHtml"
              />
              <label class="form-check-label" for="inlineRadio2"
                >Import Template</label
              >
            </div>
          </div>
          <div class="col-md-12" style="color: black">
            <vue-editor
              v-if="!selectedRow.IsExternalHtml"
              id="quickEmailMtextEditor"
              v-model="editorBody"
              :customModules="customModules"
              :editorOptions="editorOptions"
              ref="editor"
            >
            </vue-editor>
            <textarea
              v-model="externalTemplateBody"
              v-if="selectedRow.IsExternalHtml"
              id="textEditor-qnm"
              class="form-control mt-2 pt-1"
              rows="12"
            ></textarea>
            <label v-if="selectedRow.IsExternalHtml" class="generalLabel mt-4"
              >Preview</label
            >
            <div
              v-if="externalTemplateBody.length > 0"
              class="mt-2"
              style="max-height: 500px; overflow: auto"
              @keypress.prevent="() => {}"
              @keydown.prevent="() => {}"
              @keyup.prevent="() => {}"
              @contextmenu.prevent="() => {}"
            >
              <div
                class="col-md-12"
                style="
                  border: 1px solid #ccc;
                  padding: 10px;
                  pointer-events: none !important;
                "
                v-if="selectedRow.IsExternalHtml"
                v-html="externalTemplateBody"
              ></div>
            </div>
          </div>
        </div>

        <div v-if="tabs[1].active" class="bodyBorder noMargin row mt-2">
          <div class="col-md-12" style="position: relative">
            <button
              type="button"
              title="manage"
              id="pholder2btn"
              v-on:click.prevent="() => openPlaceholders('Body', 1)"
              class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
              style="
                position: absolute;
                right: 12px;
                top: 0;
                z-index: 100;
                background-color: #3498db;
              "
            ></button>
          </div>
          <div class="col-md-12 pl-2 form-check form-switch">
            <div class="form-check form-check-inline">
              <input
                @change="handleExternalHtmlSpanishChange(false)"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
                :checked="!selectedRow.IsExternalHtmlSpanish"
              />
              <label class="form-check-label" for="inlineRadio1"
                >Text Editor</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                @change="handleExternalHtmlSpanishChange(true)"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
                :checked="selectedRow.IsExternalHtmlSpanish"
              />
              <label class="form-check-label" for="inlineRadio2"
                >Import Template</label
              >
            </div>
          </div>
          <div class="col-md-12" style="color: black">
            <vue-editor
              v-if="!selectedRow.IsExternalHtmlSpanish"
              id="quickEmailMtextEditor"
              v-model="editorBodySpanish"
              :customModules="customModules"
              :editorOptions="editorOptions"
              ref="editor"
            >
            </vue-editor>
            <textarea
              v-if="selectedRow.IsExternalHtmlSpanish"
              v-model="spanishExternalTemplateBody"
              id="textEditor-qnm"
              class="form-control mt-2 pt-1"
              rows="12"
            ></textarea>

            <div
              v-if="spanishExternalTemplateBody.length > 0"
              style="margin-top: 30px"
            >
              <label
                v-if="selectedRow.IsExternalHtmlSpanish"
                class="generalLabel"
                >Preview</label
              >
              <div
                class="col-md-12"
                style="border: 1px solid #ccc; padding: 30px"
                v-if="selectedRow.IsExternalHtmlSpanish"
                v-html="spanishExternalTemplateBody"
              ></div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 mt-2"
          style="display: flex; justify-content: flex-end; gap: 10px"
        >
          <button
            v-if="selectedRow.Id && checkAuth(9866)"
            class="btn btn-danger"
            style="width: 200px"
            @click="() => handleDelete(selectedRow.Id)"
          >
            Delete
          </button>
          <button
            v-if="checkAuth(9126) || !selectedRow.Id"
            class="btn btn-success"
            :disabled="v$.$invalid || saving || deleting"
            style="width: 200px"
            @click="handleSave"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { VueEditor, Quill } from "vue3-editor";
import { mapState } from "vuex";
import types from "./types";
import globalTypes from "@/store/types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import * as placeholdersTypes from "@/components/Placeholders/types";
import Placeholders from "@/components/Placeholders/Placeholders";
import useValidate from "@vuelidate/core";
import _orderby from "lodash";
import { email, required } from "vuelidate/lib/validators";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import adminApi from "@/services/admin/api"
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize-module";
import StyledImageFormat from "@/components/VueEditor/StyledImageFormat";

import 'quill-image-uploader/dist/quill.imageUploader.min.css';

const init = {
  Id: 0,
  Title: "",
  Subject: "",
  Body: "",
  SpanishContent: "",
  ToAddress: "",
  TypeId: 1,
  Email: "",
  QuickEmailRoles: [],
  IsExternalHtml: false,
  IsExternalHtmlSpanish: false,
  BccAddress: "",
};

/**
 * @see https://github.com/kensnyder/quill-image-resize-module/issues/10
 */
Quill.register('formats/image', StyledImageFormat, true)

export default {
  name: "QuickEmailManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: {
    Placeholders,
    VueEditor,
    VueMultiselect,
  },
  data() {
    return {
      isListReady: false,
      customModules: [
        { alias: 'imageResize', module: ImageResize },
        { alias: 'imageUploader', module: ImageUploader }
      ],
      editorOptions: {
        modules: {
          imageResize: {},
          imageUploader: {
            upload: this.uploadFileToRemote
          }
        }
      },
      editorBody: "",
      editorBodySpanish: "",
      externalTemplateBody: "",
      spanishExternalTemplateBody: "",
      textArea: true,
      v$: useValidate(),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      htmlForEditor: "",
      emailFilter: "",
      activeItem: null,
      saving: false,
      deleting: false,
      isEditing: false,
      isCancelledBeforeSave: false,
      oldValue: JSON.stringify(init),
      selectedInput: "Body",
      cursorPosition: 0,
      isPlaceholdersOpen: false,
      placeholderTypeId: 1,
      tabs: [
        {
          id: 1,
          name: "tab1",
          label: "Body",
          active: true,
          show: true,
        },
        {
          id: 2,
          name: "tab2",
          label: "Spanish Body",
          active: false,
          show: true,
        },
      ],
      subOfficeIds: [],
      userOfficeId: null,
      hasAccessOtherOffices: false,
      hasAccessSubOffices: false,
    };
  },
  computed: mapState({
    roles: (state) => state.globals.roles,
    sortedRoles() {
      return _orderby.orderBy(
        this.filterRolesByOfficeAccess(this.roles),
        "Name",
        "asc"
      );
    },
    quickEmailTypes: (state) => state.quickEmailManagement.quickEmailTypes,
    list: (state) => state.quickEmailManagement.list,

    filteredEmails() {
      let emailList = deepCopy(this.list);
      if (!this.hasAccessOtherOffices) {
        for (let item of emailList) {
          if (item.QuickEmailRoles == null) {
            item.QuickEmailRoles = [];
          }
        }
      }
      return emailList
        .filter((x) =>
          !this.hasAccessOtherOffices
            ? x.QuickEmailRoles &&
              x.QuickEmailRoles.length > 0 &&
              !x.QuickEmailRoles.some(
                (y) => !this.sortedRoles.map((z) => z.Id).includes(y.Id)
              )
            : emailList
        )
        .filter(
          (item) =>
            item.Title.toLowerCase().indexOf(this.emailFilter.toLowerCase()) >
            -1
        );
    },
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(types.GET_QUICKEMAILS),
      this.$store.dispatch(types.GET_QUICKEMAILTYPES),
    ]);
    this.hasAccessOtherOffices = this.canSeeAllOffices();
    this.hasAccessSubOffices = this.canSeeSubOffices();

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      this.subOfficeIds = userInfo.subOffices;
      this.userOfficeId = userInfo.officeId;
    }

    this.isListReady = true;
  },

  methods: {
    filterRolesByOfficeAccess(roles) {
      if (this.hasAccessOtherOffices) {
        return roles;
      } else if (
        this.hasAccessSubOffices &&
        this.subOfficeIds &&
        this.subOfficeIds.length > 0
      ) {
        this.subOfficeIds.push(this.userOfficeId);
        return roles.filter((x) => this.subOfficeIds.includes(x.OfficeId));
      } else {
        return roles.filter((x) => x.OfficeId == this.userOfficeId);
      }
    },
    setBodyContent(item) {
      if (item.IsExternalHtml == true) {
        item.Body = this.externalTemplateBody;
      } else {
        item.Body = this.editorBody;
      }

      if (item.IsExternalHtmlSpanish == true) {
        item.SpanishContent = this.spanishExternalTemplateBody;
      } else {
        item.SpanishContent = this.editorBodySpanish;
      }
    },

    async uploadFileToRemote(file) {
      const formdata = new FormData()
      formdata.append('file', file)

      try {
        const { data } = await adminApi.uploadPublicEmailImage(formdata)
        const { ImageURL } = data.Data

        return ImageURL
      } catch (error) {
        this.$swal("Error!", "There was an error uploading your image", "error")
      }
    },

    handleTabChange(tab) {
      let temp = [...this.tabs];
      temp = temp.map((item) => {
        item.active = item.name === tab ? true : false;
        return item;
      });
      this.tabs = [...temp];
    },
    handleExternalHtmlChange(val) {
      if (this.selectedRow) {
        this.selectedRow.IsExternalHtml = val;
      }
    },
    handleExternalHtmlSpanishChange(val) {
      if (this.selectedRow) {
        this.selectedRow.IsExternalHtmlSpanish = val;
      }
    },
    setFocus(event) {
      this.quill = event;
    },
    selectionChange(event) {
      if (event && event.index) this.cursorPosition = event.index;
    },
    textChange(event) {
      if (event && event.ops) {
        if (event.ops.length > 0) this.cursorPosition = event.ops[0].retain;
      }
    },
    handleToEmailChange() {
      this.selectedRow.ToAddress = "";
    },
    setPlaceholder(value) {
      navigator.clipboard.writeText(value);
    },
    openPlaceholders(vm, typeId) {
      this.isPlaceholdersOpen = true;
      this.selectedInput = vm;
      this.placeholderTypeId = typeId;
    },
    reset() {
      this.activeItem = null;
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
    async handleAddTemplate() {
      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
        this.editorBody = "";
      }

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
        this.isEditing = true;
      }
    },
    async handleListSelection(row, index) {
      this.handleTabChange("tab1");
      this.setBodyContent(this.selectedRow);

      if (
        this.cleanHtml(this.oldValue) !=
          this.cleanHtml(JSON.stringify(this.selectedRow)) &&
        this.cleanHtml(JSON.stringify(this.selectedRow)) !=
          this.cleanHtml(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;

        this.selectedRow = row;

        if (this.selectedRow.IsExternalHtml == true) {
          this.externalTemplateBody = this.selectedRow.Body;
          this.editorBody = "";
        } else {
          this.editorBody = this.selectedRow.Body;
          this.externalTemplateBody = "";
        }

        if (this.selectedRow.IsExternalHtmlSpanish == true) {
          this.spanishExternalTemplateBody = this.selectedRow.SpanishContent;
          this.editorBodySpanish = "";
        } else {
          this.editorBodySpanish = this.selectedRow.SpanishContent;
          this.spanishExternalTemplateBody = "";
        }

        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;

      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeItem = row.Id;
        this.selectedRow = row;

        if (this.selectedRow.IsExternalHtml == true) {
          this.externalTemplateBody = this.selectedRow.Body;
          this.editorBody = "";
        } else {
          this.editorBody = this.selectedRow.Body;
          this.externalTemplateBody = "";
        }

        if (this.selectedRow.IsExternalHtmlSpanish == true) {
          this.spanishExternalTemplateBody = this.selectedRow.SpanishContent;
          this.editorBodySpanish = "";
        } else {
          this.editorBodySpanish = this.selectedRow.SpanishContent;
          this.spanishExternalTemplateBody = "";
        }

        this.isCancelledBeforeSave = false;
      }

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    getImagesBlobIds() {
      const editor = this.$refs.editor?.quill

      if(!editor) {
        return []
      }

      const operations = editor.getContents().ops
      const images = operations.reduce((accumulator, currentOp) => {
        if (currentOp.insert && Object.hasOwn(currentOp.insert, "image")) {
          const url = new URL(currentOp.insert.image)
          const id = url.pathname.split("/").pop()

          accumulator.push(id)
        }

        return accumulator
      }, [])
      
      return images
    },

    async handleSave(values) {
      this.selectedRow.Body = this.selectedRow.IsExternalHtml
        ? this.externalTemplateBody
        : this.editorBody;
      if (this.selectedRow.IsExternalHtml) {
        this.editorBody = "";
      } else {
        this.externalTemplateBody = "";
      }

      this.selectedRow.SpanishContent = this.selectedRow.IsExternalHtmlSpanish
        ? this.spanishExternalTemplateBody
        : this.editorBodySpanish;
      if (this.selectedRow.IsExternalHtmlSpanish) {
        this.editorBodySpanish = "";
      } else {
        this.spanishExternalTemplateBody = "";
      }

      if (this.oldValue === JSON.stringify(this.selectedRow)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }

      this.saving = true;

      let err, result, type;

      let isQuickEmailExists =
        this.list.filter(
          (x) => x.Title.toLowerCase() == this.selectedRow.Title.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id != 0) {
        type = types.PUT_QUICKEMAIL;

        isQuickEmailExists =
          this.list.filter(
            (x) => x.Title.toLowerCase() == this.selectedRow.Title.toLowerCase()
          ).length > 1;
      } else {
        type = types.POST_QUICKEMAIL;
      }
      if (isQuickEmailExists && type == types.POST_QUICKEMAIL) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This Template name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      if (this.selectedRow.Body.trim() == "") {
        this.$swal("Warning!", "Body is required!", "warning");
        this.saving = false;
        return;
      }

      let tempRoleList = null;
      if (this.selectedRow.QuickEmailRoles) {
        tempRoleList = this.selectedRow.QuickEmailRoles;
        this.selectedRow.QuickEmailRoles = this.selectedRow.QuickEmailRoles.map(
          (x) => x.Id
        );
      }

      const images = this.getImagesBlobIds();

      [err, result] = await this.$store.dispatch(type, { Media: images, ...this.selectedRow });
      this.selectedRow.QuickEmailRoles = tempRoleList;
      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.POST_QUICKEMAIL) {
          this.selectedRow.Id = result.Data.Id;
          this.list.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
        }
      } else if (err === null) { 
        this.$swal("Warning!", "No Changes Detected!", "warning");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleDelete(Id) {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.list.indexOf(this.selectedRow);
            let err, result;
            [err, result] = await this.$store.dispatch(
              types.DELETE_QUICKEMAIL,
              Id
            );
            if (!err) {
              this.reset();
            }
            if (result) {
              this.list.splice(rowIndex, 1);
              this.reset();

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Title: { required },
        Subject: { required },
        ToAddress: {
          isEmail(emailVal) {
            let rtn = false;

            if (this.selectedRow.TypeId == 3) {
              if (emailVal != "") {
                if (emailVal.includes("#")) {
                  rtn = emailVal.toLowerCase().includes("email");
                } else {
                  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (regex.test(emailVal)) rtn = true;
                }
              }
            } else {
              rtn = true;
            }
            return rtn;
          },
        },
        QuickEmailRoles: {
          hasRole() {
            let rtn = false;
            if (this.hasAccessOtherOffices) rtn = true;
            else {
              if (
                this.selectedRow.QuickEmailRoles &&
                this.selectedRow.QuickEmailRoles.length > 0
              ) {
                rtn = true;
              }
            }
            return rtn;
          },
        },
      },
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.bodyBorder {
  border: 1px solid rgb(235, 235, 235);
  padding-bottom: 10px;
  border-radius: 5px;
  box-shadow: #fafafa 0px 0px 10px;
}
.tabPosition {
  position: relative;
  top: 5px;
  right: 7px;
}

.multiselect__content-wrapper {
  overflow-x: hidden;
}
.has-error {
  border: 2px solid red !important;
}

.adminRow {
  width: 96%;
  margin: 25px 2%;
}

.textScroll {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
}

.subjectDiv {
  display: flex;
  justify-content: space-between;
}
.emailToDiv {
  display: flex;
  align-items: baseline;
}
.rolesDiv {
  display: flex;
  align-items: baseline;
}
.multiSelect {
  padding-left: 38px;
  padding-top: 20px;
}
.addCancelButton {
  display: flex;
  justify-content: flex-end;
}
.is-success {
  width: 175px;
}
.is-danger {
  width: 175px;
  margin-left: 15px;
}
.o-table__td {
  cursor: pointer;
}
.o-table__td:hover {
  background: #3bafda;
  color: #fff;
  transform: scale(1.05);
}
@media only screen and (max-width: 433px) {
  .rolesDiv {
    display: flex;
    flex-direction: column;
  }
  .emailToDiv {
    display: flex;
    flex-direction: column;
  }
  .emailTo {
    margin-left: 40px;
    margin-bottom: 20px;
  }
  .addCancelButton {
    display: flex;
    justify-content: center;
    padding-left: 23px;
  }
  .o-table__td {
    font-family: "Open Sans";
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
}
</style>
