<!--
 * @file  EarlyLoanLogReport.vue
 * @description This component is created to display the Early Loan Log Report.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 * 
 * @section Usage
 * This component can be used to display the report for logs created when submitting a early loan request.
 -->
<template>
  <div class="col-md-12">
    <BaseModal
      v-if="showFormData"
      @close="showFormData = false"
      title="Log Form Data"
    >
      <div class="col-md-12">
        <table style="width: 100%">
          <tr v-for="(value, key) in formData" :key="value">
            <th>{{ startCase(key) }}</th>
            <td>{{ formatValue(value, key) }}</td>
          </tr>
        </table>
      </div>
    </BaseModal>
    <ReportSummary
      :exportData="filteredeEarlyLoanLogList"
      reportName="EarlyLoanLogReport"
      :recordCount="filteredeEarlyLoanLogList.length"
      @updateLoadingStatus="reportLoading = $event"
      @setPayload="setPayload"
      @clearReport="clearReport"
      @setPagination="setPagination"
      :loadingSpinner="loadingSpinner"
    />
    <div
      class="datagrid-table gcsTabsTableScroll report-wrapper"
      :style="'max-height: ' + reportsPanelHeight + 'px; overflow-y:auto;'"
    >
      <o-table
        :data="filteredeEarlyLoanLogList"
        :paginated="isPaginated && filteredeEarlyLoanLogList.length > 0"
        :per-page="perPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :sticky-header="true"
      >
        <o-table-column
          v-for="(_filter, key) in filters"
          :field="key"
          :label="startCase(key)"
          :key="key"
          sortable
          searchable
        >
          <template v-slot:searchable>
            <div>
              <input
                type="text"
                class="datagrid-table"
                v-model="filters[key]"
              />
            </div>
          </template>
          <template v-slot="props">
            <span
              v-if="key === 'CustomerId'"
              @click="viewCustomer(props.row)"
              :class="
                formatMixin.getTagReportHyperLinkClass(props.row.CustomerId)
              "
            >
              <span>
                {{ props.row.CustomerId }}
              </span>
            </span>
            <span v-else>
              {{ formatValue(props.row[key], key) }}
            </span>
          </template>
        </o-table-column>
        <o-table-column
          label="View Form Data"
          position="centered"
          v-slot="props"
        >
          <button
            @click="openFormDataPopup(props.row.CustomerData)"
            class="btn btn-info ml-4"
          >
            <i class="fa fa-table"></i>
          </button>
        </o-table-column>
        <o-table-column
          label="View Customer"
          position="centered"
          v-slot="props"
        >
          <button @click="viewCustomer(props.row)" class="btn btn-info ml-4">
            <i class="far fa-address-card"></i>
          </button>
        </o-table-column>
      </o-table>
      <section
        v-if="filteredeEarlyLoanLogList.length == 0"
        class="section"
        style="text-align: center"
      >
        <div class="content has-text-grey has-text-centered mb-4">
          <p class="noMargin">
            <i
              style="font-size: 24px; color: #939393"
              class="ri-question-line"
            ></i>
          </p>
          <p class="noMargin">Nothing here.</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import BaseModal from "@/components/Base/BaseModal.vue";
import { dateFormat } from "@/helpers/dateHelpers";
import fMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import { startCase } from "lodash";
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ReportSummary from "./ReportSummary.vue";
import types from "./types";

const utilMixin = defineComponent(utilitiesMixin).methods;
const formatMixin = defineComponent(fMixin).methods;
const emitter = inject("emitter");

const store = useStore();

const setPayload = async (value) => {
  loadingSpinner.value = true;
  await store.dispatch(types.GET_EARLY_LOAN_LOG_REPORT, value);
  loadingSpinner.value = false;
};

const filters = ref({
  CustomerId: "",
  CustomerName: "",
  Campaign: "",
  Office: "",
  SentBy: "",
  SentDate: "",
  EarlyLoanProvider: "",
});

const reportLoading = ref(false);
const loadingSpinner = ref(false);
const isPaginated = ref(true);
const isPaginationSimple = ref(false);
const paginationPosition = ref("both");
const reportsPanelHeight = ref(300);
const perPage = ref(200);

const earlyLoanLogList = computed(() =>
  store.state.reports.earlyLoanLogList.map((el) => {
    return {
      CustomerData: el.CustomerData,
      EarlyLoanProvider: el.earlyLoanProvider,
      CustomerId: el.CustomerData.CustomerId,
      CustomerName: `${el.CustomerData.FirstName} ${el.CustomerData.LastName}`,
      Office: el.OfficeName,
      Campaign: el.CampaignName,
      SentBy: el.CreatedBy,
      SentDate: el.creationDate,
    };
  })
);

const filteredeEarlyLoanLogList = computed(() =>
  earlyLoanLogList.value.length > 0
    ? earlyLoanLogList.value.filter((item) => {
        return Object.keys(filters.value).every((key) =>
          utilMixin.filterString(
            formatValue(item[key], key),
            filters.value[key]
          )
        );
      })
    : []
);

const showFormData = ref(false);
const formData = ref({});
const openFormDataPopup = (earlyLoanData) => {
  formData.value = earlyLoanData;
  showFormData.value = true;
};

const clearReport = () => {
  store.dispatch(types.RESET_REPORTS);
};

const setPagination = (data) => {
  isPaginated.value = data;
};

const formatValue = (value, key) => {
  if (value) {
    switch (key) {
      case "AnnualIncome":
      case "TotalAmount":
      case "DebtAmount":
        return formatMixin.formatMoney(value);

      case "SentDate":
        return dateFormat({ date: value });

      case "Ssn":
        return formatMixin.formatSSN(value);

      case "CellPhone":
      case "HomePhone":
      case "PhoneNumber":
        return formatMixin.formatPhone(value);

      case "OwnHome":
        return formatMixin.formatYesNo(value);

      default:
        return value;
    }
  }
  return "";
};

const viewCustomer = (customer) => {
  emitter.emit("viewCustomer", {
    customerId: customer.CustomerId,
    customerName: `${customer.CustomerName}`,
  });
};

onMounted(() => {
  reportsPanelHeight.value = utilMixin.setReportGridHeight(
    window.innerHeight,
    document.getElementById("reportsSummary")
  );

  utilMixin.fixStickyHeaders();
});
</script>

<style scoped>
td,
th {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ps {
  max-height: 600px;
}
</style>
