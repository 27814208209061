<!--
 * @file PremloPopup.vue
 * @description This component is created for displaying the form for creating an early loan request for Premlo provider.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 * 
 * @section Usage
 * This component can be used to display Premlo early loan request.
 *
 * @section Props
 * - `data` (Object): The Object containing all the customer related data.
 -->
<template>
  <LoanApplicationPopup
    @close="emit('close')"
    @submit="submit"
    title="Premlo"
    :data="{ ...data, ...fieldValues }"
  >
    <template #phoneNumber>
      <div class="col-md-4">
        <label class="generalLabel">Home Phone</label>
        <input
          class="form-control"
          v-model="fieldValues.HomePhone"
          v-myMask="{ mask: ['(999) 999-9999'] }"
          :disabled="data.HomePhone"
        />
      </div>
      <div class="col-md-4">
        <label class="generalLabel">Cell Phone</label>
        <input
          class="form-control"
          v-model="fieldValues.CellPhone"
          v-myMask="{ mask: ['(999) 999-9999'] }"
          :disabled="data.CellPhone"
        />
      </div>
    </template>
    <template #otherFields>
      <div class="col-md-4">
        <label class="generalLabel">Loan Purpose</label>
        <input class="form-control" v-model="fieldValues.LoanPurpose" />
      </div>

      <div class="col-md-4">
        <label for="sm-creditScore" class="generalLabel">Credit Score</label>
        <input
          type="text"
          id="sm-creditScore"
          class="form-control"
          autocomplete="none"
          placeholder="Credit Score"
          v-myMask="{
            alias: 'numeric',
            prefix: '',
            min: 300,
            max: 850,
            rightAlign: false,
            radixPoint: '',
          }"
          v-model="fieldValues.CreditRating"
        />
      </div>

      <div class="col-md-4">
        <label class="generalLabel">Occupation</label>
        <select class="form-control" v-model="fieldValues.Occupation">
          <option
            v-for="(value, key) in occupationValues"
            :value="key"
            :key="value"
          >
            {{ value }}
          </option>
        </select>
      </div>

      <div class="col-md-4">
        <label class="generalLabel">Own Home</label>
        <div class="d-flex justify-content-around">
          <div>
            <input
              type="radio"
              id="yes"
              :value="true"
              v-model="fieldValues.OwnHome"
            />
            <label for="yes" class="ml-1">Yes</label>
          </div>
          <div>
            <input
              type="radio"
              id="no"
              :value="false"
              v-model="fieldValues.OwnHome"
            />
            <label for="no" class="ml-1">No</label>
          </div>
        </div>
      </div>
    </template>
  </LoanApplicationPopup>
</template>

<script setup>
import placeholderTypes from "@/components/Placeholders/types";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import clientTypes from "@/views/agent/EditClient/types";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";
import LoanApplicationPopup from "./LoanApplicationPopup.vue";
import dropdownOptions from "./dropdownOptions.json";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["close"]);

const { proxy } = getCurrentInstance();
const utilMixin = defineComponent(utilitiesMixin).methods;

const occupationValues = dropdownOptions.Premlo.Occupation;

const fieldValues = ref({
  LoanPurpose: "",
  CreditRating: 300,
  OwnHome: props.data.OwnHome,
  Occupation: Object.keys(occupationValues)[0],
  HomePhone: props.data.HomePhone,
  CellPhone: props.data.CellPhone,
});

const pSid = "c902f27b-4719-45e5-82c0-ce23e73f8053";
const pOid = "3H";

const submit = async (data) => {
  const LoanData = data.LoanData;
  let linkStr = "https://secure.debttrakker.net/leadimport/debtimport.aspx?";
  linkStr +=
    "pFname=" +
    LoanData.FirstName +
    "&pLName=" +
    LoanData.LastName +
    "&pEmailAddress=" +
    LoanData.Email;
  if (LoanData.HomePhone) {
    linkStr += "&pHomePhone=" + LoanData.HomePhone;
  }
  if (LoanData.CellPhone) {
    linkStr += "&pCellPhone=" + LoanData.CellPhone;
  }
  linkStr +=
    "&pDob=" +
    LoanData.DateOfBirth +
    "&pAddress=" +
    LoanData.Address +
    "&pSsn=" +
    LoanData.Ssn +
    "&pLoanAmt=" +
    LoanData.DebtAmount +
    "&pTotalIncome=" +
    LoanData.AnnualIncome +
    "&pPurposeOfLoan=" +
    LoanData.LoanPurpose +
    "&pFicoScore=" +
    LoanData.CreditRating +
    "&pOccupationId=" +
    LoanData.Occupation +
    "&pSelfEmployed=" +
    (LoanData.Occupation == "25" ? 1 : 0) +
    "&pOwnHome =" +
    (LoanData.OwnHome ? 1 : 0) +
    "&pAgentName=" +
    agentName.value +
    "&pSID=" +
    pSid +
    "&pOID=" +
    pOid +
    "&pCity=" +
    LoanData.City +
    "&pState=" +
    LoanData.State +
    "&pZipCode=" +
    LoanData.Zip;

  let link = document.createElement("a");
  link.href = linkStr;
  link.target = "_blank";
  link.click();

  let [err, result] = await store.dispatch(clientTypes.SUBMIT_EARLY_LOAN, {
    Data: {
      ...LoanData,
      Occupation: Object.values(occupationValues)[LoanData.Occupation - 1],
    },
    EarlyLoanProvider: "Premlo",
    CampaignId: data.CampaignId,
  });

  if (result) {
    proxy.$swal("Success!", "Loan Application submitted", "success");
    emit("close");
  } else {
    let errMsg = utilMixin.getApiErrorMessage(err);
    proxy.$swal("Error!", errMsg, "error");
  }
};

const agentName = ref();
const store = useStore();
const userInfo = computed(() => JSON.parse(sessionStorage.getItem("userInfo")));

onMounted(async () => {
  let [err, result] = await store.dispatch(
    placeholderTypes.PROCESS_PLACEHOLDERS,
    {
      UserId: userInfo.value.userId,
      CustomerId: props.data.CustomerId,
      Text: "##AGENT_FULL_NAME##",
    }
  );

  if (result) {
    agentName.value = result.Data.Text;
  }
});
</script>
