<!--
 * @file EnvelopeAutovoidManagement.vue
 * @description This component is created for displaying the view for adding a new setting per office for envelope autovoid.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 * 
 * @section Usage
 * This component can be used to display settings view for envelope autovoid.
 -->
<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 pt-2">
      <button
        class="col-xl-12 btn btn-primary waves-effect waves-light addTemplateButton"
        @click="addNewSetting()"
      >
        Add New Setting
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="settingFilter"
            placeholder="Filter settings"
          />
        </div>
        <div class="list col-md-12">
          <div
            class="office item row"
            v-for="(item, index) in filteredSettingList"
            :key="item.Id"
            :class="{ active: activeItem === item.SettingId }"
            :disabled="saving || deleting"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span
              :class="{ adminActiveMenuItem: activeItem === item.SettingId }"
            >
              <i class="ri-settings-2-fill"></i>
              <span style="margin-left: 8px">{{ item.OfficeName }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" v-if="isFormVisible">
      <div class="panelContent" style="padding-top: 0">
        <div
          class="d-flex justify-content-between col-xl-8 col-lg-8 col-md-12 col-sm-12"
        >
          <div v-if="isFormEdit" class="item-group mr-2">
            <div>
              <label for="dpm-emailSubject" class="form-label">Office *</label>
            </div>
            <div>
              <input
                disabled
                type="text"
                autocomplete="off"
                class="form-control"
                :value="selectedRow.OfficeName"
                id="dpm-emailSubject"
              />
            </div>
          </div>

          <div v-else class="item-group mr-2">
            <div>
              <label for="dpm-name" class="form-label">Office *</label>
            </div>
            <div>
              <VueMultiselect
                v-model="selectedRow.OfficeId"
                :options="offices"
                multiple
                :close-on-select="false"
                placeholder="Please Select"
                label="Name"
                track-by="Id"
              />
            </div>
          </div>
          <div class="item-group">
            <div>
              <label for="dpm-emailSubject" class="form-label"
                >Expiration time in hours *</label
              >
            </div>
            <div>
              <input
                type="text"
                autocomplete="off"
                class="form-control"
                v-model="selectedRow.HourInterval"
                id="dpm-emailSubject"
                placeholder="In hours"
                v-myMask="{
                  alias: 'numeric',
                  min: 1,
                  max: 99,
                  digits: 0,
                  rightAlign: false,
                }"
              />
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="offset-xl-4 col-md-2">
            <button
              class="btn btn-success form-control"
              @click="handleSave()"
              :disabled="saving || deleting"
            >
              Save
            </button>
          </div>
          <div class="col-md-2">
            <button
              class="btn btn-danger form-control"
              v-if="selectedRow.SettingId"
              @click="() => handleDelete(selectedRow.SettingId)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { cleanHtml } from "@/helpers/textHelper";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import VueMultiselect from "vue-multiselect";
import { useStore } from "vuex";
import types from "./types";

const store = useStore();
const utilMixin = defineComponent(utilitiesMixin).methods;

const initialValue = { OfficeId: [], HourInterval: 48 };
const selectedRow = ref({ ...initialValue });
const valueToRevert = ref({ ...initialValue });
const settingFilter = ref("");
const activeItem = ref(null);
const isFormVisible = ref(false);
const saving = ref(false);
const deleting = ref(false);
const oldValue = ref(JSON.stringify(initialValue));
const { proxy } = getCurrentInstance();

const envelopeSettingsList = computed(
  () => store.state.envelopeAutovoidManagement.envelopeSettingList
);

const disabledOffices = computed(() =>
  envelopeSettingsList.value.map((setting) => setting.OfficeId)
);

const offices = computed(() => {
  return store.state.globals.offices.map((el) => ({
    ...el,
    $isDisabled: disabledOffices.value.includes(el.Id),
  }));
});

const filteredSettingList = computed(() =>
  envelopeSettingsList.value.filter(
    (item) =>
      item.OfficeName?.toLowerCase().indexOf(
        settingFilter.value.toLowerCase()
      ) > -1
  )
);

const isFormEdit = computed(() => !!selectedRow.value?.SettingId);

onMounted(async () => {
  await store.dispatch(types.GET_ENVELOPE_AUTOVOID);
  await store.dispatch(globalTypes.GET_OFFICE_NAMES);
});

const checkUnsavedChanges = async () => {
  const hasUnsavedChanges =
    cleanHtml(oldValue.value) !==
      cleanHtml(JSON.stringify(selectedRow.value)) &&
    cleanHtml(JSON.stringify(selectedRow.value)) !==
      cleanHtml(JSON.stringify(initialValue));

  if (hasUnsavedChanges) {
    const dialog = await proxy.$swal({
      title: "You have unsaved changes",
      text: "You will lose it if you don't save",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, continue without saving!",
    });
    return { dialog: dialog.isConfirmed };
  } else {
    return false;
  }
};

const addNewSetting = async () => {
  const valuesChanged = await checkUnsavedChanges();

  if (valuesChanged.dialog === undefined || valuesChanged.dialog) {
    selectedRow.value = { ...initialValue };
    activeItem.value = null;
    isFormVisible.value = true;
  } else if (valuesChanged.dialog == false) {
    return;
  }
};

const handleListSelection = async (row, index) => {
  const valuesChanged = await checkUnsavedChanges();
  if (valuesChanged.dialog === undefined || valuesChanged.dialog) {
    valueToRevert.value = row;
    oldValue.value = JSON.stringify(row);
    activeItem.value = row.SettingId;
    selectedRow.value = row;
  } else if (valuesChanged.dialog == false) {
    return;
  }
  isFormVisible.value = true;
};

const handleSave = async () => {
  const type = selectedRow.value.SettingId
    ? types.UPDATE_ENVELOPE_AUTOVOID
    : types.ADD_ENVELOPE_AUTOVOID;

  const hasValidHourInterval = selectedRow.value.HourInterval > 0;
  const hasOfficeId = selectedRow.value.OfficeId.length > 0;

  const isValid =
    (type === types.UPDATE_ENVELOPE_AUTOVOID && hasValidHourInterval) ||
    (type === types.ADD_ENVELOPE_AUTOVOID &&
      hasOfficeId &&
      hasValidHourInterval);

  if (isValid) {
    const payload = {
      HourInterval: selectedRow.value.HourInterval,
      ...(isFormEdit.value
        ? { SettingId: selectedRow.value.SettingId }
        : { OfficeId: selectedRow.value.OfficeId.map((el) => el.Id) }),
    };

    saving.value = true;
    let err, result;

    [err, result] = await store.dispatch(type, payload);

    if (result) {
      proxy.$swal("Success!", result.Message, "success");
      await store.dispatch(types.GET_ENVELOPE_AUTOVOID);
      if (type == types.ADD_ENVELOPE_AUTOVOID) {
        selectedRow.value = getLastSetting();
        activeItem.value = selectedRow.value.SettingId;
      }
      oldValue.value = JSON.stringify(selectedRow.value);
    } else {
      handleError(err);
    }
    saving.value = false;
  } else {
    proxy.$swal("Check your values!", "", "warning");
  }
};

const getLastSetting = () =>
  filteredSettingList.value.length > 0
    ? filteredSettingList.value.at(-1)
    : filteredSettingList.value[0];

const handleDelete = (SettingId) => {
  deleting.value = true;
  proxy.$swal
    .fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
    .then(async (dialog) => {
      if (dialog.value) {
        let err, result;

        [err, result] = await store.dispatch(types.DELETE_ENVELOPE_AUTOVOID, {
          SettingId,
        });
        if (result) {
          reset();
          proxy.$swal("Deleted!", result.Message, "success");
        } else {
          handleError(err);
        }
      }
    });
  deleting.value = false;
};

const reset = async () => {
  activeItem.value = null;
  selectedRow.value = { ...initialValue };
  isFormVisible.value = false;
  await store.dispatch(types.GET_ENVELOPE_AUTOVOID);
};

const handleError = (err) => {
  const errMsg = utilMixin.getApiErrorMessage(err);
  proxy.$swal("Error!", errMsg, "error");
};
</script>

<style scoped>
.item-group {
  width: 100%;
}

:deep(.form-control:disabled) {
  background: #f1efef;
}
</style>
