/**
 * @file textHelpers.js
 * @description This file contains helper functions for text validation and manipulation.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 */

/**
 * Checks if a given string contains only alphabetic characters (A-Z, a-z).
 *
 * @param {string} str - The string to check.
 * @returns {boolean} Returns true if the string contains only alphabetic characters; otherwise, false.
 */
export const isOnlyAlphaChars = (str) => {
  const regex = /^[a-zA-Z ]*$/;
  return regex.test(str);
};

/**
 * Removes all HTML tags and extra spaces from the given text.
 *
 * This function takes a string input and returns a cleaned version of that string
 * by removing any HTML tags and excess whitespace.
 *
 * @param {string} text - The input string potentially containing HTML tags and spaces.
 * @returns {string} Returns the cleaned string with HTML tags removed and extra spaces trimmed.
 */
export const cleanHtml = (text) => text.replace(/( |<([^>]+)>)/gi, "");
