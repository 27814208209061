<template>
  <div class="row admin-page officePage">
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        @click="handleAddOffice"
        v-if="checkAuth(1454)"
      >
        Add New Office
      </button>
      <div class="filter form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="none"
            type="text"
            class="form-control"
            v-model="officeFilter"
            placeholder="Filter office"
            v-if="checkAuth(1454) || checkAuth(55011)"
          />
          <div class="mt-2">
            <input
              id="chkCreateDuplicateTask"
              type="checkbox"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
              v-model="isRetiredCBox"
            />

            <label
              for="chkCreateDuplicateTask"
              class="generalLabel"
              style="font-weight: normal"
              >Show Retired Offices</label
            >
          </div>
        </div>
      </div>
      <div class="list col-md-12" v-show="isListReady">
        <div
          class="office item row"
          v-for="(office, index) in filteredRows"
          :key="office.Id"
          :class="{ active: activeOffice === office.Id }"
          @click="() => handleOfficeSelection(office, index)"
          :disabled="saving || deleting"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: activeOffice === office.Id }">
            <i
              class="ri-home-8-line"
              :style="
                office.IsRetired == true ? 'color: #f1556c' : 'color: #6c757d'
              "
            ></i>
            <span
              style="margin-left: 8px"
              :style="
                office.IsRetired == true ? 'color: #f1556c' : 'color: #6c757d'
              "
              >{{ office.Name }}</span
            >
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        v-on:keydown.enter.prevent="() => {}"
        v-if="isEditing"
        class="form-horizontal formWrapper panelWrapper float-left"
        role="form"
        ref="observer"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="officeName">Name *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                type="text"
                id="nameInput-om"
                class="form-control"
                v-model="selectedRow.Name"
                :class="v$.selectedRow.Name.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Name.$touch"
                placeholder="Enter your name"
                autocomplete="none"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="addressinput">Address *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="none"
                type="text"
                id="addressLine1"
                class="form-control"
                placeholder="Enter your address"
                :class="v$.selectedRow.Address.Line1.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Address.Line1.$touch"
                v-model="selectedRow.Address.Line1"
                v-on:input="checkLength"
              />
              <p
                class="noMargin noPadding"
                style="color: #ff7979"
                v-if="isMaxLimitReachedAdress"
              >
                Maximum 50 characters are allowed!
              </p>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="addressinput2">Address 2</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="none"
                type="text"
                id="addressinput2"
                class="form-control"
                placeholder="Enter your address"
                v-model="selectedRow.Address.Line2"
                v-on:input="checkLength"
              />
              <p
                class="noMargin noPadding"
                style="color: #ff7979"
                v-if="isMaxLimitReachedAdress2"
              >
                Maximum 50 characters are allowed!
              </p>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">Timezone *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <select
                class="form-control"
                v-model="selectedRow.Address.TimeZoneId"
              >
                <option
                  v-for="item in timezones"
                  :value="item.Id"
                  :key="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="cityinput">City *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="none"
                type="text"
                class="form-control"
                id="officeCity"
                placeholder="Enter your city"
                :class="v$.selectedRow.Address.City.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Address.City.$touch"
                v-model="selectedRow.Address.City"
                v-on:input="checkLength"
              />
              <p
                class="noMargin noPadding"
                style="color: #ff7979"
                v-if="isMaxLimitReachedCity"
              >
                Maximum 50 characters are allowed!
              </p>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="state">State *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <select
                id="officeMstates"
                class="form-control"
                v-model="selectedRow.Address.StateId"
              >
                <option v-for="item in states" :key="item.Id" :value="item.Id">
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="zipinput">Zip *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="none"
                type="numeric"
                class="form-control"
                id="officeZip"
                placeholder="Enter your zip"
                v-model="selectedRow.Address.Zip"
                :class="v$.selectedRow.Address.Zip.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Address.Zip.$touch"
                v-myMask="{ mask: ['99999'] }"
                @change="zipChanged"
              />
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="phoneinput">Phone *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="none"
                type="text"
                class="form-control"
                id="phoneInput"
                v-model="selectedRow.Phone"
                v-myMask="{ mask: ['(999) 999-9999'] }"
                @change="phoneChanged"
                placeholder="Enter your phone"
                :class="v$.selectedRow.Phone.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Phone.$touch"
              />
              <input
                autocomplete="none"
                type="hidden"
                v-model="selectedRow.Phone"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="faxinput">Fax</label>
            </div>

            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="none"
                type="text"
                class="form-control"
                id="faxinput"
                v-model="selectedRow.Fax"
                @change="faxChanged"
                v-myMask="{ mask: ['(999) 999-9999'] }"
                placeholder="Enter your fax"
              />
            </div>

            <div class="col-md-2">
              <label class="form-label" for="defaultPolicyGroupId"
                >Default Policy Group</label
              >
            </div>
            <div class="col-md-4">
              <select
                v-model="selectedRow.DefaultPolicyGroupId"
                class="form-control"
                id="defaultPolicyGroupId"
              >
                <option :value="null">Select</option>
                <option
                  v-for="item in sortedPolicyGroups(policyGroups)"
                  :key="item.PolicyGroupId"
                  :value="item.PolicyGroupId"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="email">Email*</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="none"
                type="text"
                id="office-email"
                class="form-control"
                placeholder="Enter your email"
                v-model="selectedRow.Email"
                :class="v$.selectedRow.Email.$error ? 'has-error' : ''"
                @change="v$.selectedRow.Email.$touch"
              />
            </div>

            <div class="form-group col-md-6 row noPadding">
              <div class="col-md-7">
                <label class="form-label mt-1" for="defaultPolicyGroupId"
                  >Auto Dismiss Notifications Timeout (days)
                </label>
              </div>
              <div class="col-xl-5 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <CustomNumericInput
                  :value="selectedRow.OfficeSetting.NotificationDismissTimeout"
                  @updateValue="updateNotificationDismissTimeout"
                  :minNumber="0"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label" for="defaultPolicyGroupId"
                >Support Emails</label
              >
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <textarea
                class="form-control textareaInput"
                rows="2"
                placeholder="Support Emails"
                v-model="selectedRow.SupportEmails"
              ></textarea>
              <p style="font-size: 10px">
                Please separate multiple emails with a comma.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <input
              type="checkbox"
              class="checkbox-input generalCheckBox"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
              "
              v-model="selectedRow.IsRetired"
            />

            <label class="form-label">Is Retired</label>
          </div>

          <div class="form-group row pl-2 pr-2">
            <div
              class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12 legend mt-4"
              style="border: 1px solid #b4b5b7"
            >
              <h4 style="color: #6c757d">SMTP Settings</h4>
              <div class="form-group row">
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                  <label class="form-label" for="email">Smtp Host</label>
                </div>
                <div class="col-xl-8 col-lg-9 col-md-6 col-sm-12 col-xs-12">
                  <input
                    autocomplete="none"
                    type="text"
                    id="officeSmptHost"
                    class="form-control"
                    v-model="selectedRow.OfficeSmtpSetting.SmtpHost"
                    placeholder="Host Address"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                  <label class="form-label" for="smptPort">Smtp Port</label>
                </div>
                <div class="col-xl-8 col-lg-9 col-md-6 col-sm-12 col-xs-12">
                  <CustomNumericInput
                    :value="selectedRow.OfficeSmtpSetting.SmtpPort"
                    @updateValue="updateSmtpPort"
                    :minNumber="1"
                    :maxNumber="65535"
                    :placeholder="'SMTP Port'"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                  <label class="form-label" for="smptUseSSL">Use SSL</label>
                </div>
                <div class="col-xl-8 col-lg-9 col-md-6 col-sm-12 col-xs-12">
                  <input
                    autocomplete="none"
                    type="checkbox"
                    id="smptUseSSL"
                    v-model="selectedRow.OfficeSmtpSetting.UseSSL"
                    class="checkbox-input generalCheckBox"
                    style="
                      border-radius: 4px !important;
                      border: solid 1px #d1d1d1 !important;
                    "
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-4 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                  <label class="form-label" for="smtpPassword"
                    >Smtp Password</label
                  >
                </div>
                <div class="col-xl-8 col-lg-9 col-md-6 col-sm-12 col-xs-12">
                  <input
                    autocomplete="none"
                    type="text"
                    id="officeSmtpPassword"
                    class="form-control"
                    v-model="selectedRow.OfficeSmtpSetting.SmtpPassword"
                    placeholder="SMTP Password"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div
                  class="col-xl-4 col-lg-3 col-md-6 col-sm-12 col-xs-12"
                >
                  <label class="form-label" for="smtpUserName"
                    >Smtp User Name</label
                  >
                </div>
                <div class="col-xl-8 col-lg-9 col-md-6 col-sm-12 col-xs-12">
                  <input
                    autocomplete="none"
                    type="text"
                    id="officeSmtpUserName"
                    class="form-control"
                    v-model="selectedRow.OfficeSmtpSetting.SmtpUsername"
                    placeholder="SMTP User Name"
                  />
                </div>
              </div>



                <div class="form-group row">
                  <div class="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <label class="form-label" for="smtpUseSendGrid"
                    >Use SendGrid</label
                  >
                </div>
                <div class="col-xl-8 col-lg-9 col-md-6 col-sm-6 col-xs-12">
                  <input
                    autocomplete="none"
                    type="checkbox"
                    id="smtpUseSendGrid"
                    v-model="selectedRow.OfficeSmtpSetting.UseSendGrid"
                    class="checkbox-input generalCheckBox"
                    style="
                      border-radius: 4px !important;
                      border: solid 1px #d1d1d1 !important;
                    "
                  />
                </div>
                </div>

                <div
                class="form-group row"
                v-if="selectedRow.OfficeSmtpSetting.UseSendGrid"
              >
                <div
                  class="col-xl-4 col-lg-3 col-md-12 col-sm-12 col-xs-12"
                >
                  <label class="form-label">Authenticated Email *</label>
                </div>
                <div class="col-xl-8 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                  <input
                    autocomplete="none"
                    type="text"
                    v-model="
                      selectedRow.OfficeSmtpSetting.SendGridAuthenticatedEmail
                    "
                    class="form-control"
                    placeholder="Authenticated Email"
                    :class="
                      v$.selectedRow.OfficeSmtpSetting
                        .SendGridAuthenticatedEmail.$error
                        ? 'has-error'
                        : ''
                    "
                    @change="
                      v$.selectedRow.OfficeSmtpSetting
                        .SendGridAuthenticatedEmail.$touch;
                    "
                  />
                </div>
              </div>
             
              
            
            
            </div>
            <div v-if="officeNames && officeNames.length > 0 && selectedRow.Id" class="offset-xl-1 col-xl-4  col-lg-12 col-md-12 col-sm-12 col-xs-12 legend mt-4"   style="border: 1px solid #b4b5b7">
              <h4 style="color: #6c757d">Sub Offices</h4>
                <div class="d-flex align-items-center" v-for="item in officeNames.filter(x=> x.Id != selectedRow.Id)" :key="item.Id">
                  <div style="width: 20px; height: 40px;  margin: 0 12px">
                    <input
                      autocomplete="off"
                      type="checkbox"
                      class="form-control"
                      :id="item.Id"
                      :value="item.Id"
                      v-model="selectedRow.SubOfficeIds"
                    />
                  </div>
                  <div class="flex-fill">
                    <label 
                >{{  item.Name }}</label
              >
                  </div>
                
             
                </div>
            </div>
          </div>
          <div class="form-group row">
            
          </div>

          <div class="form-group row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <button
                type="button"
                id="officeSaveButton"
                @click="handleSave"
                :disabled="v$.$invalid || saving || deleting"
                class="animated-btn btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
              <button
                type="button"
                v-if="selectedRow.Id"
                :disabled="saving || deleting"
                @click="() => handleDelete(selectedRow.Id)"
                class="animated-btn btn btn-danger btn-bordered-danger waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="deleting" class="fas fa-spinner mr-1"></i>
                Delete
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { email, required } from "vuelidate/lib/validators";
import formatMixin from "@/mixins/formatMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";
import CustomNumericInput from "@/components/CustomNumericInput";
import _orderby from "lodash";

const init = {
  Id: null,
  Name: "",
  Phone: "",
  Fax: "",
  CompanyId: 1,
  DefaultPolicyGroupId: null,
  Address: {
    City: "",
    Line1: "",
    Line2: "",
    StateId: 1,
    TimeZoneId: 1,
    Zip: "",
  },
  Email: "",
  OfficeSmtpSetting: {
    SmtpHost: "",
    SmtpPassword: "",
    SmtpPort: 0,
    SmtpUsername: "",
    UseSSL: false,
    UseSendGrid: false,
    SendGridAuthenticatedEmail: "",
  },
  OfficeSetting: {
    NotificationDismissTimeout: 0,
  },
  SupportEmails: "",
  IsRetired: false,
  SubOfficeIds:[],
};

export default {
  name: "OfficeManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: { CustomNumericInput },
  data() {
    return {
      isListReady:false,
      invalidEmails: [],
      isMaxLimitReachedAdress: false,
      isMaxLimitReachedAdress2: false,
      isMaxLimitReachedCity: false,
      v$: useValidate(),
      selectedRow: deepCopy(init),
      isEditing: false,
      saving: false,
      deleting: false,
      activeOffice: null,
      officeFilter: "",
      oldValue: "",
      valueToRevert: deepCopy(init),
      isCancelledBeforeSave: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
      policyGroups: [],
      isRetiredCBox: false,
      userSubOffices:[]
    };
  },
  computed: mapState({
    timezones: (state) => state.globals.timezones,
    states: (state) => state.globals.states,
    list: (state) => state.officeManagement.list,

    filteredOffice() {


      let rtn = [];
      if(this.checkAuth(1454))
      {
        rtn = this.list;
      }
      else if(this.checkAuth(55011))
      {
        rtn = this.userSubOffices ? this.list.filter(x=> this.userSubOffices.includes(x.Id) || x.Id== this.userOfficeId) : this.list.filter(x=> x.Id == this.userOfficeId)
      }
      else
      {
        rtn = this.list.filter(x=> x.Id == this.userOfficeId);
      }

      return _orderby.orderBy(
        rtn.filter((x) => x.Name.toLowerCase().indexOf(this.officeFilter.toLowerCase()) > -1
        ),
        "Name",
        "asc"
      );
    },
    filteredRows() {
      if (this.isRetiredCBox) {
        return this.filteredOffice;
      } else {
        return this.filteredOffice.filter((row) => !row.IsRetired);
      }
    },
    officeNames() {
      return _orderby.orderBy(
        this.list.filter(x=> !x.IsRetired).map(function (x) {
          return {Id:x.Id, Name:x.Name}
        }),
        "Name",
        "asc"
      );
    },
    
  }),
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_OFFICES),
      this.$store.dispatch(globalTypes.GET_TIMEZONES),
      this.$store.dispatch(globalTypes.GET_STATES),
    ]);

    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].OfficeSmtpSetting == null) {
        this.list[i].OfficeSmtpSetting = deepCopy(init.OfficeSmtpSetting);
      }
    }

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null && userInfo.moduleFunctions != null) {
      this.userOfficeId = userInfo.officeId;
      this.userSubOffices = userInfo.subOffices
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    if (!this.hasAccessOtherOffices) {
      let indx = this.list.findIndex((x) => x.Id == this.userOfficeId);
      if (indx > -1) {
        this.handleOfficeSelection(this.list[indx]);
      }
    }
    this.isListReady=true;
  },

  methods: {

    updateUserSubOffices(subOffices){

      this.userSubOffices = subOffices;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo != null && userInfo.moduleFunctions != null) {
        
          userInfo.subOffices = subOffices;
          sessionStorage.setItem("userInfo",JSON.stringify(userInfo));
        }

    },
    checkLength() {
      if (this.selectedRow.Address.Line1.length > 50) {
        this.selectedRow.Address.Line1 = this.selectedRow.Address.Line1.slice(
          0,
          50
        );
        this.isMaxLimitReachedAdress = true;
      } else {
        this.isMaxLimitReachedAdress = false;
      }

      if (this.selectedRow.Address.Line2.length > 50) {
        this.selectedRow.Address.Line2 = this.selectedRow.Address.Line2.slice(
          0,
          50
        );
        this.isMaxLimitReachedAdress2 = true;
      } else {
        this.isMaxLimitReachedAdress2 = false;
      }

      if (this.selectedRow.Address.City.length > 50) {
        this.selectedRow.Address.City = this.selectedRow.Address.City.slice(
          0,
          50
        );
        this.isMaxLimitReachedCity = true;
      } else {
        this.isMaxLimitReachedCity = false;
      }
    },

    sortedPolicyGroups(policyGroups) {
      return _orderby.orderBy(policyGroups, "Name", "asc");
    },

    phoneChanged(event) {
      if (event.target.value && event.target.value != "") {
        this.selectedRow.Phone = event.target.value;
      }
    },
    faxChanged(event) {
      if (event.target.value && event.target.value != "") {
        this.selectedRow.Fax = event.target.value;
      }
    },
    async zipChanged(event) {
      if (event.target.value && event.target.value != "") {
        this.selectedRow.Address.Zip = event.target.value;
      }

      if (
        this.selectedRow.Address.Zip &&
        this.selectedRow.Address.Zip.length == 5
      ) {
        let result = await this.$store.dispatch(
          globalTypes.GET_ZIP_DATA,
          this.selectedRow.Address.Zip
        );

        if (result && result.Data && result.Data.length > 0) {
          if (this.selectedRow.Address.City == "") {
            this.selectedRow.Address.City = result.Data[0].PrimaryCity;
          }
          this.selectedRow.Address.StateId = result.Data[0].StateId;
        }
      }
    },

    async handleAddOffice() {
      if (
        this.cleanFormatPhone(this.oldValue) !=
          this.cleanFormatPhone(JSON.stringify(this.selectedRow)) &&
        this.cleanFormatPhone(JSON.stringify(this.selectedRow)) !=
          this.cleanFormatPhone(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {
        this.selectedRow = deepCopy(init);
        this.activeOffice = null;
        this.isEditing = true;
      }
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();

        this.isCancelledBeforeSave = false;
        this.selectedRow = deepCopy(init);
        this.activeOffice = null;
        this.isEditing = true;
      }
    },
    validateEmail(email) {
      let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(email);
    },

    async handleSave(values) {

      if (
        this.cleanFormatPhone(this.oldValue) ==
        this.cleanFormatPhone(JSON.stringify(this.selectedRow))
      ) {
        this.$swal("Warning!", "No Changes Detected!", "warning");

        return;
      }

      if (this.selectedRow.SupportEmails) {
        let emails = this.selectedRow.SupportEmails.split(",").map((email) =>
          email.trim()
        );
        this.invalidEmails = emails.filter(
          (email) => !this.validateEmail(email)
        );

        if (this.invalidEmails.length > 0) {
          this.$swal(
            "Warning!",
            "There is an incorrect email in Support Emails!",
            "warning"
          );
          return;
        }
      }

      this.saving = true;

      let err, result, type;

      let isOfficeExists =
        this.list.filter(
          (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
        ).length > 0;

      if (this.selectedRow.Id) {
        type = types.PUT_OFFICE;
        isOfficeExists =
          this.list.filter(
            (x) => x.Name.toLowerCase() == this.selectedRow.Name.toLowerCase()
          ).length > 1;
      } else {
        type = types.POST_OFFICE;
      }
      if (isOfficeExists) {
        this.saving = false;
        this.$swal(
          "Warning!",
          "This office name is already exists in list!",
          "warning"
        );
        this.saving = false;
        return;
      }

      [err, result] = await this.$store.dispatch(type, this.selectedRow);

      if (result) {
        this.$swal("Success!", result.Message, "success");
        if (type == types.POST_OFFICE) {
          this.selectedRow.Id = result.Data.Id;
          this.list.push(this.selectedRow);
          this.oldValue = JSON.stringify(this.selectedRow);
        } else {
          this.oldValue = JSON.stringify(this.selectedRow);
          if(this.selectedRow.Id == this.userOfficeId)
          {
            this.updateUserSubOffices(this.selectedRow.SubOfficeIds);
          }
        }

   
        //this.reset();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.saving = false;
    },
    async handleDelete(Id) {
      this.deleting = true;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (dialog) => {
          if (dialog.value) {
            let rowIndex = this.list.indexOf(this.selectedRow);
            let err, result;
            [err, result] = await this.$store.dispatch(types.DELETE_OFFICE, Id);
            if (!err) {
              this.reset();
            }
            if (result) {
              this.list.splice(rowIndex, 1);
              this.reset();

              this.$swal("Deleted!", result.Message, "success");
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
            }
          }
        });
      this.deleting = false;
    },
    handleCancel() {
      this.isEditing = false;
    },
    updateSmtpPort(value) {
      this.selectedRow.OfficeSmtpSetting.SmtpPort = value;
    },

    updateNotificationDismissTimeout(value) {
      this.selectedRow.OfficeSetting.NotificationDismissTimeout = value;
    },

    async handleOfficeSelection(row, index) {
      if (
        this.cleanFormatPhone(this.oldValue) !=
          this.cleanFormatPhone(JSON.stringify(this.selectedRow)) &&
        this.cleanFormatPhone(JSON.stringify(this.selectedRow)) !=
          this.cleanFormatPhone(JSON.stringify(init))
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            this.isCancelledBeforeSave = true;
          } else {
            this.isCancelledBeforeSave = false;
            return;
          }
        });
      } else {

        this.valueToRevert = deepCopy(row);
        this.oldValue = JSON.stringify(row);
        this.activeOffice = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }
      this.isEditing = true;
      if (this.isCancelledBeforeSave) {
        this.selectedRow = deepCopy(this.valueToRevert);

        let indx = this.list.findIndex((x) => x.Id == this.valueToRevert.Id);
        this.list[indx] = this.valueToRevert;
        this.list.push();
        //this.list.set(indx,this.valueToRevert);

        this.valueToRevert = deepCopy(row);

        this.oldValue = JSON.stringify(row);
        this.activeOffice = row.Id;

        this.selectedRow = row;
        this.isCancelledBeforeSave = false;
      }

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }

      if (this.selectedRow.Id != null && this.selectedRow.Id > 0) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          globalTypes.GET_COMPANY_POLICY_GROUP_IDS,
          { OfficeId: this.selectedRow.Id, OfficeIds: [] }
        );

        if (result) {
          this.policyGroups = result.Data;
        }
      }
    },
    reset() {
      this.activeOffice = null;
      //this.$store.dispatch(types.GET);
      this.selectedRow = deepCopy(init);
      this.isEditing = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Name: { required },
        Email: { required, email },
        Address: {
          Line1: { required },
          City: { required },
          Zip: { required },
        },
        OfficeSmtpSetting: {
          SendGridAuthenticatedEmail: {
            isValid(email) {
              if (!this.selectedRow.OfficeSmtpSetting.UseSendGrid) return true;
              else {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return email != "" && regex.test(email);
              }
            },
          },
        },
        Phone: {
          isPhone(phone) {
            phone = this.cleanFormatPhone(phone);
            const regex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
            return regex.test(phone);
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.has-error {
  border: 2px solid red;
}
</style>
