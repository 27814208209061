export default {

  GET_ASSIGNED_NEGOTIATOR_TASK:"GET_ASSIGNED_NEGOTIATOR_TASK",
  ACCEPT_NEW_SETTLEMENT:"ACCEPT_NEW_SETTLEMENT",
  INSERT_1ON1_NEGOTIATOR_LOG:"INSERT_1ON1_NEGOTIATOR_LOG",
  GET_ONE_TIME_SETTLEMENT_FEE:"GET_ONE_TIME_SETTLEMENT_FEE",
  GET_PROCESSED_FEES: "GET_PROCESSED_FEES",
  GET_AUDITOR_USERS: "GET_AUDITOR_USERS",
  GET_PAYMENT_TYPES_BY_POLICY_GROUP: "GET_PAYMENT_TYPES_BY_POLICY_GROUP",
  BULK_ASSIGN_NEGOTIATOR_TASK: "BULK_ASSIGN_NEGOTIATOR_TASK",
  GET_ATTONERY_INFO: "GET_ATTONERY_INFO",
  GET_ALL_NEGOTIATOR_TASK: "GET_ALL_NEGOTIATOR_TASK",
  GET_SETTLEMENT_APPROVALS: "GET_SETTLEMENT_APPROVALS",
  GET_NEGOTIATOR_TASK_DETAILS: "GET_NEGOTIATOR_TASK_DETAILS",
  SAVE_NEGOTIATOR_TASK: "SAVE_NEGOTIATOR_TASK",
  POSTPONE_NEGOTIATOR_TASK: "POSTPONE_NEGOTIATOR_TASK",
  REMOVE_NEGOTIATOR_TASK: "REMOVE_NEGOTIATOR_TASK",
  REQUEST_SETTLEMENT: "REQUEST_SETTLEMENT",
  ATTORNEY_TASK_DECLINE: "ATTORNEY_TASK_DECLINE",
  ATTORNEY_TASK_APPROVE: "ATTORNEY_TASK_APPROVE",
  GET_PAYMENT_TYPES: "GET_PAYMENT_TYPES",
  SET_PAYMENT_TYPES: "SET_PAYMENT_TYPES",
  GET_NEGOTIATOR_PHASES: "GET_NEGOTIATOR_PHASES",
  SET_NEGOTIATOR_PHASES: "SET_NEGOTIATOR_PHASES",
  GET_NEGOTIATOR_CSR_CORRESPONDENCE: "GET_NEGOTIATOR_CSR_CORRESPONDENCE",
  SET_NEGOTIATOR_CSR_CORRESPONDENCE: "SET_NEGOTIATOR_CSR_CORRESPONDENCE",
  POST_NEGOTIATOR_TASKS_SEND_EMAIL:"POST_NEGOTIATOR_TASKS_SEND_EMAIL",
  NEG_PHASE_GCS_VALIDATE: "NEG_PHASE_GCS_VALIDATE",
  SEARCH_PAYEE: "SEARCH_PAYEE",
  ADD_NEW_PAYEE: "ADD_NEW_PAYEE",
  GET_PAYEE_DETAILS: "GET_PAYEE_DETAILS",
  UPDATE_BANK: "UPDATE_BANK",
  ADD_NEW_BANK: "ADD_NEW_BANK",
  REMOVE_BANK: "REMOVE_BANK",
  ADD_NEW_CONTACT: "ADD_NEW_CONTACT",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  REMOVE_CONTACT: "REMOVE_CONTACT",
  ADD_NEW_ADDRESS: "ADD_NEW_ADDRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  REMOVE_ADDRESS: "REMOVE_ADDRESS",
  ADD_SETTLEMENT: "ADD_SETTLEMENT",
  GET_CASHFLOW_CREDITOR: "GET_CASHFLOW_CREDITOR",
  PHONE_PAY_INFO_EDIT_NEGO_TASK: "PHONE_PAY_INFO_EDIT_NEGO_TASK",
};
