<template>
  <div class="row admin-page officePage">
    <div v-if="saving" class="loading">
      <div class="text-center" style="margin-top: 350px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12">
      <div class="table is-striped table is-hoverable">
        <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
          <input
            autocomplete="off"
            type="text"
            class="form-control"
            v-model="xchangeFilter"
            placeholder="Filter"
          />
        </div>
        <div class="list col-md-12" v-show="isListReady">
          <a
            class="item row"
            v-for="(item, index) in filteredHasAccessOtherOffices"
            :class="{ active: activeItem === item.Id }"
            :disabled="saving || deleting"
            :key="item.Id"
            @click="() => handleListSelection(item, index)"
            :style="[
              index % 2 === 0
                ? { 'background-color': '#F1EFEF' }
                : { 'background-color': '#FFFFFF' },
            ]"
          >
            <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
              <i class="ri-shuffle-fill" style="vertical-align: middle"></i>
              <span style="margin-left: 10px">{{ item.Name }}</span>
            </span>
          </a>

          <section
            v-if="filteredHasAccessOtherOffices.length == 0"
            class="section"
            style="text-align: center"
          >
            <div class="content has-text-grey has-text-centered mb-4">
              <p class="noMargin">
                <i
                  style="font-size: 24px; color: #939393"
                  class="ri-question-line"
                ></i>
              </p>
              <p class="noMargin">Nothing here.</p>
            </div>
          </section>
        </div>
        <div
          v-if="isMobileDevice()"
          style="
            border-top: 1px solid rgb(0, 0, 0, 0);
            margin: 20px 0 0 9px;
            padding-bottom: 20px;
          "
          class="col-md-12 scrollToSelectedItem"
        ></div>
      </div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12" v-if="isEditing">
      <div class="col-md-12">
        <ul
          class="nav nav-tabs"
          style="border-bottom: 2px solid #3bafda !important"
        >
          <li v-for="tab in tabs" :key="tab.id" class="nav-item tab-item">
            <a
              data-toggle="tab"
              :aria-expanded="tab.id === activeDay"
              v-on:click.prevent="changeActive(tab.id)"
              class="tab-link"
              :class="{ active: tab.id === activeDay }"
            >
              <span class="d-sm-inline-block tab-item-label">
                {{ tab.label }}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div>
        <div class="col-md-12 row mt-3">
          <label class="" for="displayAsWarningPopUp">Enabled:</label>
          <input
            v-model="selectedRow.IsEnabled"
            type="checkbox"
            class="checkbox-input generalCheckBox float-right ml-2"
            style="
              margin-top: 1px;
              border-radius: 4px !important;
              border: solid 1px #d1d1d1 !important;
            "
          />
        </div>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 noPadding row">
        <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 noPadding">
          <div class="col-12 mt-2">
            <label class="col-form-label">Start Time:</label>
          </div>
          <div class="form-group row">
            <div class="col-xl-11 col-lg-11 col-md-4 col-sm-4 noMargin">
              <DatePicker
                mode="time"
                :locale="'en-US'"
                v-model="selectedRow.StartTime"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    autocomplete="off"
                    class="form-control ml-2"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="Start Time"
                  />
                </template>
              </DatePicker>
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 noPadding">
          <div class="col-12 mt-2">
            <label class="col-form-label">End Time:</label>
          </div>

          <div class="form-group row">
            <div class="col-xl-11 col-lg-11 col-md-4 col-sm-4 noMargin">
              <DatePicker
                mode="time"
                :locale="'en-US'"
                v-model="selectedRow.EndTime"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    autocomplete="off"
                    class="form-control ml-2"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="End Time"
                  />
                </template>
              </DatePicker>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="col-md-12 mt-2">
          <label class="col-form-label">Time Zone:</label>
        </div>

        <div class="form-group row">
          <div class="col-xl-4 col-lg-8 col-md-5 col-sm-5 noMargin">
            <select
              v-model="selectedRow.TimeZoneId"
              class="ml-2"
              :class="
                selectedRow.IsEnabled == false
                  ? 'readonlyInput form-control'
                  : 'form-control'
              "
              :disabled="selectedRow.IsEnabled == false"
              id="negotiators"
            >
              <option :value="0">Please Select</option>
              <option v-for="item in timezones" :key="item.Id" :value="item.Id">
                {{ item.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div class="col-md-12 mt-2">
          <label class="col-form-label">Max Daily Assignment:</label>
        </div>

        <div class="form-group row">
          <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 noMargin">
            <input
              type="text"
              class="form-control ml-2"
              v-model="selectedRow.MaxAssignment"
              v-myMask="{ alias: 'numeric', rightAlign: false, min: 0 }"
            />
          </div>
        </div>
      </div>

      <div
        class="offset-xl-4 col-xl-2 offset-lg-6 col-lg-3 col-md-6 col-sm-12 mt-3"
      >
        <button
          @click="handleSave"
          type="button"
          style="width: 100%"
          class="btn btn-success"
          :disabled="saving"
        >
          <i v-if="saving" class="spinner-border spinner-border-sm"></i>
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import types from "./types";
import deepCopy from "@/helpers/deepCopy";
import formatMixin from "@/mixins/formatMixin.js";
import _orderby from "lodash";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import globalTypes from "@/store/types";
import { DatePicker } from "v-calendar";

const init = {
  Id: 0,
  CompanyId: 0,
  OfficeId: 0,
  DayOfWeek: 0,
  IsEnabled: false,
  StartTime: "08:00:00",
  EndTime: "17:00:00",
  TimeZoneId: 0,
  MaxAssignment: 0,
};

export default {
  name: "POASettingsManagement",
  mixins: [formatMixin, utilitiesMixin],
  components: { DatePicker },
  computed: mapState({
    timezones: (state) => state.globals.timezones,
    offices: (state) => state.globals.offices,

    filteredHasAccessOtherOffices() {

      let offices = [];

      if(this.canSeeAllOffices())
      {
        offices = deepCopy(this.offices);
      }
      else if(this.canSeeSubOffices())
      {
        let userOffices = this.offices.filter(x=> x.Id == this.userOfficeId);
        if(userOffices.length > 0)
        {
            offices.push(userOffices[0]); 
        }
        let userSubOffices = this.offices.filter(x=> this.userSubOffices.includes(x.Id));
        for(let item of userSubOffices)
        {
          offices.push(item);
        }
      }
      else
      {
         offices = this.offices.filter(x=> x.Id == this.userOfficeId);
      }

      return _orderby.orderBy(offices,"Name","asc");
    },
  }),
  data() {
    return {
      isListReady:false,
      companyId: 0,
      xChangeList: [],
      saving: false,
      deleting: false,
      isEditing: false,
      activeItem: null,
      isCancelledBeforeSave: false,
      xchangeFilter: "",
      oldValue: deepCopy(init),
      selectedRow: deepCopy(init),
      valueToRevert: deepCopy(init),
      isLoading: false,
      activeDay: 0,
      userOfficeId: 0,
      userSubOffices:[],
      hasAccessOtherOffices: false,
      oldDayData: null,
      tabs: [
        {
          id: 0,
          name: "sunday",
          label: "Sunday",
          iconName: "",
        },
        {
          id: 1,
          name: "monday",
          label: "Monday",
          iconName: "",
        },
        {
          id: 2,
          name: "tuesday",
          label: "Tuesday",
          iconName: "",
        },
        {
          id: 3,
          name: "wednesday",
          label: "Wednesday",
          iconName: "",
        },
        {
          id: 4,
          name: "thursday ",
          label: "Thursday",
          iconName: "",
        },
        {
          id: 5,
          name: "friday",
          label: "Friday",
          iconName: "",
        },
        {
          id: 6,
          name: "saturday",
          label: "Saturday",
          iconName: "",
        },
      ],
    };
  },

  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_TIMEZONES),
      this.$store.dispatch(globalTypes.GET_OFFICE_NAMES),
      this.getXchangeList(),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      this.userOfficeId = userInfo.officeId;
      this.companyId = userInfo.companyId;
      this.userSubOffices = userInfo.subOffices;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    if (!this.hasAccessOtherOffices) {
      let indx = this.offices.findIndex((x) => x.Id == this.userOfficeId);
      if (indx > -1) {
        this.handleListSelection(this.offices[indx]);
      }
    }
    this.isListReady=true;
  },
  
  methods: {
    async getXchangeList() {
      let err, result;

      [err, result] = await this.$store.dispatch(types.GET_XCHANGE);
      if (result) {
        this.xChangeList = deepCopy(result.Data);
      }
    },
    getDayData() {
      let rtn = null;
      let data = this.xChangeList.filter((x) => x.OfficeId == this.activeItem);

      if (data) {
        rtn = data.find((day) => day.DayOfWeek == this.activeDay);
      }
      return rtn;
    },

    async changeActive(tabId) {
      let tempData = deepCopy(this.selectedRow);

      if (this.selectedRow && this.selectedRow.StartTime) {
        tempData.StartTime = this.dateToTime(this.selectedRow.StartTime);
      }
      if (this.selectedRow && this.selectedRow.EndTime) {
        tempData.EndTime = this.dateToTime(this.selectedRow.EndTime);
      }
      if (this.selectedRow && this.selectedRow.MaxAssignment) {
        tempData.MaxAssignment = parseInt(this.selectedRow.MaxAssignment);
      }

      let isCancelled = false;
      if (
        this.oldDayData != null &&
        JSON.stringify(this.oldDayData) != JSON.stringify(tempData)
      ) {
        await this.$swal({
          title: "You have unsaved changes",
          text: "You will lose it if you don't save",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, continue without saving!",
        }).then(async (dialog) => {
          if (dialog.value) {
            isCancelled = false;
          } else {
            isCancelled = true;
          }
        });
      }
      if (isCancelled) {
        return;
      }

      this.activeDay = tabId;
      this.selectedRow.DayOfWeek = tabId;
      this.bindDayData(this.selectedRow.OfficeId);
    },
    timeToDate(time) {
      let date = new Date();
      let [startHour, startMin] = time.split(":");
      date.setHours(startHour);
      date.setMinutes(startMin);
      date.setSeconds(0);

      return date;
    },
    dateToTime(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (hours > 0) return hours + ":" + minutes + ":" + seconds;
      else return minutes + ":" + seconds;
    },
    async handleListSelection(row) {
      if (this.oldDayData != null) {
        let tempData = deepCopy(this.selectedRow);
        if (this.selectedRow && this.selectedRow.StartTime) {
          tempData.StartTime = this.dateToTime(this.selectedRow.StartTime);
        }
        if (this.selectedRow && this.selectedRow.EndTime) {
          tempData.EndTime = this.dateToTime(this.selectedRow.EndTime);
        }
        if (this.selectedRow && this.selectedRow.MaxAssignment) {
          tempData.MaxAssignment = parseInt(this.selectedRow.MaxAssignment);
        }

        let isCancelled = false;
        if (JSON.stringify(this.oldDayData) != JSON.stringify(tempData)) {
          await this.$swal({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          }).then(async (dialog) => {
            if (dialog.value) {
              isCancelled = false;
            } else {
              isCancelled = true;
            }
          });
        }
        if (isCancelled) {
          return;
        }
      }

      this.activeItem = row.Id;
      this.isEditing = true;
      this.activeDay = 0;
      this.bindDayData(row.Id);
    },

    bindDayData(officeId) {
      let currentDayData = this.getDayData();
      if (currentDayData) {
        this.selectedRow.StartTime = currentDayData.StartTime;
        this.selectedRow.EndTime = currentDayData.EndTime;
        this.selectedRow.TimeZoneId = currentDayData.TimeZoneId;
        this.selectedRow.MaxAssignment = currentDayData.MaxAssignment;
        this.selectedRow.IsEnabled = currentDayData.IsEnabled;
        this.selectedRow.OfficeId = currentDayData.OfficeId;
        this.selectedRow.CompanyId = currentDayData.CompanyId;
        this.selectedRow.Id = currentDayData.Id;
        this.oldDayData = deepCopy(this.selectedRow);
        this.selectedRow.StartTime = this.timeToDate(currentDayData.StartTime);
        this.selectedRow.EndTime = this.timeToDate(currentDayData.EndTime);
      } else {
        this.selectedRow.StartTime = this.timeToDate(init.StartTime);
        this.selectedRow.EndTime = this.timeToDate(init.EndTime);
        this.selectedRow.CompanyId = 1;
        this.selectedRow.OfficeId = officeId;
        this.selectedRow.TimeZoneId = 0;
        this.selectedRow.MaxAssignment = 0;
        this.selectedRow.IsEnabled = false;
        this.oldDayData = deepCopy(this.selectedRow);
        this.oldDayData.StartTime = init.StartTime;
        this.oldDayData.EndTime = init.EndTime;
        this.selectedRow.Id = 0;

        this.oldDayData.Id = init.Id;
      }
    },

    async handleSave() {
      let editData = deepCopy(this.selectedRow);

      if (this.selectedRow && this.selectedRow.MaxAssignment) {
        editData.MaxAssignment = parseInt(this.selectedRow.MaxAssignment);
      }
      if (this.selectedRow && this.selectedRow.StartTime) {
        editData.StartTime = this.dateToTime(this.selectedRow.StartTime);
      }

      if (this.selectedRow && this.selectedRow.EndTime) {
        editData.EndTime = this.dateToTime(this.selectedRow.EndTime);
      }

      if (JSON.stringify(this.oldDayData) == JSON.stringify(editData)) {
        this.$swal("Warning!", "No Changes Detected!", "warning");
        return;
      }

      if (this.selectedRow.IsEnabled) {
        if (this.selectedRow.StartTime == "") {
          this.$swal("Warning!", "Start time is empty.", "warning");
          return;
        }
        if (this.selectedRow.EndTime == "") {
          this.$swal("Warning!", "End time is empty.", "warning");
          return;
        }
        if (this.selectedRow.StartTime > this.selectedRow.EndTime) {
          this.$swal(
            "Warning!",
            "The start time cannot be greater than the end time.",
            "warning"
          );
          return;
        }
        if (this.selectedRow.TimeZoneId == 0) {
          this.$swal("Warning!", "Time zone is required.", "warning");
          return;
        }
        if (this.selectedRow.MaxAssignment <= 0) {
          this.$swal(
            "Warning!",
            "Max assignment must be greater than zero.",
            "warning"
          );
          return;
        }
      }

      this.saving = true;
      let err, result;
      let type = types.ADD_XCHANGE;
      if (this.selectedRow.Id > 0) {
        type = types.UPDATE_XCHANGE;
      }

      [err, result] = await this.$store.dispatch(type, editData);

      if (result) {
        this.$swal("Success!", result.Message, "success");
        this.selectedRow.Id = result.Data.Id;
        this.oldDayData = deepCopy(result.Data);
        await this.sleep(2000);
        await this.getXchangeList();
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }

      this.saving = false;
    },
  },
};
</script>
<style scoped>
.tab-item .active {
  background-color: #3bafda;
  color: #ffffff !important;
}
</style>
