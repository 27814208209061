<template>
  <div class="row admin-page officePage">
    <Placeholders
      :typeId="0"
      @setPlaceholderVisibility="isPlaceholdersOpen = $event"
      @setPlaceholderContent="setPlaceholder($event)"
      v-if="isPlaceholdersOpen"
    ></Placeholders>
    <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 panelWrapper">
      <button
        type="button"
        class="col-md-12 btn btn-primary waves-effect waves-light"
        v-on:click.prevent="addNewQuickSms"
      >
        Add New Quick SMS
      </button>
      <div class="col-lg-12 col-md-12 col-sm-12 noPadding">
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          v-model="smsFilter"
          placeholder="Filter quick sms"
        />
      </div>
      <div class="list col-md-12" v-show="isListReady">
        <div
          class="office item row"
          v-for="(item, index) in filteredSmsTemplates"
          :key="item.Id"
          v-on:click.prevent="() => handleSelection(item, index)"
          :disabled="saving"
          :style="[
            index % 2 === 0
              ? { 'background-color': '#F1EFEF' }
              : { 'background-color': '#FFFFFF' },
          ]"
        >
          <span :class="{ adminActiveMenuItem: activeItem === item.Id }">
            <i class="ri-chat-1-fill"></i>
            <span style="margin-left: 8px">{{ item.Title }}</span>
          </span>
        </div>
      </div>
      <div
        v-if="isMobileDevice()"
        style="
          border-top: 1px solid rgb(0, 0, 0, 0);
          margin: 20px 0 0 9px;
          padding-bottom: 20px;
        "
        class="col-md-12 scrollToSelectedItem"
      ></div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
      <form
        v-if="isEditing"
        class="form-horizontal formWrapper panelWrapper float-left"
        role="form"
      >
        <div class="panelContent">
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">Title *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <input
                autocomplete="off"
                type="text"
                id="txtTitle"
                class="form-control"
                v-model="selectedRow.Title"
                :class="v$.selectedRow.Title.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Title.$touch"
                placeholder="Enter Title"
                v-on:input="checkLength"
              />
              <p style="color: #ff7979" v-if="isMaxLimitReached">
                Maximum 50 characters are allowed!
              </p>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">Roles *</label>
            </div>
            <div class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <VueMultiselect
                id="ddlsubTask"
                v-model="selectedRow.Roles"
                :options="sortedRoles"
                :multiple="true"
                :close-on-select="false"
                label="Name"
                track-by="Id"
                :class="v$.selectedRow.Roles.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.Roles.$touch"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">SMS Content *</label>
            </div>
            <div
              class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12"
              style="position: relative"
            >
              <textarea
                id="txtContent"
                class="form-control"
                v-model="selectedRow.SmsContent"
                :class="v$.selectedRow.SmsContent.$error ? 'has-error' : ''"
                @blur="v$.selectedRow.SmsContent.$touch"
                placeholder="Enter Content"
                rows="4"
                style="resize: none"
              />
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: 0;
                  right: -24px;
                "
              >
                <button
                  type="button"
                  title="manage"
                  id="pholder1btn-qsm"
                  v-on:click.prevent="openPlaceholders"
                  class="btn btn-primary fas fa-hashtag is-small cursor-pointer text-light rounded"
                  style="
                    position: absolute;
                    top: 0;
                    z-index: 100;
                    background-color: #3498db;
                  "
                ></button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <label class="form-label">Spanish SMS Content</label>
            </div>
            <div
              class="col-xl-4 col-lg-9 col-md-9 col-sm-9 col-xs-12"
              style="position: relative"
            >
              <textarea
                class="form-control"
                v-model="selectedRow.SpanishContent"
                placeholder="Enter Spanish Content"
                rows="4"
                style="resize: none"
              />
              <div
                style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  top: 0;
                  right: -24px;
                "
              ></div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
              <button
                type="button"
                v-on:click.prevent="handleSave"
                :disabled="v$.$invalid || saving"
                class="animated-btn btn btn-success btn-bordered-success waves-effect width-md waves-light submit-button float-right"
              >
                <i v-if="saving" class="fas fa-spinner mr-1"></i>
                Save
              </button>
              <div style="width: 100px; float: right; margin: 20px 0">
                <div style="width: 20px; float: left; margin: 0 12px">
                  <input
                    type="checkbox"
                    :id="selectedRow.Id"
                    class="form-control"
                    v-model="selectedRow.IsActive"
                  />
                </div>
                <label class="col-form-label">Active</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import types from "./types";
import globalTypes from "@/store/types";
import placeholdersTypes from "@/components/Placeholders/types";
import { mapState } from "vuex";
import Placeholders from "@/components/Placeholders/Placeholders";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import VueMultiselect from "vue-multiselect";
import _orderby from "lodash";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
const init = {
  Id: 0,
  Title: "",
  SmsContent: "",
  SpanishContent: "",
  IsActive: true,
  Roles: [],
};
export default {
  name: "QuickSmsManagement",
  computed: mapState({
    roles: (state) => state.globals.roles,
    sortedRoles() {
      return _orderby.orderBy(this.filterRolesByOfficeAccess(this.roles), "Name", "asc");
    },
    quickSmsList: (state) => state.quickSmsManagement.quickSmsList,
    dispoList: (state) => state.globals.dispositionOptionList,
    bannedKeywords: (state) => state.globals.bannedKeywords,
    filteredSmsTemplates() {



      return _orderby.orderBy(
        this.quickSmsList.filter(
                (x) =>
                  !x.Roles.some(
                    (y) =>
                      !this.sortedRoles.map((z) => z.Id).includes(y.Id)
                  )
              ).filter(
          (item) =>
            item.Title.toLowerCase().indexOf(this.smsFilter.toLowerCase()) > -1
        ),
        [(item) => item.Title.toLowerCase()],
        "asc"
      );
    },
    mappedRoles() {
      return this.roles.map((item) => {
        return { Id: +item.Id, Name: item.Name, OfficeId: item.OfficeId };
      });
    },
  }),
  components: { Placeholders, VueMultiselect },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      isListReady:false,
      isMaxLimitReached: false,
      v$: useValidate(),
      saving: false,
      isEditing: false,
      selectedRow: Object.assign({}, init),
      oldValue: "",
      activeItem: null,
      isPlaceholdersOpen: false,
      cancelBeforeChanged: false,
      smsFilter: "",
      subOfficeIds:[],
      userOfficeId:null,
      hasAccessOtherOffices: false,
      hasAccessSubOffices: false,
    };
  },
  async mounted() {

    await Promise.all([
      this.$store.dispatch(placeholdersTypes.GET_PLACEHOLDERS),
      this.$store.dispatch(globalTypes.GET_DISPOSITION_OPTIONS_FOR_ADMIN),
      this.$store.dispatch(globalTypes.GET_ROLES),
      this.$store.dispatch(types.GET_TEMPLATES),
    ]);
    this.hasAccessOtherOffices = this.canSeeAllOffices();
    this.hasAccessSubOffices = this.canSeeSubOffices();

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo != null) {
      this.subOfficeIds = userInfo.subOffices;
      this.userOfficeId = userInfo.officeId;
 
    }
    this.isListReady= true;

  },
  methods: {
    filterRolesByOfficeAccess(roles) {

      if (this.hasAccessOtherOffices) {
        return roles;
      } else if (
              this.hasAccessSubOffices &&
              this.subOfficeIds &&
              this.subOfficeIds.length > 0
      ) {
        this.subOfficeIds.push(this.userOfficeId);
        return roles.filter((x) => this.subOfficeIds.includes(x.OfficeId));
      } else {
        return roles.filter((x) => x.OfficeId == this.userOfficeId );
      }
    },


    checkLength() {
      if (this.selectedRow.Title.length > 50) {
        this.selectedRow.Title = this.selectedRow.Title.slice(0, 50);
        this.isMaxLimitReached = true;
      } else {
        this.isMaxLimitReached = false;
      }
    },
    async addNewQuickSms() {
      if (
        this.selectedRow &&
        this.selectedRow.Id != 0 &&
        JSON.stringify(this.selectedRow) != this.oldValue
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.isCancelledBeforeSave = true;
            } else {
              this.isCancelledBeforeSave = false;
              return;
            }
          });
      } else {
        this.isEditing = true;
        this.selectedRow = deepCopy(init);
        this.activeItem = null;
      }

      if (this.isCancelledBeforeSave) {
        this.isEditing = true;
        this.selectedRow = deepCopy(init);
        this.oldValue = JSON.stringify(this.selectedRow);
        this.activeItem = null;
      }
      this.isCancelledBeforeSave = false;
    },
    async handleSelection(item) {
      if (
        this.selectedRow &&
        JSON.stringify(this.selectedRow) != this.oldValue &&
        JSON.stringify(this.selectedRow) != JSON.stringify(init)
      ) {
        await this.$swal
          .fire({
            title: "You have unsaved changes",
            text: "You will lose it if you don't save",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, continue without saving!",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              this.cancelBeforeChanged = true;
            } else {
              this.cancelBeforeChanged = false;
              return;
            }
          });
      } else {
        this.selectedRow = deepCopy(item);
        this.oldValue = JSON.stringify(item);
        this.activeItem = item.Id;
        this.isEditing = true;
      }

      if (this.cancelBeforeChanged) {
        this.selectedRow = deepCopy(item);
        this.oldValue = JSON.stringify(item);
        this.activeItem = item.Id;
        this.isEditing = true;
      }
      this.isCancelledBeforeSave = false;

      let el = this.$el.querySelector(".scrollToSelectedItem");
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    openPlaceholders() {
      this.isPlaceholdersOpen = true;
    },
    setPlaceholder(value) {
      navigator.clipboard.writeText(value);
    },
    // setPlaceholder(placeholder) {
    //   navigator.clipboard.writeText(placeholder);
    //   let textarea = document.getElementById("txtContent");
    //   let start = textarea.selectionStart;
    //   let end = textarea.selectionEnd;
    //   let value = textarea.value;
    //   if (this.selectedRow) {
    //     this.selectedRow.SmsContent =
    //       value.slice(0, start) + placeholder + value.slice(end);
    //   }

    //   // update cursor to be at the end of insertion
    //   start += value.length;
    //   textarea.selectionStart = textarea.selectionEnd = start;

    //   textarea.focus();
    // },
    async handleSave() {
      this.saving = true;

      if (JSON.stringify(this.selectedRow) == this.oldValue) {
        this.$swal.fire("Warning!", "No Changes Detected!", "warning");
      } else {
        let continueSave = true;
        let saveData = deepCopy(this.selectedRow);

        let bannedWords = [];
        if (saveData.SmsContent.length > 0) {
          let bodyText = saveData.SmsContent.replaceAll(" ", "").toLowerCase();
          for (let item of this.bannedKeywords) {
            let cleanWord = item.replaceAll(" ", "").toLowerCase();
            if (cleanWord == "") cleanWord = item;
            if (bodyText.includes(cleanWord)) {
              bannedWords.push(item);
            }
          }
        }

        if (bannedWords.length > 0) {
          let message =
            bannedWords.length == 1
              ? "The following keyword is not allowed: " + bannedWords[0]
              : "The following keywords are not allowed: " +
                bannedWords.join(", ");
          this.$swal("Warning!", message, "warning");
          this.saving = false;
          return;
        }

        saveData.Roles = saveData.Roles.map((x) => x.Id);

        let type = types.PUT_SMS_TEMPLATE;
        if (this.selectedRow.Id == 0) type = types.POST_SMS_TEMPLATE;

        if (
          type == types.PUT_SMS_TEMPLATE &&
          this.selectedRow.IsActive == false
        ) {
          let matchedDispositions = this.dispoList.filter(
            (x) => x.SmsId == this.selectedRow.Id
          );
          if (matchedDispositions.length > 0) {
            await this.$swal
              .fire({
                title:
                  "This quick SMS template is being used by the following dispositions:<br>" +
                  matchedDispositions.map((x) => x.Name).join("<br>"),
                text: "If you disable this quick SMS template, these dispositions will no longer send an SMS automatically. Would you like to continue?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              })
              .then(async (dialog) => {
                if (dialog.value) {
                  continueSave = true;
                } else {
                  continueSave = false;
                }
              });
          }
        }

        if (continueSave == true) {
          let err, result;

          [err, result] = await this.$store.dispatch(type, saveData);

          if (result) {
            this.$swal.fire("Success!", result.Message, "success");
            if (type == types.POST_SMS_TEMPLATE) {
              this.selectedRow.Id = result.Data.Id;
              this.quickSmsList.push(this.selectedRow);
            } else if (type == types.PUT_SMS_TEMPLATE) {
              let indx = this.quickSmsList.findIndex(
                (x) => x.Id == this.selectedRow.Id
              );

              if (indx >= 0) {
                this.quickSmsList[indx] = this.selectedRow;
              }
            }
            this.oldValue = JSON.stringify(this.selectedRow);
          } else {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
          }
        }
      }
      this.saving = false;
    },
  },
  validations() {
    return {
      selectedRow: {
        Title: { required },
        SmsContent: { required },

        Roles: {
          atLeastOneRole(Roles) {
            return Roles.length > 0;
          },
        },
      },
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
