import Quill from 'quill'

const BaseImageFormat = Quill.import('formats/image')

const ATTRIBUTES = [
    'alt',
    'height',
    'width',
    'style'
];

/**
 * @class Extension of the base `formats/image` from Quill that allows setting the `style` attribute
 */
class StyledImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ATTRIBUTES.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }

    format(name, value) {
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

export default StyledImageFormat