<template>
  <div class="col-md-12">
    <div v-if="isLoading" class="loading">
      <div class="text-center" style="margin-top: 150px">
        <i class="spinner-grow text-info m-2"></i>
      </div>
    </div>
    <div
      v-if="
        hasAccessTo1on1TaskDistribution &&
        showAcceptNewSettlementButton &&
        !(AssignedSettlementId > 0)
      "
      class="centerBoxPosition"
    >
      <div class="centerBox">
        <div><i class="fas fa-handshake handShake"></i></div>
        <div class="contentBox">
          <button @click="acceptNewSettlement()" class="btn btn-primary">
            Accept New Settlement
          </button>
        </div>
      </div>
    </div>

    <div>
      <div class="form-group row">
        <div
          class="col-md-12 noPadding"
          style="margin-bottom: -4px !important; margin-left: -1px !important"
        >
          <ul class="nav nav-tabs" style="border: none; margin: 0 0 -2px -12px">
            <li
              v-if="hasAuth(7470) && !hasAccessTo1on1TaskDistribution"
              key="-1"
              class="nav-item tab-item"
              style="
                width: 200px;
                max-width: 20%;
                float: left;
                position: relative;
                text-align: center;
                margin-bottom: -2px;
              "
            >
              <a
                data-toggle="tab"
                class="tab-link customer-tab-link"
                style="background-color: #f7f7f7; border-bottom: 0 !important"
                :style="negotiatorTaskGridTab ? 'opacity:1;' : 'opacity:0.7;'"
                @click="handleTabChange(-1, 0)"
              >
                <span class="customer-tab-header-icon">
                  <i class="ri-list-check-2"></i>
                </span>
                <span class="customer-tab-header" style="width: 80%; margin: 0"
                  >Negotiator Tasks</span
                >
              </a>
            </li>

            <li
              v-if="hasAuth(9127) && !hasAccessTo1on1TaskDistribution"
              key="-1"
              class="nav-item tab-item"
              style="
                width: 220px;
                max-width: 20%;
                float: left;
                position: relative;
                text-align: center;
                margin-bottom: -2px;
              "
            >
              <a
                data-toggle="tab"
                class="tab-link customer-tab-link"
                style="background-color: #f7f7f7; border-bottom: 0 !important"
                :style="attorneyApprovalGridTab ? 'opacity:1;' : 'opacity:0.5;'"
                @click="handleTabChange(-3, 0)"
              >
                <span class="customer-tab-header-icon">
                  <i class="ri-list-check-2"></i>
                </span>
                <span class="customer-tab-header" style="width: 80%; margin: 0"
                  >Attorney Approvals</span
                >
              </a>
            </li>

            <li
              v-if="hasAuth(8593) && !hasAccessTo1on1TaskDistribution"
              key="-1"
              class="nav-item tab-item"
              style="
                width: 220px;
                max-width: 20%;
                float: left;
                position: relative;
                text-align: center;
                margin-bottom: -2px;
              "
            >
              <a
                data-toggle="tab"
                class="tab-link customer-tab-link"
                style="background-color: #f7f7f7; border-bottom: 0 !important"
                :style="
                  settlementApprovalGridTab ? 'opacity:1;' : 'opacity:0.5;'
                "
                @click="handleTabChange(-2, 0)"
              >
                <span class="customer-tab-header-icon">
                  <i class="ri-list-check-2"></i>
                </span>
                <span class="customer-tab-header" style="width: 80%; margin: 0"
                  >Settlement Approval</span
                >
              </a>
            </li>
            <li
              v-for="(tab, index) in tabs"
              :key="tab.id"
              class="nav-item tab-item"
              style="
                width: 150px;
                max-width: 10%;
                float: left;
                position: relative;
              "
              :style="
                'float:left; width:200px; max-width:' +
                getTabWidth(tabs.length, 80) +
                '%;'
              "
            >
              <a
                data-toggle="tab"
                :aria-expanded="tab.active"
                @click="handleTabChange(tab.id, index)"
                class="tab-link customer-tab-link"
                :class="{ negotiatorTaskActiveTab: tab.active }"
              >
                <span class="customer-tab-icons float-left">
                  <i :class="tab.iconName"></i>
                </span>
                <span class="tab-item-label customer-tab-text">{{
                  tab.label
                }}</span>
                <span
                  class="customer-tab-icons float-left"
                  v-on:click.stop="handleTabClose(tab.type, index)"
                >
                  <i
                    v-if="!hasAccessTo1on1TaskDistribution"
                    style="
                      width: 16px;
                      height: 16px;
                      border-radius: 5px;
                      padding: 2px;
                      background-color: rgba(42, 64, 76, 0.1);
                      line-height: 140%;
                    "
                    :class="tab.closeIcon"
                  ></i>
                </span>
                <span
                  v-if="tab.active"
                  style="
                    width: 94%;
                    height: 8px;
                    background-color: #f4f9ff;
                    position: absolute;
                    left: 1px;
                    bottom: -6px;
                  "
                ></span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-show="
          !hasAccessTo1on1TaskDistribution &&
          (negotiatorTaskGridTab ||
            settlementApprovalGridTab ||
            attorneyApprovalGridTab)
        "
        class="form-group row"
      >
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-12 row newPanelWrapper negotiatorTasks"
          style="border-top-right-radius: 0px; border-top-left-radius: 0px"
        >
          <div class="col-xl-2 col-md-6 col-12">
            <select
              v-model="filter.negotiatorId"
              class="form-control"
              id="negotiators"
            >
              <option v-if="checkAuth(7478)" :value="null">
                Select Negotiators
              </option>
              <option v-if="checkAuth(7478)" :value="-1">Unassigned</option>
              <option
                v-for="item in filteredNegotiatorList"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div class="col-xl-2 col-md-6 col-12">
            <input
              autocomplete="off"
              v-model="filter.csmId"
              type="text"
              class="form-control"
              placeholder="Customer ID"
            />
          </div>
          <div class="col-xl-2 col-md-6 col-12">
            <input
              autocomplete="off"
              v-model="filter.taskId"
              type="text"
              class="form-control"
              placeholder="Task ID"
            />
          </div>
          <div class="col-xl-2 col-md-6 col-12">
            <select
              v-model="filter.phaseId"
              class="form-control"
              id="selectPhase"
            >
              <option :value="null">Select a Phase</option>
              <option
                v-for="item in negotiationPhaseList"
                :key="item.Id"
                :value="item.Id"
              >
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div class="col-xl-4 col-lg-12 col-md-12 col-12 row">
            <button
              @click="refreshGrid()"
              type="button"
              class="btn btn-info waves-effect waves-light mr-2"
              :disabled="isLoading"
            >
              <span class="btn-label"> <i class="fas fa-search"></i> </span
              >Search
            </button>

            <button
              @click="clearFilter()"
              :disabled="isLoading"
              type="button"
              class="btn btn-danger waves-effect waves-light mr-2"
            >
              <span class="btn-label"
                ><i class="mdi mdi-close-circle-outline"></i></span
              >Clear
            </button>
            <div v-if="checkAuth(9128)">
              <div
                v-if="
                  (negotiatorTaskGridTab == true &&
                    negotiatorTasks.length > 0) ||
                  (attorneyApprovalGridTab == true &&
                    attorneyApprovals.length > 0) ||
                  (settlementApprovalGridTab == true &&
                    settlementApprovals.length > 0)
                "
              >
                <button
                  type="button"
                  class="btn btn-primary waves-effect waves-light"
                  @click="exportGrid()"
                >
                  <span class="btn-label"
                    ><i class="ri-share-box-line"></i></span
                  >Export
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-12 noMargin pt-1">
            <input
              type="checkbox"
              class="checkbox-input generalCheckBox"
              @change="refreshGrid"
              v-model="filter.includePostponed"
              style="
                border-radius: 4px !important;
                border: solid 1px #d1d1d1 !important;
                vertical-align: text-top;
              "
            />

            <label class="generalLabel ml-2 mt-1" style="font-weight: normal"
              >Show Postponed Tasks</label
            >
          </div>
        </div>
        <div class="col-12 datagrid-table negotiatorTableScroll">
          <div class="row mt-1">
            <div class="col-xl-8 col-lg-8 col-md-12">
              <div style="display: flex; align-items: baseline">
                <div class="mr-3">
                  <label class="generalLabel" style="font-size: 1.2em">
                    {{
                      activeTabName == "" ? "Negotiator Tasks" : activeTabName
                    }}</label
                  >
                </div>
                <div
                  v-if="showLawSuitIndicator(activeTabId)"
                  class="mr-1 mt-1"
                  style="display: flex"
                >
                  <label
                    style="
                      width: 14px;
                      height: 14px;
                      background-color: #f1bfbf;
                      border-radius: 2px;
                      margin-right: 5px;
                      margin-top: 2px;
                    "
                  ></label>
                </div>
                <div
                  v-if="showLawSuitIndicator(activeTabId)"
                  class="mr-3"
                  style="display: flex"
                >
                  <label class="generalLabel" style="margin-top: 0"
                    >Lawsuit</label
                  >
                </div>

                <div
                  v-if="activeTabName == 'Negotiator Tasks' && checkAuth(3842)"
                  style="display: flex"
                >
                  <label
                    for="bulkAssign"
                    style="min-width: 100px"
                    class="generalLabel mt-2 mr-1"
                    >Bulk Assign:</label
                  >
                  <select
                    id="bulkAssign"
                    style="margin: 0 0 0 0"
                    class="form-control"
                    v-model="bulkAssignAgentId"
                    @change="bulkAssign()"
                  >
                    <option :value="0">Assign Agent</option>
                    <option :value="null">Unassign</option>
                    <option
                      v-for="item in filteredNegotiatorList"
                      :key="item.Id"
                      :value="item.Id"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
              <div
                style="
                  display: flex;
                  align-items: center;
                  flex-direction: row-reverse;
                "
              >
                <button
                  :disabled="isActiveButtonDisabled"
                  @click="changePage(1)"
                  type="button"
                  class="btn btn-xs btn-outline-light waves-effect waves-light ml-1"
                >
                  <i
                    style="font-size: 15px; font-weight: bold; color: #999"
                    class="ri-arrow-right-s-line"
                  ></i>
                </button>

                <label
                  class="col-form-label ml-1 mr-1"
                  style="font-weight: bold"
                  >of {{ totalPageCount }}</label
                >
                <input
                  autocomplete="off"
                  v-model="filter.pageNo"
                  type="text"
                  class="generalTextBox text-center"
                  style="width: 50px; padding: 4px"
                  @keypress="keyChange"
                />
                <button
                  @click="changePage(-1)"
                  type="button"
                  class="btn btn-xs btn-outline-light waves-effect waves-light mr-1"
                >
                  <i
                    style="font-size: 15px; font-weight: bold; color: #999"
                    class="ri-arrow-left-s-line"
                  ></i>
                </button>

                <label
                  class="col-form-label ml-1 mr-1"
                  style="font-weight: bold"
                >
                  / Page</label
                >
                <input
                  autocomplete="off"
                  v-model="filter.pageSize"
                  type="text"
                  class="generalTextBox text-center"
                  style="width: 50px; padding: 4px"
                  @keypress="keyChange"
                />
              </div>
            </div>
          </div>
          <div class="row" style="position: relative">
            <div
              style="width: 100%; cursor: pointer"
              v-if="negotiatorTasks.length > 0"
            >
              <div
                v-if="
                  negotiatorTaskGridTab &&
                  negotiatorTasks.length > 0 &&
                  checkAuth(3842)
                "
                style="
                  width: 30px;
                  height: 50px;
                  position: absolute;
                  top: 0;
                  left: 15px;
                  z-index: 40;
                "
              >
                <input
                  @change="selectAllNegotiatorTasks()"
                  :checked="allNegotiatorTasksSelected"
                  type="checkbox"
                  class="checkbox-input generalCheckBox"
                  style="
                    border-radius: 4px !important;
                    border: solid 1px #d1d1d1 !important;
                    float: left;
                    margin-top: 80%;
                  "
                />
              </div>
              <o-table
                v-model="selectedTask"
                v-show="negotiatorTaskGridTab"
                :data="negotiatorTasks"
                :bordered="isBordered"
                :striped="isStriped"
                :hoverable="isHoverable"
                :focusable="isFocusable"
                @dblclick="triggerOpenTask($event)"
              >
                <o-table-column
                  field="Assign"
                  label=""
                  v-if="checkAuth(3842)"
                  v-slot="props"
                >
                  <input
                    type="checkbox"
                    @change="selectTask(props.row.Id)"
                    :checked="isTaskSelected(props.row.Id)"
                    class="checkbox-input generalCheckBox"
                    style="
                      border-radius: 4px !important;
                      border: solid 1px #d1d1d1 !important;
                      vertical-align: text-top;
                    "
                  />
                </o-table-column>
                <o-table-column
                  field="IsLawSuit"
                  label=""
                  sortable
                  position="centered"
                  v-slot="props"
                  style="margin: 0 !important; padding: 0 !important"
                >
                  <div
                    style="
                      width: 8px;
                      height: 32px;
                      margin: 0;
                      position: relative;
                    "
                  >
                    <span
                      style="
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                      "
                      :style="
                        props.row.Creditor.IsLawSuit == true
                          ? 'background-color:#f1bfbf'
                          : 'background-color:inherit'
                      "
                    ></span>
                  </div>
                </o-table-column>
                <o-table-column
                  field="SettlementDate"
                  label="Requested"
                  sortable
                  v-slot="props"
                  width="%20"
                >
                  {{ formatGridDate(props.row.SettlementDate) }}
                </o-table-column>
                <o-table-column
                  field="Updated"
                  label="Updated"
                  sortable
                  v-slot="props"
                  width="%20"
                >
                  {{ formatGridDate(props.row.LastUpdatedDate) }}
                </o-table-column>
                <o-table-column
                  field="ClientName"
                  label="Customer Name"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.ClientName }}
                </o-table-column>

                <o-table-column
                  field="Creditor.Name"
                  label="Creditor"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Creditor.Name }}
                </o-table-column>

                <o-table-column
                  field="Creditor.CurrentCreditorName"
                  label="Current Creditor"
                  sortable
                  position="centered"
                  v-slot="props"
                >
                  {{ props.row.Creditor.CurrentCreditorName }}
                </o-table-column>
                <o-table-column
                  field="Creditor.AccountNumber"
                  label="Account Number"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Creditor.AccountNumber }}
                </o-table-column>
                <o-table-column
                  field="NegotiatorName"
                  label="Negotiator"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.NegotiatorName }}
                </o-table-column>

                <o-table-column
                  field="NegotiationPhase.Name"
                  label="Neg. Phase"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.NegotiationPhase.Name }}
                </o-table-column>

                <o-table-column
                  field="paymentDay"
                  label="Payment Day"
                  sortable
                  v-slot="props"
                >
                  {{
                    props.row.paymentDay != null
                      ? new Date(props.row.paymentDay).toLocaleDateString()
                      : ""
                  }}
                </o-table-column>

                <o-table-column
                  field="Creditor.OriginalBalance"
                  label="Creditor Balance"
                  sortable
                  v-slot="props"
                >
                  {{ formatMoney(props.row.Creditor.OriginalBalance) }}
                </o-table-column>

                <o-table-column
                  field="GlobalBalanceOffline"
                  label="Global Balance"
                  sortable
                  v-slot="props"
                >
                  {{ formatMoney(props.row.GlobalBalanceOffline) }}
                </o-table-column>

                <o-table-column field="manage" label="Manage" v-slot="props">
                  <i
                    @click="
                      openEditNegotiatorTask(
                        props.row.Id,
                        props.row.ClientName,
                        props.row.NegotiatorId
                      )
                    "
                    class="fas fa-pen"
                    style="
                      color: #1da1f2;
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>

                  {{ props.row.manage }}
                </o-table-column>

                <o-table-column field="decline" label="Postpone" v-slot="props">
                  <i
                    @click="openDeclineNegotiatorTaskPopUp(props.row.Id)"
                    class="fas fa-times"
                    style="
                      color: red;
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>
                </o-table-column>
                <o-table-column field="remove" label="Remove" v-slot="props">
                  <i
                    @click="
                      openRemoveNegotiatorTaskPopUp(
                        props.row.Id,
                        props.row.CustomerId
                      )
                    "
                    class="fe-trash-2"
                    style="
                      color: red;
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>
                </o-table-column>
              </o-table>
            </div>
            <div style="width: 100%; cursor: pointer">
              <o-table
                v-show="settlementApprovalGridTab"
                :data="settlementApprovals"
                :bordered="isBordered"
                :striped="isStriped"
                :hoverable="isHoverable"
                :focusable="isFocusable"
                @dblclick="triggerOpenSettlementApprovals($event)"
              >
                <o-table-column
                  field="IsLawSuit"
                  label=""
                  sortable
                  position="centered"
                  v-slot="props"
                  style="margin: 0 !important; padding: 0 !important"
                >
                  <div
                    style="
                      width: 8px;
                      height: 32px;
                      margin: 0;
                      position: relative;
                    "
                  >
                    <span
                      style="
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                      "
                      :style="
                        props.row.Creditor.IsLawSuit == true
                          ? 'background-color:#f1bfbf'
                          : 'background-color:inherit'
                      "
                    ></span>
                  </div>
                </o-table-column>

                <o-table-column
                  field="SettlementDate"
                  label="Requested"
                  sortable
                  v-slot="props"
                  width="%20"
                >
                  {{ formatGridDate(props.row.SettlementDate) }}
                </o-table-column>
                <o-table-column
                  field="Updated"
                  label="Updated"
                  sortable
                  v-slot="props"
                  width="%20"
                >
                  {{ formatGridDate(props.row.LastUpdatedDate) }}
                </o-table-column>

                <o-table-column
                  field="ClientName"
                  label="Customer Name"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.ClientName }}
                </o-table-column>

                <o-table-column
                  field="Creditor.Name"
                  label="Creditor"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Creditor.Name }}
                </o-table-column>

                <o-table-column
                  field="Creditor.CurrentCreditorName"
                  label="Current Creditor"
                  sortable
                  position="centered"
                  v-slot="props"
                >
                  {{ props.row.Creditor.CurrentCreditorName }}
                </o-table-column>
                <o-table-column
                  field="Creditor.AccountNumber"
                  label="Account Number"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Creditor.AccountNumber }}
                </o-table-column>
                <o-table-column
                  field="NegotiatorName"
                  label="Negotiator"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.NegotiatorName }}
                </o-table-column>

                <o-table-column
                  field="NegotiationPhase.Name"
                  label="Neg. Phase"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.NegotiationPhase.Name }}
                </o-table-column>

                <o-table-column
                  field="paymentDay"
                  label="Payment Day"
                  sortable
                  v-slot="props"
                >
                  {{
                    props.row.paymentDay != null
                      ? new Date(props.row.paymentDay).toLocaleDateString()
                      : ""
                  }}
                </o-table-column>

                <o-table-column
                  field="Creditor.OriginalBalance"
                  label="Creditor Balance"
                  sortable
                  v-slot="props"
                >
                  {{ formatMoney(props.row.Creditor.OriginalBalance) }}
                </o-table-column>

                <o-table-column
                  field="GlobalBalanceOffline"
                  label="Global Balance"
                  sortable
                  v-slot="props"
                >
                  {{ formatMoney(props.row.GlobalBalanceOffline) }}
                </o-table-column>

                <o-table-column
                  field="Creditor.AuditorName"
                  label="Auditor"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Creditor.AuditorName }}
                </o-table-column>

                <o-table-column field="manage" label="Manage" v-slot="props">
                  <i
                    @click="
                      openSettlementApprovalDetails(
                        props.row.Id,
                        props.row.ClientName,
                        props.row.NegotiatorId,
                        props.row.Creditor.AuditorId
                      )
                    "
                    class="fas fa-pen"
                    style="
                      color: #1da1f2;
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>

                  {{ props.row.manage }}
                </o-table-column>
              </o-table>
            </div>

            <div style="width: 100%; cursor: pointer">
              <o-table
                v-show="attorneyApprovalGridTab"
                :data="attorneyApprovals"
                :bordered="isBordered"
                :striped="isStriped"
                :hoverable="isHoverable"
                :focusable="isFocusable"
                @dblclick="triggerOpenAttorneyApprovals($event)"
              >
                <o-table-column
                  field="IsLawSuit"
                  label=""
                  sortable
                  position="centered"
                  v-slot="props"
                  style="margin: 0 !important; padding: 0 !important"
                >
                  <div
                    style="
                      width: 8px;
                      height: 32px;
                      margin: 0;
                      position: relative;
                    "
                  >
                    <span
                      style="
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                      "
                      :style="
                        props.row.Creditor.IsLawSuit == true
                          ? 'background-color:#f1bfbf'
                          : 'background-color:inherit'
                      "
                    ></span>
                  </div>
                </o-table-column>
                <o-table-column
                  field="SettlementDate"
                  label="Requested"
                  sortable
                  v-slot="props"
                  width="%20"
                >
                  {{ formatGridDate(props.row.SettlementDate) }}
                </o-table-column>
                <o-table-column
                  field="Updated"
                  label="Updated"
                  sortable
                  v-slot="props"
                  width="%20"
                >
                  {{ formatGridDate(props.row.LastUpdatedDate) }}
                </o-table-column>

                <o-table-column
                  field="ClientName"
                  label="Customer Name"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.ClientName }}
                </o-table-column>

                <o-table-column
                  field="Creditor.Name"
                  label="Creditor"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Creditor.Name }}
                </o-table-column>

                <o-table-column
                  field="Creditor.CurrentCreditorName"
                  label="Current Creditor"
                  sortable
                  position="centered"
                  v-slot="props"
                >
                  {{ props.row.Creditor.CurrentCreditorName }}
                </o-table-column>
                <o-table-column
                  field="Creditor.AccountNumber"
                  label="Account Number"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.Creditor.AccountNumber }}
                </o-table-column>
                <o-table-column
                  field="AttorneyName"
                  label="Attorney Name"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.AttorneyName }}
                </o-table-column>
                <o-table-column
                  field="NegotiatorName"
                  label="Negotiator"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.NegotiatorName }}
                </o-table-column>

                <o-table-column
                  field="NegotiationPhase.Name"
                  label="Neg. Phase"
                  sortable
                  v-slot="props"
                >
                  {{ props.row.NegotiationPhase.Name }}
                </o-table-column>

                <o-table-column
                  field="paymentDay"
                  label="Payment Day"
                  sortable
                  v-slot="props"
                >
                  {{
                    props.row.paymentDay != null
                      ? new Date(props.row.paymentDay).toLocaleDateString()
                      : ""
                  }}
                </o-table-column>

                <o-table-column
                  field="Creditor.OriginalBalance"
                  label="Creditor Balance"
                  sortable
                  v-slot="props"
                >
                  {{ formatMoney(props.row.Creditor.OriginalBalance) }}
                </o-table-column>

                <o-table-column
                  field="GlobalBalanceOffline"
                  label="Global Balance"
                  sortable
                  v-slot="props"
                >
                  {{ formatMoney(props.row.GlobalBalanceOffline) }}
                </o-table-column>

                <o-table-column field="manage" label="Manage" v-slot="props">
                  <i
                    @click="
                      openAttornetApprovalDetails(
                        props.row.Id,
                        props.row.ClientName,
                        props.row.NegotiatorId
                      )
                    "
                    class="fas fa-pen"
                    style="
                      color: #1da1f2;
                      cursor: pointer;
                      display: flex;
                      justify-content: center;
                    "
                  ></i>

                  {{ props.row.manage }}
                </o-table-column>
              </o-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="item in tabs"
      :key="item.id"
      class="form-group row"
      v-show="item.active"
    >
      <KeepAlive :max="6">
        <EditNegotiatorTask
          :taskId="item.id"
          :ref="'ntask-' + item.id"
          :isSettlementApproval="item.type == 'settlementApproval'"
          @closeEditNegotiatorTaskPopUp="closeEditNegotiatorTaskPopUp"
        ></EditNegotiatorTask>
      </KeepAlive>
    </div>

    <div v-if="isDeclineNegotiatorTaskOpen" class="backgroundLock">
      <div
        style="position: relative; margin: 10% auto"
        class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
      >
        <DeclineNegotiatorTask
          :taskId="selectedTaskId"
          @closeDeclineNegotiatorTaskPopUp="closeDeclineNegotiatorTaskPopUp"
          @taskDeclined="taskDeclined"
        ></DeclineNegotiatorTask>
      </div>
    </div>
    <div v-if="isRemoveNegotiatorTaskOpen" class="backgroundLock">
      <div
        style="position: relative; margin: 10% auto"
        class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
      >
        <RemoveNegotiatorTask
          :taskId="selectedTaskId"
          :customerId="selectedCustomerId"
          @closeRemoveNegotiatorTaskPopUp="closeRemoveNegotiatorTaskPopUp"
          @taskRemoved="taskRemoved"
        ></RemoveNegotiatorTask>
      </div>
    </div>
  </div>
</template>

<script>
import types from "./types";
import globalTypes from "@/store/types";
import { mapState } from "vuex";
import { camelCase } from "lodash";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import EditNegotiatorTask from "@/views/agent/NegotiatorTasks/EditNegotiatorTask";
import DeclineNegotiatorTask from "@/views/agent/NegotiatorTasks/DeclineNegotiatorTask";
import RemoveNegotiatorTask from "@/views/agent/NegotiatorTasks/RemoveNegotiatorTask";
import deepCopy from "@/helpers/deepCopy";

export default {
  name: "NegotiatorTasks",
  components: {
    EditNegotiatorTask,
    DeclineNegotiatorTask,
    RemoveNegotiatorTask,
  },
  mixins: [formatMixin, utilitiesMixin],
  data() {
    return {
      AssignedSettlementId: null,
      showAcceptNewSettlementButton: false,
      hasAccessTo1on1TaskDistribution: false,
      hasAccessOtherOffices: false,
      userOfficeId: 0,
      isLoading: false,
      isActiveButton: false,
      activeTabId: 1,
      activeTabName: "Negotiator Tasks",
      negotiatorTasks: [],
      negotiatorTaskGridTotalRecord: 0,
      settlementApprovals: [],
      attorneyApprovals: [],
      attorneyApprovalGridTab: false,
      attorneyApprovalGridTotalRecord: 0,
      settlementApprovalGridTotalRecord: 0,
      arrPermission: [],
      selectedTask: null,
      tabs: [],
      negotiatorTaskGridTab: false,
      settlementApprovalGridTab: false,
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "top",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "small",
      currentPage: 1,
      perPage: 15,
      isBordered: true,
      isStriped: true,
      isHoverable: true,
      isFocusable: true,
      isEditNegotiatorTaskOpen: false,
      isDeclineNegotiatorTaskOpen: false,
      isRemoveNegotiatorTaskOpen: false,
      editNegotiatorTask: [],
      selectedTaskId: 0,
      selectedCustomerId: 0,
      filteredNegotiatorList: [],
      clearNegotiatorFilter: null,
      filter: {
        negotiatorId: null,
        phaseId: null,
        csmId: null,
        taskId: null,
        pageNo: 1,
        pageSize: 10,
        includePostponed: false,
      },
      allowedKeyCodes: [13, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
      userId: null,
      checkedTaskIds: [],
      allNegotiatorTasksSelected: false,
      bulkAssignAgentId: 0,
    };
  },
  watch: {
    activeTabName(name) {
      this.updatePathByView(name);
    },
  },
  activated() {
    this.updatePathByView(this.activeTabName);
  },
  computed: mapState({
    salesAgents: (state) => state.globals.salesAgents,
    filteredAgents() {
      return this.salesAgents.filter((x) =>
        this.hasAccessOtherOffices ? x : x.OfficeId == this.userOfficeId
      );
    },

    negotiatorList: (state) => state.globals.negotiators,
    negotiationPhaseList: (state) => state.negotiatorTasks.negotiationPhaseList,
    totalRecords() {
      const totalRecords = {
        1: this.negotiatorTaskGridTotalRecord,
        2: this.settlementApprovalGridTotalRecord,
        3: this.attorneyApprovalGridTotalRecord,
      };

      return totalRecords[this.activeTabId] || 0;
    },

    totalPageCount() {
      return this.getTotalPageCount(this.totalRecords, this.filter.pageSize);
    },

    isActiveButtonDisabled() {
      return this.filter.pageNo < 1 || this.filter.pageNo > this.totalPageCount;
    },
  }),
  methods: {
    async getNewTask() {
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(
        types.GET_ASSIGNED_NEGOTIATOR_TASK
      );
      if (result) {
        if (result.Data && result.Data.SettlementDetailId > 0) {
          this.AssignedSettlementId = parseInt(result.Data.SettlementDetailId);
          this.openEditNegotiatorTask(
            result.Data.SettlementDetailId,
            result.Data.CustomerName,
            result.Data.NegotiatorId
          );
        } else {
          this.AssignedSettlementId = null;
          this.showAcceptNewSettlementButton = true;
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },
    async acceptNewSettlement() {
      this.isLoading = true;
      let err, result;
      [err, result] = await this.$store.dispatch(types.ACCEPT_NEW_SETTLEMENT);
      if (result) {
        if (result.Data && result.Data.SettlementDetailId > 0) {
          this.AssignedSettlementId = parseInt(result.Data.SettlementDetailId);
          this.openEditNegotiatorTask(
            result.Data.SettlementDetailId,
            result.Data.CustomerName,
            result.Data.NegotiatorId
          );
        } else {
          this.AssignedSettlementId = null;
          this.$swal(
            "Warning!",
            "No automated task is available at the moment.",
            "warning"
          );
        }
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },
    getCashFlowDataSync(data) {
      if (typeof this.$refs != "undefined" && this.$refs) {
        let tabNames = Object.keys(this.$refs);
        for (let item of tabNames) {
          if (typeof this.$refs[item] != "undefined" && this.$refs[item]) {
            this.$refs[item].dataSyncForCashFlow(data, this.activeTabId);
          }
        }
      }
    },
    bulkAssign() {
      if (this.checkedTaskIds.length == 0) {
        this.$swal("Warning", "No task selected!", "warning");
        this.bulkAssignAgentId = 0;
        return;
      }

      if (this.bulkAssignAgentId > 0 || this.bulkAssignAgentId == null) {
        let agentIndex = this.filteredNegotiatorList.findIndex(
          (x) => x.Id == this.bulkAssignAgentId
        );

        let agentName =
          agentIndex > -1 ? this.filteredNegotiatorList[agentIndex].Name : "";

        let message =
          this.bulkAssignAgentId == null
            ? "Are you sure to unassign " +
              this.checkedTaskIds.length +
              " task(s)?"
            : "Are you sure to change the assigned agent of " +
              this.checkedTaskIds.length +
              " task(s) to " +
              agentName +
              "?";
        this.$swal
          .fire({
            title: "Confirm",
            text: message,
            type: "warning",
            showCancelButton: this.checkedTaskIds.length > 0 ? true : false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          })
          .then(async (dialog) => {
            if (dialog.value) {
              if (
                this.checkedTaskIds.length > 0 &&
                (this.bulkAssignAgentId > 0 || this.bulkAssignAgentId == null)
              ) {
                let payload = {
                  TaskIds: this.checkedTaskIds.join(","),
                  AssignedUserId: this.bulkAssignAgentId,
                };

                if (payload) {
                  let err, result;

                  [err, result] = await this.$store.dispatch(
                    types.BULK_ASSIGN_NEGOTIATOR_TASK,
                    payload
                  );

                  if (result) {
                    this.$swal("Success", result.Message, "success");
                    this.checkedTaskIds = [];
                    this.allNegotiatorTasksSelected = false;
                    this.resetBulkAssign();
                    await this.refreshGrid();
                  } else {
                    let errMsg = this.getApiErrorMessage(err);
                    this.$swal("Error!", errMsg, "error");
                  }
                }
              } else {
                this.bulkAssignAgentId = 0;
              }
            } else {
              this.bulkAssignAgentId = 0;
            }
          });
      }
    },
    selectAllNegotiatorTasks() {
      let taskIds = this.negotiatorTasks.map((x) => x.Id);
      this.allNegotiatorTasksSelected = !this.allNegotiatorTasksSelected;
      if (taskIds.length == this.checkedTaskIds.length) {
        this.checkedTaskIds = [];
      } else if (
        !this.allNegotiatorTasksSelected &&
        this.checkedTaskIds.length > 0
      ) {
        this.checkedTaskIds = [];
      } else {
        this.checkedTaskIds = [];
        for (let index = 0; index < taskIds.length; index++) {
          this.checkedTaskIds.push(taskIds[index]);
        }
      }
    },
    isTaskSelected(taskId) {
      return this.checkedTaskIds.findIndex((x) => x == taskId) > -1;
    },
    selectTask(taskId) {
      let indx = this.checkedTaskIds.findIndex((x) => x == taskId);
      if (indx > -1) {
        this.checkedTaskIds.splice(indx, 1);
      } else {
        this.checkedTaskIds.push(taskId);
      }
    },
    resetBulkAssign() {
      this.bulkAssignAgentId = 0;
      this.checkedTaskIds = [];
      this.allNegotiatorTasksSelected = false;
    },
    async exportGrid() {
      if (this.totalRecords > 10000) {
        this.$swal(
          "WARNING",
          "Please narrow down your search parameters. Maximum allowed export count: 10,000 records.",
          "warning"
        );
        return;
      }
      this.isLoading = true;
      let ssId = 0;
      let reportName = "";
      if (this.negotiatorTaskGridTab == true) {
        ssId = 1;
        reportName = "NegotiatorTasks";
      } else if (this.attorneyApprovalGridTab == true) {
        ssId = 4;
        reportName = "AttorneyApprovals";
      } else if (this.settlementApprovalGridTab == true) {
        ssId = 2;
        reportName = "SettlementApprovals";
      }
      if (ssId > 0) {
        let err, result;

        [err, result] = await this.$store.dispatch(
          types.GET_ALL_NEGOTIATOR_TASK,
          {
            NegotiatorId: this.filter.negotiatorId,
            NegotiationPhaseId: this.filter.phaseId,
            CustomerId:
              this.filter.csmId == null
                ? this.filter.csmId
                : this.filter.csmId.replace(/\D/g, ""),
            TaskId: this.filter.taskId,
            SettlementStatusId: ssId,
            PageNumber: this.filter.pageNo,
            PageSize: this.filter.pageSize,
            IsExport: true,
            IncludePostponed: this.filter.includePostponed,
          }
        );
        if (result) {
          this.saveByteArray(
            reportName + "-" + new Date().getTime() + ".xls",
            result
          );
        } else {
          let errMsg = this.getApiErrorMessage(err);
          this.$swal("Error!", errMsg, "error");
        }
      }
      this.isLoading = false;
    },
    saveByteArray(name, byte) {
      let blob = new Blob([byte], { type: "application/xlsx" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = name;
      link.download = fileName;
      link.click();
    },
    showLawSuitIndicator(tabId) {
      if (tabId == 1) {
        return (
          this.negotiatorTasks.filter((x) => x.Creditor.IsLawSuit == true)
            .length > 0
        );
      } else if (tabId == 2) {
        return (
          this.settlementApprovals.filter((x) => x.Creditor.IsLawSuit == true)
            .length > 0
        );
      } else if (tabId == 3) {
        return (
          this.attorneyApprovals.filter((x) => x.Creditor.IsLawSuit == true)
            .length > 0
        );
      } else {
        return false;
      }
    },
    handleCreditorSync(data) {
      try {
        if (this.userId != data.UserId) {
          if (
            this.negotiatorTaskGridTab == true &&
            this.negotiatorTasks &&
            data.CustomerId &&
            data.CustomerId.length > 0
          ) {
            let indx = this.negotiatorTasks.findIndex(
              (x) => x.CustomerId == data.CustomerId[0]
            );
            if (indx > -1) {
              this.refreshGrid();
            }
          }

          if (
            this.settlementApprovalGridTab == true &&
            this.settlementApprovals &&
            data.CustomerId &&
            data.CustomerId.length > 0
          ) {
            let indx = this.settlementApprovals.findIndex(
              (x) => x.CustomerId == data.CustomerId[0]
            );
            if (indx > -1) {
              this.refreshGrid();
            }
          }

          if (
            this.attorneyApprovalGridTab == true &&
            this.attorneyApprovals &&
            data.CustomerId &&
            data.CustomerId.length > 0
          ) {
            let indx = this.attorneyApprovals.findIndex(
              (x) => x.CustomerId == data.CustomerId[0]
            );
            if (indx > -1) {
              this.refreshGrid();
            }
          }
        }

        let taskTab = this.$refs["ntask-" + data.TaskId];
        if (typeof taskTab != "undefined" && taskTab) {
          taskTab.dataSyncProcessCreditor(
            data.CustomerId,
            data.CreditorId,
            data.UserId,
            data.UserName,
            data.TaskId
          );
        }
      } catch {
        //todo: log errors
      }
    },

    hasAuth(mfid) {
      return this.arrPermission.includes(mfid);
    },
    triggerOpenTask(event) {
      this.openEditNegotiatorTask(
        event.Id,
        event.ClientName,
        event.NegotiatorId
      );
    },
    triggerOpenSettlementApprovals(event) {
      this.openSettlementApprovalDetails(
        event.Id,
        event.ClientName,
        event.NegotiatorId,
        event.Creditor.AuditorId
      );
    },
    triggerOpenAttorneyApprovals(event) {
      this.openAttornetApprovalDetails(
        event.Id,
        event.ClientName,
        event.NegotiatorId
      );
    },

    keyChange(event) {
      if (!this.allowedKeyCodes.includes(event.keyCode)) {
        event.preventDefault();
      } else if (event.keyCode == 13) {
        this.changePage(0);
      }
    },
    getTabWidth(tabCount, totalWidth) {
      let rtn =
        tabCount > 4
          ? Math.floor(totalWidth / tabCount)
          : Math.floor(totalWidth / 4);

      return rtn;
    },
    resetTabChange() {
      this.settlementApprovalGridTab = false;
      this.negotiatorTaskGridTab = false;
      this.attorneyApprovalGridTab = false;
      this.tabs.map((t) => {
        t.active = false;
      });
    },
    createTab(tabItem) {
      if (this.tabs.length < 6) {
        this.tabs.push(tabItem);
        this.activeTabId = tabItem.id;
      } else {
        this.$swal(
          "Warning!",
          "You have more than 6 open tabs. Please close the redundant ones and try again",
          "warning"
        );
        this.handleTabChange(-1, 0);
      }
    },
    async handleTabChange(id, index) {
      this.negotiatorTaskGridTab = false;
      this.settlementApprovalGridTab = false;
      this.attorneyApprovalGridTab = false;

      this.tabs.map((t) => {
        t.active = false;
      });

      if (id < 0) {
        if (id == -1) {
          this.negotiatorTaskGridTab = true;
          this.activeTabId = 1;
          this.activeTabName = "Negotiator Tasks";
          this.refreshGrid();
        } else if (id == -2) {
          this.settlementApprovalGridTab = true;
          this.activeTabId = 2;
          this.activeTabName = "Settlement Approval";
          this.refreshGrid();
        } else if (id == -3) {
          this.attorneyApprovalGridTab = true;
          this.activeTabId = 3;
          this.activeTabName = "Attorney Approvals";
          this.refreshGrid();
        }

        return;
      }

      if (index < 0) return;

      this.tabs[index].active = true;

      if (this.tabs[index].id > 0) {
        this.activeTabId = this.tabs[index].id;
      }
    },
    async handleTabClose(tabType, indx) {
      if (indx > -1) {
        this.tabs.splice(indx, 1);
      }

      if (tabType == "settlementApproval") {
        this.handleTabChange(-2, 0);
      } else {
        this.handleTabChange(-1, 0);
      }
    },
    async refreshGrid() {
      this.isLoading = true;
      if (this.negotiatorTaskGridTab == true) {
        await this.getAllNegotiatorTask();
      } else if (this.settlementApprovalGridTab == true) {
        await this.getSettlementApprovals();
      } else if (this.attorneyApprovalGridTab == true) {
        await this.getAttorneyApprovals();
      }
      this.isLoading = false;
    },
    async getAllNegotiatorTask() {
      if (!this.hasAuth(7470)) return;

      let err, result;

      [err, result] = await this.$store.dispatch(
        types.GET_ALL_NEGOTIATOR_TASK,
        {
          NegotiatorId: this.filter.negotiatorId,
          NegotiationPhaseId: this.filter.phaseId,
          CustomerId:
            this.filter.csmId == null
              ? this.filter.csmId
              : this.filter.csmId.replace(/\D/g, ""),
          TaskId: this.filter.taskId,
          SettlementStatusId: 1,
          PageNumber: this.filter.pageNo,
          PageSize: this.filter.pageSize,
          IncludePostponed: this.filter.includePostponed,
        }
      );

      if (result && result.Data) {
        this.negotiatorTasks = result.Data.Tasks;
        this.negotiatorTaskGridTotalRecord = result.Data.Total;
      }
    },
    async getSettlementApprovals() {
      if (!this.hasAuth(8593)) return;
      let err, result;

      [err, result] = await this.$store.dispatch(
        types.GET_SETTLEMENT_APPROVALS,
        {
          NegotiatorId: this.filter.negotiatorId,
          NegotiationPhaseId: this.filter.phaseId,
          CustomerId:
            this.filter.csmId == null
              ? this.filter.csmId
              : this.filter.csmId.replace(/\D/g, ""),
          TaskId: this.filter.taskId,
          SettlementStatusId: 2,
          PageNumber: this.filter.pageNo,
          PageSize: this.filter.pageSize,
          IncludePostponed: this.filter.includePostponed,
        }
      );

      if (result && result.Data) {
        this.settlementApprovals = result.Data.Tasks;
        this.settlementApprovalGridTotalRecord = result.Data.Total;
      }
    },
    async getAttorneyApprovals() {
      if (!this.hasAuth(9127)) return;

      let err, result;

      [err, result] = await this.$store.dispatch(
        types.GET_SETTLEMENT_APPROVALS,
        {
          NegotiatorId: this.filter.negotiatorId,
          NegotiationPhaseId: this.filter.phaseId,
          CustomerId:
            this.filter.csmId == null
              ? this.filter.csmId
              : this.filter.csmId.replace(/\D/g, ""),
          TaskId: this.filter.taskId,
          SettlementStatusId: 4,
          PageNumber: this.filter.pageNo,
          PageSize: this.filter.pageSize,
          IncludePostponed: this.filter.includePostponed,
        }
      );

      if (result && result.Data) {
        this.attorneyApprovals = result.Data.Tasks;
        this.attorneyApprovalGridTotalRecord = result.Data.Total;
      }
    },
    async clearFilter() {
      this.filter.negotiatorId = this.clearNegotiatorFilter;
      this.filter.phaseId = null;
      this.filter.csmId = null;
      this.filter.taskId = null;
      this.filter.pageNo = 1;
      this.filter.pageSize = 10;
      this.filter.includePostponed = false;
      this.isLoading = true;
      if (this.settlementApprovalGridTab) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_SETTLEMENT_APPROVALS,
          {
            NegotiatorId: this.clearNegotiatorFilter,
            NegotiationPhaseId: null,
            CustomerId: null,
            TaskId: null,
            SettlementStatusId: 2,
            PageNumber: this.filter.pageNo,
            PageSize: this.filter.pageSize,
            IncludePostponed: this.filter.includePostponed,
          }
        );

        if (result && result.Data) {
          this.settlementApprovals = result.Data.Tasks;
          this.settlementApprovalGridTotalRecord = result.Data.Total;
        }
      } else if (this.negotiatorTaskGridTab) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_ALL_NEGOTIATOR_TASK,
          {
            NegotiatorId: this.clearNegotiatorFilter,
            NegotiationPhaseId: null,
            CustomerId: null,
            TaskId: null,
            SettlementStatusId: 1,
            PageNumber: this.filter.pageNo,
            PageSize: this.filter.pageSize,
            IncludePostponed: this.filter.includePostponed,
          }
        );

        if (result && result.Data) {
          this.negotiatorTasks = result.Data.Tasks;
          this.negotiatorTaskGridTotalRecord = result.Data.Total;
        }
      } else if (this.attorneyApprovalGridTab) {
        let err, result;
        [err, result] = await this.$store.dispatch(
          types.GET_ALL_NEGOTIATOR_TASK,
          {
            NegotiatorId: this.clearNegotiatorFilter,
            NegotiationPhaseId: null,
            CustomerId: null,
            TaskId: null,
            SettlementStatusId: 4,
            PageNumber: this.filter.pageNo,
            PageSize: this.filter.pageSize,
            IncludePostponed: this.filter.includePostponed,
          }
        );

        if (result && result.Data) {
          this.attorneyApprovals = result.Data.Tasks;
          this.attorneyApprovalGridTotalRecord = result.Data.Total;
        }
      } else {
        this.$swal(
          "Error!",
          "An error occured while clearing filters.",
          "error"
        );
      }
      this.isLoading = false;
    },
    openEditNegotiatorTask(id, customerName, assingedNegotiatorId) {
      if (!(assingedNegotiatorId > 0)) {
        this.$swal(
          "Warning",
          "The task must have an assigned negotiator to edit",
          "warning"
        );
        return;
      }

      let indx = this.tabs.findIndex((x) => x.id == id);
      if (indx > -1) {
        this.handleTabChange(0, indx);
        return;
      }
      let tabItem = deepCopy(this.getDefaultTabObject());
      tabItem.label = customerName + "-" + id;
      tabItem.type = "negotiatorTask";
      (tabItem.id = id), this.resetTabChange();
      this.createTab(tabItem);

      this.$nextTick(() => {
        let indx = this.tabs.findIndex((x) => x.id == id);
        this.handleTabChange(tabItem.id, indx);
      });
    },
    getDefaultTabObject() {
      return {
        id: 0,
        label: "",
        iconName: "ri-list-check-2",
        active: true,
        closeIcon: "fe-x",
        type: "negotiatorTask",
      };
    },
    openSettlementApprovalDetails(
      id,
      customerName,
      assingedNegotiatorId,
      auditorId
    ) {
      if (!(assingedNegotiatorId > 0)) {
        this.$swal(
          "Warning",
          "The task must have an assigned negotiator to edit",
          "warning"
        );
        return;
      }

      if (!this.checkAuth(55842) && auditorId > 0 && auditorId != this.userId) {
        this.$swal(
          "Warning",
          "This task already has an auditor assigned",
          "warning"
        );
        return;
      }

      let indx = this.tabs.findIndex((x) => x.id == id);
      if (indx > -1) {
        this.handleTabChange(0, indx);
        return;
      }
      let tabItem = deepCopy(this.getDefaultTabObject());
      tabItem.label = customerName + "-" + id;
      tabItem.type = "settlementApproval";
      (tabItem.id = id), this.resetTabChange();

      this.createTab(tabItem);
    },

    openAttornetApprovalDetails(id, customerName, assingedNegotiatorId) {
      if (!(assingedNegotiatorId > 0)) {
        this.$swal(
          "Warning",
          "The task must have an assigned negotiator to edit",
          "warning"
        );
        return;
      }
      let indx = this.tabs.findIndex((x) => x.id == id);
      if (indx > -1) {
        this.handleTabChange(0, indx);
        return;
      }
      let tabItem = deepCopy(this.getDefaultTabObject());
      tabItem.label = customerName + "-" + id;
      tabItem.type = "attorneyApprovals";
      (tabItem.id = id), this.resetTabChange();

      this.createTab(tabItem);
    },

    async closeEditNegotiatorTaskPopUp(data) {
      if (this.hasAccessTo1on1TaskDistribution) {
        let indx = this.tabs.findIndex((x) => x.id == data.TaskId);

        if (indx > -1) {
          this.handleTabClose(this.tabs[indx].type, indx);
        }
        await this.getNewTask();
      } else {
        let indx = this.tabs.findIndex((x) => x.id == data.TaskId);

        if (indx > -1) {
          this.handleTabClose(this.tabs[indx].type, indx);
        }
        if (data.GridType == "settlementApproval") {
          this.handleTabChange(-2, 0);
          this.getSettlementApprovals();
        } else if (data.GridType == "attorneyApproval") {
          this.handleTabChange(-3, 0);
          this.getAttorneyApprovals();
        } else {
          this.handleTabChange(-1, 0);
          await this.getAllNegotiatorTask();
        }
      }
    },
    openDeclineNegotiatorTaskPopUp(id) {
      this.isDeclineNegotiatorTaskOpen = true;
      this.selectedTaskId = id;
    },
    openRemoveNegotiatorTaskPopUp(id, customerId) {
      this.isRemoveNegotiatorTaskOpen = true;
      this.selectedTaskId = id;
      this.selectedCustomerId = customerId;
    },
    closeDeclineNegotiatorTaskPopUp() {
      this.isDeclineNegotiatorTaskOpen = false;
    },
    closeRemoveNegotiatorTaskPopUp() {
      this.isRemoveNegotiatorTaskOpen = false;
    },
    taskDeclined() {
      this.isDeclineNegotiatorTaskOpen = false;
      this.getAllNegotiatorTask();

      let indx = this.tabs.findIndex((x) => x.id == this.selectedTaskId);
      if (indx > -1) this.handleTabClose(this.tabs[indx].type, indx);
    },
    taskRemoved() {
      this.isRemoveNegotiatorTaskOpen = false;
      this.getAllNegotiatorTask();

      let indx = this.tabs.findIndex((x) => x.id == this.selectedTaskId);
      if (indx > -1) this.handleTabClose(this.tabs[indx].type, indx);
    },
    async changePage(increment) {
      this.filter.pageNo = parseInt(this.filter.pageNo) + parseInt(increment);

      if (this.filter.pageNo < 1) {
        this.filter.pageNo = 1;
        return;
      }

      if (this.filter.pageSize < 1) {
        this.filter.pageSize = 1;
        return;
      }

      if (this.filter.pageNo > this.totalPageCount) {
        this.filter.pageNo = this.totalPageCount;
        if (increment == 0) this.refreshGrid(); // enter pressed
        return;
      }

      this.refreshGrid();
    },
    getTotalPageCount(totalRecord, pageSize) {
      pageSize = pageSize < 1 ? 1 : pageSize;
      return (
        parseInt(totalRecord / pageSize) +
        parseInt(totalRecord % pageSize > 0 ? 1 : 0)
      );
    },
    updatePathByView(path) {
      this.$store.commit(
        globalTypes.SET_CURRENT_KO_PATH,
        this.$koPaths.negotiator[camelCase(path)]
      );
    },
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(types.GET_NEGOTIATOR_PHASES),
      this.$store.dispatch(globalTypes.GET_NEGOTIATORS),
    ]);

    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo != null) {
      this.arrPermission = this.getItemsFromSplittedString(
        userInfo.moduleFunctions
      );
      if (this.hasAuth(7478)) {
        this.filteredNegotiatorList = deepCopy(this.negotiatorList);
        this.filteredNegotiatorList = this.filteredNegotiatorList.filter(
          (x) => x.IsOnOneNegotiator != true
        );
        this.clearNegotiatorFilter = null;
      } else {
        this.filteredNegotiatorList = [];
        this.filteredNegotiatorList.push({
          Id: userInfo.userId,
          Name: userInfo.firstName + " " + userInfo.lastName,
        });
        this.clearNegotiatorFilter = this.filteredNegotiatorList[0].Id;
        this.filter.negotiatorId = this.clearNegotiatorFilter;
      }
      this.userId = parseInt(userInfo.userId);
    }
    if (userInfo != null) {
      this.userOfficeId = userInfo.officeId;
    }
    this.hasAccessOtherOffices = this.canSeeAllOffices();

    this.hasAccessTo1on1TaskDistribution = this.checkAuth(572922);

    if (!this.hasAccessTo1on1TaskDistribution) {
      if (this.hasAuth(7470)) {
        this.handleTabChange(-1, 0);
      } else if (this.hasAuth(8593)) {
        this.handleTabChange(-2, 0);
      } else if (this.hasAuth(9127)) {
        this.handleTabChange(-3, 0);
      }
    } else {
      await this.getNewTask();
    }
  },
};
</script>

<style>
.negotiatorTasks {
  display: flex;
  align-items: center;
}
.o-pag--simple {
  padding-top: 10px !important;
  justify-content: right !important;
}

.negotiatorTableScroll {
  overflow-y: auto;
  overflow-x: auto;
}
.negotiatorTaskActiveTab {
  background-color: #f7f7f7 !important;
}

.gridName {
  position: absolute;
  top: 10px;
  left: 12px;
  font-family: "Open Sans";
  font-size: 1.2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000 !important;
}

.centerBoxPosition {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.centerBox {
  width: 600px;
  height: 400px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.handShake {
  position: absolute;
  left: 0;
  top: -40px;
  font-size: 480px;
  z-index: 0;
  color: #3baedb4d;
}

.contentBox {
  position: relative;
  z-index: 1;
  text-align: center;
}
</style>
