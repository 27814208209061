<template>
  <div v-if="CustomerCopy">
    <div class="col-md-12">
      <div class="form-group row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 alignLeft">
          <div class="col-md-12 newPanelWrapper noMargin">
            <div class="row noMargin">
              <label
                style="font-size: 18px; color: #2a404c"
                class="generalLabel"
                for="contactInformation"
                >Customer Information</label
              >
            </div>
            <form
              class="form"
              role="form"
              @click="() => $emit('update:Customer', CustomerCopy)"
            >
              <div class="form-group row">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="FirstName"
                      >First Name *</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      type="text"
                      autocomplete="none"
                      id="FirstName"
                      class="generalTextBox"
                      placeholder="First Name"
                      v-model="CustomerCopy.CustomerProfile.FirstName"
                      :class="
                        v$.CustomerCopy.CustomerProfile.FirstName.$error
                          ? 'has-error'
                          : ''
                      "
                      v-on:input="checkLength"
                    />
                    <p
                      class="noPadding noMargin"
                      style="color: #ff7979"
                      v-if="isMaxLimitReachedFirstName"
                    >
                      Maximum 50 characters are allowed!
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="LastName"
                      >Last Name *</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      type="text"
                      id="LastName"
                      class="generalTextBox"
                      placeholder="Last Name"
                      v-model="CustomerCopy.CustomerProfile.LastName"
                      :class="
                        v$.CustomerCopy.CustomerProfile.LastName.$error
                          ? 'has-error'
                          : ''
                      "
                      @blur="v$.CustomerCopy.CustomerProfile.LastName.$touch"
                      v-on:input="checkLength"
                    />
                    <p
                      class="noPadding noMargin"
                      style="color: #ff7979"
                      v-if="isMaxLimitReachedLastName"
                    >
                      Maximum 50 characters are allowed!
                    </p>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="MiddleInitial"
                      >Middle Initial</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      type="text"
                      id="middleInitial"
                      class="generalTextBox"
                      placeholder=""
                      maxlength="1"
                      v-model="CustomerCopy.CustomerProfile.MiddleInitial"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="DateOfBirth"
                      >Date of Birth</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <DatePicker
                      mode="date"
                      :locale="'en-US'"
                      v-model="CustomerCopy.CustomerProfile.DateOfBirth"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          autocomplete="none"
                          id="DateOfBirthofClientP"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>

              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="MothersMaidenName"
                      >Mother's Maiden Name</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      type="text"
                      id="MothersMaidenName"
                      class="generalTextBox"
                      placeholder="Mother's Maiden Name"
                      v-model="CustomerCopy.CustomerProfile.MothersMaidenName"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="gender">Gender</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="CustomerCopy.CustomerProfile.GenderId"
                      id="Gender"
                      class="generalSelectBox form-control"
                    >
                      <option :value="convertNull('null')">Select</option>
                      <option
                        v-for="item in genderList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">SSN</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <SsnController
                      :value="CustomerCopy.CustomerProfile.SsnEncrypted"
                      @ssnChanged="ssnChanged"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="leadDebtAmount"
                      >Lead Debt Amount</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      class="generalTextBox"
                      id="leadDebtAmount"
                      type="text"
                      :value="CustomerCopy.CustomerProfile.DebtAmount"
                      @change="updateDebtAmountInput"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-xl-4 col-lg-8 col-md-6 col-sm-12"></div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel">Phones</label>
                </div>
                <div class="col-md-12 noMargin">
                  <PhoneGrid
                    :Phones="CustomerCopy.CustomerProfile.CustomerPhones"
                    @updatePhones="updatePhones"
                    :customerId="CustomerCopy.Id"
                    style="border-radius: 8px"
                  ></PhoneGrid>
                </div>
              </div>
              <div class="form-group row mt-4">
                <div class="col-md-12 noMargin">
                  <label class="generalLabel">Emails</label>
                </div>
                <div class="col-md-12 noMargin">
                  <EmailGrid
                    :Emails="CustomerCopy.CustomerProfile.CustomerEmails"
                    @updateEmails="updateEmails"
                    :customerId="CustomerCopy.Id"
                  ></EmailGrid>
                </div>
              </div>
              <div>
                <div class="row noMargin" style="position: relative">
                  <label
                    style="font-size: 18px"
                    class="generalLabel mt-4"
                    for="mailingAddress"
                    >Mailing Address</label
                  >
                  <span
                    v-if="CustomerCopy.Id > 0"
                    :class="
                      CustomerCopy.CustomerProfile.Address.ValidationStatus ==
                      'Validated'
                        ? 'badge-success'
                        : 'badge-danger'
                    "
                    class="badge"
                    style="
                      position: absolute;
                      top: 38px;
                      left: 150px;
                      padding: 5px 10px;
                    "
                  >
                    {{
                      CustomerCopy.CustomerProfile.Address.ValidationStatus
                        ? CustomerCopy.CustomerProfile.Address.ValidationStatus
                        : "Not Validated"
                    }}
                  </span>
                </div>
                <div class="form-group row mt-2">
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12">
                      <label class="generalLabel" for="AddressLine1"
                        >Address Line1</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        autocomplete="none"
                        type="text"
                        id="AddressLine1"
                        class="generalTextBox"
                        placeholder="Address Line 1"
                        v-model="CustomerCopy.CustomerProfile.Address.Line1"
                        @change="addressChange"
                        v-on:input="checkLength"
                      />
                      <p
                        v-if="isMaxLimitReachedAdress"
                        class="noMargin noPadding"
                        style="color: #ff7979"
                      >
                        Maximum 50 characters are allowed!
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12">
                      <label class="generalLabel" for="AddressLine2"
                        >Address Line2</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        autocomplete="none"
                        type="text"
                        id="AddressLine2"
                        class="generalTextBox"
                        placeholder="Address Line 2"
                        v-model="CustomerCopy.CustomerProfile.Address.Line2"
                        @change="addressChange"
                        v-on:input="checkLength"
                      />
                      <p
                        class="noMargin noPadding"
                        style="color: #ff7979"
                        v-if="isMaxLimitReachedAdress2"
                      >
                        Maximum 50 characters are allowed!
                      </p>
                    </div>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="Zip">Zip Code</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        autocomplete="none"
                        type="numeric"
                        id="Zip"
                        class="generalTextBox"
                        placeholder="Zip Code"
                        v-myMask="{ mask: ['99999'] }"
                        @change="zipChanged"
                        v-model="CustomerCopy.CustomerProfile.Address.Zip"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="City">City</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        autocomplete="none"
                        type="text"
                        id="City"
                        class="generalTextBox"
                        placeholder="City"
                        v-model="CustomerCopy.CustomerProfile.Address.City"
                        @change="addressChange"
                        v-on:input="checkLength"
                      />
                      <p
                        class="noMargin noPadding"
                        style="color: #ff7979"
                        v-if="isMaxLimitReachedCity"
                      >
                        Maximum 50 characters are allowed!
                      </p>
                    </div>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="State">State</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <select
                        v-model="CustomerCopy.CustomerProfile.Address.StateId"
                        id="State"
                        class="generalSelectBox form-control"
                        @change="addressChange"
                      >
                        <option :disabled="CustomerCopy.Id > 0" :value="null">
                          Select State
                        </option>
                        <option
                          v-for="item in states"
                          :key="item.Id"
                          :value="item.Id"
                        >
                          {{ item.Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="TimeZone"
                        >TimeZone</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <select
                        v-model="
                          CustomerCopy.CustomerProfile.Address.TimeZoneId
                        "
                        id="TimeZone"
                        class="generalSelectBox form-control"
                        @change="addressChange"
                      >
                        <option :value="convertNull('null')">
                          Select Timezone
                        </option>
                        <option
                          v-for="item in timezones"
                          :key="item.Id"
                          :value="item.Id"
                        >
                          {{ item.Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group row mt-2">
                  <UspsAddressVerification
                    v-if="CustomerCopy.CustomerProfile.Address"
                    :SalesUserId="CustomerCopy.SalesUserId"
                    :OfficeId="CustomerCopy.OfficeId"
                    :Address="CustomerCopy.CustomerProfile.Address.Line1"
                    :Address2="CustomerCopy.CustomerProfile.Address.Line2"
                    :City="CustomerCopy.CustomerProfile.Address.City"
                    :Zip="CustomerCopy.CustomerProfile.Address.Zip"
                    :State="
                      getStateShort(
                        CustomerCopy.CustomerProfile.Address.StateId
                      )
                    "
                    :CustomerId="CustomerCopy.Id"
                    @sendVerifyResult="getAddressVerificationResult"
                  />
                </div>
              </div>
              <div>
                <div class="row noMargin" style="position: relative">
                  <label
                    style="font-size: 18px"
                    class="generalLabel mt-4"
                    for="mailingAddress"
                    >Banking Information</label
                  >
                  <span
                    v-if="CustomerCopy.Id > 0"
                    :class="
                      CustomerCopy.Bank.ValidationStatus == 'Validated'
                        ? 'badge-success'
                        : 'badge-danger'
                    "
                    class="badge"
                    style="
                      position: absolute;
                      top: 38px;
                      left: 190px;
                      padding: 5px 10px;
                    "
                  >
                    {{
                      CustomerCopy.Bank.ValidationStatus
                        ? CustomerCopy.Bank.ValidationStatus
                        : "Not Validated"
                    }}
                  </span>
                </div>
                <div class="form-group row mt-2">
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12">
                      <label class="generalLabel" for="RouteNumber"
                        >Routing Number</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        autocomplete="none"
                        type="text"
                        id="RouteNumber"
                        class="generalTextBox"
                        placeholder="Routing Number"
                        v-model="CustomerCopy.Bank.RoutingNumber"
                        @blur="checkRouteNumber()"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12">
                      <label class="generalLabel">Bank Name</label>
                    </div>
                    <div class="col-md-12 noMargin">
                      <input
                        autocomplete="none"
                        type="text"
                        id="BankName"
                        class="generalTextBox"
                        placeholder="Bank Name"
                        v-model="CustomerCopy.Bank.Name"
                        @change="bankNameChange"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row mt-2">
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12">
                      <label class="generalLabel" for="slcAccountType"
                        >Account Type</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <select
                        v-model="CustomerCopy.Bank.AccountTypeId"
                        id="slcAccountType"
                        class="generalSelectBox form-control"
                      >
                        <option :value="convertNull('null')">Select</option>
                        <option
                          v-for="item in accountTypeList"
                          :key="item.Id"
                          :value="item.Id"
                        >
                          {{ item.Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 noPadding">
                    <div class="col-md-12">
                      <label class="generalLabel" for="AccountNumber"
                        >Account Number</label
                      >
                    </div>
                    <div class="col-md-12 noMargin">
                      <AccountNumberController
                        :value="CustomerCopy.Bank.AccountNumberEncrypted"
                        :authId="7522"
                        @numberChanged="numberChanged"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <div class="mb-3" v-if="CustomerCopy.Id > 0">
            <ul class="nav" style="margin-left: -0.8px; position: relative">
              <li
                v-for="tab in subTabs"
                :key="tab.id"
                style="margin: 0 12px 0 0; position: relative"
                v-show="isAuth(tab.id)"
              >
                <!--v-if="isAuth(tab.id)" temp-->
                <a
                  data-toggle="tab"
                  :aria-expanded="tab.active"
                  v-on:click.prevent="() => handleTabChange(tab)"
                  class="policySubTabItem"
                  :class="{ policySubTabItemActive: tab.active }"
                >
                  <span class="d-sm-none">
                    <i :class="tab.iconName"></i>
                  </span>
                  <span class="d-none d-sm-inline-block tab-item-label">{{
                    tab.label
                  }}</span>
                </a>
                <!-- design code, do not remove -->
                <span
                  v-if="tab.active"
                  style="
                    width: 98%;
                    height: 8px;
                    background-color: white;
                    position: absolute;
                    left: 1px;
                    bottom: -6px;
                    z-index: 1;
                  "
                ></span>
                <!-- design code, do not remove -->
              </li>
              <!-- design code, do not remove -->
              <span
                style="
                  width: 10px;
                  height: 10px;
                  background-color: #ffffff;
                  border-left: 1px solid #e6e6e6;
                  position: absolute;
                  left: -0.5px;
                  bottom: -10px;
                  z-index: 1;
                "
              ></span>
              <!-- design code, do not remove -->
            </ul>
            <div
              v-if="!dispositionBinded"
              class="col-md-12 newPanelWrapper noMargin"
              style="background-color: #ffffff"
            >
              <div class="text-center" style="margin: 6em 0">
                <i class="spinner-border text-primary m-2"></i>
              </div>
            </div>
            <DispositionPanel
              v-show="CustomerCopy.Id && subTabs[0].active && dispositionBinded"
              :customerDispositionLogs="customerDispositionLogs"
              :customerId="Customer.Id"
              :statusId="Customer.StatusId"
              :fromEdit="1"
            />
            <ReminderGrid
              v-show="CustomerCopy.Id && subTabs[1].active && reminderBinded"
              :customerReminderLogs="customerReminders"
              :customerId="Customer.Id"
              :fromEdit="1"
              :tabClicked="subTabs[1].active"
            />
            <ClientLogs
              v-show="CustomerCopy.Id && subTabs[2].active && systemLogBinded"
              :systemLogs="systemLogs"
              :customerId="Customer.Id"
              :tabClicked="subTabs[2].active"
            />
            <CallLogs
              v-show="CustomerCopy.Id && isAuth(2543) && subTabs[3].active"
              :phones="Customer.CustomerProfile.CustomerPhones"
              :tabClicked="subTabs[3].active"
            ></CallLogs>
            <ScriptTab
              v-show="CustomerCopy.Id && subTabs[4].active"
              :filteredScripts="filteredScripts"
              :languageId="CustomerCopy.LanguageId"
              :userCanSpeakSpanish="userCanSpeakSpanish"
              @updateSelectedScript="selectedScript = $event"
            ></ScriptTab>

            <div
              v-if="!isMobileDevice() && subTabs[4].active"
              class="scriptPopupButton"
              style="position: absolute; top: 58px; left: 70px"
            >
              <span
                v-if="filteredScripts.length > 0 && selectedScript != null"
                class="badge badge-success ml-2"
                style="cursor: pointer; padding: 6px"
                @click="openScriptPopup()"
              >
                <i class="fe-external-link mr-1"></i>Show as popup
              </span>
            </div>
          </div>
          <div class="col-md-12 newPanelWrapper" style="margin-top: 0px">
            <div class="row noMargin">
              <label
                style="font-size: 18px; color: #2a404c"
                class="generalLabel"
                >Financial Information</label
              >
            </div>
            <form
              @submit.prevent
              @click="() => $emit('update:Customer', CustomerCopy)"
            >
              <div class="form-group row topBorder mt-3">
                <div class="col-md-3 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="txtCustomerIncome"
                      >Customer<br />M. Income</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      v-model="CustomerCopy.CustomerProfile.Income"
                      id="txtCustomerIncome"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="txtCoCustomersIncome"
                      >Co-Customers<br />M. Income</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      class="generalTextBox"
                      v-model="coCustomersIncome"
                      id="txtCoCustomersIncome"
                      type="text"
                      disabled="disabled"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="txtCoCustomersIncome"
                      >Additional<br />Income</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      class="generalTextBox"
                      v-model="additionalIncome"
                      id="txtCoCustomersIncome"
                      type="text"
                      disabled="disabled"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel">Total<br />Income</label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      :value="
                        parseFloat(CustomerCopy.CustomerProfile.Income) +
                        coCustomersIncome +
                        additionalIncome
                      "
                      class="generalTextBox"
                      disabled="disabled"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="checkAuth(932451)"
                class="form-group row mt-2 pb-2"
                style="
                  border-top: 1px solid #dee2e6;
                  border-bottom: 1px solid #dee2e6;
                "
              >
                <div class="col-md-4 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel mt-1" for="MiddleInitial"
                      >Expense Type</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="expenseType.TypeId"
                      id="expenseTypeList"
                      class="generalSelectBox"
                    >
                      <option value="0">Select Expense Type</option>
                      <option
                        v-for="item in expenseTypeList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 col-8 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel mt-1" for="MiddleInitial"
                      >Amount</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      v-model="expenseType.Amount"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-4 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="MiddleInitial"></label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <span class="commandButtons mt-1">
                      <i
                        class="ri-add-fill text-success"
                        v-on:click.prevent="() => addExpenseType()"
                      ></i>
                      <i
                        class="ri-close-line text-danger"
                        style="color: red"
                        v-on:click.prevent="() => cancelExpenseType()"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="form-group row mt-1"
                style="max-height: 268px; overflow-x: hidden; overflow-y: auto"
              >
                <div
                  v-for="item in orderedExpenses"
                  :key="item.TypeId"
                  class="col-md-4 col-sm-12 noPadding"
                >
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="MiddleInitial">
                      {{ getExpenseName(item.TypeId) }}
                      <i
                        v-if="!pinnedExpenseIds.includes(item.TypeId)"
                        v-on:click.prevent="() => removeExpenseType(item)"
                        class="ri-close-line text-danger removeButtons"
                        style="color: red; cursor: pointer"
                      ></i>
                    </label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      v-model="item.Amount"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row topBorder align-right">
                <div class="col-md-12 text-right">
                  <label
                    style="width: 120px; text-align: left"
                    class="generalLabel"
                    for="totalExpenses"
                  >
                    Total Expenses:
                  </label>
                  <label class="generalLabel ml-2" for="totalExpenses">
                    {{ formatMoney(getExpensesTotal()) }}
                  </label>
                </div>
              </div>
              <div class="form-group row topBorder">
                <div class="col-md-12 text-right">
                  <label
                    style="width: 120px; text-align: left"
                    class="generalLabel"
                    for="availableBudget"
                  >
                    Available Budget:
                  </label>
                  <label class="generalLabel ml-2" for="availableBudget">
                    {{ formatMoney(getAvailableBudget()) }}
                  </label>
                </div>
              </div>
              <div class="row noMargin mt-4 d-flex align-items-center">
                <label
                  style="font-size: 18px"
                  class="generalLabel mt-1"
                  for="mailingAddress"
                  >Budget Details</label
                >
                <i
                  @click="toggleBudgetDetails"
                  style="font-size: 20px; cursor: pointer"
                  class="ml-2"
                  :class="
                    budgetDetailsExpanded
                      ? 'fas fa-caret-square-up'
                      : 'fas fa-caret-square-down'
                  "
                >
                </i>
              </div>
              <div v-if="budgetDetailsExpanded">
                <div class="row mt-2 budgetOddRow">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Total Monthly Income All Sources</label
                    >
                    <label class="generalLabel" style="color: green">{{
                      formatMoney(getTotalIncomeAllSources())
                    }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Monthly Program Cost</label
                    >
                    <label class="generalLabel" style="color: red">{{
                      formatMoney(getDraftAmount())
                    }}</label>
                  </div>
                </div>
                <div class="row budgetOddRow">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Total Monthly expenses excluding program cost</label
                    >
                    <label class="generalLabel" style="color: red">{{
                      formatMoney(getExpensesTotal())
                    }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Total Monthly expenses including program cost</label
                    >
                    <label class="generalLabel" style="color: red">{{
                      getTotalExpensesIncludingProgramCost()
                    }}</label>
                  </div>
                </div>
                <div class="row budgetOddRow">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Funds Available without program cost</label
                    >
                    <label
                      class="generalLabel"
                      :style="
                        getAvailableBudget() > 0 ? 'color:green;' : 'color:red'
                      "
                      >{{ formatMoney(getAvailableBudget()) }}</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Funds Available with program cost</label
                    >
                    <label
                      class="generalLabel"
                      :style="
                        getFundsWithProgramCost() > 0
                          ? 'color:green;'
                          : 'color:red'
                      "
                      >{{ formatMoney(getFundsWithProgramCost()) }}</label
                    >
                  </div>
                </div>
                <div class="row budgetOddRow">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Monthly debt-to-income ratio w/o program cost</label
                    >
                    <label class="generalLabel"
                      >{{ getMonthlyRatioWithoutProgramCost() }}%</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 d-flex">
                    <label class="generalLabel flex-fill"
                      >Monthly debt-to-income ratio w/ program cost</label
                    >
                    <label class="generalLabel"
                      >{{ getMonthlyRatioWithProgramCost() }}%</label
                    >
                  </div>
                </div>
              </div>

              <div class="row noMargin">
                <label
                  style="font-size: 18px"
                  class="generalLabel mt-4"
                  for="mailingAddress"
                  >Other Information</label
                >
              </div>
              <div
                v-if="checkAuth(permissions.CAN_SEE_CAMPAIGN_INFORMATION)"
                class="form-group row mt-2"
              >
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12">
                    <label class="generalLabel" for="slcCampaign"
                      >Campaign</label
                    >
                    {{ CustomerCopy.CustomerCampaignDetail.VendorLeadId }}
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      id="slcCampaign"
                      class="generalSelectBox form-control"
                      v-model="CustomerCopy.CustomerCampaignDetail.CampaignId"
                    >
                      <option :value="convertNull('null')">
                        Select Campaign
                      </option>
                      <option
                        v-for="item in activeCampaigns"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12">
                    <label class="generalLabel" for="hardship">Hardship</label>
                    <i
                      v-if="CustomerCopy.HardshipId > 0"
                      class="fas fa-sticky-note ml-1"
                      style="cursor: pointer"
                      @click="openHardShipNote()"
                      v-bind:class="[isHardShip ? 'closeColor' : 'activeColor']"
                    ></i>
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      id="slcHardship"
                      v-model="CustomerCopy.HardshipId"
                      class="generalSelectBox form-control"
                    >
                      <option :value="convertNull('null')">
                        Select Hardship
                      </option>
                      <option
                        v-for="item in hardshipList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-12 noMargin pt-1" v-if="isHardShip">
                    <textarea
                      v-if="CustomerCopy.HardshipId > 0"
                      id="hardShipNote"
                      class="form-control textareaInput generalSelectBox"
                      rows="5"
                      placeholder="Please write your note"
                      v-model="CustomerCopy.HardshipStatementNotes"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12">
                    <label class="generalLabel" for="occupation"
                      >Occupation</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      id="slcOccupation"
                      v-model="CustomerCopy.CustomerProfile.OccupationId"
                      class="generalSelectBox form-control"
                    >
                      <option :value="convertNull('null')">
                        Select Occupation
                      </option>
                      <option
                        v-for="item in occupationList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12">
                    <label class="generalLabel" for="slcResidenceOwnerShip"
                      >Residence Ownership</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      id="slcResidenceOwnerShip"
                      v-model="
                        CustomerCopy.CustomerProfile.ResidenceOwnershipId
                      "
                      class="generalSelectBox form-control"
                    >
                      <option :value="convertNull('null')">Select</option>
                      <option
                        v-for="item in residenceOwnershipTypeList"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-md-6 col-sm-12 noPadding"
                  v-if="CustomerCopy.CustomerProfile.ResidenceOwnershipId == 1"
                >
                  <div class="col-md-12">
                    <label class="generalLabel" for="MiddleInitial"
                      >Residence Years</label
                    >
                  </div>
                  <div class="col-md-12">
                    <input
                      autocomplete="none"
                      v-model="CustomerCopy.CustomerProfile.ResidenceYears"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'numeric',
                        min: 0,
                        max: 100,
                        digits: 0,
                        allowMinus: false,
                        rightAlign: false,
                      }"
                      @change="residenceYearsChanged"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="MiddleInitial"
                      >Declared Bankruptcy?</label
                    >
                    <input
                      autocomplete="none"
                      v-model="CustomerCopy.CustomerProfile.DeclaredBankruptcy"
                      type="checkbox"
                      class="checkbox-input generalCheckBox ml-2"
                      style="
                        border-radius: 4px !important;
                        border: solid 1px #d1d1d1 !important;
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row mt-2">
                <div
                  class="col-lg-4 col-md-12 col-sm-12 noPadding"
                  v-if="CustomerCopy.CustomerProfile.DeclaredBankruptcy"
                >
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="slcChapter"
                      >Chapter:</label
                    >
                    <select
                      id="slcChapter"
                      class="generalSelectBox form-control"
                      v-model="CustomerCopy.CustomerProfile.ChapterId"
                    >
                      <option :value="convertNull('null')">Select</option>
                      <option :value="parseInt('1')">Chapter 7</option>
                      <option :value="parseInt('2')">Chapter 13</option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-lg-4 col-md-12 col-sm-12 noPadding"
                  v-if="CustomerCopy.CustomerProfile.DeclaredBankruptcy"
                >
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="txtYearFiled"
                      >Year Filed:</label
                    >
                    <input
                      autocomplete="none"
                      id="txtYearFiled"
                      v-model="CustomerCopy.CustomerProfile.YearFiled"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'numeric',
                        min: 1900,
                        max: new Date().getFullYear(),
                        rightAlign: false,
                        digits: 0,
                        allowMinus: false,
                      }"
                      @change="yearFiledChanged"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-4 col-md-12 col-sm-12 noPadding"
                  v-if="CustomerCopy.CustomerProfile.DeclaredBankruptcy"
                >
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="txtYearFiled"
                      >Year Discharged:</label
                    >
                    <input
                      autocomplete="none"
                      id="txtYearDischarged"
                      v-model="CustomerCopy.CustomerProfile.YearDischarged"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'numeric',
                        min: 1900,
                        max: new Date().getFullYear(),
                        rightAlign: false,
                        digits: 0,
                        allowMinus: false,
                      }"
                      @change="yearDischargedChanged"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="CustomerCopy.IsTermSettlement"
                class="form-group row mt-2"
              >
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12">
                    <label class="generalLabel" for="TermSettlementStartDate"
                      >Term Settlement Start Date</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      type="text"
                      id="TermSettlementStartDate"
                      class="generalTextBox"
                      placeholder="TermSettlementStartDate"
                      disabled="true"
                      :value="
                        new Date(
                          CustomerCopy.TermSettlementStartDate
                        ).toLocaleDateString()
                      "
                    />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 noPadding">
                  <div class="col-md-12">
                    <label class="generalLabel" for="TermSettlementEndDate"
                      >Term Settlement End Date</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <input
                      autocomplete="none"
                      type="text"
                      id="TermSettlementEndDate"
                      class="generalTextBox"
                      placeholder="TermSettlementEndDate"
                      disabled="true"
                      :value="
                        new Date(
                          CustomerCopy.TermSettlementEndDate
                        ).toLocaleDateString()
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                class="row noMargin"
                v-if="checkAuth(7581) || checkAuth(7582)"
              >
                <label style="font-size: 18px" class="generalLabel mt-2"
                  >Tags</label
                >
              </div>
              <div
                v-if="checkAuth(932451)"
                class="form-group row mt-2 pb-2"
                style="
                  border-top: 1px solid #dee2e6;
                  border-bottom: 1px solid #dee2e6;
                "
              >
                <div class="col-md-4 col-sm-12 noPadding">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel mt-1" for="TagType"
                      >Tag Type</label
                    >
                  </div>
                  <div class="col-md-12 noMargin">
                    <select
                      v-model="selectedTagType.Id"
                      @change="bindTagType(selectedTagType.Id)"
                      id="TagType"
                      class="generalSelectBox"
                    >
                      <option :value="null">Select Tag Type</option>
                      <option
                        v-for="item in sortedTagTypes"
                        :key="item.Id"
                        :value="item.Id"
                      >
                        {{ item.TagName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4 col-8 noPadding" v-if="selectedTagType.Id">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel mt-1">Value</label>
                  </div>
                  <div
                    class="col-md-12 noMargin"
                    v-if="
                      selectedTagType.TagDataType == 'Option Box' &&
                      selectedTagType.TagDataOptions
                    "
                  >
                    <select
                      v-model="selectedTagValue"
                      class="form-control"
                      id="optionBox"
                    >
                      <option :value="null">Please Select</option>
                      <option
                        v-for="tag in selectedTagType.TagDataOptions"
                        :key="tag.Id"
                      >
                        {{ tag.Name }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'CheckItem'"
                  >
                    <input
                      autocomplete="none"
                      v-model="selectedTagValue"
                      type="checkbox"
                      class="checkbox-input generalCheckBox ml-2"
                      style="
                        border-radius: 4px !important;
                        border: solid 1px #d1d1d1 !important;
                      "
                    />
                  </div>
                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Text'"
                  >
                    <input
                      v-model="selectedTagValue"
                      autocomplete="none"
                      class="generalTextBox"
                      type="text"
                    />
                  </div>

                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Expense'"
                  >
                    <input
                      v-model="selectedTagValue"
                      autocomplete="none"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>

                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Number'"
                  >
                    <input
                      v-model="selectedTagValue"
                      autocomplete="none"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'numeric',
                        min: 0,
                        rightAlign: false,
                      }"
                    />
                  </div>
                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Money'"
                  >
                    <input
                      v-model="selectedTagValue"
                      autocomplete="none"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>
                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Income'"
                  >
                    <input
                      v-model="selectedTagValue"
                      autocomplete="none"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'currency',
                        prefix: '$',
                        rightAlign: false,
                      }"
                    />
                  </div>

                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Percentage'"
                  >
                    <input
                      v-model="selectedTagValue"
                      autocomplete="off"
                      class="generalTextBox"
                      type="text"
                      v-myMask="{
                        alias: 'decimal',
                        min: 0,
                        max: 100,
                        digits: 2,
                        rightAlign: false,
                      }"
                    />
                  </div>
                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Date'"
                  >
                    <DatePicker
                      mode="date"
                      :locale="'en-US'"
                      v-model="selectedTagValue"
                      :popover="{ visibility: 'click' }"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          autocomplete="none"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </DatePicker>
                  </div>
                  <div
                    class="col-md-12 noMargin"
                    v-if="selectedTagType.TagDataType == 'Date and Time'"
                  >
                    <DatePicker
                      mode="datetime"
                      :locale="'en-US'"
                      v-model="selectedTagValue"
                      :popover="{ visibility: 'click' }"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          autocomplete="none"
                          class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
                <div class="col-md-4 col-4 noPadding" v-if="selectedTagType.Id">
                  <div class="col-md-12 noMargin">
                    <label class="generalLabel" for="MiddleInitial"></label>
                  </div>
                  <div class="col-md-12 noMargin">
                    <span class="commandButtons mt-1">
                      <i @click="addTag()" class="ri-add-fill text-success"></i>
                      <i
                        @click="cancelTag()"
                        class="ri-close-line text-danger"
                        style="color: red"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="!tagsAdding && checkAuth(7581)">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                  "
                >
                  <div class="row noMargin" v-if="isSections">
                    <label style="font-size: 18px" class="generalLabel mt-2"
                      >Sections</label
                    >
                  </div>

                  <div v-if="isSections">
                    <button
                      type="button"
                      class="btn btn-light btn-sm mr-2"
                      @click="toggleAllDivExpand"
                    >
                      Expand All
                    </button>
                    <button
                      type="button"
                      class="btn btn-light btn-sm mr-2"
                      @click="toggleAllDivCollapse"
                    >
                      Collapse All
                    </button>
                  </div>
                </div>

                <div
                  v-for="(section, index) in uiTags.filter(
                    (x) => x.IsSection == true
                  )"
                  :key="index"
                  class="form-group mt-2"
                >
                  <div
                    @click="toggleDiv(index)"
                    style="
                      background-color: white;
                      border: 1px solid #dee2e6;
                      padding: 7px;
                      border-radius: 5px;
                      display: flex;
                      justify-content: space-between;
                      cursor: pointer;
                    "
                  >
                    <div>
                      <i
                        class="ml-2"
                        :class="
                          section.IsOpen == true ? 'fas fa-tags' : 'fas fa-tag'
                        "
                        :style="
                          section.IsOpen ? 'color:#3bafda' : 'color:#4b4b4b'
                        "
                      ></i>
                      <a
                        class="ml-1"
                        style="font-weight: 600"
                        :style="
                          section.IsOpen ? 'color:#3bafda' : 'color:#4b4b4b'
                        "
                      >
                        {{ section.Name }}</a
                      >
                    </div>

                    <div>
                      <span
                        style="color: #3bafda; cursor: pointer"
                        @click="saveCustomerFromTags"
                      >
                        <i class="fas fa-save mr-3" style="font-size: 16px"></i
                      ></span>
                      <i
                        class="mr-2"
                        :class="
                          section.IsOpen == true
                            ? 'fas fa-angle-up'
                            : 'fas fa-angle-down'
                        "
                        :style="
                          section.IsOpen ? 'color:#3bafda' : 'color:#4b4b4b'
                        "
                      ></i>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-end;
                      "
                      v-if="section.IsOpen"
                    >
                      <div
                        v-for="item in getSectionTags(section.Id)"
                        :key="item.TagTypeId"
                        class="col-md-6 col-sm-12 noPadding"
                      >
                        <div class="col-md-12 noMargin">
                          <label class="generalLabel" for="tagTypeName">
                            {{ getTagTypeName(item.TagTypeId) }}
                            <i
                              v-if="
                                checkAuth(7582) &&
                                !pinnedTagIds.includes(item.TagTypeId)
                              "
                              v-on:click.prevent="
                                () => removeTag(item.TagTypeId)
                              "
                              class="ri-close-line text-danger removeButtons"
                              style="color: red; cursor: pointer"
                            ></i>
                          </label>
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Money'"
                        >
                          <input
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            autocomplete="none"
                            class="generalTextBox"
                            type="text"
                            v-myMask="{
                              alias: 'currency',
                              prefix: '$',
                              rightAlign: false,
                            }"
                          />
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'CheckItem'"
                        >
                          <input
                            autocomplete="none"
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            type="checkbox"
                            class="checkbox-input generalCheckBox ml-2"
                            style="
                              border-radius: 4px !important;
                              border: solid 1px #d1d1d1 !important;
                            "
                          />
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Option Box'"
                        >
                          <select
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            class="form-control"
                            id="optionBox"
                          >
                            <option
                              v-for="tag in getTagDataOptions(item.TagTypeId)"
                              :key="tag.Id"
                            >
                              {{ tag.Name }}
                            </option>
                          </select>
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Text'"
                        >
                          <input
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            autocomplete="none"
                            class="generalTextBox"
                            type="text"
                          />
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Number'"
                        >
                          <input
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            autocomplete="none"
                            class="generalTextBox"
                            type="text"
                            v-myMask="{
                              alias: 'numeric',
                              min: 0,
                              rightAlign: false,
                            }"
                          />
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Expense'"
                        >
                          <input
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            autocomplete="none"
                            class="generalTextBox"
                            type="text"
                            v-myMask="{
                              alias: 'currency',
                              prefix: '$',
                              rightAlign: false,
                            }"
                          />
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Income'"
                        >
                          <input
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            autocomplete="none"
                            class="generalTextBox"
                            type="text"
                            v-myMask="{
                              alias: 'currency',
                              prefix: '$',
                              rightAlign: false,
                            }"
                          />
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Percentage'"
                        >
                          <input
                            :disabled="!canEditTag(item)"
                            v-model="item.TagValue"
                            autocomplete="none"
                            class="generalTextBox"
                            type="text"
                            v-myMask="{
                              alias: 'decimal',
                              min: 0,
                              max: 100,
                              digits: 2,
                              rightAlign: false,
                            }"
                          />
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="getTagDataType(item.TagTypeId) == 'Date'"
                        >
                          <DatePicker
                            mode="date"
                            :locale="'en-US'"
                            v-model="item.TagValue"
                            :popover="{ visibility: 'click' }"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                :disabled="!canEditTag(item)"
                                autocomplete="none"
                                class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </DatePicker>
                        </div>

                        <div
                          class="col-md-12 noMargin"
                          v-if="
                            getTagDataType(item.TagTypeId) == 'Date and Time'
                          "
                        >
                          <DatePicker
                            mode="datetime"
                            :locale="'en-US'"
                            v-model="item.TagValue"
                            :popover="{ visibility: 'click' }"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                :disabled="!canEditTag(item)"
                                autocomplete="none"
                                class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-end;
                  "
                >
                  <div
                    v-for="item in getSectionTags(-1)"
                    :key="item.TagTypeId"
                    class="col-md-6 col-sm-12 noPadding"
                  >
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="tagTypeName">
                        {{ getTagTypeName(item.TagTypeId) }}
                        <i
                          v-if="
                            checkAuth(7582) &&
                            !pinnedTagIds.includes(item.TagTypeId)
                          "
                          v-on:click.prevent="() => removeTag(item.TagTypeId)"
                          class="ri-close-line text-danger removeButtons"
                          style="color: red; cursor: pointer"
                        ></i>
                      </label>
                    </div>
                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'CheckItem'"
                    >
                      <input
                        autocomplete="none"
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        type="checkbox"
                        class="checkbox-input generalCheckBox ml-2"
                        style="
                          border-radius: 4px !important;
                          border: solid 1px #d1d1d1 !important;
                        "
                      />
                    </div>
                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Option Box'"
                    >
                      <select
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        class="form-control"
                        id="optionBox"
                      >
                        <option
                          v-for="tag in getTagDataOptions(item.TagTypeId)"
                          :key="tag.Id"
                        >
                          {{ tag.Name }}
                        </option>
                      </select>
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Text'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Expense'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'currency',
                          prefix: '$',
                          rightAlign: false,
                        }"
                      />
                    </div>
                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Number'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'numeric',
                          min: 0,
                          rightAlign: false,
                        }"
                      />
                    </div>
                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Money'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'currency',
                          prefix: '$',
                          rightAlign: false,
                        }"
                      />
                    </div>
                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Income'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'currency',
                          prefix: '$',
                          rightAlign: false,
                        }"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Percentage'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'decimal',
                          min: 0,
                          max: 100,
                          digits: 2,
                          rightAlign: false,
                        }"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Date'"
                    >
                      <DatePicker
                        mode="date"
                        :locale="'en-US'"
                        v-model="item.TagValue"
                        :popover="{ visibility: 'click' }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            :disabled="!canEditTag(item)"
                            autocomplete="none"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </DatePicker>
                    </div>
                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Date and Time'"
                    >
                      <DatePicker
                        mode="datetime"
                        :locale="'en-US'"
                        v-model="item.TagValue"
                        :popover="{ visibility: 'click' }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            :disabled="!canEditTag(item)"
                            autocomplete="none"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>

                <div
                  class="noMargin"
                  v-if="getUnpinnedTags().length > 0 && uiTags.length > 0"
                >
                  <label style="font-size: 18px" class="generalLabel mt-2"
                    >Other</label
                  >
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-end;
                  "
                >
                  <div
                    v-for="item in getUnpinnedTags()"
                    :key="item.TagTypeId"
                    class="col-md-6 col-sm-12 noPadding"
                  >
                    <div class="col-md-12 noMargin">
                      <label class="generalLabel" for="tagTypeName">
                        {{ getTagTypeName(item.TagTypeId) }}
                        <i
                          v-if="
                            canEditTag(item) &&
                            !pinnedTagIds.includes(item.TagTypeId)
                          "
                          v-on:click.prevent="() => removeTag(item.TagTypeId)"
                          class="ri-close-line text-danger removeButtons"
                          style="color: red; cursor: pointer"
                        ></i>
                      </label>
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'CheckItem'"
                    >
                      <input
                        autocomplete="none"
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        type="checkbox"
                        class="checkbox-input generalCheckBox ml-2"
                        style="
                          border-radius: 4px !important;
                          border: solid 1px #d1d1d1 !important;
                        "
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Option Box'"
                    >
                      <select
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        class="form-control"
                        id="optionBox"
                      >
                        <option
                          v-for="tag in getTagDataOptions(item.TagTypeId)"
                          :key="tag.Id"
                        >
                          {{ tag.Name }}
                        </option>
                      </select>
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Text'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Number'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'numeric',
                          min: 0,
                          rightAlign: false,
                        }"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Money'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'currency',
                          prefix: '$',
                          rightAlign: false,
                        }"
                      />
                    </div>
                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Income'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'currency',
                          prefix: '$',
                          rightAlign: false,
                        }"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Expense'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'currency',
                          prefix: '$',
                          rightAlign: false,
                        }"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Percentage'"
                    >
                      <input
                        :disabled="!canEditTag(item)"
                        v-model="item.TagValue"
                        autocomplete="none"
                        class="generalTextBox"
                        type="text"
                        v-myMask="{
                          alias: 'decimal',
                          min: 0,
                          max: 100,
                          digits: 2,
                          rightAlign: false,
                        }"
                      />
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Date'"
                    >
                      <DatePicker
                        mode="date"
                        :locale="'en-US'"
                        v-model="item.TagValue"
                        :popover="{ visibility: 'click' }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            :disabled="!canEditTag(item)"
                            autocomplete="none"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </DatePicker>
                    </div>

                    <div
                      class="col-md-12 noMargin"
                      v-if="getTagDataType(item.TagTypeId) == 'Date and Time'"
                    >
                      <DatePicker
                        mode="datetime"
                        :locale="'en-US'"
                        v-model="item.TagValue"
                        :popover="{ visibility: 'click' }"
                      >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            :disabled="!canEditTag(item)"
                            autocomplete="none"
                            class="form-control px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div v-for="item in tabs" :key="item.id">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import globalTypes from "@/store/types";
import types from "./types";
import dispositionTypes from "@/views/agent/Disposition/types";
import notificationTypes from "@/views/agent/Notification/types";
import api from "@/services/client/customerApi";
import to from "@/helpers/to";
import EmailGrid from "@/components/Grids/EmailGrid";
import PhoneGrid from "@/components/Grids/PhoneGrid";
import SsnController from "@/components/SsnController";
import ClientLogs from "@/views/agent/EditClient/ClientLogs";
import ReminderGrid from "@/views/agent/Notification/ReminderGrid";
import DispositionPanel from "@/views/agent/Disposition/DispositionPanel";
import CallLogs from "@/views/agent/CallLogs/CallLogs";
import ScriptTab from "@/views/agent/Scripts/Scripts";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import formatMixin from "@/mixins/formatMixin";
import _orderby from "lodash";
import { mapState } from "vuex";
import deepCopy from "@/helpers/deepCopy";
import useValidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { DatePicker } from "v-calendar";
import UspsAddressVerification from "@/components/UspsAddressVerification";
import AccountNumberController from "@/components/AccountNumberController";
import { PERMISSIONS } from "@/constants/Permissions";
import { TAG_TYPES } from "@/constants/TagManagement";

export default {
  name: "ClientProfile",
  components: {
    EmailGrid,
    PhoneGrid,
    SsnController,
    DispositionPanel,
    DatePicker,
    ClientLogs,
    ReminderGrid,
    CallLogs,
    ScriptTab,
    UspsAddressVerification,
    AccountNumberController,
  },
  props: {
    Customer: {
      Type: Object,
      default: () => null,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    Customer: {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(this.Customer) != JSON.stringify(this.CustomerCopy)
        ) {
          this.CustomerCopy = Object.assign({}, this.Customer);
          this.setCustomerExpenseUiSettings();
          this.setCustomerTagsUiSettings();
        }
      },
      deep: true,
    },
    checkIsSection(newVal) {
      this.isSections = newVal;
    },
  },
  mixins: [utilitiesMixin, formatMixin],
  data() {
    return {
      budgetDetailsExpanded: false,
      saveClickTime: null,
      isSections: false,
      showAllDiv: false,
      tagsAdding: false,
      uiTags: [],
      showDiv: null,
      isMaxLimitReachedFirstName: false,
      isMaxLimitReachedLastName: false,
      isMaxLimitReachedAdress: false,
      isMaxLimitReachedAdress2: false,
      isMaxLimitReachedCity: false,
      oldValue: null,
      selectedTagType: { Id: null, TagName: "", TagDataType: null },
      selectedTagValue: null,
      pinnedExpenseIds: [],
      pinnedTagIds: [],
      customerReminders: [],
      customerDispositionLogs: [],
      systemLogs: [],
      dispositionBinded: true,
      reminderBinded: true,
      systemLogBinded: true,
      isHardShip: false,
      CustomerCopy: Object.assign({}, this.Customer),
      v$: useValidate(),
      date: null,
      discountListModified: [],
      selectedRow: {
        CustomerId: this.Customer.Id,
        UserId: JSON.parse(sessionStorage.getItem("userInfo")).userId,
        Name: "",
        CustomerDispositionId: "",
        CommentText: "",
      },
      userRoles: JSON.parse(sessionStorage.getItem("userInfo")).roles,
      saving: false,
      subTabs: [
        {
          id: 1,
          name: "dispositions",
          label: "Dispositions",
          iconName: " fas fa-edit",
          active: true,
        },
        {
          id: 2,
          name: "reminders",
          label: "Reminders",
          iconName: "far fa-clock",
          active: false,
        },
        {
          id: 3,
          name: "logs",
          label: "Logs",
          iconName: "fas fa-list",
          active: false,
        },
        {
          id: 4,
          name: "callLogs",
          label: "Call Logs",
          iconName: " fas fa-phone-square",
          active: false,
        },
        {
          id: 5,
          name: "scriptTab",
          label: "Scripts",
          iconName: " fas fa-clipboard-list",
          active: false,
        },
      ],
      selectedScript: null,
      expenseType: { TypeId: 0, Name: "", Amount: 0 },
      permissions: PERMISSIONS,
    };
  },
  computed: mapState({
    customerTagTypes: (state) => state.globals.customerTagTypes,
    sortedTagTypes() {
      if (this.checkAuth(7586)) {
        return this.customerTagTypes
          .slice()
          .sort((a, b) => a.TagName.localeCompare(b.TagName));
      } else {
        return this.customerTagTypes
          .filter((item) => !item.IsHidden)
          .sort((a, b) => a.TagName.localeCompare(b.TagName));
      }
    },
    states: (state) => state.globals.states,
    timezones: (state) => state.globals.timezones,
    genderList: (state) => state.globals.genders,
    hardshipList: (state) => state.globals.hardshipList,
    occupationList: (state) => state.globals.occupationList,
    accountTypeList: (state) => state.globals.accountTypeList,
    residenceOwnershipTypeList: (state) =>
      state.globals.residenceOwnershipTypeList,
    expenseTypeList: (state) => state.globals.expenseTypeList,
    scripts: (state) => state.globals.scriptList,
    campaignList: (state) => state.globals.campaignList,
    activeCampaigns() {
      let newCampaignList = deepCopy(
        this.campaignList.filter((x) => x.IsActive)
      );

      if (
        this.Customer.CustomerCampaignDetail != null &&
        this.Customer.CustomerCampaignDetail.CampaignId > 0
      ) {
        let indx = newCampaignList.findIndex(
          (x) => x.CampaignId == this.Customer.CustomerCampaignDetail.CampaignId
        );
        if (indx < 0) {
          newCampaignList.push({
            Id: this.Customer.CustomerCampaignDetail.CampaignId,
            Name: this.Customer.CustomerCampaignDetail.Name,
          });
        }
      }

      return newCampaignList;
      //return this.campaignList.filter((x) => x.IsActive);
    },
    customerStatuses: (state) => state.globals.customerStatuses,

    filteredScripts() {
      let scriptList = [];
      if (
        this.userCanSpeakSpanish == true &&
        this.CustomerCopy.LanguageId == 2
      ) {
        scriptList = this.scripts.filter((x) => x.SpanishScript != null);
      } else {
        scriptList = this.scripts;
      }

      if (scriptList.length > 0) {
        return _orderby.orderBy(
          scriptList
            .filter((x) => x.IsActive)
            .filter(
              (x) =>
                x.Roles.length == 0 ||
                (x.Roles.length > 0 && x.Roles.map((y) => +y.Id).includes(0)) ||
                (x.Roles.length > 0 &&
                  this.findCommonElements(
                    x.Roles.map((y) => +y.Id),
                    this.userRoles
                  ))
            )
            .filter(
              (x) =>
                x.Statuses.length == 0 ||
                (x.Statuses.length > 0 &&
                  x.Statuses.map((y) => +y.Id).includes(0)) ||
                (x.Statuses.length > 0 &&
                  x.Statuses.map((y) => y.Id).includes(this.Customer.StatusId))
            ),
          "DisplayOrder"
        );
      } else {
        return [];
      }
    },
    coCustomersIncome() {
      let totalCoCustomerIncome = 0;
      if (this.CustomerCopy.CoCustomerProfiles.length) {
        let includedCoCustomers = this.CustomerCopy.CoCustomerProfiles.filter(
          (x) => !x.IsExcluded
        );

        for (let item of includedCoCustomers) {
          totalCoCustomerIncome += parseFloat(item.Income);
        }
      }
      return totalCoCustomerIncome;
    },
    allUiSettings: (state) => state.globals.allUiSettings,
    orderedExpenses() {
      return this.CustomerCopy && this.CustomerCopy.CustomerExpenses.length > 0
        ? _orderby.orderBy(this.CustomerCopy.CustomerExpenses, "UiOrder", "asc")
        : [];
    },
    orderedTags() {
      return this.CustomerCopy && this.CustomerCopy.CustomerTags.length > 0
        ? _orderby.orderBy(this.CustomerCopy.CustomerTags, "UiOrder", "asc")
        : [];
    },

    checkIsSection() {
      return this.uiTags.some((tag) => tag.IsSection);
    },
    additionalIncome() {
      let rtn = 0;
      if (
        this.CustomerCopy &&
        this.CustomerCopy.CustomerTags.length > 0 &&
        this.customerTagTypes &&
        this.customerTagTypes.length > 0
      ) {
        let incomeTagIds = this.customerTagTypes
          .filter((x) => x.TagDataType == "Income")
          .map((x) => x.Id);
        let customerIncomes = this.CustomerCopy.CustomerTags.filter((x) =>
          incomeTagIds.includes(x.TagTypeId)
        );
        let totalTagIncome = 0;
        for (let item of customerIncomes) {
          totalTagIncome += item.TagValue ? parseFloat(item.TagValue) : 0;
        }
        rtn = totalTagIncome;
      }
      return rtn;
    },
  }),

  unmounted() {
    //  this.emptyTheObjectsOnUnmount();
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(globalTypes.GET_GENDERS),
      this.$store.dispatch(globalTypes.GET_HARDSHIPS_LIST),
      this.$store.dispatch(globalTypes.GET_OCCUPATIONS_LIST),
      this.$store.dispatch(globalTypes.GET_RESIDENCE_OWNERSHIP_TYPES),
      this.$store.dispatch(globalTypes.GET_EXPENSE_TYPES_LIST),
      this.$store.dispatch(globalTypes.GET_ACCOUNT_TYPES_LIST),
      this.$store.dispatch(globalTypes.GET_SCRIPTS),
      this.$store.dispatch(globalTypes.GET_STATUS_NAMES),
      this.$store.dispatch(globalTypes.GET_CAMPAIGNLIST, {
        Roles: this.userRoles,
      }),
      this.$store.dispatch(globalTypes.GET_CUSTOMER_TAG_TYPE),
      this.$store.dispatch(globalTypes.GET_UI_SETTINGS),
    ]);

    if (!this.CustomerCopy.Id) {
      if (this.customerStatuses.length == 0) {
        await this.$store.dispatch(globalTypes.GET_STATUS_NAMES);
      }

      if (this.customerStatuses.length > 0) {
        let priorityList = this.customerStatuses.map((x) => x.Priority);

        let highestPriority = Math.min.apply(Math, priorityList);

        let beginningStatusId = this.customerStatuses.find(
          (x) => x.Priority == highestPriority
        ).Id;

        if (beginningStatusId) this.CustomerCopy.StatusId = beginningStatusId;
      }

      if (
        this.CustomerCopy.SalesUserId == 0 ||
        this.CustomerCopy.SalesUserId == null
      ) {
        // SET ASSIGNEDUSER for a new Client
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo && userInfo.userId) {
          this.CustomerCopy.SalesUserId = userInfo.userId;
          this.userCanSpeakSpanish = userInfo.canSpeakSpanish;
        }
      }
    }

    if (this.Customer.Id > 0) {
      await this.getCustomerDispoLogs();

      await this.getCustomerReminders();

      this.isHardShip =
        this.CustomerCopy.HardshipId > 0 &&
        this.CustomerCopy.HardshipStatementNotes;
    }

    this.$Emitter.on("updateCustomerDispoLogs", async (data) => {
      if (this.Customer.Id == data.CustomerId) {
        await this.getCustomerDispoLogs();
      }
    });
    this.$Emitter.on("updateReminderLogs", async (data) => {
      if (data && data.CustomerId && this.Customer.Id == data.CustomerId) {
        await this.getCustomerReminders();
      }
    });

    this.setCustomerExpenseUiSettings();
    this.setCustomerTagsUiSettings();

    this.$Emitter.on("updateCustomerProfileTags", async () => {
      this.setCustomerTagsUiSettings();
    });
    this.oldValue = deepCopy(this.CustomerCopy);
  },

  methods: {
    async toggleBudgetDetails() {
      this.budgetDetailsExpanded = !this.budgetDetailsExpanded;
    },
    getMonthlyRatioWithoutProgramCost() {
      let expenses = this.getExpensesTotal();
      let incomes = this.getTotalIncomeAllSources();

      let ratio = this.calculatePercentage(expenses, incomes).toFixed(2);
      return ratio;
    },
    getMonthlyRatioWithProgramCost() {
      let expenses = this.getExpensesTotal();
      let incomes = this.getTotalIncomeAllSources();
      let draft = this.getDraftAmount();

      let ratio = this.calculatePercentage(
        parseFloat(expenses + draft),
        incomes
      ).toFixed(2);
      return ratio;
    },
    getDraftAmount() {
      let rtn = 0;
      if (
        this.CustomerCopy &&
        this.CustomerCopy.CustomerGlobalInformation &&
        this.CustomerCopy.CustomerGlobalInformation.DraftAmount
      ) {
        let dAmount = parseFloat(
          this.CustomerCopy.CustomerGlobalInformation.DraftAmount
        );
        if (dAmount > 0) {
          rtn = dAmount;
        }
      }
      return rtn;
    },
    getFundsWithProgramCost() {
      let rtn = this.getAvailableBudget() - this.getDraftAmount();
      return rtn;
    },
    getTotalExpensesIncludingProgramCost() {
      let rtn = this.getExpensesTotal() + this.getDraftAmount();
      return this.formatMoney(rtn);
    },
    canEditTag(item) {
      if (item.IsElevated == true) {
        return this.checkAuth(7582) && this.checkAuth(7584);
      } else {
        return this.checkAuth(7582);
      }
    },
    updateTags() {
      this.setCustomerTagsUiSettings();
    },
    saveCustomerFromTags() {
      if (
        this.saveClickTime &&
        this.getTimeDifference(new Date(), this.saveClickTime) < 2
      )
        return;
      this.saveClickTime = new Date();
      this.$emit("saveCustomerFromTags");
    },

    getSectionTags(sectionId) {
      let tagIds = [];
      let sectionTags = [];
      let defaultValueTags = [];

      let tags = this.uiTags.filter(
        (y) => y.SectionId == sectionId && !y.IsSection
      );

      defaultValueTags = tags.filter((tag) => tag.DefaultValue);

      if (tags && tags.length > 0) {
        tagIds = tags.map((x) => x.Id);
      }
      if (tagIds && tagIds.length > 0) {
        sectionTags = this.orderedTags.filter(
          (x) => tagIds.includes(x.TagTypeId) && !this.isTagHidden(x.TagTypeId)
        );

        if (defaultValueTags.length > 0) {
          const defaultValueMap = new Map(
            defaultValueTags.map((tag) => [tag.Id, tag.DefaultValue])
          );

          sectionTags.forEach((sectionTag) => {
            if (
              !sectionTag.TagValue &&
              defaultValueMap.has(sectionTag.TagTypeId)
            ) {
              if (
                this.getTagDataType(sectionTag.TagTypeId) ===
                TAG_TYPES.CHECK_ITEM
              ) {
                var checkItemValue =
                  defaultValueMap.get(sectionTag.TagTypeId).toLowerCase() ===
                  "true";
                sectionTag.TagValue = checkItemValue;
              } else {
                sectionTag.TagValue = defaultValueMap.get(sectionTag.TagTypeId);
              }
            }
          });
        }
      }
      return sectionTags;
    },

    getUnpinnedTags() {
      return this.orderedTags.filter(
        (x) =>
          !this.pinnedTagIds.includes(x.TagTypeId) &&
          !this.isTagHidden(x.TagTypeId)
      );
    },

    isTagHidden(id) {
      let rtn = false;
      if (!this.checkAuth(7586)) {
        if (this.customerTagTypes.length > 0 && id > 0) {
          let index = this.customerTagTypes.findIndex((x) => x.Id == id);
          rtn = index > -1 ? this.customerTagTypes[index].IsHidden : false;
        }
      }
      return rtn;
    },

    toggleDiv(index) {
      let newList = this.uiTags.filter((x) => x.IsSection == true);
      newList[index].IsOpen = !newList[index].IsOpen;
    },

    toggleAllDivExpand() {
      this.uiTags.forEach((item) => {
        item.IsOpen = true;
      });
    },
    toggleAllDivCollapse() {
      this.uiTags.forEach((item) => {
        item.IsOpen = false;
      });
    },

    checkLength() {
      if (
        this.CustomerCopy.CustomerProfile.Address.Line1 &&
        this.CustomerCopy.CustomerProfile.Address.Line1.length > 50
      ) {
        this.CustomerCopy.CustomerProfile.Address.Line1 =
          this.CustomerCopy.CustomerProfile.Address.Line1.slice(0, 50);
        this.isMaxLimitReachedAdress = true;
      } else {
        this.isMaxLimitReachedAdress = false;
      }

      if (
        this.CustomerCopy.CustomerProfile.Address.Line2 &&
        this.CustomerCopy.CustomerProfile.Address.Line2.length > 50
      ) {
        this.CustomerCopy.CustomerProfile.Address.Line2 =
          this.CustomerCopy.CustomerProfile.Address.Line2.slice(0, 50);
        this.isMaxLimitReachedAdress2 = true;
      } else {
        this.isMaxLimitReachedAdress2 = false;
      }

      if (
        this.CustomerCopy.CustomerProfile.Address.City &&
        this.CustomerCopy.CustomerProfile.Address.City.length > 50
      ) {
        this.CustomerCopy.CustomerProfile.Address.City =
          this.CustomerCopy.CustomerProfile.Address.City.slice(0, 50);
        this.isMaxLimitReachedCity = true;
      } else {
        this.isMaxLimitReachedCity = false;
      }

      if (
        this.CustomerCopy.CustomerProfile.LastName &&
        this.CustomerCopy.CustomerProfile.LastName.length > 50
      ) {
        this.CustomerCopy.CustomerProfile.LastName =
          this.CustomerCopy.CustomerProfile.FirstName.slice(0, 50);
        this.isMaxLimitReachedLastName = true;
      } else {
        this.isMaxLimitReachedLastName = false;
      }

      if (
        this.CustomerCopy.CustomerProfile.FirstName &&
        this.CustomerCopy.CustomerProfile.FirstName.length > 50
      ) {
        this.CustomerCopy.CustomerProfile.FirstName =
          this.CustomerCopy.CustomerProfile.FirstName.slice(0, 50);
        this.isMaxLimitReachedFirstName = true;
      } else {
        this.isMaxLimitReachedFirstName = false;
      }
    },
    setCustomerExpenseUiSettings() {
      let jsonSetting = null;
      let expenseUiSettings = null;
      if (this.allUiSettings != null && this.allUiSettings.length > 0) {
        let indx = this.allUiSettings.findIndex(
          (x) => x.SettingName.toLowerCase() == "customer expenses"
        );
        if (indx > -1) {
          jsonSetting = deepCopy(this.allUiSettings[indx].Data);
        }
      }

      if (jsonSetting != null && jsonSetting != "") {
        try {
          expenseUiSettings = JSON.parse(jsonSetting);

          if (expenseUiSettings && Array.isArray(expenseUiSettings)) {
            this.CustomerCopy.CustomerExpenses.forEach(
              (item) => (item["UiOrder"] = 9999)
            );
            let idList = expenseUiSettings.map((x) => x.Id);
            this.pinnedExpenseIds = deepCopy(idList);
            let matchedIds = [];

            for (let item of this.CustomerCopy.CustomerExpenses) {
              if (idList.includes(item.TypeId)) {
                item.UiOrder = expenseUiSettings.filter(
                  (x) => x.Id == item.TypeId
                )[0].UiOrder;
                matchedIds.push(item.TypeId);
              }
            }

            let expensesToPush = expenseUiSettings.filter(
              (x) => !matchedIds.includes(x.Id)
            );

            for (let expense of expensesToPush) {
              this.CustomerCopy.CustomerExpenses.push({
                TypeId: expense.Id,
                Amount: 0,
                UiOrder: expense.UiOrder,
              });
            }
          }
        } catch {
          //err
        }
      }
    },
    setCustomerTagsUiSettings() {
      let jsonSetting = null;
      let tagUiSettings = null;
      if (this.allUiSettings != null && this.allUiSettings.length > 0) {
        let indx = this.allUiSettings.findIndex(
          (x) => x.SettingName.toLowerCase() == "customer tags"
        );
        if (indx > -1) {
          jsonSetting = deepCopy(this.allUiSettings[indx].Data);
        }
      }

      if (jsonSetting != null && jsonSetting != "") {
        try {
          tagUiSettings = JSON.parse(jsonSetting);
          this.uiTags = deepCopy(tagUiSettings);
          if (tagUiSettings && Array.isArray(tagUiSettings)) {
            this.CustomerCopy.CustomerTags.forEach(
              (item) => (item["UiOrder"] = 9999)
            );
            let idList = tagUiSettings.map((x) => x.Id);
            this.pinnedTagIds = deepCopy(idList);
            let matchedIds = [];
            for (let item of this.CustomerCopy.CustomerTags) {
              if (idList.includes(item.TagTypeId)) {
                item.UiOrder = tagUiSettings.filter(
                  (x) => x.Id == item.TagTypeId
                )[0].UiOrder;
                matchedIds.push(item.TagTypeId);
              }
            }

            let tagsToPush = tagUiSettings.filter(
              (x) => !matchedIds.includes(x.Id)
            );
            let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            for (let tag of tagsToPush) {
              let indx = this.CustomerCopy.CustomerTags.findIndex(
                (x) => x.TagTypeId == tag.Id
              );
              if (indx == -1) {
                this.CustomerCopy.CustomerTags.push({
                  CustomerId: this.CustomerCopy.Id,
                  TagTypeId: tag.Id,
                  TagValue: null,
                  CreatedUserId: userInfo.userId,
                  TagCreatedDate: new Date(),
                  UiOrder: tag.UiOrder,
                });
              }
            }
          }
        } catch {
          //err
        }
      }
    },
    bankNameChange() {
      if (this.oldValue) {
        if (
          JSON.stringify(this.oldValue.Bank) !=
          JSON.stringify(this.CustomerCopy.Bank)
        ) {
          this.CustomerCopy.Bank.ValidationStatus = "Not Validated";
        }
      }
    },
    addressChange() {
      if (this.oldValue) {
        if (
          JSON.stringify(this.oldValue.CustomerProfile.Address) !=
          JSON.stringify(this.CustomerCopy.CustomerProfile.Address)
        ) {
          this.CustomerCopy.CustomerProfile.Address.ValidationStatus =
            "Not Validated";
        }
      }

      if (this.CustomerCopy.CustomerProfile.Address) {
        this.$emit("updatePASNumber");
      }
    },
    getTagTypeName(id) {
      let rtn = "";
      if (this.customerTagTypes.length > 0 && id > 0) {
        let index = this.customerTagTypes.findIndex((x) => x.Id == id);
        rtn = index > -1 ? this.customerTagTypes[index].TagName : "";
      }

      return rtn;
    },
    getTagDataType(id) {
      let rtn = "";
      if (this.customerTagTypes.length > 0 && id > 0) {
        let index = this.customerTagTypes.findIndex((x) => x.Id == id);
        rtn = index > -1 ? this.customerTagTypes[index].TagDataType : "";
      }

      return rtn;
    },
    getTagDataOptions(id) {
      let rtn = null;
      if (this.customerTagTypes.length > 0 && id > 0) {
        let index = this.customerTagTypes.findIndex((x) => x.Id == id);
        rtn = index > -1 ? this.customerTagTypes[index].TagDataOptions : null;
      }

      return rtn;
    },
    addTag() {
      if (this.selectedTagValue == null) {
        this.$swal(
          "Warning!",
          "Please enter a value to add this tag.",
          "warning"
        );
        return;
      }
      if (this.selectedTagType.Id) {
        let tagTypes = this.CustomerCopy.CustomerTags.filter(
          (x) => x.TagTypeId == this.selectedTagType.Id
        );

        if (tagTypes.length == 0) {
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          let tag = {
            CustomerId: this.CustomerCopy.Id,
            TagTypeId: this.selectedTagType.Id,
            TagValue: this.selectedTagValue,
            CreatedUserId: userInfo.userId,
            TagCreatedDate: new Date(),
          };
          this.tagsAdding = true;
          this.CustomerCopy.CustomerTags.push(tag);
          this.selectedTagType.Id = null;
          this.tagsAdding = false;
        } else {
          this.$swal(
            "Error!",
            "This tag type already exists in list!",
            "error"
          );
        }
      }
    },
    removeTag(typeId) {
      let indx = this.CustomerCopy.CustomerTags.findIndex(
        (x) => x.TagTypeId == typeId
      );
      if (indx > -1) this.CustomerCopy.CustomerTags.splice(indx, 1);
    },
    cancelTag() {
      this.selectedTagType.Id = null;
      this.selectedTagType.TagName = "";
      this.selectedTagType.TagDataType = null;
    },
    bindTagType(id) {
      this.selectedTagValue = null;
      let indx = this.customerTagTypes.findIndex((x) => x.Id == id);

      if (indx > -1) {
        this.selectedTagType = deepCopy(this.customerTagTypes[indx]);
        if (this.selectedTagType.TagDataType == "CheckItem") {
          this.selectedTagValue = true;
        }
      }
    },
    emptyTheObjectsOnUnmount() {
      this.customerReminders = null;
      this.customerDispositionLogs = null;
      this.systemLogs = null;
      this.dispositionBinded = null;
      this.reminderBinded = null;
      this.systemLogBinded = null;
      this.CustomerCopy = null;
      //this.v$ = null;
      this.date = null;
      this.discountListModified = null;
      this.selectedRow = null;
      this.userRoles = null;
      this.saving = null;
      this.subTabs = null;
      this.selectedScript = null;
      this.expenseType = null;
    },
    async getCustomerSystemLogs() {
      this.systemLogBinded = false;
      let err, result;
      [err, result] = await this.$store.dispatch(
        dispositionTypes.GET_DISPOSITIONLOGS,
        this.Customer.Id
      );
      if (result) {
        this.systemLogBinded = true;
        this.systemLogs = deepCopy(result.Data);
      }
    },
    async getCustomerReminders() {
      this.reminderBinded = false;
      let err, result;
      [err, result] = await this.$store.dispatch(
        notificationTypes.GET_CUSTOMER_REMINDERS_LOCAL,
        this.Customer.Id
      );
      if (result) {
        this.reminderBinded = true;
        this.customerReminders = deepCopy(result.Data);
      }
    },
    async getCustomerDispoLogs() {
      this.customerDispositionLogs = [];
      let err, result;
      this.dispositionBinded = false;

      [err, result] = await this.$store.dispatch(
        dispositionTypes.GET_CUSTOMERDISPOSITIONS,
        this.Customer.Id
      );

      if (result) {
        this.dispositionBinded = true;

        this.customerDispositionLogs = deepCopy(result.Data);
      }
    },
    updateDebtAmountInput(event) {
      if (event && event.target && event.target.value) {
        this.CustomerCopy.CustomerProfile.DebtAmount = event.target.value;
        this.$emit("updatePaymentCalculator");
      }
    },
    openHardShipNote() {
      this.isHardShip = !this.isHardShip;
    },

    async zipChanged(event) {
      if (event.target.value) {
        this.CustomerCopy.CustomerProfile.Address.Zip = event.target.value;
      }

      if (
        this.CustomerCopy.CustomerProfile.Address.Zip &&
        this.CustomerCopy.CustomerProfile.Address.Zip.length == 5
      ) {
        let result = await this.$store.dispatch(
          globalTypes.GET_ZIP_DATA,
          this.CustomerCopy.CustomerProfile.Address.Zip
        );

        if (result && result.Data && result.Data.length > 0) {
          if (result.Data[0].PrimaryCity) {
            this.CustomerCopy.CustomerProfile.Address.City =
              result.Data[0].PrimaryCity;
          }
          this.CustomerCopy.CustomerProfile.Address.StateId =
            result.Data[0].StateId;

          if (result.Data[0].TimeZone && result.Data[0].TimeZone.length > 0) {
            this.CustomerCopy.CustomerProfile.Address.TimeZoneId =
              result.Data[0].TimeZone[0][0].Id;
          }
        }
      }
    },
    updateEmails(value) {
      this.CustomerCopy.CustomerProfile.CustomerEmails = value;
    },
    updatePhones(value) {
      this.CustomerCopy.CustomerProfile.CustomerPhones = value;
    },
    ssnChanged(value) {
      this.CustomerCopy.CustomerProfile.SsnEncrypted = value;
    },
    async numberChanged(value) {
      let encryptedValue = await this.AES256_GCM_ENCRYPT(value);

      if (!this.CustomerCopy.SalesUserId || !this.CustomerCopy.OfficeId) {
        this.$swal(
          "Warning",
          this.CustomerCopy.Id
            ? "Agent and office must be assigned to verify account number."
            : "Please save the customer to validate banking information.",
          "warning"
        );
        this.CustomerCopy.Bank.AccountNumberEncrypted = encryptedValue;
        return;
      }
      if (this.CustomerCopy.Bank.AccountNumberEncrypted != encryptedValue) {
        if (
          this.Customer &&
          this.Customer.CustomerGlobalInformation &&
          this.Customer.CustomerGlobalInformation.IsDpg != true
        ) {
          let err, result;

          [err, result] = await this.$store.dispatch(
            types.VALIDATE_ACCOUNT_NUMBER,
            {
              CustomerId: this.CustomerCopy.Id,
              OfficeId: this.CustomerCopy.OfficeId,
              RoutingNumber: this.CustomerCopy.Bank.RoutingNumber,
              AccountNumber: value,
            }
          );

          if (err) {
            let errMsg = this.getApiErrorMessage(err);
            this.$swal("Error!", errMsg, "error");
            this.CustomerCopy.Bank.ValidationStatus = "Invalid Bank Account";
          } else {
            this.CustomerCopy.Bank.ValidationStatus = "Validated";
          }
        }
      }

      this.CustomerCopy.Bank.AccountNumberEncrypted = encryptedValue;
    },
    handleTabChange(tab) {
      let temp = [...this.subTabs];
      temp = temp.map((item) => {
        item.active = item.name === tab.name ? true : false;
        return item;
      });
      this.subTabs = [...temp];
    },
    async openScriptPopup() {
      let data = {
        ScriptList: this.filteredScripts,
        SelectedScript: this.selectedScript,
        CustomerId: this.CustomerCopy.Id,
        LanguageId: this.CustomerCopy.LanguageId,
      };
      this.$Emitter.emit("openScriptPopup", data);
    },
    isAuth(tabId) {
      let rtn = true;
      if (tabId == 4 && !this.checkAuth(2543)) rtn = false;
      if (tabId == 5 && this.filteredScripts.length == 0) rtn = false;

      return rtn;
    },
    findCommonElements(arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async checkRouteNumber() {
      if (this.CustomerCopy.Bank.RoutingNumber) {
        if (this.oldValue) {
          if (
            JSON.stringify(this.oldValue.Bank) !=
            JSON.stringify(this.CustomerCopy.Bank)
          ) {
            this.CustomerCopy.Bank.ValidationStatus = "Not Validated";
          }
        }

        if (!this.CustomerCopy.SalesUserId || !this.CustomerCopy.OfficeId) {
          this.$swal(
            "Warning",
            this.CustomerCopy.Id
              ? "Agent and office must be assigned to verify routing number."
              : "Please save the customer to validate banking information.",
            "warning"
          );
          return;
        } else if (
          this.Customer.Bank.RoutingNumber == null ||
          this.Customer.Bank.RoutingNumber.length != 9
        ) {
          this.$swal("Warning", "Routing number must be 9 digits.", "warning");
          return;
        } else {
          if (
            this.Customer &&
            this.Customer.CustomerGlobalInformation &&
            this.Customer.CustomerGlobalInformation.IsDpg != true
          ) {
            let payload = {
              CustomerId: this.CustomerCopy.Id,
              RoutingNumber: this.Customer.Bank.RoutingNumber,
              OfficeId: this.Customer.OfficeId,
            };

            let err, result;

            [err, result] = await this.$store.dispatch(
              globalTypes.CHECK_ROUTE_NUMBER,
              payload
            );

            if (result && result.Data) {
              this.CustomerCopy.Bank.Name = result.Data.Name;
            } else {
              let errMsg = this.getApiErrorMessage(err);
              this.$swal("Error!", errMsg, "error");
              this.CustomerCopy.Bank.Name = "";
            }
          }
        }
      } else {
        this.CustomerCopy.Bank.Name = "";
      }
    },
    addExpenseType() {
      let expenseName = "";
      if (this.expenseType.TypeId > 0) {
        let index = this.expenseTypeList.findIndex(
          (x) => x.Id == this.expenseType.TypeId
        );
        expenseName = index > -1 ? this.expenseTypeList[index].Name : "";
      }

      let exp = this.CustomerCopy.CustomerExpenses.filter(
        (x) => x.TypeId == this.expenseType.TypeId
      );
      if (exp.length == 0) {
        if (
          this.expenseType.TypeId > 0 &&
          expenseName != "" &&
          this.expenseType.Amount > 0
        ) {
          this.CustomerCopy.CustomerExpenses.push({
            TypeId: this.expenseType.TypeId,
            Amount: this.expenseType.Amount,
          });
          this.expenseType.Name = "";
          this.expenseType.Amount = 0;
          this.expenseType.TypeId = 0;
        }
      } else {
        this.$swal(
          "Error!",
          "This expense type already exists in list!",
          "error"
        );
      }
    },
    cancelExpenseType() {
      this.expenseType.TypeId = 0;
      this.expenseType.Name = "";
      this.expenseType.Amount = 0;
    },
    removeExpenseType(item) {
      let indx = this.CustomerCopy.CustomerExpenses.findIndex(
        (x) => x.TypeId == item.TypeId
      );
      if (indx >= 0) this.CustomerCopy.CustomerExpenses.splice(indx, 1);
    },

    getExpensesTotal() {
      let total = 0;

      this.CustomerCopy.CustomerExpenses.forEach((x) => {
        const amount = parseFloat(x.Amount);
        if (!isNaN(amount)) {
          total += amount;
        }
      });

      let expenseTags = this.CustomerCopy.CustomerTags.filter(
        (x) => this.getTagDataType(x.TagTypeId) == "Expense"
      );
      if (expenseTags) {
        expenseTags.forEach((x) => {
          const tagValue = parseFloat(x.TagValue);
          if (!isNaN(tagValue)) {
            total += tagValue;
          }
        });
      }

      return total;
    },
    getTotalIncomeAllSources() {
      let totalIncome =
        parseFloat(this.coCustomersIncome) +
        parseFloat(this.CustomerCopy.CustomerProfile.Income) +
        parseFloat(this.additionalIncome);

      return totalIncome;
    },
    getAvailableBudget() {
      let budget = 0;
      let totalIncome =
        parseFloat(this.coCustomersIncome) +
        parseFloat(this.CustomerCopy.CustomerProfile.Income) +
        parseFloat(this.additionalIncome);

      budget = parseFloat(totalIncome - this.getExpensesTotal());

      return budget;
    },
    getExpenseName(typeId) {
      let rtn = "";
      if (this.expenseTypeList.length > 0 && typeId > 0) {
        let index = this.expenseTypeList.findIndex((x) => x.Id == typeId);
        rtn = index > -1 ? this.expenseTypeList[index].Name : "";
      }

      return rtn;
    },
    getStateShort(stateId) {
      let stateIndex = this.states.findIndex((x) => x.Id == stateId);
      return stateIndex > -1 ? this.states[stateIndex].ShortCode : "";
    },
    getAddressVerificationResult(value) {
      if (value.Address) {
        this.CustomerCopy.CustomerProfile.Address.Line1 = value.Address;
      }
      if (value.Address2) {
        this.CustomerCopy.CustomerProfile.Address.Line2 = value.Address2;
      }
      if (value.City) {
        this.CustomerCopy.CustomerProfile.Address.City = value.City;
      }
      if (value.Zip) {
        this.CustomerCopy.CustomerProfile.Address.Zip = value.Zip;
      }

      if (value.StateShort) {
        let stateIndex = this.states.findIndex(
          (x) => x.ShortCode == value.StateShort
        );
        if (stateIndex > -1) {
          this.CustomerCopy.CustomerProfile.Address.StateId =
            this.states[stateIndex].Id;
        }
      }

      if (value.ErrorDescription && value.HasErrors == true) {
        this.CustomerCopy.CustomerProfile.Address.ValidationStatus =
          "Invalid Address";
      } else {
        this.CustomerCopy.CustomerProfile.Address.ValidationStatus =
          "Validated";
      }
    },
    residenceYearsChanged(event) {
      if (event.target.value) {
        this.CustomerCopy.CustomerProfile.ResidenceYears = event.target.value;
      }
    },
    yearFiledChanged(event) {
      if (event.target.value) {
        this.CustomerCopy.CustomerProfile.YearFiled = event.target.value;
      }
    },
    yearDischargedChanged(event) {
      if (event.target.value) {
        this.CustomerCopy.CustomerProfile.YearDischarged = event.target.value;
      }
    },
  },
  validations() {
    return {
      CustomerCopy: {
        CustomerProfile: {
          FirstName: { required },
          LastName: { required },
        },
      },
    };
  },
};
</script>
<style scoped>
.commandButtons {
  font-size: 24px;
  float: left;
  margin: 3px;
  font-weight: bold;
  cursor: pointer;
}

.closeColor {
  color: #1abc9c;
}
.activeColor {
  color: rgb(75, 75, 75);
}
.budgetOddRow {
  background-color: #e9e9e9;
}
</style>
