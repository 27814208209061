/**
 * @file EnvelopeAutovoidManagement/store.js
 * @description This file contains store for Envelope Autovoid Management.
 * @author Andrea Daza <acon_globant_adaza@ghtulsa.com>
 */

import to from "@/helpers/to";
import api from "@/services/admin/api";
import types from "./types";
export default {
  state: {
    envelopeSettingList: [],
  },
  actions: {
    GET_ENVELOPE_AUTOVOID: async ({ commit }) => {
      let err, result;

      [err, result] = await to(api.getVoidSettings());

      commit(types.SET_ENVELOPE_AUTOVOID, result ? result.Data : []);
    },

    ADD_ENVELOPE_AUTOVOID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.addVoidSettings(payload));

      return [err, result];
    },

    UPDATE_ENVELOPE_AUTOVOID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.updateVoidSettings(payload));

      return [err, result];
    },

    DELETE_ENVELOPE_AUTOVOID: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteVoidSettings(payload));

      return [err, result];
    },
  },
  mutations: {
    SET_ENVELOPE_AUTOVOID: (state, payload) => {
      state.envelopeSettingList = payload;
    },
  },
};
